import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../features/authentication"
import { useLogout } from "../../features/authentication"

import AuditorCompanySetupForm from "./AuditorCompanySetupForm"
import AddAuditingAccountToExistingAuditingCompanyForm from "./AddAuditingAccountToExistingAuditingCompanyForm"
import { Button } from "react-bootstrap"
import AddAuditingToExistingAccountForm from "./AddAuditingToExistingAccountForm"

export default function AuditorSetup() {
  //check if a user is signed in.
  //if user is signed in, have option to sign out and set up a new auditor account OR to register as an auditor with their current account
  //if a user is not signed in, let them know they can sign in and register their existing account or continu with a new auditor-only account
  const navigate = useNavigate()
  const { user, dispatch } = useAuthContext()
  const { logout } = useLogout()

  //controlling if new-setup or not
  const [signupNewUser, setSignupNewUser] = useState(false)
  const [signupExistingUser, setSignupExistingUser] = useState(false)
  const [signupNewCompany, setSignupNewCompany] = useState(false)

  //   if (user) console.log("is user", user)
  //   else console.log("is not user")

  if (user && user.isAuditor) {
    return (
      <div className="page-container container m-auto">
        <p className="error">
          The account that is logged in is already an auditing account.
        </p>
        <Button variant="secondary" onClick={() => navigate("/")}>
          Back
        </Button>
      </div>
    )
  }

  //present options for signing up depending if the user is an authenticated user
  if (!signupNewUser && !signupExistingUser) {
    return (
      <div className="page-container container m-auto">
        {/* options for signing up */}
        {!user && (
          <section>
            <p>
              Already have an account? <a href="/">Login</a> make the account an
              auditing account as well. Or choose an option below to create an
              account.
            </p>
            <Button
              variant="primary"
              className="mt-3 me-3"
              onClick={() => {
                setSignupNewUser(true)
                setSignupNewCompany(true)
              }}
            >
              New Auditing Company + Account
            </Button>
            <Button
              variant="primary"
              className="mt-3"
              onClick={() => {
                setSignupNewUser(true)
                setSignupNewCompany(false)
              }}
            >
              My Company Already Uses eLab Tracker
            </Button>
          </section>
        )}
        {user && (
          <section>
            <p>
              Click below to add auditing your current account. Or{" "}
              <span
                className="clickable text-muted text-decoration-underline"
                onClick={async () => await logout()}
              >
                log out
              </span>{" "}
              to create an all-new auditing account.
            </p>
            <Button
              variant="primary"
              className="mt-3 me-3"
              onClick={() => {
                setSignupExistingUser(true)
                setSignupNewUser(false)
              }}
            >
              Add auditing to my account
            </Button>
          </section>
        )}
      </div>
    )
  }

  //handle signup option chosen
  return (
    <div className="page-container container m-auto">
      <Button
        variant="secondary"
        onClick={() => {
          setSignupExistingUser(false)
          setSignupNewCompany(false)
          setSignupNewUser(false)
        }}
      >
        Cancel
      </Button>
      {/* new user with new auditing company */}
      {signupNewUser && signupNewCompany && !signupExistingUser && (
        <AuditorCompanySetupForm dispatch={dispatch} />
      )}

      {/* new user with existing auditing company */}
      {signupNewUser && !signupNewCompany && !signupExistingUser && (
        <AddAuditingAccountToExistingAuditingCompanyForm />
      )}

      {/* existing user but new auditing company i.e. they are part of a lab client company */}
      {signupExistingUser && <AddAuditingToExistingAccountForm user={user} />}
    </div>
  )
}
