import { useEffect, useState } from "react"

const useFilterEvaluations = (
  evaluationList,
  filterTerm,
  searchTerm,
  setSelectedData
) => {
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    let newList = []
    setSelectedData("")

    //filter
    if (filterTerm === "all") {
      newList = evaluationList
    } else {
      newList = evaluationList.filter((e) => e.category === filterTerm)
    }
    //search
    if (searchTerm) {
      newList = newList.filter((e) => {
        return (
          e.name
            .trim()
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          e.category
            .trim()
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          e.standard
            .trim()
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
        )
      })
    }

    setFilteredList(newList)
  }, [evaluationList, filterTerm, searchTerm])

  return filteredList
}

export { useFilterEvaluations }
