import React from "react"
import { useCompanyContext } from "../../../company"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function EvaluationType() {
  const { isAuditView } = useCompanyContext()

  if (isAuditView) return <AuditView />

  return <LabView />
}
