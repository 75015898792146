import { Timestamp } from "firebase/firestore"
import Select from "react-select"
import DateInput from "../../../../components/basicFormComponents/DateInput"

import formatSelectArray from "../../../../util/formatSelectArray"
import format from "date-fns/format"

//options for Selection of equipment status
const statusOptions = formatSelectArray([
  "in service",
  "out of service",
  "discarded",
])

export default function EquipmentStatusForm({
  newStatus,
  statusChangeDate,
  statusComment,
  minDate,
  updateFields,
}) {
  const updateStatusChangeDate = (newDate) =>
    updateFields({ statusChangeDate: newDate })

  return (
    <div>
      <DateInput
        label="date of status change*"
        required={true}
        value={statusChangeDate}
        setValue={updateStatusChangeDate}
        min={
          minDate instanceof Timestamp
            ? format(minDate.toDate(), "yyyy-MM-dd")
            : minDate
        }
        max={format(new Date(), "yyyy-MM-dd")}
      />

      <span>new status*: </span>
      <Select
        onChange={(option) => updateFields({ newStatus: option })}
        options={statusOptions}
        value={newStatus}
        required={true}
      />

      <label>
        <span>comment: </span>
        <input
          type="text-area"
          onChange={(e) => updateFields({ statusComment: e.target.value })}
          value={statusComment}
          className="form-control"
        />
      </label>
    </div>
  )
}
