import { useRef, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSignup } from "../index"
import { useAuthContext } from "../../authentication"

import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"

//components and hooks from index
import { useCompanyContext } from "../index"

export default function SignupUser() {
  //constant data
  const { user } = useAuthContext()
  const {
    usersOverMaxPermitted,
    moderatorsOverMaxPermitted,
    adminsOverMaxPermitted,
  } = useCompanyContext()
  const { addNewUser, error, isPending } = useSignup()
  const { companyId } = useParams()
  const navigate = useNavigate()
  const email = useRef()
  const password = useRef()
  const displayName = useRef()
  const [rights, setRights] = useState("")
  const [formError, setFormError] = useState("")

  const rightsOptions = formatSelectArray(["admin", "moderator", "user"])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    //check for values
    if (!email || !password || !rights || !displayName)
      return setFormError("you must fill out all fields")
    const userObject = {
      email: email.current.value,
      rights,
      displayName: displayName.current.value,
    }
    //check iif the user can be added with the selected rights based on max permitted allowances.
    if (
      (rights === "user" && usersOverMaxPermitted) ||
      (rights === "moderator" && moderatorsOverMaxPermitted) ||
      (rights === "admin" && adminsOverMaxPermitted)
    ) {
      return setFormError(
        `The maximum permitted number of employees with a ${rights} rights are already assigned. See your primary account manager to increase allowances`
      )
    }

    const uid = await addNewUser({
      newUser: userObject,
      password: password.current.value,
    })

    if (error) return setFormError(error)

    navigate(`/company/${companyId}/user/${uid}`)
  }

  return (
    <>
      {!user.admin && (
        <p className="error">
          You must have admin rights to sign up another user to the company
          roster
        </p>
      )}
      <div className="page-container container d-flex flex-wrap align-items-start justify-content-around gap-2 pl-2 pr-2 pt-3 pb-3">
        <form className="auth-form" onSubmit={handleSubmit}>
          <h2>Sign Up Employee</h2>
          <div className="form-group">
            <label>
              <span>email:</span>
              <input
                required
                type="email"
                placeholder="email@email.com"
                ref={email}
                className="form-control"
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              <span>password:</span>
              <input
                required
                type="password"
                placeholder=""
                ref={password}
                className="form-control"
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              <span>display name:</span>
              <input
                required
                type="text"
                placeholder="employee name"
                ref={displayName}
                className="form-control"
              />
            </label>
          </div>
          <div className="form-group mb-3">
            <span>user permissions:</span>
            <Select
              options={rightsOptions}
              onChange={(option) => setRights(option.value)}
            />
          </div>

          {!isPending && (
            <button type="submit" className="btn text-bg-primary">
              Sign up
            </button>
          )}
          {isPending && (
            <button disabled className="btn text-bg-primary">
              Loading...
            </button>
          )}
          {error && <div className="error">{error}</div>}
          {formError && <div className="error">{formError}</div>}
        </form>
      </div>
    </>
  )
}
