import { useState } from "react"
import { useFirestore } from "../../../hooks/useFirestore"
import { useStorage } from "../../../hooks/useStorage"
import { useEquipmentHistory } from "../index"

import { Timestamp } from "firebase/firestore"
import { getFullDate, isAfterSpecificMonth } from "../../../util/dateUtils"
import { addMonths, addSeconds, compareAsc } from "date-fns"

export function useChangeEquipment() {
  const [error, setError] = useState(null)
  const {
    setDocument,
    updateDocument,
    getDocument,
    isPending,
    error: firestoreError,
  } = useFirestore()

  const {
    uploadFile,
    isPending: uploadPending,
    error: uploadError,
  } = useStorage()

  const { getLastStatus, getLastCustody, isDiscarded, sortHistory } =
    useEquipmentHistory()

  const addEquipmentPiece = async ({
    selectedType,
    pieceID,
    serialNumber,
    make,
    model,
    acquisitionDate,
    companyId,
    user,
  }) => {
    setError(null)

    const defaultCustodyObject = selectedType.value.defaultCustody
      ? selectedType.value.defaultCustody
      : {
          displayName: `Default - (${user.displayName})`,
          uid: user.uid,
          rights: "default",
          userDisplayName: user.displayName,
        }

    //update firestore:
    let equipmentObject = {
      typePrefix: selectedType.value.prefix,
      id: pieceID,
      status: "unset",
      make: make,
      model: model,
      companyId,
      createdAt: Timestamp.fromDate(new Date()),
      acquisitionDate: "none",
      serialNumber: serialNumber,
      currentCustody: defaultCustodyObject,
      defaultCustody: defaultCustodyObject,
      history: [
        {
          id: Number(Math.floor(Math.random() * 100000000)),
          category: "equipment created",
          enteredAt: Timestamp.fromDate(new Date()),
          dateOfAction: Timestamp.fromDate(new Date()),
          loggedBy: {
            uid: user.uid,
            displayName: user.displayName,
          },
          performedBy: {
            uid: user.uid,
            displayName: user.displayName,
          },
          comment: "Equipment added to eLabTracker",
        },
        {
          id: Number(Math.floor(Math.random() * 100000000)),
          category: "acquisition",
          enteredAt: Timestamp.fromDate(new Date()),
          dateOfAction: Timestamp.fromDate(new Date(0)),
          loggedBy: {
            uid: user.uid,
            displayName: user.displayName,
          },
          performedBy: {
            uid: user.uid,
            displayName: user.displayName,
          },
          comment: "Equipment piece acquired",
        },
      ],
    }
    if (acquisitionDate) {
      //console.log(acquisitionDate, getFullDate(acquisitionDate))
      equipmentObject.acquisitionDate = Timestamp.fromDate(
        getFullDate(acquisitionDate)
      )
      equipmentObject.history[1].dateOfAction = Timestamp.fromDate(
        getFullDate(acquisitionDate)
      )
    }

    //create Equipment Doc in collection using setDocunment
    await setDocument(
      `companies/${companyId}/equipment/${pieceID}`,
      equipmentObject
    )

    const newEquipmentData = await getDocument(
      `companies/${companyId}/equipment/${pieceID}`
    )

    return newEquipmentData
  }

  //changed the status of a piece of equipment in the firestore adn retrieve the new document data
  const changeStatus = async ({
    companyId,
    user,
    equipment,
    statusChangeDate,
    newStatus,
    statusComment,
    performDefaultAction = true,
    performdefaultCustodyAction = true,
  }) => {
    setError(null)
    //check form entries
    if (!statusChangeDate) return { error: "date entry is required" }
    if (!newStatus) return { error: "new status is required" }

    //gets last status before the date entered
    const { lastStatus, isMostRecent: isMostRecentStatus } = getLastStatus(
      equipment.history,
      getFullDate(statusChangeDate)
    )
    //check if status change is allowed
    const previousStatus = lastStatus ? lastStatus.status : null
    let thisStatus = newStatus.value
    if (isDiscarded(equipment.history))
      return {
        error:
          "The current status is discarded. no equipment data can be changed once it has been discarded.",
      }
    if (
      previousStatus === "out of service" &&
      thisStatus === "in service" &&
      (typeof equipment.nextCalibration === "undefined" ||
        typeof equipment.lastCalibration === "undefined" ||
        isAfterSpecificMonth(
          equipment.nextCalibration.toDate(),
          getFullDate(statusChangeDate)
        ) ||
        getFullDate(statusChangeDate) < equipment.lastCalibration.toDate()) &&
      performDefaultAction
    )
      return {
        error:
          "This equipment cannot be set to in service while out of calibration or at a date before the last calibration.",
      }

    //get latest status change date to determine if status should update on equipemnt doc
    let changeStatus = true
    let allowDiscard = true
    let changeToDefaultCustody = false
    const updateObject = {}

    equipment.history.forEach((h) => {
      if (h.dateOfAction.toDate() > getFullDate(statusChangeDate))
        allowDiscard = false
      if (
        h.category === "status" &&
        h.dateOfAction.toDate() > getFullDate(statusChangeDate)
      ) {
        changeStatus = false
      }
    })
    //warn user about status change effects
    if (!allowDiscard && thisStatus === "discarded")
      return {
        error:
          "There are equipment updates after the entered date. Discard can only be the final equipment update.",
      }
    if (!changeStatus) thisStatus = equipment.status
    if (
      changeStatus &&
      performdefaultCustodyAction &&
      previousStatus === "in service" &&
      thisStatus === "out of service"
    ) {
      if (
        !window.confirm(
          "changing the status from in service to out of service will reset the next calibration date and move custody to the appropriate lab. Do you want to continue?"
        )
      )
        return { error: "the status change was cancelled" }

      updateObject["nextCalibration"] = Timestamp.fromDate(
        getFullDate(statusChangeDate)
      )
      changeToDefaultCustody = true
    }

    //get last custody for the history object
    const { lastCustody, isMostRecent: custodyIsMostRecent } = getLastCustody(
      equipment.history,
      getFullDate(statusChangeDate)
    )

    if (
      thisStatus === "out of service" &&
      !custodyIsMostRecent &&
      performDefaultAction
    )
      return {
        error: "status cannot be changed to out of service between custodies",
      }
    const custody = lastCustody ? lastCustody.custody : equipment.defaultCustody

    //update firestore:
    //create histopry object
    const historyObject = {
      id: Number(Math.floor(Math.random() * 100000000)),
      category: "status",
      enteredAt: Timestamp.fromDate(new Date()),
      dateOfAction: Timestamp.fromDate(getFullDate(statusChangeDate)),
      loggedBy: {
        uid: user.uid,
        displayName: user.displayName,
      },
      custody: changeToDefaultCustody ? equipment.defaultCustody : custody,
      comment: statusComment,
      status: performDefaultAction ? thisStatus : newStatus.value,
    }

    if (changeStatus && thisStatus === "discarded") {
      if (
        !window.confirm(
          "changing the status to discarded will disallow any future calibrations, status changes, custody changes, etc... to this piece of equipment. Are you sure you want to continue?"
        )
      )
        return { error: "the status change was cancelled" }

      updateObject["discardedAt"] = Timestamp.fromDate(
        getFullDate(statusChangeDate)
      )
      updateObject["nextCalibration"] = ""
      updateObject["currentCustody"] = {
        uid: "discarded",
        displayname: "discarded",
      }
      historyObject.category = "status - discarded"
      historyObject["custody"] = {
        uid: equipment.defaultCustody.uid,
        displayName: "discarded",
        rights: "discarded",
      }
    }

    updateObject.history = sortHistory(
      [historyObject, ...equipment.history],
      true
    )
    updateObject.status = thisStatus

    //update equipment document
    await updateDocument(
      `companies/${companyId}/equipment/${equipment.id}`,
      updateObject
    )

    const newEquipmentData = await getDocument(
      `companies/${companyId}/equipment/${equipment.id}`
    )

    //change custody to default if status went from in servcie to out of service
    if (changeToDefaultCustody) {
      await changeCustody({
        companyId,
        user,
        equipment: newEquipmentData,
        custodyChangeDate: statusChangeDate,
        changeCustodyTo: newEquipmentData.defaultCustody,
        custodyComment:
          "Automatically assigned to lab due to status changing to out of service",
      })
    }
    //changeCustody to discard if new status is discared
    if (allowDiscard && thisStatus === "discarded") {
      await changeCustody({
        companyId,
        user,
        equipment: newEquipmentData,
        custodyChangeDate: statusChangeDate,
        changeCustodyTo: {
          displayName: "discarded",
          uid: "discarded",
          rights: "discarded",
        },
        custodyComment:
          "Custody automatically set to discarded due to status changing to discarded",
        performDefaultAction: false,
      })
    }

    return newEquipmentData
  }

  //change the custody of a piece of equipment in the firestore.
  const changeCustody = async ({
    companyId,
    user,
    equipment,
    custodyChangeDate,
    changeCustodyTo,
    custodyComment,
    performDefaultAction = true,
  }) => {
    console.log(changeCustodyTo)
    //check form entries
    if (!custodyChangeDate) return { error: "date entry is required" }
    if (!changeCustodyTo)
      return { error: "new custody is required from the dropdown menu" }

    //check if custodyChange is allowed
    const { lastStatus, isMostRecent } = getLastStatus(
      equipment.history,
      getFullDate(custodyChangeDate)
    )
    if (
      lastStatus.status !== "in service" &&
      changeCustodyTo.uid !== equipment.defaultCustody.uid &&
      changeCustodyTo.uid !== "discarded" &&
      performDefaultAction
    ) {
      return {
        error:
          "custody can only be changed from the lab or default equipment manager if the equipment is in service at the time",
      }
    }

    //separate check if the calibration data is null
    if (
      performDefaultAction &&
      (!equipment.nextCalibration || !equipment.lastCalibration)
    )
      return {
        error:
          "The custody cannot be changed while the equipment is out of calibration or at a date before the last calibration.",
      }

    //check if calibration data is conducive to custody change
    if (
      performDefaultAction &&
      (typeof equipment.nextCalibration === "undefined" ||
        typeof equipment.lastCalibration === "undefined" ||
        isAfterSpecificMonth(
          equipment.nextCalibration.toDate(),
          getFullDate(custodyChangeDate)
        ) ||
        getFullDate(custodyChangeDate) < equipment.lastCalibration.toDate())
    )
      return {
        error:
          "The custody cannot be changed while the equipment is out of calibration or at a date before the last calibration.",
      }

    //update firestore:
    //create history object
    const historyObject = {
      id: Number(Math.floor(Math.random() * 100000000)),
      category: "custody",
      enteredAt: Timestamp.fromDate(new Date()),
      dateOfAction: Timestamp.fromDate(
        addSeconds(getFullDate(custodyChangeDate), -1)
      ),
      loggedBy: {
        uid: user.uid,
        displayName: user.displayName,
      },
      status: lastStatus.status,
      comment: custodyComment,
      custody: changeCustodyTo,
    }

    //get latest custody change date to determine if custody should change on equipment doc
    let currentCustody = changeCustodyTo
    const { isMostRecent: isMostRecentCustody } = getLastCustody(
      equipment.history,
      getFullDate(custodyChangeDate)
    )
    if (!isMostRecentCustody) currentCustody = equipment.currentCustody

    //update equipment document
    await updateDocument(`companies/${companyId}/equipment/${equipment.id}`, {
      history: sortHistory([historyObject, ...equipment.history], true),
      currentCustody: currentCustody,
    })

    const newEquipmentData = await getDocument(
      `companies/${companyId}/equipment/${equipment.id}`
    )

    return newEquipmentData
  }

  const calibrateEquipment = async ({
    companyId,
    user,
    equipment,
    type,
    calibrationDate,
    calibrationDocument,
    documentsOverMaxPermitted,
    calibratedBy,
    comment,
  }) => {
    //check if calibaration is allowed
    if (equipment.status === "discarded")
      return { error: "Cannot perform calibration on discarded equipment." }

    //check required fields
    if (!calibrationDocument)
      return {
        error:
          "A valid calibration document must be uploaded to calibrate the equipment",
      }
    if (documentsOverMaxPermitted)
      return {
        error:
          "Your company's account is over the maximum permitted amount of files saved, you must contact your primary account holder to increase the allowance.",
      }
    if (!calibrationDate)
      return { error: "You must enter a calibration date to continue" }
    if (!calibratedBy)
      return { error: "You must select the user that calibrated the equipment" }

    //get last custody and status
    const { lastStatus, isMostRecent: isMostRecentStatus } = getLastStatus(
      equipment.history,
      getFullDate(calibrationDate)
    )
    const { lastCustody, isMostRecent: isMostRecentCustody } = getLastCustody(
      equipment.history,
      getFullDate(calibrationDate)
    )

    //add document into firebase storage for calibration
    const uploadPath = `companies/${companyId}/equipment/${equipment.typePrefix}/${equipment.id}/${equipment.id}_${calibrationDate}`
    const metadata = {
      contentType: "application/pdf",
    }
    const calibrationURL = await uploadFile(
      uploadPath,
      calibrationDocument,
      metadata
    )

    //update firestore:
    //create history object
    const historyObject = {
      id: Number(Math.floor(Math.random() * 100000000)),
      category: "calibration",
      enteredAt: Timestamp.fromDate(new Date()),
      dateOfAction: Timestamp.fromDate(getFullDate(calibrationDate)),
      loggedBy: {
        uid: user.uid,
        displayName: user.displayName,
      },
      comment,
      performedBy: calibratedBy.value,
      calibrationURL,
      status: lastStatus.status,
      custody: lastCustody ? lastCustody.custody : equipment.defaultCustody,
    }

    //older calibrations should not effect next and last calibration dates
    let actualLastCalibrationDate = null
    if (equipment.lastCalibration) {
      actualLastCalibrationDate = equipment.lastCalibration.toDate()
      if (
        compareAsc(
          actualLastCalibrationDate,
          new Date(getFullDate(calibrationDate))
        ) !== 1
      ) {
        actualLastCalibrationDate = new Date(getFullDate(calibrationDate))
      }
    } else {
      actualLastCalibrationDate = new Date(getFullDate(calibrationDate))
    }
    //update equipment document
    await updateDocument(`companies/${companyId}/equipment/${equipment.id}`, {
      history: sortHistory([historyObject, ...equipment.history], true),
      lastCalibration: Timestamp.fromDate(actualLastCalibrationDate),
      nextCalibration: Timestamp.fromDate(
        addMonths(actualLastCalibrationDate, type.calibrationFrequency)
      ),
    })

    const newEquipmentData = await getDocument(
      `companies/${companyId}/equipment/${equipment.id}`
    )

    return newEquipmentData
  }

  const maintainEquipment = async ({
    companyId,
    user,
    equipment,
    maintenanceDate,
    maintainedBy,
    comment,
  }) => {
    if (equipment.status === "discarded")
      return { error: "Maintainence cannot be entered for discared equipment." }
    //check form entries
    if (!maintenanceDate) return { error: "date entry is required" }
    if (!maintainedBy) return { error: "must select who performed maintenance" }

    //get last status and custody
    const { lastStatus } = getLastStatus(
      equipment.history,
      getFullDate(maintenanceDate)
    )
    const { lastCustody } = getLastCustody(
      equipment.history,
      getFullDate(maintenanceDate)
    )

    //update firestore:
    const historyObject = {
      id: Number(Math.floor(Math.random() * 100000000)),
      category: "maintenance",
      enteredAt: Timestamp.fromDate(new Date()),
      dateOfAction: Timestamp.fromDate(new Date(getFullDate(maintenanceDate))),
      loggedBy: {
        uid: user.uid,
        displayName: user.displayName,
      },
      comment,
      performedBy: maintainedBy.value,
      custody: lastCustody ? lastCustody.custody : equipment.defaultCustody,
      status: lastStatus.status,
    }
    await updateDocument(`companies/${companyId}/equipment/${equipment.id}`, {
      history: [historyObject, ...equipment.history],
    })
  }

  const changeDefaultCustody = async ({
    companyId,
    user,
    equipment,
    uidToAssign,
    displayNameToAssign,
  }) => {
    if (!user.admin)
      return setError("Only admins can change the default custody.")

    //if the equipment's currentCustody is the default, also change the currentCustody to the new default.

    const updateObject = {
      defaultCustody: {
        displayName: `Default - (${displayNameToAssign})`,
        uid: uidToAssign,
        rights: "default",
        userDisplayName: displayNameToAssign,
      },
    }

    //if the equipment's currentCustody is the default, also change the currentCustody to the new default.
    if (
      equipment.currentCustody.rights === "lab" ||
      equipment.currentCustody.rights === "default"
    )
      updateObject.currentCustody = {
        displayName: `Default - (${displayNameToAssign})`,
        uid: uidToAssign,
        rights: "default",
        userDisplayName: displayNameToAssign,
      }

    await updateDocument(
      `companies/${companyId}/equipment/${equipment.id}`,
      updateObject
    )
  }

  return {
    addEquipmentPiece,
    changeStatus,
    changeCustody,
    calibrateEquipment,
    maintainEquipment,
    changeDefaultCustody,
    error,
    firestoreError,
    uploadError,
    isPending,
    uploadPending,
  }
}
