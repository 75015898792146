import { useState } from "react"
import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { useDocument } from "../../../hooks/useDocument"
import { Modal, Button } from "react-bootstrap"
import EquipmentCustodyForm from "./formComponents/EquipmentCustodyForm"
import { useChangeEquipment } from "../hooks/useChangeEquipment"
import { CheckboxInput } from "../../../components/basicFormComponents/"

const INITIAL_DATA = {
  custodyChangeDate: "",
  newCustody: null,
  custodyComment: "",
  performDefaultAction: true,
}

export default function ChangeCustodyModal({
  show,
  handleClose,
  equipment,
  type,
}) {
  const { user } = useAuthContext()
  const { companyData } = useCompanyContext()
  const { changeCustody, error, firestoreError, isPending } =
    useChangeEquipment()
  //form entry state
  const [formError, setFormError] = useState("")
  const [data, setData] = useState(INITIAL_DATA)

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleDefaultActionToggle = () => {
    updateFields({ performDefaultAction: !data.performDefaultAction })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.moderator)
      return setFormError(
        "must have moderator rights to transfer equipment between users"
      )

    const newEquipment = await changeCustody({
      companyId: companyData.companyId,
      user,
      equipment,
      custodyChangeDate: data.custodyChangeDate,
      changeCustodyTo: data.newCustody.value,
      custodyComment: data.custodyComment,
      performDefaultAction: data.performDefaultAction,
    })

    if (typeof newEquipment.error !== "undefined") {
      setFormError(newEquipment.error)
    } else if (firestoreError) {
      setFormError(firestoreError)
    } else {
      handleModalClose()
    }
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Transfer Custody of {equipment.type.name} {equipment.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to transfer equipment between users
            </p>
          )}

          <form className="" id="custody-form" onSubmit={handleSubmit}>
            <EquipmentCustodyForm
              {...data}
              minDate={
                typeof equipment.history.find(
                  (h) => h.category === "custody"
                ) === "undefined" || !data.performDefaultAction
                  ? equipment.acquisitionDate
                  : equipment.history.find((h) => h.category === "custody")
                      .dateOfAction
              }
              updateFields={updateFields}
              defaultCustody={equipment.defaultCustody}
              employeeList={companyData.employees}
            />

            {(error || formError) && (
              <CheckboxInput
                label="Ignore Logical Constraints"
                onChange={handleDefaultActionToggle}
                value={!data.performDefaultAction}
                horizontal={true}
              />
            )}

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
            {firestoreError && <div className="error">{firestoreError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="custody-form"
            disabled={isPending}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
