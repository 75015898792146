//returns equipmentType data for a matching prefix

import { useEffect, useState } from "react"

const useGetEquipmentType = (equipmentTypes, typePrefix) => {
  const [type, setType] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (equipmentTypes.length) {
      setError("This equipmentType is not found")
      equipmentTypes.forEach((t) => {
        if (t.prefix.toLowerCase().trim() === typePrefix.toLowerCase().trim()) {
          setType(t)
          setError("")
        }
      })
    }
  }, [equipmentTypes, typePrefix])

  return { type, error }
}

export { useGetEquipmentType }
