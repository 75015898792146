import { useState, useEffect } from "react"
import { useFirestore } from "./useFirestore"
import { functions } from "../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useStorage } from "./useStorage"
import { useLogin, useVerify } from "../features/authentication"
import { serverTimestamp, Timestamp } from "firebase/firestore"
import {
  addDays,
  addMonths,
  addYears,
  startOfDay,
  startOfMonth,
} from "date-fns"

export function useClientSetup() {
  const [error, setError] = useState("")
  const [isPending, setIsPending] = useState(false)
  const { login, error: loginError, isPending: loginPending } = useLogin()
  const {
    verifyByEmail,
    error: verificationError,
    isPending: verificationPending,
  } = useVerify()
  const changeClaims = httpsCallable(functions, "changeUserData-changeClaims")
  const signupNewUser = httpsCallable(functions, "signupUser-signupNewUser")
  const {
    error: firestoreError,
    isPending: firestorePending,
    setDocument,
    getDocument,
    updateDocument,
  } = useFirestore()
  const {
    error: storageError,
    isPending: stragePending,
    uploadDocument,
  } = useStorage()

  const resetError = () => {
    setError("")
  }

  //error and pending listeners defined below
  useDetermineErrors({ firestoreError, storageError, loginError, setError })
  useDeterminePending({
    firestorePending,
    stragePending,
    loginPending,
    setIsPending,
  })

  const companyNameIsAvailable = async (companyName) => {
    const nameToCheck = companyName.toLowerCase().trim().replace(" ", "-")
    const companies = await getDocument("public/companies")
    if (companies.list.length === 0) return true
    if (companies.list.some((company) => company.id === nameToCheck))
      return false
    return true
  }

  const createClientAccount = async ({
    displayName,
    email,
    password,
    companyId,
    companyName,
    agreedToUserTerms = false,
    signedNameForAgreementofTermsAndConditions = null,
  }) => {
    const userId = await signupNewUser({
      email,
      password,
      displayName,
      rights: "primary",
    })
    if (userId.data.error) {
      setIsPending(false)
      setError(userId.data.error)
      console.log(userId.data)
      return
    }
    //add tokens to user
    const claimChangeResponse = await changeClaims({
      initial: true,
      rights: "primary",
      companyId,
      uid: userId.data.response.uid,
      email,
      newClaimsObject: {
        company: companyId,
        primary: true,
        admin: true,
        moderator: true,
      },
    })

    //log that user in
    await login(email, password)

    //update firestore
    const systemMessage = `Welcome ${displayName}! You have jsut setup a company account. Until Payment is processed, your company account will be in Sandbox mode. Once the payment method is setup you will have the tier that you have selected.`
    const userObject = {
      displayName,
      email,
      companyId,
      companyName,
      rights: "primary",
      performedTutorial: false,
      uid: userId.data.response.uid,
      agreedToUserTerms,
      signedNameForAgreementofTermsAndConditions,
      signedUserTermsAndConditionsAt: Timestamp.fromDate(new Date()),
    }
    //add new userDocument
    await setDocument(`users/${userId.data.response.uid}`, userObject)
    //add messages subcollection
    await setDocument(`users/${userId.data.response.uid}/messages/system`, {
      messages: [
        {
          createdAt: Timestamp.fromDate(new Date()),
          incoming: true,
          content: systemMessage,
        },
      ],
      unread: true,
      from: { uid: "system", displayName: "System", rights: "system" },
    })

    await verifyByEmail()

    return { user: userId.data.response, email, password }
  }

  const createClientCompany = async ({
    name,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    companyId,
    primaryAccount,
    serviceLevelId,
    paymentPlan,
  }) => {
    const companies = await getDocument("public/companies")
    companies.list.push({
      name,
      steetAddress: address,
      streetAddress2: address2,
      state,
      city,
      zipCode: zip,
      companyPhone: phone,
      timezone: "EST",
      id: companyId,
      primaryAccount,
      subscription: serviceLevelId,
    })
    const pricingDoc = await getDocument("public/pricing")
    const serviceData = pricingDoc.tiers.filter(
      (tier) => tier.id === serviceLevelId
    )[0]

    await updateDocument("public/companies", { ...companies })
    //create the company document
    await setDocument(`companies/${companyId}`, {
      companyName: name,
      employees: [primaryAccount],
      createdAt: serverTimestamp(),
      state,
      city,
      companyId,
      streetAddress: address,
      streetAddress2: address2,
      zipCode: zip,
      companyPhone: phone,
      availablePositions: [],
      timezone: "EST",
      evaluationNotificationFrequency: "weekly",
      equipmentNotificationFrequency: "weekly",
      documentsOwned: Number(0),
      messaging: serviceData.messaging,
      additionalDocuments: serviceData.additionalDocs,
      maxPermittedEquipment: serviceData.equipmentIncluded,
      maxPermittedDocuments: serviceData.documentsIncluded,
      maxPermittedAdmins: serviceData.adminsIncluded,
      maxPermittedModerators: serviceData.moderatorsIncluded,
      maxPermittedUsers: serviceData.usersIncluded,
    })

    //create billing data subcollection
    await setDocument(`companies/${companyId}/billing/history`, {
      initialSignup: serverTimestamp(),
    })
    await setDocument(`companies/${companyId}/billing/billingInfo`, {
      primaryAccount,
      serviceLevelId: serviceLevelId,
      selectedServiceLevelId: serviceLevelId,
      paymentPlan,
      subscriptionStartDate: new Date(),
      nextSubscriptionInvoiceDate:
        serviceLevelId === "0"
          ? startOfDay(addDays(new Date(), 1))
          : paymentPlan === "monthly"
          ? startOfDay(addMonths(new Date(), 1))
          : startOfDay(addYears(new Date(), 1)),
      nextOverageInvoiceDate: startOfDay(
        startOfMonth(addMonths(new Date(), 1))
      ),
    })

    await setDocument(
      `companies/${companyId}/equipment/equipmentClassification`,
      {
        equipmentCategories: [],
        equipmentTypes: [],
      }
    )
    await setDocument(
      `companies/${companyId}/equipment/equipmentNotifications`,
      {
        updatedLast: [],
      }
    )

    //create evaluations subcollection
    await setDocument(
      `companies/${companyId}/evaluations/evaluationClassification`,
      { evaluationCategories: [] }
    )
    await setDocument(
      `companies/${companyId}/evaluations/evaluationNotifications`,
      {
        updatedLast: [],
      }
    )

    //create additionalDocuments subcollection
    await setDocument(
      `companies/${companyId}/additionalDocuments/documentClassification`,
      { folders: [] }
    )
    await setDocument(
      `companies/${companyId}/additionalDocuments/documentNotifications`,
      { updatedLast: new Date() }
    )
    await setDocument(
      `companies/${companyId}/additionalDocuments/deletedDocuments`,
      { updatedLast: new Date() }
    )
  }

  const updateBillingData = async ({ companyId, updateObject }) => {
    await updateDocument(`companies/${companyId}/billing/billingInfo`, {
      ...updateObject,
    })
  }

  return {
    error,
    isPending,
    resetError,
    companyNameIsAvailable,
    createClientAccount,
    createClientCompany,
    updateBillingData,
  }
}

//useEffects
const useDetermineErrors = ({
  firestoreError,
  storageError,
  loginError,
  setError,
}) => {
  let error = ""
  useEffect(() => {
    if (firestoreError) error = `Firestore error: ${firestoreError}`
    if (storageError) error = `${error}, Storage error: ${storageError}`
    if (loginError) error = `${error}, Signup error: ${loginError}}`

    if (error) setError(`ERROR: ${error}`)
  }, [firestoreError, storageError, loginError])
}

const useDeterminePending = ({
  firestorePending,
  stragePending,
  loginPending,
  setIsPending,
}) => {
  useEffect(() => {
    setIsPending(firestorePending || stragePending || loginPending)
  }, [firestorePending, stragePending, loginPending])
}
