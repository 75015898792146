import React from "react"
import { useCompanyContext } from "../../../company"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function EquipmentReportsPage() {
  const { isAuditView, auditId } = useCompanyContext()

  if (isAuditView) return <AuditView />
  return <LabView />
}
