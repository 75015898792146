import { BasicFormBody } from "../../../components/basicFormComponents"

export default function AddEmployeeForm({
  displayName,
  accountEmail,
  accountPassword,
  accountConfirmPassword,
  isAuditor,
  auditingAdmin,
  updateFields,
  children,
}) {
  return (
    <BasicFormBody
      formData={[
        {
          label: "Display Name",
          value: displayName,
          autocomplete: "none",
          required: true,
          onChange: (e) => {
            updateFields({ displayName: e.target.value })
          },
          horizontal: true,
        },
        {
          label: "Email",
          value: accountEmail,
          autocomplete: "email",
          type: "email",
          required: true,
          onChange: (e) => {
            updateFields({ accountEmail: e.target.value })
          },
          horizontal: true,
        },
        {
          label: "Password",
          type: "password",
          value: accountPassword,
          required: true,
          onChange: (e) => {
            updateFields({ accountPassword: e.target.value })
          },
          horizontal: true,
        },
        {
          label: "Confirm Password",
          type: "password",
          value: accountConfirmPassword,
          autoComplete: "",
          required: true,
          onChange: (e) => {
            updateFields({ accountConfirmPassword: e.target.value })
          },
          horizontal: true,
        },
        {
          label: "Auditor account?",
          type: "checkbox",
          value: isAuditor,
          onChange: (e) => {
            updateFields({ isAuditor: e.target.checked })
          },
          horizontal: true,
        },
        {
          label: "Admin account?",
          type: "checkbox",
          value: auditingAdmin,
          onChange: (e) => {
            updateFields({ auditingAdmin: e.target.checked })
          },
          horizontal: true,
        },
      ]}
    >
      {children}
    </BasicFormBody>
  )
}
