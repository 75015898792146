import { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext, useLogin } from '../index'



export default function Login({ placeholder }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { dispatch } = useAuthContext()
    placeholder = placeholder ? placeholder : false
    const navigate = useNavigate()

    const { isPending, error, login } = useLogin()

     const handleSubmit = async (e) => {
        e.preventDefault()
        await login(email, password)
        dispatch({ type: 'REFRESH' })
        setTimeout(() => { navigate("/company") }, 500)
        setEmail('')
        setPassword('')
    }

    return (
        <form className="auth-form d-block m-auto" onSubmit={handleSubmit}>
            <h2>Login</h2>
            <div className="form-group">
                <label>
                    {!placeholder && <span>email:</span>}
                    <input
                        required
                        type="email"
                        placeholder={placeholder ? "email" : ""}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="form-control"
                    />
                </label>
            </div>
            <div className="form-group">
                <label>
                    {!placeholder && <span>password:</span>}
                    <input
                        required
                        type="password"
                        placeholder={placeholder ? "password" : ""}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="form-control"
                    />
                </label>
            </div>

            {!isPending && <button type="submit" className="btn text-bg-primary">Login</button>}
            {isPending && <button disabled className="btn text-bg-primary">Loading...</button>}
            {error && <div className="error">{error}</div>}

            <p className="auth-options"><Link to="/forgot-password">Forgot password?</Link></p>
        </form>
    )
}
