import { useState, useEffect, useMemo } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext, useVerify } from "../../authentication/index"
import { useFirestore } from "../../../hooks/useFirestore"

import { useUpdateUser, RemoveUserRightsModal } from "../index"
import InfoIcon from "../../../components/basicComponents/InfoIcon"
import Select from "react-select"

import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"
import { Button } from "react-bootstrap"
import formatSelectArray from "../../../util/formatSelectArray"

export default function UserDetails() {
  const { uniqueId, companyId } = useParams()
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const {
    companyData,
    usersOverMaxPermitted,
    moderatorsOverMaxPermitted,
    adminsOverMaxPermitted,
  } = useCompanyContext()
  const { isPending, error, getDocument, updateDocument } = useFirestore()
  const { updateCompanyEmployee } = useUpdateUser()
  const changeClaims = httpsCallable(functions, "changeUserData-changeClaims")
  const {
    error: verificationError,
    isPending: verificationSendPending,
    verifyByEmail,
    checkEmailVerified,
  } = useVerify()

  const [displayData, setDisplayData] = useState(null)
  const [changingClaim, setChangingClaim] = useState(false)
  const [newClaim, setNewClaim] = useState("")
  const [newPosition, setNewPosition] = useState("")
  const [changingPosition, setChangingPosition] = useState("")

  //modal control
  const [showRemoveRightsModal, setShowRemoveRightsModal] = useState(false)
  const removeRightsModalShow = () => setShowRemoveRightsModal(true)
  const removeRightsModalHide = () => setShowRemoveRightsModal(false)

  const [pageError, setPageError] = useState("")

  let ownsPage = user.uid === uniqueId

  //determine if the user has the rights to change the rights, and what rights would be available to change to. custom hook defined below.
  const { allowChangeClaim, rightsOptions } = useDetermineRightsActions({
    currentUser: user,
    userOwnsPage: ownsPage,
    pageData: displayData,
  })

  const handleChangeClaim = async () => {
    // check if company user limits have been reached
    if (
      (newClaim.value.selected === "user" && usersOverMaxPermitted) ||
      (newClaim.value.selected === "moderator" && moderatorsOverMaxPermitted) ||
      (newClaim.value.selected === "admin" && adminsOverMaxPermitted)
    ) {
      return alert(
        `The maximum permitted number of employees with ${newClaim.value.selected} rights are already assigned. See your primary account manager to increase allowances`
      )
    }
    //call cloud function
    const claimsResponse = await changeClaims({
      email: displayData.email,
      uid: uniqueId,
      companyId: companyId,
      rights: newClaim.label,
      initial: false,
      newClaimsObject: {
        admin: newClaim.value.admin,
        moderator: newClaim.value.moderator,
        primary: newClaim.value.primary,
      },
      updateFirestoreUserdocObject: {
        ...newClaim.value,
        rights: newClaim.value.selected,
      },
    })

    //update employee list in company doc in Firestore if claim change was successful
    if (claimsResponse.error) return setPageError(claimsResponse.error)
    await updateCompanyEmployee({
      companyId,
      uid: uniqueId,
      companyEmployees: companyData.employees,
      changedProps: { rights: newClaim.label },
    })

    setChangingClaim(false)
    setNewClaim("")
  }

  const positionOptions = formatSelectArray(
    companyData.availablePositions.map((position) => position.title)
  )
  const handleChangePosition = async () => {
    await updateCompanyEmployee({
      companyId,
      uid: uniqueId,
      companyEmployees: companyData.employees,
      changedProps: { position: newPosition.value },
    })
    setChangingPosition(false)
    setNewPosition(null)
  }

  const handleVerifyEmail = async () => {
    const emailVerified = checkEmailVerified({
      account: displayData,
      companyId: companyData.companyId,
    })
    if (emailVerified)
      return window.alert(
        "This account's email has already been verificationSendPending."
      )

    verifyByEmail()
  }

  useEffect(() => {
    getDocument(`users/${uniqueId}`).then((response) => {
      const companyEmployeeData = companyData.employees.find(
        (employee) => employee.uid === uniqueId
      )
      setDisplayData({ ...companyEmployeeData, ...response })
    })
    //cleanup
    return setDisplayData(null)
  }, [uniqueId])

  if (!displayData || isPending) return <p className="loading">... Loading</p>

  return (
    <div className="page-container container">
      <div className="d-flex">
        <h2 className="page-title">User Details</h2>
        {user.primary && !ownsPage && (
          <Button
            className="ms-auto"
            variant="danger"
            onClick={() => removeRightsModalShow()}
          >
            Remove User's Access
          </Button>
        )}
      </div>
      <div className="d-flex flex-wrap">
        <p className="me-5">Hello, {displayData.displayName}</p>
        <p className="ms-5 me-5">Email: {displayData.email}</p>

        <div className="ms-5 d-flex">
          Rights: {displayData.rights}
          <InfoIcon infoTitle={`${displayData.rights}rights`} />
          {!changingClaim && allowChangeClaim && !ownsPage && (
            <p
              className="clickable text-muted text-decoration-underline ms-4"
              onClick={() => setChangingClaim(true)}
            >
              edit
            </p>
          )}
          {changingClaim && (
            <div className="ms-4">
              <Select
                options={rightsOptions}
                onChange={(option) => setNewClaim(option)}
              />
            </div>
          )}
          {changingClaim && (
            <button
              className="btn text-bg-primary btn-sm ms-2"
              onClick={handleChangeClaim}
            >
              Change
            </button>
          )}
          {changingClaim && (
            <p
              className="text-muted clickable text-decoration-underline ms-3"
              onClick={() => {
                setChangingClaim(false)
                setNewClaim("")
              }}
            >
              cancel
            </p>
          )}
        </div>
      </div>

      <div className="d-flex mt-3">
        <div className="ms-5 d-flex">
          Position: {displayData.position || "no position assigned"}
          <InfoIcon infoTitle={"position"} />
          {!changingPosition && allowChangeClaim && !ownsPage && (
            <p
              className="clickable text-muted text-decoration-underline ms-4"
              onClick={() => setChangingPosition(true)}
            >
              edit
            </p>
          )}
          {changingPosition && (
            <div className="ms-4">
              <Select
                options={positionOptions}
                onChange={(option) => setNewPosition(option)}
              />
            </div>
          )}
          {changingPosition && (
            <button
              className="btn text-bg-primary btn-sm ms-2"
              onClick={handleChangePosition}
            >
              Change
            </button>
          )}
          {changingPosition && (
            <p
              className="text-muted clickable text-decoration-underline ms-3"
              onClick={() => {
                setChangingPosition(false)
                setNewPosition("")
              }}
            >
              cancel
            </p>
          )}
        </div>
      </div>

      {/* email verification statement */}
      {!displayData.emailVerified && ownsPage && (
        <div className="mt-4">
          Email is Not Verified.
          <Button
            variant="primary"
            className="ms-4"
            onClick={handleVerifyEmail}
            disabled={verificationSendPending}
          >
            {" "}
            Send Verification Email
          </Button>
        </div>
      )}

      <hr />

      <div className="d-flex gap-4">
        <Link to="equipment" className="btn text-bg-primary">
          See User's Equipment
        </Link>

        <Link to="evaluations" className="btn text-bg-primary">
          See User's Evaluations
        </Link>
      </div>

      {/* Modals */}
      {showRemoveRightsModal && (
        <RemoveUserRightsModal
          show={removeRightsModalShow}
          handleClose={removeRightsModalHide}
          userToRemove={{ ...displayData, uid: uniqueId }}
        />
      )}
    </div>
  )
}

const useDetermineRightsActions = ({ currentUser, userOwnsPage, pageData }) => {
  const [allowChangeClaim, setAllowChangeClaim] = useState(false)
  const [rightsOptions, setRightsOptions] = useState([])

  useMemo(() => {
    if (pageData) {
      //allow user to change claims if they are an admin or primary that doesn't own the page, then determine which options they have
      if (currentUser.admin) {
        setAllowChangeClaim(
          !userOwnsPage &&
            pageData.rights !== "admin" &&
            pageData.rights !== "primary"
        )
        setRightsOptions([
          {
            label: "moderator",
            value: {
              selected: "moderator",
              moderator: true,
              admin: false,
              primary: false,
            },
          },
          {
            label: "user",
            value: {
              selected: "user",
              moderator: false,
              admin: false,
              primary: false,
            },
          },
        ])
      }
      if (currentUser.primary) {
        setAllowChangeClaim(!userOwnsPage)
        setRightsOptions([
          {
            label: "admin",
            value: {
              selected: "admin",
              moderator: true,
              admin: true,
              primary: false,
            },
          },
          {
            label: "moderator",
            value: {
              selected: "moderator",
              moderator: true,
              admin: false,
              primary: false,
            },
          },
          {
            label: "user",
            value: {
              selected: "user",
              moderator: false,
              admin: false,
              primary: false,
            },
          },
        ])
      }
    }
  }, [pageData])

  return { allowChangeClaim, rightsOptions }
}
