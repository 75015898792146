import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useAuthContext } from "../../authentication"
import { useMessaging, MessageForm } from "../index"


const INITIAL_DATA = {
    tag: null,
    content: ''
}

export default function MessageModal({ show, handleClose, companyName, sendTo, messageId }) {
  const { user } = useAuthContext()
  const [data, setData] = useState(INITIAL_DATA)
  const { formError, firestoreError, isPending, sendMessage } = useMessaging()

  function updateFields(fields) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    await sendMessage({
        to: sendTo,
        from: { displayName: user.displayName, uid: messageId },
        tag: data.tag.value,
        content: data.content
    })

    if(formError || firestoreError) return
    handleClose()
  }

  const handleModalClose = () => handleClose() 

  return (
    <div>
        <Modal show={show} onHide={handleModalClose}>
            <Modal.Header closeButton>
            <Modal.Title>Send Message to {sendTo.displayName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
            <form className="" id="message-form" onSubmit={(e)=>handleSubmit(e)}>
                <MessageForm {...data} updateFields={updateFields} />

                {firestoreError && <div className="error">{firestoreError}</div>}
                {formError && <div className="error">{formError}</div>}

            </form>

            </Modal.Body>
            <Modal.Footer>

            <Button 
                variant="secondary" 
                onClick={handleModalClose}
                disabled = {isPending}
            >
                Close
            </Button>
            <Button
                type="submit"
                form="message-form"
                variant="primary" 
                disabled = {isPending}
            >
                Send
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}
