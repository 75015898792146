import { useEffect, useState } from "react"

const useGetEmployeeEvaluationData = (companyData, evaluationData) => {
  const [usersEvaluationData, setUsersEvaluationData] = useState([])
  const [employeeOptions, setEmployeeOptions] = useState([])

  useEffect(() => {
    let usersData = []
    let allEmployees = []
    if (companyData && evaluationData) {
      companyData.employees.forEach((e) => {
        if (evaluationData[e.uid]) usersData.push(evaluationData[e.uid])
        else allEmployees.push({ label: e.displayName, value: e })
      })
    }
    setUsersEvaluationData(usersData)
    setEmployeeOptions(allEmployees)
  }, [companyData, evaluationData])

  return { usersEvaluationData, employeeOptions }
}

export { useGetEmployeeEvaluationData }
