import { useGetAudit } from "../../index"
import { useUserContext } from "../../../users"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../../authentication"

export default function AuditLandingPageLoader({ auditId, children }) {
  const { companyId } = useParams()
  const { userData } = useUserContext()
  const { error, isPending, getAuditDocument } = useGetAudit()
  //   const { user } = useAuthContext()
  //   console.log(user)

  const [auditData, setAuditData] = useState(null)
  const [viewInfo, setViewInfo] = useState(null)

  useEffect(() => {
    //only want to get auditData once there is userData so that data can be compared to identify what party the user is in (lab or auditor)
    if (userData) {
      getAuditDocument({ auditId }).then((res) => {
        if (error) return console.log(error)

        let isAuditorView =
          typeof res.auditorsPermitted.find(
            (auditor) => userData.uid === auditor.uid
          ) === "undefined"
            ? false
            : true

        let isPermittedView =
          typeof res.uidsPermitted.find((uid) => userData.uid === uid) ===
          "undefined"
            ? false
            : true

        const viewInfo = {
          isInternalAudit: res.auditingCompanyId === res.labCompanyId,
          isAuditorView,
          isPermittedView,
          isCompanyView: typeof companyId === "undefined" ? false : true,
        }

        setViewInfo(viewInfo)

        setAuditData(res)
      })
    }
  }, [userData])

  //display error or loading
  if (error)
    return (
      <div className="error">
        <p>
          The database could not find the data for the audit. Or, if it did, you
          do not have permission to view the data:
        </p>
        <p>{error}</p>
      </div>
    )

  if (isPending || !viewInfo) return <p>...loading</p>

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return (
            viewInfo &&
            auditData &&
            React.cloneElement(child, { viewInfo, auditData })
          )
        }
        return child
      })}
    </>
  )
}
