import { useState } from "react"
import { Modal, Button } from "react-bootstrap"
import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { useChangeEquipment, useEquipmentHistory } from "../index"
import EquipmentStatusForm from "./formComponents/EquipmentStatusForm"
import { CheckboxInput } from "../../../components/basicFormComponents/"

const INITIAL_DATA = {
  newStatus: "",
  statusChangeDate: "",
  statusComment: "",
  performDefaultAction: true,
  performdefaultCustodyAction: true,
}

export default function ChangeStatusModalModal({
  show,
  handleClose,
  equipment,
}) {
  const { user } = useAuthContext()
  const { companyData } = useCompanyContext()
  const { changeStatus, error, firestoreError, isPending } =
    useChangeEquipment()
  const { getLastStatus } = useEquipmentHistory()
  //form entry state
  const [formError, setFormError] = useState("")
  const [data, setData] = useState(INITIAL_DATA)

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleDefaultActionToggle = () => {
    updateFields({ performDefaultAction: !data.performDefaultAction })
  }
  const handleDefaultCustodyAction = () => {
    updateFields({
      performdefaultCustodyAction: !data.performdefaultCustodyAction,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.moderator)
      return setFormError(
        "must have moderator rights to change the status of equipment in the database"
      )

    //update the equipment document in firestore and get the data
    const newEquipment = await changeStatus({
      companyId: companyData.companyId,
      user,
      equipment,
      ...data,
    })

    if (typeof newEquipment.error !== "undefined") {
      setFormError(newEquipment.error)
    } else if (firestoreError) {
      setFormError(firestoreError)
    } else {
      handleModalClose()
    }
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Change Status of {equipment.type.name} {equipment.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to change the status of equipment
            </p>
          )}

          <form className="" id="status-form" onSubmit={handleSubmit}>
            <EquipmentStatusForm
              {...data}
              minDate={
                data.performDefaultAction &&
                typeof equipment.history.find(
                  (h) => h.category === "status"
                ) !== "undefined"
                  ? equipment.history.find((h) => h.category === "status")
                      .dateOfAction
                  : equipment.acquisitionDate
              }
              updateFields={updateFields}
            />
            {(formError || error) && (
              <CheckboxInput
                label="Ignore Logical Constraints"
                onChange={handleDefaultActionToggle}
                value={!data.performDefaultAction}
                horizontal={true}
              />
            )}
            {data.newStatus.value === "out of service" && (
              <CheckboxInput
                label="Keep Same Custody"
                onChange={handleDefaultCustodyAction}
                value={!data.performdefaultCustodyAction}
                horizontal={true}
              />
            )}

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
            {firestoreError && <div className="error">{firestoreError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending}
          >
            Close
          </Button>
          <Button
            type="submit"
            form="status-form"
            variant="primary"
            disabled={isPending}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
