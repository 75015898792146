import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { format } from "date-fns"
import { useFirestore } from "../../../../hooks/useFirestore"
import { useAuthContext } from "../../../authentication/index"
import {
  EquipmentHistoryLog,
  CalibrationModal,
  MaintenanceModal,
  ChangeStatusModal,
  ChangeCustodyModal,
  DeleteEquipmentModal,
  useEquipmentHistory,
  ChangeDefaultCustodyModal,
} from "../../index"
import InfoIcon from "../../../../components/basicComponents/InfoIcon"
import { getFullDate } from "../../../../util/dateUtils"
import { Timestamp } from "firebase/firestore"
import DateInput from "../../../../components/basicFormComponents/DateInput"
import { Button } from "react-bootstrap"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
import { EquipmentPieceReport } from "../../reports/index"

export default function LabView({ equipmentTypes, equipmentList }) {
  const navigate = useNavigate()
  const { equipmentId, companyId } = useParams()
  const { user } = useAuthContext()
  const equipment = equipmentList.find((eq) => eq.id === equipmentId)

  const { isPending, error, updateDocument } = useFirestore()

  const [changingAcquisitionDate, setChangingAcquisitionDate] = useState(false)
  const [changingMake, setChangingMake] = useState(false)
  const [changingModel, setChangingModel] = useState(false)
  const [changingSN, setChangingSN] = useState(false)

  //changing the data if it doesn't exist or if user is admin
  const [make, setMake] = useState(equipment.make)
  const [model, setModel] = useState(equipment.model)
  const [SN, setSN] = useState(equipment.serialNumber)
  const [acquisitionDate, setAcquisitionDate] = useState(
    equipment.acquisitionDate
  )

  //get type data
  const [type] = useState(() =>
    equipmentTypes.find((t) => t.prefix === equipment.typePrefix)
  )

  //update the equipment data that was not entered upon creation
  const saveChanges = async () => {
    let updateObject = { make, model, serialNumber: SN, acquisitionDate }
    if (acquisitionDate instanceof Timestamp) {
    } else if (acquisitionDate !== "none" && acquisitionDate !== "") {
      //change the history array's acquisition element
      let acquisitionElement = null
      const updatedHistoryArray = equipment.history.filter((h) => {
        if (h.category === "acquisition") acquisitionElement = h
        return h.category !== "acquisition"
      })
      acquisitionElement.dateOfAction = Timestamp.fromDate(
        getFullDate(acquisitionDate)
      )
      acquisitionElement.loggedBy = {
        displayName: user.displayName,
        uid: user.uid,
      }
      updatedHistoryArray.unshift(acquisitionElement)
      //get the full update object
      updateObject = {
        ...updateObject,
        acquisitionDate: Timestamp.fromDate(getFullDate(acquisitionDate)),
        history: updatedHistoryArray,
      }
    }

    await updateDocument(
      `companies/${companyId}/equipment/${equipmentId}`,
      updateObject
    )

    setChangingAcquisitionDate(false)
    setChangingModel(false)
    setChangingMake(false)
    setChangingSN(false)
  }

  //deleting a history log item
  const { deleteHistoryItem } = useEquipmentHistory()

  const deleteHistory = async (historyId) => {
    await deleteHistoryItem({
      isAdmin: user.admin,
      equipment,
      historyIdToDelete: historyId,
    })
  }

  //navigation control
  const handleNavigateToUser = (e, uid) => {
    if (e.button === 2 || e.button === 0) return // ignore left and right clicks
    if (e.button === 1)
      window.open(
        `/company/${companyId}/user/${equipment.currentCustody.uid}`,
        "_blank"
      )
  }

  //modal control
  const [showCalibrationModal, setShowCalibrationModal] = useState(false)
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false)
  const [showChangeCustodyModal, setShowChangeCustodyModal] = useState(false)
  const [showChangeDefaultCustodyModal, setShowChangeDefaultCustodyModal] =
    useState(false)
  const [showDeleteEquipmentModal, setShowDeleteEquipmentModal] =
    useState(false)

  const calibrationModalShow = () => setShowCalibrationModal(true)
  const calibrationModalHide = () => setShowCalibrationModal(false)
  const maintenanceModalShow = () => setShowMaintenanceModal(true)
  const maintenanceModalHide = () => setShowMaintenanceModal(false)
  const changeStatusModalShow = () => setShowChangeStatusModal(true)
  const changeStatusModalHide = () => setShowChangeStatusModal(false)
  const changeCustodyModalShow = () => setShowChangeCustodyModal(true)
  const changeCustodyModalHide = () => setShowChangeCustodyModal(false)
  const changeDefaultCustodyModalShow = () =>
    setShowChangeDefaultCustodyModal(true)
  const changeDefaultCustodyModalHide = () =>
    setShowChangeDefaultCustodyModal(false)
  const changeDeleteEquipmentModalShow = () => setShowDeleteEquipmentModal(true)
  const changeDeleteEquipmentModalHide = () =>
    setShowDeleteEquipmentModal(false)

  //Report Control
  const [displayReport, setDisplayReport] = useState(false)
  const [displayedReport, setDisplayedReport] = useState(null)

  return (
    <>
      <ContentOptionalRight
        content={
          <>
            <div className="d-flex justify-content-around flex-wrap">
              <h1 className="page-title">
                {equipment.type.name}:{" "}
                <span className="fw-bold">{equipment.id}</span>
              </h1>
              {user.admin && !equipment.deleted && (
                <Button
                  className="bg-secondary"
                  onClick={changeDeleteEquipmentModalShow}
                >
                  Delete Equipment Piece
                </Button>
              )}
            </div>
            <div className="object-data-format mt-3 p-3 container">
              <div className="row">
                <p className="object-data col col-md d-flex text-nowrap">
                  <span className="key-name">Type: </span>
                  <span className="value">{equipment.type.name}</span>
                </p>
                <p className="object-data col-md d-flex text-nowrap">
                  <span className="key-name">ID: </span>
                  <span className="value">{equipment.id}</span>
                </p>

                <div className="object-data col-md d-flex text-nowrap align-items-middle">
                  <span className="key-name">Acquired: </span>
                  {equipment.acquisitionDate !== "" &&
                    equipment.acquisitionDate !== "none" &&
                    !changingAcquisitionDate && (
                      <span className="value">
                        {format(
                          equipment.acquisitionDate.toDate(),
                          "MMM dd, yyyy"
                        )}
                      </span>
                    )}

                  <UpdateInfoButton
                    user={user}
                    isChanging={changingAcquisitionDate}
                    setIsChanging={setChangingAcquisitionDate}
                    submit={saveChanges}
                    isBlank={
                      equipment.acquisitionDate === "" ||
                      equipment.acquisitionDate === "none"
                    }
                  >
                    <DateInput
                      value={acquisitionDate}
                      setValue={setAcquisitionDate}
                      max={format(new Date(), "yyyy-MM-dd")}
                    />
                  </UpdateInfoButton>
                </div>
              </div>

              <div className="row">
                <div className="object-data col-md d-flex text-nowrap">
                  <span className="key-name">Make: </span>
                  {equipment.make && !changingMake && (
                    <span className="value">{equipment.make}</span>
                  )}
                  <UpdateInfoButton
                    user={user}
                    isChanging={changingMake}
                    setIsChanging={setChangingMake}
                    submit={saveChanges}
                    isBlank={!make}
                  >
                    <input
                      type="text"
                      onChange={(e) => setMake(e.target.value)}
                      value={make}
                    />
                  </UpdateInfoButton>
                </div>

                <div className="object-data col-md d-flex text-nowrap">
                  <span className="key-name">Model: </span>
                  {equipment.model && !changingModel && (
                    <span className="value">{equipment.model}</span>
                  )}
                  <UpdateInfoButton
                    user={user}
                    isChanging={changingModel}
                    setIsChanging={setChangingModel}
                    submit={saveChanges}
                    isBlank={!model}
                  >
                    <input
                      type="text"
                      onChange={(e) => setModel(e.target.value)}
                      value={model}
                    />
                  </UpdateInfoButton>
                </div>

                <div className="object-data col-md d-flex text-nowrap flex-grow-1">
                  <span className="key-name">SN: </span>
                  {equipment.serialNumber && !changingSN && (
                    <span className="value">{equipment.serialNumber}</span>
                  )}
                  <UpdateInfoButton
                    user={user}
                    isChanging={changingSN}
                    setIsChanging={setChangingSN}
                    submit={saveChanges}
                    isBlank={!equipment.serialNumber}
                  >
                    <input
                      type="text"
                      onChange={(e) => setSN(e.target.value)}
                      value={SN}
                    />
                  </UpdateInfoButton>
                </div>
              </div>
              <hr />

              {/* Calibration Info */}
              {(typeof type.calibrationRequired === "undefined" ||
                type.calibrationRequired) && (
                <div className="row">
                  <p className="object-data col">
                    <span className="key-name">Last Calibration: </span>
                    {equipment.lastCalibration && (
                      <span className="value">
                        {format(
                          equipment.lastCalibration.toDate(),
                          "MMM dd, yyyy"
                        )}
                      </span>
                    )}
                    {!equipment.lastCalibration && (
                      <span className="value text-muted">none</span>
                    )}
                  </p>
                  <p className="object-data col">
                    <span className="key-name">Next Calibration Due: </span>
                    {equipment.nextCalibration && (
                      <span className="value">
                        {format(
                          equipment.nextCalibration.toDate(),
                          "MMM, yyyy"
                        )}
                      </span>
                    )}
                    {!equipment.nextCalibration && (
                      <span className="value text-muted">N/A</span>
                    )}
                  </p>
                </div>
              )}

              {/* run a report on this equipment */}
              <div className="reports mt-4">
                <Button
                  onClick={(e) => {
                    setDisplayReport(true)
                    setDisplayedReport(
                      <EquipmentPieceReport
                        equipment={equipment}
                        date={new Date()}
                        handleModalClose={() => setDisplayReport(false)}
                        show={displayReport}
                      />
                    )
                  }}
                >
                  Get Equipment Info Report
                </Button>
              </div>

              <hr />
              {/* Editable Data */}
              <div className="row">
                <InfoIcon infoTitle="Editing Equipment Pieces" />
                {equipment.status !== "discarded" && (
                  <>
                    <div className="object-data editable d-flex pt-3">
                      <InfoIcon infoTitle="Equipment Custody Definition" />
                      <span className="key-name ms-3">Custody: </span>
                      <span
                        className="value clickable text-decoration-underline"
                        onMouseDown={(e) =>
                          handleNavigateToUser(e, equipment.currentCustody.uid)
                        }
                        onDoubleClick={() =>
                          navigate(
                            `/company/${companyId}/user/${equipment.currentCustody.uid}`
                          )
                        }
                      >
                        {equipment.currentCustody.displayName}
                      </span>
                      {user.moderator &&
                        (equipment.currentCustody.displayName !==
                          equipment.defaultCustody.displayName ||
                          equipment.status === "in service") && (
                          <span
                            className="text-muted clickable ms-4 text-decoration-underline"
                            onClick={() => changeCustodyModalShow()}
                          >
                            Change
                          </span>
                        )}
                    </div>

                    <div className="object-data editable d-flex pt-3">
                      <InfoIcon infoTitle="Equipment Default Custody Definition" />
                      <span className="key-name ms-3">Default Custody: </span>
                      <span
                        className="value clickable text-decoration-underline"
                        onMouseDown={(e) =>
                          handleNavigateToUser(e, equipment.defaultCustody.uid)
                        }
                        onDoubleClick={() =>
                          navigate(
                            `/company/${companyId}/user/${equipment.defaultCustody.uid}`
                          )
                        }
                      >
                        {equipment.defaultCustody.displayName}
                      </span>
                      {user.admin && (
                        <span
                          className="text-muted clickable ms-4 text-decoration-underline"
                          onClick={() => changeDefaultCustodyModalShow(true)}
                        >
                          Change
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div className="object-data editable d-flex pb-3 pt-3">
                  <InfoIcon infoTitle="Equipment Status Definition" />
                  <span className="key-name ms-3">Status: </span>
                  <span className="value">{equipment.status}</span>
                  {user.moderator && equipment.status !== "discarded" && (
                    <span
                      className="text-muted clickable ms-4 text-decoration-underline"
                      onClick={() => changeStatusModalShow()}
                    >
                      Change
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Buttons at bottom of section */}
            <div className="d-flex mt-3 buttons px-4">
              {user.moderator &&
                equipment.status !== "discarded" &&
                (typeof type.calibrationRequired === "undefined" ||
                  type.calibrationRequired) && (
                  <button
                    className="btn text-bg-primary"
                    onClick={() => calibrationModalShow()}
                  >
                    Calibrate
                  </button>
                )}

              {user.moderator && equipment.status !== "discarded" && (
                <button
                  className="btn text-bg-primary ms-3"
                  onClick={() => maintenanceModalShow()}
                >
                  Add Maintenance
                </button>
              )}

              <Link
                className="btn text-bg-primary ms-auto"
                to={`/company/${companyId}/company-equipment/types/${equipment.typePrefix}`}
              >
                Go to {type.name}
              </Link>
            </div>
            <hr />
            <EquipmentHistoryLog
              canDelete={user.admin}
              history={equipment.history}
              equipmentType={equipment.type.name}
              equipmentId={equipment.id}
              calibrationRequired={
                typeof type.calibrationRequired === "undefined" ||
                type.calibrationRequired
              }
              deleteHistory={deleteHistory}
            />

            {/* Modals */}
            {showCalibrationModal && (
              <CalibrationModal
                show={calibrationModalShow}
                handleClose={calibrationModalHide}
                equipment={equipment}
              />
            )}

            {showMaintenanceModal && (
              <MaintenanceModal
                show={maintenanceModalShow}
                handleClose={maintenanceModalHide}
                equipment={equipment}
              />
            )}

            {showChangeCustodyModal && (
              <ChangeCustodyModal
                show={changeCustodyModalShow}
                handleClose={changeCustodyModalHide}
                equipment={equipment}
                type={equipment.type}
              />
            )}

            {showChangeDefaultCustodyModal && (
              <ChangeDefaultCustodyModal
                show={changeDefaultCustodyModalShow}
                handleClose={changeDefaultCustodyModalHide}
                equipment={equipment}
              />
            )}

            {showChangeStatusModal && (
              <ChangeStatusModal
                show={changeStatusModalShow}
                handleClose={changeStatusModalHide}
                equipment={equipment}
              />
            )}

            {showDeleteEquipmentModal && (
              <DeleteEquipmentModal
                show={showDeleteEquipmentModal}
                handleClose={changeDeleteEquipmentModalHide}
                equipment={equipment}
              />
            )}

            {/* Reports */}

            <EquipmentPieceReport
              equipment={equipment}
              date={new Date()}
              handleModalClose={() => setDisplayReport(false)}
              show={displayReport}
            />
          </>
        }
      />
    </>
  )
}

const UpdateInfoButton = ({
  user,
  isBlank,
  isChanging,
  setIsChanging,
  submit,
  children,
}) => {
  if (isChanging) {
    return (
      <div className="d-flex text-nowrap align-items-center">
        {children}
        <span
          className="clickable text-decoration-underline fs-6 ms-2"
          onClick={submit}
          title="update"
        >
          {isBlank ? "add" : user.admin ? "add" : ""}
        </span>
        |
        <span
          className="clickable text-decoration-underline fs-6 ms-1"
          onClick={() => setIsChanging(false)}
          title="cancel"
        >
          X
        </span>
      </div>
    )
  } else {
    return (
      <span
        className="clickable text-decoration-underline fs-6 ms-2"
        onClick={() => setIsChanging(true)}
      >
        {isBlank ? "add" : user.admin ? "edit" : ""}
      </span>
    )
  }
}
