import { useState } from "react"

//components
import { Modal, Button } from "react-bootstrap"
import AddEmployeeForm from "./AddEmployeeForm"
import { useSignupAuditor } from "../hooks/useSignupAuditor"

const INITIAL_DATA = {
  displayName: "",
  accountEmail: "",
  accountPassword: "",
  accountConfirmPassword: "",
  isAuditor: false,
  auditingAdmin: false,
}

export default function AddEmployeeModal({ show, handleClose }) {
  //form entry state
  const [data, setData] = useState(INITIAL_DATA)
  const [formError, setFormError] = useState(null)

  const { addNewAuditor, error, isPending } = useSignupAuditor()

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    //check passwords match
    if (data.accountPassword !== data.accountConfirmPassword)
      return setFormError("Passwords do not match.")

    //add the account
    const {
      error: submitError,
      success,
      message,
    } = await addNewAuditor({
      newUser: {
        email: data.accountEmail,
        displayName: data.displayName,
        rights: data.auditingAdmin ? "admin" : "user",
        isAuditor: data.isAuditor,
        auditingAdmin: data.auditingAdmin,
      },
      password: data.accountPassword,
    })

    if (!success) return setFormError(submitError)

    return handleModalClose()
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add an Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="" id="add-employee-form" onSubmit={handleSubmit}>
            <AddEmployeeForm {...data} updateFields={updateFields} />
          </form>
          {formError && <p className="error">{formError}</p>}
          {error && <p className="error">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button
            type="submit"
            form="add-employee-form"
            variant="primary"
            disabled={isPending}
          >
            Add User
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
