import { useMemo, useState } from "react"
import { useCollection } from "../../../hooks/useCollection"
import { useFirestore } from "../../../hooks/useFirestore"
import { useCompanyContext } from "../../company"
import checkDuplicateInArray from "../../../util/checkDuplicateInArray"
import { determineOverdueStatus } from "../../../util/dateUtils"

export function useEvaluation() {
  const [error, setError] = useState(null)
  const { companyData, evaluationCategories } = useCompanyContext()
  const {
    updateDocument,
    getDocument,
    isPending,
    error: firestoreError,
  } = useFirestore()

  //get objects representing the evaluations for the user from the evaluation documents in the company's evaluations collection
  const useGetUserEvaluations = ({ companyId, uid }) => {
    const [evaluations, setEvaluations] = useState([])
    const { documents: companyEvalDocs } = useCollection(
      `companies/${companyId}/evaluations`
    )

    useMemo(() => {
      let newList = []
      if (companyEvalDocs) {
        newList = companyEvalDocs
          .filter((evalDoc) => !evalDoc.deleted && evalDoc[uid])
          .map((doc) => {
            let notification = false
            let evaluationStatus = null
            if (
              doc[uid].required &&
              (typeof doc[uid].currentEvaluation === "undefined" ||
                typeof doc[uid].currentEvaluation.nextEvaluation ===
                  "undefined")
            ) {
              notification = true
              evaluationStatus = "overdue"
            } else {
              evaluationStatus = determineOverdueStatus(
                doc[uid].currentEvaluation?.nextEvaluation?.toDate()
              )
              notification = evaluationStatus ? true : false
            }
            return {
              name: doc.name,
              category: doc.category,
              standard: doc.standard,
              notification,
              evaluationStatus,
              ...doc[uid],
            }
          })
      }
      setEvaluations(newList)
      //   console.log(newList)

      return () => (newList = [])
    }, [companyEvalDocs])

    return evaluations
  }

  //returns array of managers that have rights, uid, and displayName properties that are evaluated on the specific evaluation.
  const getEvaluatingManagers = async ({
    positions,
    employees,
    evaluationId,
    userId,
  }) => {
    const evaluation = await getDocument(
      `companies/${companyData.companyId}/evaluations/${evaluationId}`
    )

    let managerList = []

    // Check to see if the person can evaluate
    Object.keys(evaluation).forEach((key) => {
      if (typeof evaluation[key].currentEvaluation === "undefined") return
      let employee = employees.find((emp) => emp.uid === key)
      let companyPosition = positions.find(
        (pos) => pos.title === employee.position
      )
      if (companyPosition && companyPosition.canEvaluate && key !== userId) {
        managerList.push({
          displayName: evaluation[key].displayName,
          uid: evaluation[key].uid,
          position: employee.position,
        })
      }
    })

    return managerList
  }
  //toggle if a user on an evaluations user list is required to be evaluated
  const toggleRequireUserEvaluation = async ({
    companyId,
    evaluationId,
    userId,
    userEvalInfo,
  }) => {
    setError("")
    const updateObject = {
      [userId]: { ...userEvalInfo, required: !userEvalInfo.required },
    }

    await updateDocument(`companies/${companyId}/evaluations/${evaluationId}`, {
      ...updateObject,
    })

    if (firestoreError) setError(firestoreError)
  }

  const getUserFrequencyOfEvaluation = async ({
    companyId,
    evaluationId,
    userId,
  }) => {
    setError("")
    const evaluationInfo = await getDocument(
      `companies/${companyId}/evaluations/${evaluationId}`
    )

    if (firestoreError)
      return setError(
        `firestore error occured while getting users evaluation frequency: ${firestoreError}`
      )

    const usersEvaluationInfo = evaluationInfo[userId]
    return usersEvaluationInfo.frequencyOfEvaluation
  }

  const setUserFrequencyOfEvaluation = async ({
    companyId,
    evaluationId,
    userId,
    newFrequencyOfEvaluation,
  }) => {
    setError("")
    const evaluationInfo = await getDocument(
      `companies/${companyId}/evaluations/${evaluationId}`
    )

    if (firestoreError)
      return setError(
        `firestore error occured while getting users frequency of evaluation: ${firestoreError}`
      )

    const usersEvaluationInfo = evaluationInfo[userId]
    const updateObject = {
      [userId]: {
        ...usersEvaluationInfo,
        frequencyOfEvaluation: Number(newFrequencyOfEvaluation),
      },
    }

    await updateDocument(`companies/${companyId}/evaluations/${evaluationId}`, {
      ...updateObject,
    })

    if (firestoreError)
      return setError(
        `Firestore error when attempting to set new frequency of evaluation: ${firestoreError}`
      )

    return { message: "setting new frequency of evaluation was successful" }
  }

  const addEvaluationCategory = async ({ newCategory }) => {
    //make sure not duplicate
    const { exists } = checkDuplicateInArray(evaluationCategories, newCategory)

    if (exists) return setError("The category is already in use")

    //addCategory by updating the equipmentClassification doc
    if (evaluationCategories) {
      await updateDocument(
        `companies/${companyData.companyId}/evaluations/evaluationClassification`,
        {
          evaluationCategories: [...evaluationCategories, newCategory.trim()],
        }
      )
    } else {
      await updateDocument(
        `companies/${companyData.companyId}/evaluations/evaluationClassification`,
        {
          evaluationCategories: [newCategory.trim()],
        }
      )
    }
  }

  return {
    error,
    firestoreError,
    isPending,
    useGetUserEvaluations,
    getEvaluatingManagers,
    toggleRequireUserEvaluation,
    getUserFrequencyOfEvaluation,
    setUserFrequencyOfEvaluation,
    addEvaluationCategory,
  }
}
