import { useNavigate } from "react-router-dom"
import { useAuditingCompanyContext } from "../hooks/useAuditingCompanyContext"
import { useAuthContext } from "../../authentication"
import { useEffect, useState } from "react"
import sortObjects from "../../../util/sortObjects"
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../components/TableHeaderSortable"

export default function EmployeeList({ searchTerm }) {
  const navigate = useNavigate()
  const { auditingCompanyData } = useAuditingCompanyContext()
  const { user } = useAuthContext()

  //state for selecting an employee from the list
  const [selectedEmployee, setSelectedEmployee] = useState("")

  const handleEmployeeNavigation = (uid) =>
    navigate(
      `/auditing-company/${auditingCompanyData.companyId}/user-details/${uid}`
    )

  //data and display control
  const [sortBy, setSortBy] = useState("displayName")
  const [sortAsc, setSortAsc] = useState(true)
  const [filteredData, setFilteredData] = useState([])
  const changeSortBy = (newSortBy) => {
    if (sortBy === newSortBy && sortAsc) return setSortAsc(false)

    setSortBy(newSortBy)
    setSortAsc(true)
  }
  //filtering employeeList based on searchTerm passed
  useGetFilteredData({
    employeeList: auditingCompanyData.employees,
    searchTerm,
    setFilteredData,
    setSelectedEmployee,
    sortBy,
    sortAsc,
  })
  //table display control:
  const tableHeaderArray = [
    { sortable: true, title: "Name", changeSortByTo: "displayName" },
    { sortable: true, title: "Email", changeSortByTo: "email" },
    { sortable: false, title: "Is Auditor", changeSortByTo: "" },
    { sortable: false, title: "Is Admin", changeSortByTo: "" },
    // { sortable: false, title: "Needs Evaluation", changeSortByTo: "" },
  ]

  return (
    <div className="data-list">
      {filteredData.length > 0 && (
        <Table striped hover bordered className="data-list-table" size="md">
          <TableHeaderSortable
            thArray={tableHeaderArray}
            sortBy={sortBy}
            sortAsc={sortAsc}
            changeSortBy={changeSortBy}
            classes="text-center text-wrap"
          />
          <tbody className="text-left ps-2">
            {filteredData.map((e) => (
              <tr
                key={e.uid}
                onClick={() => setSelectedEmployee(e.uid)}
                onDoubleClick={() => handleEmployeeNavigation(e.uid)}
                className={
                  selectedEmployee === e.uid
                    ? "active text-center"
                    : "text-center"
                }
              >
                <td>{e.displayName}</td>
                <td>{e.email}</td>
                <td>{e.isAuditor ? "yes" : "no"}</td>
                <td>{e.auditingAdmin ? "yes" : "no"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )
}

const useGetFilteredData = ({
  employeeList,
  searchTerm,
  setFilteredData,
  setSelectedEmployee,
  sortBy,
  sortAsc,
}) => {
  useEffect(() => {
    let newList = []
    setSelectedEmployee("")

    newList = employeeList.filter((e) => {
      return (
        e.displayName
          .trim()
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase()) ||
        e.email.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
    })

    newList = sortObjects(newList, sortBy, sortAsc)

    setFilteredData(newList)
  }, [employeeList, searchTerm, sortBy, sortAsc])
}
