//compatible with firebase 9 and react
import { createContext, useReducer, useMemo, useEffect } from "react"
import { auth } from "../../../firebase/config"
import { onAuthStateChanged } from "firebase/auth"
import { useDocument } from "../../../hooks/useDocument"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload }
    case "LOGOUT":
      return { ...state, user: null }
    case "AUTH_IS_READY":
      return { user: action.payload, authIsReady: true }
    case "TOKEN_IS_READY":
      return { user: action.payload, tokenIsReady: true, authIsReady: true }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    tokenIsReady: false,
    refresh: false,
  })

  useMemo(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdTokenResult((token) => {
            console.log(token)
          })
          .then((tokenResult) => {
            if (
              typeof tokenResult.claims.company === "undefined" &&
              typeof tokenResult.claims.auditingCompanyId === "undefined"
            ) {
              dispatch({
                type: "AUTH_IS_READY",
                payload: {
                  ...user,
                },
              })
            } else {
              dispatch({
                type: "TOKEN_IS_READY",
                payload: {
                  ...user,
                  admin: tokenResult.claims.admin,
                  primary: tokenResult.claims.primary,
                  moderator: tokenResult.claims.moderator,
                  company: tokenResult.claims.company,
                  isAuditor: tokenResult.claims.isAuditor,
                  isConfirmedAuditor: tokenResult.claims.isConfirmedAuditor,
                  auditingAdmin: tokenResult.claims.auditingAdmin,
                  auditingCompanyId: tokenResult.claims.auditingCompanyId,
                },
              })
            }
          })
      } else {
        dispatch({ type: "AUTH_IS_READY", payload: user })
      }
      unsub()
    })
  }, [state.refresh])

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
