//compatible with firebase 9 and react
import { useState } from 'react'

//firebase imports
import { auth } from '../../../firebase/config'
import { sendPasswordResetEmail, updateProfile, updatePassword } from 'firebase/auth'

export const useUpdateAccount = () => {
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [message, setMessage] = useState('')

    const resetForgottenPassword = async (email) => {
        setError(null)
        setIsPending(true)

        try {
            await sendPasswordResetEmail(auth, email)
            setMessage('check your email for further instruction')
            setIsPending(false)
        } catch (err) {
            setError(`Could not reset password: ${err.message}`)
            setIsPending(false)
        }
    }

    const updateDisplayName = async (displayName) => {
        setError(null)
        setIsPending(false)
        setMessage('')

        try {
            await updateProfile(auth.currentUser, displayName)
            setMessage(`Your display name has been updated to "${displayName}"`)
            setIsPending(false)
        } catch (err) {
            setError(`could not update account: ${err.message}`)
            setIsPending(false)
        }
    }

    const resetPassword = async (newPassword, confirmPassword) => {
        setError(null)
        setIsPending(false)
        setMessage('')

        if(newPassword !== confirmPassword) {
            return setError('Passwords do not match')
        }

        try {
            await updatePassword(auth.currentUser, newPassword)
            setMessage(`Your password has been successfully updated`)
            setIsPending(false)
        } catch (err) {
            setError(`could not change password: ${err.message}`)
            setIsPending(false)
        }
    }

    return { error, isPending, message, resetForgottenPassword, updateDisplayName, resetPassword }

}