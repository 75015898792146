//styles
import styles from "./FlipCard.module.css"

export default function FlipCard({ front, back }) {
  return (
    <div className={styles["flip-card"]}>
      <div className={styles["flip-card-inner"]}>
        <div className={styles["flip-card-front"]}>{front}</div>
        <div className={styles["flip-card-back"]}>{back}</div>
      </div>
    </div>
  )
}
