import { Button } from "react-bootstrap"

//styles
import styles from "./MessageCard.module.css"

export default function MessageCard({
  message,
  handleGoToMessage,
  handleMarkUnread,
}) {
  return (
    <div
      className={
        message.messages[0].incoming
          ? `${styles["message-card"]} ${styles["recieved"]}`
          : `${styles["message-card"]} ${styles["sent"]}`
      }
    >
      <div className={styles["header-info"]}>
        <p className="font-weight-bold">From: {message.from.displayName}</p>
        <p className="">{message.messages[0].tag}</p>
        {message.messages[0].incoming && (
          <>
            {message.unread && <p className="ms-3">unread</p>}
            {!message.unread && (
              <>
                <p className="">read</p>
                <p
                  className="fs-6 clickable text-decoration-underline text-muted"
                  onClick={() =>
                    handleMarkUnread(message.from.uid, message.unread)
                  }
                >
                  mark unread
                </p>
              </>
            )}
          </>
        )}
        {!message.messages[0].incoming && (
          <>
            {message.lastMessageSeen && (
              <p className="text-muted fs-6">recieved</p>
            )}
            {!message.lastMessageSeen && (
              <p className="text-muted fs-6">not recieved</p>
            )}
          </>
        )}
      </div>

      <div className={styles["body-container"]}>
        <div className={styles.content}>
          {message.messages[0].content.substring(0, 100)}
          {message.messages[0].content.length > 100 && "..."}
        </div>
        <div className={styles.button}>
          <Button
            type="button"
            variant="primary"
            onMouseDown={(e) => handleGoToMessage(e, message, message.unread)}
          >
            Go to
          </Button>
        </div>
      </div>
    </div>
  )
}
