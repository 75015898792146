import { useContext } from "react"
import { AuditingCompanyContext } from "../context/AuditingCompanyContext"

export const useAuditingCompanyContext = () => {
  const context = useContext(AuditingCompanyContext)

  if (context === undefined) {
    throw new Error(
      "useAuditingCompanyContext() must be used inside an AuditingCompanyProvider"
    )
  }

  return context
}
