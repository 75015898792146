import { useRef, useState } from "react"
import { Modal, Button } from "react-bootstrap"

import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import InfoIcon from "../../../components/basicComponents/InfoIcon"

import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"

export default function RemoveUserRightsModal({
  show,
  handleClose,
  userToRemove,
}) {
  const { user } = useAuthContext()
  const [isPending, setIsPending] = useState(false)
  const { companyData } = useCompanyContext()
  const [formError, setFormError] = useState("")
  //form entry state
  const inputId = useRef()

  console.log(userToRemove)

  const removeRights = httpsCallable(
    functions,
    "changeUserData-removeUserRightsFromCompany"
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")

    if (!user.primary)
      return setFormError(
        "must have admin rights to add a maintainence log item to the database"
      )

    //check the validity of the input
    if (inputId.current.value !== userToRemove.uid) {
      return setFormError(
        "You must enter the user's Id exactly as it appears in the directions to validate the change."
      )
    }
    setIsPending(true)

    //call cloud function
    const removeRightsResponse = await removeRights({
      email: userToRemove.email,
      companyId: companyData.companyId,
      uid: userToRemove.uid,
      rights: userToRemove.rights,
    })

    if (removeRightsResponse.error) {
      setIsPending(false)
      console.log(removeRightsResponse.fullError)
      return setFormError(removeRightsResponse.error)
    }
    console.log(removeRightsResponse.message)
    setIsPending(false)

    handleClose()
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove {userToRemove.displayName}'s rights.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.admin && (
            <p className="error">
              Must be the primary account holder to remove rights from a user.
            </p>
          )}

          <form className="" onSubmit={handleSubmit}>
            <label>
              <div className="d-flex">
                <span className="me-2">
                  Validate by typing "{userToRemove.uid}"
                </span>
                <InfoIcon infoTitle="removingausersrights" />
              </div>
              <input
                required
                ref={inputId}
                type="text-area"
                className="form-control"
              />
            </label>

            {formError && <div className="error">{formError}</div>}
          </form>
          <p>
            Note, if you company also has an auditing account, auditing rights
            will need to be removed from the Auditor Dashboard separately.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isPending}>
            Execute
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
