//styles
import { useState } from "react"
import "./FAQ.css"

export default function FAQPage() {
  const [expandedItemId, setExpandedItemId] = useState(null)
  const faqs = [
    {
      question:
        "My laboratory’s documents are confidential. How do you handle security?",
      answer:
        "Your lab’s security is important to us. The files that you upload to eLab Tracker are not downloadable to anyone except accounts which are a part of your company. Also, the files you upload are yours, eLab Tracker does not share your files or data with other clients or any third party except for our hosting services which are handled by Google.",
      id: 0,
    },
    {
      question: "What if all of my lab data is lost?",
      answer:
        "Our Cloud data is backed up daily and the backups are not deleted for 90 days. Your data and files are recoverable during that time if anything were to happen.",
      id: 1,
    },
    {
      question: "If I unsubscribe, how would I get my data?",
      answer:
        "If you wish to unsubscribe, you will have to contact eLab tracker to get the files you were storing with us. Any other data that is not associated with a file, will however eventually be lost in it’s current form. Your data will be stored for a maximum of 120 days after subscription termination and you will have access to it to use and backup as you see necessary. eLab Tracker may be able to assist in the backup of this data in certain formats.",
      id: 2,
    },
    {
      question:
        "I have terminated/plan to terminate an employee. How can I make sure they don’t have access to eLab Tracker with malicious intent?",
      answer:
        "If the terminated employee has the “moderator” or “admin” role in your eLab Tracker, you can limit their access to read only (“user” role), by going to their user details page and changing their role (this can be done by any admin or primary account holder). After they are the “user” role, you can deny them access to any data by clicking the red “Remove Access” button and following instructions (this can ONLY be done by the Primary account holder). This will immediately remove their access. Access can always be re-granted by changing the role of that employee back to “user”, “moderator”, or “admin”.",
      id: 3,
    },
    {
      question:
        "Are the Basic, Advanced, and Mega subscriptions my only options?",
      answer:
        "No. If you feel the default subscription tiers do not fit your needs, please contact sales@elabTracker.com. We want to serve you and are willing to add a custom product to do so. Please let us discuss other options.",
      id: 4,
    },
    {
      question:
        "My company has several labs in many locations, do I have to make several accounts? Will any employees that move between labs also need second accounts?",
      answer:
        "Currently, eLab Tracker does not support multiple-lab clients as well as we would like. In our current state, new accounts would have to be made for each. Please contact sales@elabTracker.com to express your interest as this may speed up the development process for this new feature. However,  eLab Tracker can support “satellite” offices that are included in the parent lab’s accreditations, equipment schedules, procedures, etc…",
      id: 5,
    },
  ]

  const handleExpand = (id) => {
    if (id === expandedItemId) return setExpandedItemId(null)
    return setExpandedItemId(id)
  }

  return (
    <div className="page-container container m-auto">
      <section className="FAQs">
        <h1 className="fw-bold fs-2">Frequently Asked Questions</h1>
        <ul className="FAQ-list">
          {faqs.map((faq) => (
            <li
              className={
                expandedItemId === faq.id
                  ? "FAQ-list-item expanded"
                  : "FAQ-list-item"
              }
              key={faq.id}
            >
              <p
                className="FAQ-question clickable"
                onClick={() => handleExpand(faq.id)}
              >
                {faq.question}
              </p>
              <p className="FAQ-answer">{faq.answer}</p>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}
