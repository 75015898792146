import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useCompanyContext } from "../../index"
import { useServiceAgreement } from "../../hooks/useServiceAgreement"

import PriceCalculator from "../../../../components/PriceCalculator/PriceCalculator"
import { Button } from "react-bootstrap"
import Select from "react-select"
import InfoIcon from "../../../../components/basicComponents/InfoIcon"

export default function ServiceAgreement({ billingData, serviceData }) {
  const { companyData, equipmentList } = useCompanyContext()
  const {
    getQuantitiesUsed,
    error: serviceAgreementError,
    isPending: serviceAgreementPending,
  } = useServiceAgreement()

  //Price Calculator Control
  const [showCalculator, setShowCalculator] = useState(false)

  if (serviceAgreementPending || !serviceData)
    return (
      <p className="loading">
        ...Getting Service Agreement and quantities used
      </p>
    )

  if (serviceAgreementError) {
    return (
      <div className="error">
        {serviceAgreementError && (
          <p>Service Agreement retrieval error: {serviceAgreementError}</p>
        )}
      </div>
    )
  }

  return (
    <div>
      {/* ServiceAgreement limites and overages - uses calculator table that the PriceCalculatorUses */}
      <div>
        <PriceCalculator
          showForm={false}
          showSetup={false}
          companyData={companyData}
          equipmentList={equipmentList}
          paymentPlan={billingData.paymentPlan}
          serviceId={serviceData.id}
        />
      </div>
      <hr />

      {/* defined below */}
      <WarningAllowances companyData={companyData} serviceData={serviceData} />
      <ChangeServiceLevel
        plan={billingData.paymentPlan}
        serviceData={serviceData}
        primaryAccount={billingData.primaryAccount}
      />
      <hr />
      <div>
        <p>
          Compare tiers based on current usage using our{" "}
          <span
            onClick={() => setShowCalculator(true)}
            className="text-decoration-underline clickable"
            href="#calculator-data-form"
          >
            Price Calculator
          </span>
          {showCalculator && (
            <span
              onClick={() => setShowCalculator(false)}
              className="ms-3 text-muted clickable text-decoration-underline"
            >
              cancel
            </span>
          )}
        </p>

        {showCalculator && (
          <>
            <hr />
            <PriceCalculator
              showForm={true}
              showSetup={`${serviceData.id}` === "0"}
              companyData={companyData}
              equipmentList={equipmentList}
              payFrequency={billingData.paymentPlan}
              serviceId={serviceData.id}
            />
          </>
        )}
        {showCalculator && (
          <>
            <Button
              variant="secondary"
              onClick={() => setShowCalculator(false)}
              className="ms-3"
            >
              Close
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

const ChangeServiceLevel = ({ plan, serviceData, primaryAccount }) => {
  const { companyId } = useParams()
  const { changePaymentPlan, upgradeFromSandbox, error, isPending } =
    useServiceAgreement()
  //change serrvice level control
  const [formError, setFormError] = useState(null)
  const [changingServiceLevel, setChangingServiceLevel] = useState(false)
  const [newServiceLevel, setNewServiceLevel] = useState(null)
  const [changingPaymentPlan, setChangingPaymentPlan] = useState(false)
  const [newPlan, setNewPlan] = useState({ label: plan, value: plan })

  const serviceOptions = [
    { label: "basic", value: "1" },
    { label: "advanced", value: "2" },
    { label: "mega", value: "3" },
  ]
  const planOptions = [
    { label: "monthly", value: "monthly" },
    { label: "annual", value: "annual" },
  ]

  const handleChangePaymentPlan = async () => {
    await changePaymentPlan({
      companyId,
      paymentPlan: newPlan.value,
    })
    if (error) return
    setChangingPaymentPlan(false)
  }

  const handleChangeServiceLevel = async () => {
    setFormError(null)
    const upgradeRes = await upgradeFromSandbox({
      companyId,
      paymentPlan: newPlan.value,
      serviceLevelId: newServiceLevel.value,
      primaryAccount,
    })
    // console.log(upgradeRes)
    //check for errors
    if (error) return
    if (upgradeRes.error) return setFormError(upgradeRes.error)

    setChangingServiceLevel(false)
    window.alert("you have successfully updraged. Thank you!")
  }

  return (
    <div>
      <div className="d-flex mb-3 mt-3">
        {/* TODO: only show this if sandbox otherwise show instructions */}
        {!changingServiceLevel && `${serviceData.id}` === "0" && (
          <div className="d-flex me-4">
            <Button
              onClick={() => {
                setChangingPaymentPlan(false)
                setChangingServiceLevel(true)
                setNewPlan({ label: plan, value: plan })
              }}
              disabled={changingPaymentPlan || isPending}
            >
              Upgrade from Sandbox
            </Button>
            <InfoIcon infoTitle="Upgrade From Sandbox" />
          </div>
        )}

        {changingServiceLevel && (
          <div className="d-flex align-items-baseline">
            <Select
              options={serviceOptions}
              value={newServiceLevel}
              onChange={(option) => setNewServiceLevel(option)}
              className="me-4"
            />
            <Button onClick={handleChangeServiceLevel} disabled={isPending}>
              Save This
            </Button>
            <p
              onClick={() => setChangingServiceLevel(false)}
              className="text-muted text-decoration-underline clickable ms-3"
            >
              cancel
            </p>
            <InfoIcon infoTitle="Changing Payment Plans" />
          </div>
        )}

        {!changingPaymentPlan && (
          <div className="d-flex ms-4">
            <Button
              onClick={() => {
                setChangingPaymentPlan(true)
                setChangingServiceLevel(false)
                setNewServiceLevel(null)
              }}
              disabled={changingServiceLevel || isPending}
            >
              Change Payment Plan
            </Button>
            <InfoIcon infoTitle="Changing Payment Plans" />
          </div>
        )}
        {changingPaymentPlan && (
          <div className="d-flex align-items-baseline">
            <Select
              options={planOptions}
              value={newPlan}
              onChange={(option) => setNewPlan(option)}
              className="me-4"
            />
            <Button onClick={handleChangePaymentPlan} disabled={isPending}>
              Save This
            </Button>
            <p
              onClick={() => setChangingPaymentPlan(false)}
              className="text-muted text-decoration-underline clickable ms-3"
            >
              cancel
            </p>
            <InfoIcon infoTitle="Changing Payment Plans" />
          </div>
        )}
      </div>
      {error && <p className="error">{error}</p>}
      {formError && <p className="error">{formError}</p>}
      {serviceData.id != 0 && (
        <p className="mb-3">
          To change service level, please contact sales@elabtracker.com
        </p>
      )}
    </div>
  )
}

const WarningAllowances = ({ companyData, serviceData }) => {
  const {
    incrementEquipmentAllowance,
    incrementDocumentAllowance,
    incrementUserAllowance,
    incrementModeratorAllowance,
    incrementAdminAllowance,
    error,
    isPending,
  } = useServiceAgreement()
  return (
    <div className="mt-4 mb-4 user-select-none text-center">
      <div className="d-flex justify-content-center">
        <h4 className="me-2">Set Overage Allowances</h4>
        <InfoIcon infoTitle="Setting Overage Allowances" />
      </div>
      <div className="d-flex text-center justify-content-evenly flex-wrap">
        <Incrementor
          incrementFunction={incrementEquipmentAllowance}
          increment={serviceData.additionalEquipmentFee.lotSize}
          title="Equipment Pieces"
          currentValue={companyData.maxPermittedEquipment}
          minValue={serviceData.equipmentIncluded}
          companyId={companyData.companyId}
        />

        <Incrementor
          incrementFunction={incrementDocumentAllowance}
          increment={serviceData.additionalDocumentFee.lotSize}
          title="Documents / Files"
          currentValue={companyData.maxPermittedDocuments}
          minValue={serviceData.documentsIncluded}
          companyId={companyData.companyId}
        />
      </div>

      <div className="d-flex text-center justify-content-evenly flex-wrap">
        <Incrementor
          incrementFunction={incrementAdminAllowance}
          increment={serviceData.additionalAdminFee.lotSize}
          title="Admins"
          currentValue={companyData.maxPermittedAdmins}
          minValue={serviceData.adminsIncluded}
          companyId={companyData.companyId}
        />

        <Incrementor
          incrementFunction={incrementModeratorAllowance}
          increment={serviceData.additionalModeratorFee.lotSize}
          title="Moderators"
          currentValue={companyData.maxPermittedModerators}
          minValue={serviceData.moderatorsIncluded}
          companyId={companyData.companyId}
        />

        <Incrementor
          incrementFunction={incrementUserAllowance}
          increment={serviceData.additionalUserFee.lotSize}
          title="Users"
          currentValue={companyData.maxPermittedUsers}
          minValue={serviceData.usersIncluded}
          companyId={companyData.companyId}
        />
      </div>
    </div>
  )
}

const Incrementor = ({
  incrementFunction,
  increment,
  title,
  currentValue,
  minValue,
  companyId,
}) => {
  return (
    <div className="mt-4">
      <p>{title}</p>
      {currentValue > minValue && (
        <span
          onClick={() =>
            incrementFunction({
              companyId,
              newValue: currentValue - increment,
            })
          }
          className="fs-3 ms-2 me-3 text-muted clickable"
        >
          -
        </span>
      )}
      <span className="p-2 pe-4 ps-4 bg-white rounded-2">{currentValue}</span>
      <span
        onClick={() =>
          incrementFunction({
            companyId,
            newValue: currentValue + increment,
          })
        }
        className="fs-3 ms-3 me-2 text-muted clickable user-select-none"
      >
        +
      </span>
    </div>
  )
}
