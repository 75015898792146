//returns an array of objects in the form of {label: , value: } to use in the options prop of a Select Component
//take an array of anything 
//takes the name of the property to display as a label for a Select Component (this should be a string)
//takes the name(s) of properties to have as a value (this should be an object)
//if the array is of just strings no need to pass the labelProp or valueProp
export default function formatSelectArray(arrayToFormat, labelProp, valueProp) {
    let optionsArray = arrayToFormat.map((option) => {
        //if option is a string, then label and value will both just be the string
        if(typeof option == 'string') return {label: option, value: option}

        //if more complocated than strings:
        let label = null
        let value = null

        if (typeof labelProp == 'string') label = option[labelProp]
        else {
            console.log('code ERROR: the labelProp is not a string. formatSelectArray requires the labelProp to be a string.')
        }

        if (valueProp instanceof String) value = option[valueProp]
        else if (valueProp instanceof Object) {
            value = {}
            //iterate through object to get the intended properties
            Object.keys(valueProp).forEach((key) => {
                value[key] = option[key]
            })
        } else {
            console.log("codeError: formatSelect Array did not recognize the data type of the valueProp that was passed")
        }

        return { label, value }
    })

    return optionsArray
}