import { useState } from "react"
import Select from "react-select"
import formatSelectArray from "../../../../util/formatSelectArray"
import DateInput from "../../../../components/basicFormComponents/DateInput"
import { verifyPDF } from "../../../../util/verifyFileInput"
import { format } from "date-fns"

export default function EvaluateUserForm({
  evaluationDate,
  nextEvaluationDate,
  evaluatedBy,
  comment,
  updateFields,
  managerOptions,
  setFormError,
}) {
  const [tempUser, setTempUser] = useState(null)

  const updateEvaluationDate = (newDate) => {
    updateFields({ evaluationDate: newDate })
  }

  const updateNextEvaluationDate = (newDate) => {
    updateFields({ nextEvaluationDate: newDate })
  }

  //format managers to be used by react-select
  const managers = formatSelectArray(
    [
      ...managerOptions,
      { displayName: `by others`, uid: "none", role: "third party" },
    ],
    "displayName",
    { ...managerOptions[0] }
  )

  const handleEvaluatedBy = (option) => {
    setTempUser(null)
    // console.log(option, managerOptions)
    if (option.label !== "by others")
      return updateFields({ evaluatedBy: option })
    const tempUser = {
      value: { displayName: option.label, uid: "none", role: "third party" },
      label: "by others",
    }

    return setTempUser(tempUser)
  }

  //check validity of selected file in form
  const handleFileChange = (e) => {
    updateFields({ evaluationDocument: null })
    setFormError("")
    const { pdf: selected, error: fileError } = verifyPDF(e.target.files[0])

    if (fileError) return setFormError(fileError)
    updateFields({ evaluationDocument: selected })
  }

  return (
    <div>
      <label>evaluated by:</label>
      <Select
        onChange={(option) => handleEvaluatedBy(option)}
        options={managers}
        value={tempUser ? tempUser : evaluatedBy}
      />

      {tempUser && (
        <label>
          <span>evaluating party: </span>
          <input
            type="text"
            onChange={(e) =>
              updateFields({
                evaluatedBy: {
                  value: { ...tempUser.value, displayName: e.target.value },
                  label: e.target.value,
                },
              })
            }
            className="form-control"
          />
        </label>
      )}

      <DateInput
        label="evaluation date*"
        required
        max={format(new Date(), "yyyy-MM-dd")}
        value={evaluationDate}
        setValue={updateEvaluationDate}
      />

      <label>
        <span>evaluation Doc (optional) - pdf only: </span>
        <input
          type="file"
          onChange={(e) => handleFileChange(e)}
          className="form-control"
        />
      </label>

      <label>
        <span>comment: </span>
        <input
          type="text-area"
          onChange={(e) => updateFields({ comment: e.target.value })}
          value={comment}
          className="form-control"
        />
      </label>

      <DateInput
        label="Schedule next evaluation*"
        required
        min={format(new Date(), "yyyy-MM-dd")}
        value={nextEvaluationDate}
        setValue={updateNextEvaluationDate}
      />
    </div>
  )
}
