import React from "react"
import { Button } from "react-bootstrap"
import { useAuditStatus } from "../hooks/useAuditStatus"

const AuditStatusLabControls = ({
  auditStatus,
  auditId,
  handleNavigateToAudit,
}) => {
  const {
    error,
    isPending,
    acceptPendingAudit,
    rejectPendingAudit,
    changeAuditStatusToOnHold,
  } = useAuditStatus()

  const handleAccept = async () => {
    const { success, errorMessage, message } = await acceptPendingAudit({
      auditId: auditId,
    })
    if (!success)
      return alert(
        `Acceptance not successful. eLab Tracker says: ${errorMessage}`
      )

    alert(
      "You have accepted the Audit, the auditing company now has access to view equipment, evaluations, and documents stored in your eLab Tracker account until the audit is marked as complete."
    )
    return handleNavigateToAudit()
  }

  const handleReject = async () => {
    const { success, errorMessage, message } = rejectPendingAudit({
      auditId: auditId,
    })
    if (!success)
      return alert(
        `Rejection not successful. eLab Tracker says: ${errorMessage}`
      )

    alert(
      "You have rejected the Audit request, the auditing company will have no access to your eLab Tracker data whatsoever. They must open a new audit request, this request is no longer valid and cannot be restored."
    )
    return handleNavigateToAudit()
  }

  const handlePlaceOnHold = async () => {
    const { success, errorMessage, message } = await changeAuditStatusToOnHold({
      auditId: auditId,
    })
    if (!success)
      return alert(
        `Status change not successful. eLab Tracker says: ${errorMessage}`
      )

    alert(
      "You have placed the Audit on hold, the auditing company will have no access to your eLab Tracker data  while the audit is on hold. A lab company representative must re-accept the audit to allow auditor access."
    )
    return handleNavigateToAudit()
  }

  return (
    <div className="buttons d-flex gap-3 mt-3">
      {auditStatus === "pending" && (
        <Button
          variant="primary"
          className="btn-sm"
          onClick={handleAccept}
          disabled={isPending}
        >
          Accept Audit
        </Button>
      )}

      {auditStatus === "on hold" && (
        <Button
          variant="secondary"
          className="btn-sm"
          onClick={handleAccept}
          disabled={isPending}
        >
          Resume Audit
        </Button>
      )}

      {auditStatus === "active" && (
        <Button
          variant="secondary"
          className="btn-sm"
          onClick={handlePlaceOnHold}
          disabled={isPending}
        >
          Place Audit On Hold
        </Button>
      )}

      {auditStatus === "pending" && (
        <Button
          className="ms-auto btn-sm"
          variant="secondary"
          onClick={handleReject}
          disabled={isPending}
        >
          Reject Audit
        </Button>
      )}
    </div>
  )
}

const AuditStatusAuditorControls = ({
  auditStatus,
  auditId,
  handleNavigateToAuditingCompany,
}) => {
  const { error, isPending, changeAuditStatusToComplete, deletePendingAudit } =
    useAuditStatus()

  const handleMarkCompleted = async () => {
    const confirmMessage =
      "Are you sure you want to mark this audit as completed? This will remove auditing access from this lab company until a new audit request is sent."

    if (!window.confirm(confirmMessage))
      return alert("Action aborted, audit is still active")

    const { success, errorMessage, message } =
      await changeAuditStatusToComplete({
        auditId: auditId,
      })
    if (!success)
      return alert(`Action not successful. eLab Tracker says: ${errorMessage}`)
    alert(
      "You have successfully marked the audit as complete. You will no longer have access to the company's eLab Tracker data"
    )
    return handleNavigateToAuditingCompany()
  }

  const handleDeleteAudit = async () => {
    const confirmMessage =
      "Are you sure you want to delete this audit request? This cannot be undone. A new audit request will have to be sent to start an audit on this lab/company."

    if (!window.confirm(confirmMessage))
      return alert("Action aborted, audit request is still pending.")
    const { success, errorMessage, message } = deletePendingAudit({
      auditId: auditId,
    })
    if (!success)
      return alert(
        `Deletion not successful. eLab Tracker says: ${errorMessage}`
      )
    alert("Audit request successfully deleted")
    return handleNavigateToAuditingCompany()
  }

  return (
    <div className="buttons d-flex gap-3 mt-3">
      {(auditStatus === "active" || auditStatus == "on hold") && (
        <Button
          variant="secondary"
          className="btn-sm"
          onClick={handleMarkCompleted}
          disabled={isPending}
        >
          Mark Audit as Complete
        </Button>
      )}

      {auditStatus === "pending" && (
        <Button
          className="ms-auto btn-sm"
          variant="secondary"
          onClick={handleDeleteAudit}
          disabled={isPending}
        >
          Delete Audit Request
        </Button>
      )}
    </div>
  )
}

export { AuditStatusLabControls, AuditStatusAuditorControls }
