import { useCompanyContext } from "../../../company"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function EquipmentPiece() {
  const { isAuditView, auditId, equipmentTypes, equipmentList } =
    useCompanyContext()

  if (isAuditView)
    return (
      <AuditView
        equipmentTypes={equipmentTypes}
        equipmentList={equipmentList}
      />
    )
  return (
    <LabView equipmentTypes={equipmentTypes} equipmentList={equipmentList} />
  )
}
