import { useState } from 'react'
//layout
import ContentOptionalRight from '../../../components/layouts/ContentOptionalRight'
//components
import { CompanyEvaluationList } from '../../evaluations' 

export default function CompanyEvaluations() {
    const [searchTerm, setSearchTerm] = useState('')

    return (
        <ContentOptionalRight 
            contentTitle="Employee Evaluations"
            content={(<>
                <input 
                    type="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="search eval. name or cat...."
                    className="ms-5"
                    style={ {width: '300px'} }
                />
                <CompanyEvaluationList searchTerm={searchTerm} />
            </>)}
        />
    )
}
