import { format } from "date-fns"

export default function DateInput({
  label,
  value,
  setValue,
  min = null,
  max = null,
  required,
  className,
  horizontal,
  options,
}) {
  min = min ? min : null
  max = max ? max : null
  required = required ? required : false

  return (
    <label
      style={
        horizontal
          ? {
              width: "90%",
              display: "flex",
              gap: "1rem .5rem",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              alignContent: "baseline",
            }
          : null
      }
      className={className}
    >
      <span style={horizontal ? { marginRight: "1rem", width: "20%" } : null}>
        {label}
        {required ? "*" : ""}:{" "}
      </span>
      <input
        className="form-control"
        type="date"
        required={required}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        min={min}
        max={max}
        {...options}
        style={
          horizontal
            ? {
                flexGrow: 2,
                height: "3em",
              }
            : null
        }
      />
    </label>
  )
}
