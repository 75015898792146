export default function TextInput({
  label,
  value,
  onChange,
  placeholder,
  type = "text",
  required,
  horizontal,
  className,
  options,
}) {
  return (
    <label
      style={
        horizontal
          ? {
              width: "90%",
              display: "flex",
              gap: "1rem .5rem",
              justifyContent: "flex-start",
              flexWrap: "nowrap",
              alignContent: "baseline",
            }
          : null
      }
      className={className}
    >
      <span style={horizontal ? { marginRight: "1rem", width: "20%" } : null}>
        {label}
        {required ? "*" : ""}:{" "}
      </span>
      <input
        {...options}
        type={type}
        onChange={(e) => onChange(e)}
        value={value}
        className="form-control"
        placeholder={placeholder}
        required={required}
        style={
          horizontal
            ? {
                flexGrow: 2,
                height: "3em",
              }
            : null
        }
      />
    </label>
  )
}
