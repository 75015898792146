import { useState, useEffect } from "react"
import { BasicFormBody } from "../basicFormComponents"
import CalculatorTable from "./CalculatorTable"
import { useServiceAgreement } from "../../features/company"
import { formatUSD } from "../../util/formatCurrency"

export default function PriceCalculator({
  showForm = true,
  serviceId = "2",
  paymentPlan = "monthly",
  showSetup = true,
  companyData = null,
  equipmentList = [],
}) {
  const { getServiceAgreement, getQuantitiesUsed, error, isPending } =
    useServiceAgreement()
  const [serviceLevelId, setServiceLevelId] = useState(serviceId)
  const [data, setData] = useState(null)
  const [formError, setFormError] = useState("")

  //fetch the service agreement and update if it changes
  useEffect(() => {
    getServiceAgreement({ serviceLevelId }).then((res) => {
      updateFields({ serviceData: res })
    })
  }, [serviceLevelId])

  //get quantities used and set payment plan in data. if no company data or equipment list, the function will return a formatted array with no quantities
  useEffect(() => {
    updateFields({
      quantities: getQuantitiesUsed({ companyData, equipmentList }),
      paymentPlan: paymentPlan,
    })
  }, [])

  //form control
  const updateFields = (fields) => {
    setData((prevData) => {
      return { ...prevData, ...fields }
    })
  }

  const tierSubtotal = () => {
    if (!data.paymentPlan) return "N/A"
    let subtotal = 0
    if (data.paymentPlan === "monthly") subtotal += data.serviceData.monthlyFee
    else subtotal += data.serviceData.annualFee

    if (showSetup) subtotal += data.serviceData.setupFee
    return subtotal
  }

  if (data?.quantities.length === 0 || !data?.serviceData)
    return <p>loading...</p>
  return (
    <div>
      {/* basic agreement info */}
      <div className="d-flex justify-content-evenly mt-3">
        <p>
          <strong>Subscription Tier:</strong> {data.serviceData.name}
        </p>
        <p>|</p>
        <p>
          <strong>Payment Plan: </strong>
          {data.paymentPlan}
        </p>
      </div>
      <div className="d-flex justify-content-evenly mt-1">
        <p>
          <strong>Subscription Cost: </strong>
          {formatUSD(
            data.paymentPlan === "monthly"
              ? data.serviceData.monthlyFee
              : data.serviceData.annualFee
          )}
        </p>
        <p>|</p>

        {showSetup && (
          <>
            <p>
              <strong>setupFee: </strong>
              {formatUSD(data.serviceData.setupFee)}
            </p>
            <p>|</p>
          </>
        )}
        <p>
          <strong>Total*:</strong> {formatUSD(tierSubtotal())}
        </p>
      </div>

      <hr />
      <CalculatorTable
        serviceData={data.serviceData}
        paymentPlan={data.paymentPlan}
        quantitiesUsed={data.quantities}
      />

      <p className="fs-6">
        * - Total is based on the subscription cost and the setup fee if
        applicable. Setup fees are only charged upon initial signup.
      </p>
      <p className="fs-6">
        ** - Subscription charges are billed according to the payment plan
        selected. Overages are billed on the first of every month at the overage
        rate.
      </p>

      {/* form inputs */}
      {showForm && (
        <form id="calculator-data-form" onSubmit={(e) => e.preventDevault()}>
          <CalculatorDataForm
            updateFields={updateFields}
            setServiceLevelId={setServiceLevelId}
            {...data}
            serviceLevelId={serviceLevelId}
            setFormError={setFormError}
          />
        </form>
      )}
      {formError && <p className="error">{formError}</p>}
    </div>
  )
}

function CalculatorDataForm({
  updateFields,
  setServiceLevelId,
  quantities,
  paymentPlan,
  serviceLevelId,
  setFormError,
}) {
  const changeQuantities = (id, newValue) => {
    const newQuantities = quantities.map((use) => {
      if (use.id !== id) return use
      return { ...use, quantityUsed: newValue < 0 ? 0 : newValue }
    })

    updateFields({ quantities: newQuantities })
  }

  const formData = [
    {
      type: "radio",
      label: "subscription tier",
      groupName: "subscription-tier",
      options: [
        { value: "0", label: "Sandbox" },
        { value: "1", label: "Basic" },
        { value: "2", label: "Advanced" },
        { value: "3", label: "Mega" },
      ],
      value: serviceLevelId,
      onChange: (e) => setServiceLevelId(e.target.value),
      horizontal: true,
      style: { gridColumn: "span 2" },
    },
    {
      type: "radio",
      label: "payment plan",
      groupName: "payment-plan",
      options: [
        { value: "monthly", label: "Monthly" },
        { value: "annual", label: "Yearly" },
      ],
      value: paymentPlan,
      onChange: (e) => updateFields({ paymentPlan: e.target.value }),
      horizontal: true,
      style: { gridColumn: "span 2" },
    },
    {
      type: "number",
      label: "users",
      value: quantities.find((use) => use.id === "users").quantitiesUsed,
      onChange: (e) => changeQuantities("users", e.target.value),
      options: { min: "0" },
    },
    {
      type: "number",
      label: "moderators",
      value: quantities.find((use) => use.id === "moderators").quantitiesUsed,
      onChange: (e) => changeQuantities("moderators", e.target.value),
      options: { min: "0" },
    },
    {
      type: "number",
      label: "admins",
      value: quantities.find((use) => use.id === "admins").quantitiesUsed,
      onChange: (e) => changeQuantities("admins", e.target.value),
      options: { min: "0" },
    },
    {
      type: "number",
      label: "equipment",
      value: quantities.find((use) => use.id === "equipment").quantitiesUsed,
      onChange: (e) => changeQuantities("equipment", e.target.value),
      options: { min: "0", step: 10 },
    },
    {
      type: "number",
      label: "documents",
      value: quantities.find((use) => use.id === "documents").quantitiesUsed,
      onChange: (e) => changeQuantities("documents", e.target.value),
      options: { min: "0", step: 100 },
    },
  ]

  return <BasicFormBody columns={2} formData={formData}></BasicFormBody>
}
