import React from "react"
import { useCompanyContext } from "../../../company"
import AuditView from "./AuditView"
import LabView from "./LabView"

export default function EquipmentTypeList({ searchTerm }) {
  const { isAuditView, auditId } = useCompanyContext()
  //No differnce between AuditView nd LabView. however they shall remain separate in the event that there will be a difference later.

  if (isAuditView) return <AuditView searchTerm={searchTerm} />

  return <LabView searchTerm={searchTerm} />
}
