import "./legal.css"

export default function TermsAndConditionsForAuditorJSX() {
  return (
    <div className="terms-and-conditions">
      <h2>Terms and Conditions of Use</h2>
      <p className="updated-statement">
        <strong>Last updated:</strong> 01/13/2024
      </p>
      <p className="introduction">
        Please read these <i>Terms and Conditions</i> ("Terms", "Terms and
        Conditions") carefully before using eLab Tracker’s ("us", "we", or
        "our") Service. eLab Tracker’s service in the is defined as any use of
        features provided within eLab Tracker’s platform that are accessible
        after creating an account for any given laboratory or auditing entity.
      </p>{" "}
      <p className="introduction">
        Your access to and use of the Service is conditioned upon your
        acceptance of and compliance with these Terms. These Terms apply to all
        users, and others who access or use the Service.
      </p>{" "}
      <p className="introduction">
        {" "}
        By accessing or using the Service, you agree, on behalf of yourself and
        all others who use the Service within your organization or company, to
        be bound by these Terms. If you disagree with any part of the terms,
        then you do not have permission to access the Service.
      </p>
      <section>
        <h3>Accounts</h3>
        <p>
          When you create an account with us, you must provide accurate,
          complete, and current information. You are responsible for maintaining
          the confidentiality of your account and password, and you agree to
          accept responsibility for all activities that occur under your
          account.{" "}
        </p>
        <p>
          You must notify us immediately upon becoming aware of any breach of
          security or unauthorized use of your account.
        </p>
      </section>
      <section>
        <h3>Intellectual Property</h3>
        <p>
          The Service and its original content, features, and functionality are
          and will remain the exclusive property of eLab Tracker and its
          licensors.{" "}
        </p>
        <p>
          You may not reproduce, distribute, modify, create derivative works of,
          publicly display, publicly perform, republish, download, store, or
          transmit any of the material on our Service, except as generally and
          ordinarily permitted according to these Terms.
        </p>
        <p>
          As stated in our Privacy Policy, no user-generated content or
          documents are the intellectual property of elab Tracker without
          written consent.
        </p>
      </section>
      <section>
        <h3>User Content</h3>{" "}
        <p>
          Our Service allows you to store files as well as equipment and staff
          related data. You are solely responsible for the content you upload,
          share, or otherwise make available through the Service.
        </p>
        <p>
          {" "}
          By uploading content, you grant us a non-exclusive, royalty-free,
          worldwide, sublicensable, transferable license to use, reproduce,
          distribute, and display the content for the purpose of providing the
          Service.
        </p>
        <p>
          {" "}
          This includes reproduction and distribution or display of information
          to those permitted to view/download the information by you or a
          representative of your company who has permissions to access unless
          otherwise authorized as outlined in our Privacy Policy.
        </p>
      </section>
      <section>
        <h3>Prohibited Uses</h3>
        <p>You agree not to use the Service to: </p>
        <ul style={{ listStyle: "disc", marginLeft: "1.5em" }}>
          <li>Violate any applicable laws or regulations.</li>{" "}
          <li>
            {" "}
            Infringe upon the rights of others, including intellectual property
            rights.
          </li>{" "}
          <li>
            {" "}
            Upload, transmit, or distribute any viruses or malicious code.
          </li>{" "}
          <li>
            {" "}
            Attempt to gain unauthorized access to the Service, other users'
            accounts, or any related systems or networks.
          </li>
        </ul>{" "}
      </section>
      <section>
        <h3>Termination</h3>{" "}
        <p>
          We may terminate or suspend your account and access to the Service
          immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach these Terms.
        </p>
        <p>
          {" "}
          We may remove the ability to use certain features of the Service if
          timely payment is not received. In cases of extreme delinquency,
          access to the Service may be completely removed from accounts
          associated with your company account.
        </p>
        <p>
          {" "}
          Administrators and Primary Account Managers for your company or
          organization’s account may terminate individual users’ account rights,
          access, and available features of users who are within said
          organization’s company account as they see fit for any reason your
          organization deems necessary. eLab Tracker will not reverse any
          termination of rights, access, or features without written permission
          of the owner of the associated company’s account.
        </p>
      </section>
      <section>
        <h3>Payment</h3>{" "}
        <p>
          Payment is requested by way of an invoice. The invoice will be for the
          selected or agreed upon service level sent at the selected or agreed
          upon renewal frequency. Subscription-related invoices are requests for
          payment for continued service through the coming period (i.e. the next
          month or next year). Overage-related invoices are sent monthly and are
          requests for payment based on units used over the service level’s
          included quantities. Overages will not be billed or allowed without
          the company’s primary manager’s consent; any overage-related invoices
          for overages lacking consent in this way may be forgiven or otherwise
          refunded in partial or full by request. If such a request is made,
          eLab Tracker retains the right to investigate the potentially faulty
          charge and accept or deny the request in partial or full based on the
          results of the investigation.
        </p>
      </section>
      <section>
        <h3>Disclaimer</h3>{" "}
        <p>
          The Service is provided "as is" and "as available" without any
          warranties, expressed or implied. We do not warrant that the Service
          will be uninterrupted, timely, secure, or error-free.
        </p>
      </section>
      <section>
        <h3>Limitation of Liability</h3>{" "}
        <p>
          In no event shall eLab Tracker, its directors, employees, partners,
          agents, suppliers, or affiliates be liable for any indirect,
          incidental, special, consequential, or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses.
        </p>
      </section>
      <section>
        <h3>Governing Law</h3>{" "}
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of The United States of America, without regard to its conflict
          of law provisions.
        </p>
      </section>
      <section>
        <h3>Technical</h3>
        <p>
          Requirements eLab Tracker is optimized for internet browser use on a
          desktop/laptop/tablet sized screen. It is also optimized for use on a
          Chromium browser (i.e. Google Chrome, Microsoft Edge, etc…). eLab
          Tracker is also not optimized for individuals with accessibility needs
          such as individuals who are blind or those that require use of a
          screen reader.
        </p>
      </section>
      <section>
        <h3>Changes</h3>{" "}
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 14 days' notice prior to any new terms taking effect.
        </p>
      </section>
      <section>
        <h3>Contact Us</h3>{" "}
        <p>
          If you have any questions about these Terms, please contact us at
          sales@elabtracker.com or help@elabtracker.com.
        </p>
      </section>
    </div>
  )
}
