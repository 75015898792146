import { useNavigate } from "react-router-dom"

import { Button } from "react-bootstrap"
import PriceCalculator from "../components/PriceCalculator/PriceCalculator"
import TabbedContent from "../components/layouts/TabbedContent"
import TierIncludesComparison from "../components/TierIncludesComparison"

export default function PricingPage() {
  const navigate = useNavigate()
  return (
    <div className="ps-5 pe-5 m-auto">
      <TabbedContent
        pageTitle="Pricing"
        tabs={[
          { title: "Compare Tiers", content: <TierIncludesComparison /> },
          {
            title: "Price Calculator",
            content: (
              <div>
                <p>
                  Use the form below to determine your price. Choose your
                  subscription tier to see what is included in each to pick what
                  is right for your lab's size. Keep in mind:
                </p>
                <hr />
                <section>
                  <h2>Price Calculator:</h2>
                  <PriceCalculator />
                  <Button onClick={() => navigate("/account-setup")}>
                    Signup now
                  </Button>
                </section>
                <hr />

                <section>
                  If you have any questions about pricing or if the above
                  pre-priced plans don't work for your company or lab, please
                  email sales@elabtracker.com. If you want to discuss a
                  different quote, please include "quote" in your email's
                  subject line.
                </section>
              </div>
            ),
          },
        ]}
      />
      <section>
        <ul className="with-style" style={{ listStyleType: "square" }}>
          <li className="ms-5 mt-2">
            eLab Tracker works best when{" "}
            <strong>everyone in your lab has access</strong>. Everyone in your
            lab should have access to procedures, schedule tracking, and your
            manuals.
          </li>
          <li className="ms-5 mt-2">
            When choosing quantities, Users, Moderators and Admins are all
            exclusive; i.e. the advanced tier allows a total of 20 employees
            counting (16 users + 2 moderators + 1 admin + 1 primary account)
          </li>
          <li className="ms-5 mt-2">
            <strong>Users</strong> are employees that will have read only
            rights, they can see everything, but can't edit much.
          </li>
          <li className="ms-5 mt-2">
            <strong>Moderators</strong> are employees that maybe have a manager
            role in your company; they can add edit the company's equipment and
            evaluation data.
          </li>
          <li className="ms-5 mt-2">
            <strong>Admins</strong> are employees that can do anything within
            the limits of the software short of seeing and permitting billing
            information. They can add users, remove them, change others' roles
            and positions, they can also add, delete, and move documents in the
            document store. All that in addition to what a Moderator does.
          </li>
          <li className="ms-5 mt-2">
            The <strong>Primary Account</strong> holder is also an admin, but
            has access to billing information and the ability to adjust
            allowable overages. No overages can be billed unless explicitly
            allowed by this user. They are also the one that recieves the
            invoices.
          </li>
          <li className="ms-5 mt-2">
            The <strong>Document Store</strong> available in Advanced and Mega
            is a folder system designed to serve up all current documents while
            saving historical doucments at the same time. All tiers have the
            ability to save documents associated with equipment and skill
            evaluations.
          </li>
        </ul>
      </section>
    </div>
  )
}
