import { useState, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useFirestore } from "../../../hooks/useFirestore"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { statesAbbr } from "../../../lib/states"
//components
import { Button, Table } from "react-bootstrap"
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
import InfoIcon from "../../../components/basicComponents/InfoIcon"
import LogoCropperModal from "../../../components/LogoCropperModal"
import { TextInput } from "../../../components/basicFormComponents/index"

export default function AuditingCompanySettings({ auditingCompanyData }) {
  const { auditingCompanyId } = useParams()
  const {
    updateDocument,
    error: fireStoreError,
    isPending: fireStorePending,
  } = useFirestore()
  const stateOptions = formatSelectArray(statesAbbr)

  //logo modal control
  const [showLogoCropperModal, setShowLogoCropperModal] = useState(false)
  const logoCropperModalShow = () => setShowLogoCropperModal(true)
  const logoCropperModalHide = () => setShowLogoCropperModal(false)

  //address data control
  const streetAddressRef = useRef()
  const cityRef = useRef()
  const [state, setState] = useState(null)
  const zipRef = useRef()
  const [timezone, setTimezone] = useState({ label: "EST", value: "EST" }) //TODO: hook up to a Select component
  const emailVerificationTextRef = useRef()
  useEffect(() => {
    emailVerificationTextRef.current.value =
      auditingCompanyData.emailVerificationText
  }, [])

  useSetupInitialValues({
    streetAddressRef,
    cityRef,
    setState,
    zipRef,
    setTimezone,
    auditingCompanyData,
  })

  //update anything that changed on the page other than the company logo
  const handleUpdateSettings = async () => {
    console.log("updating settings")

    const confirmMessage =
      "you are changing the company's email verification requirements. The value you are trying to change must be contained in all auditors signing up under your company's account. Changing it may have security implications. Are you sure you want to change the email verification?"
    if (
      emailVerificationTextRef.current.value !==
        auditingCompanyData.emailVerificationText &&
      !window.confirm(confirmMessage)
    )
      return alert("action successfully aborted")

    const updateObject = {
      streetAddress: streetAddressRef.current.value,
      city: cityRef.current.value,
      zipCode: zipRef.current.value,
      state: state.value,
      timezone: timezone.value,
      emailVerificationText: emailVerificationTextRef.current.value,
    }

    await updateDocument(`auditingCompanies/${auditingCompanyId}`, updateObject)
    if (!fireStoreError) alert("update successful")
  }

  return (
    <>
      <ContentOptionalRight
        contentTitle="Company Settings"
        content={
          <>
            <div className="display-info container">
              <div className="row">
                <div className="company-name col-12 col-md-6">
                  <p>
                    <span className="fw-bold me-3">Company Name: </span>
                    {auditingCompanyData.companyName}
                  </p>
                  <p className="d-flex flex-no-wrap align-items-baseline mt-3">
                    <span className="fw-bold me-3">email verification: </span>
                    <input
                      className="ps-1"
                      type="text"
                      ref={emailVerificationTextRef}
                      style={{ width: "50%" }}
                    />
                  </p>
                </div>

                {/* company logo block */}
                <div className="company-logo col-12 col-md-6 mt-4 mt-md-0 align-flex-end">
                  <div className="d-flex">
                    <p className="me-1">Company Logo</p>
                    <InfoIcon infoTitle="Change Navbar Logo" />
                  </div>
                  <div
                    className="company-logo-container mb-4"
                    style={{
                      position: "relative",
                      zIndex: "1",
                    }}
                  >
                    {auditingCompanyData.navLogoURL && (
                      <img
                        className="nav-logo-img"
                        src={auditingCompanyData.navLogoURL}
                        alt={auditingCompanyData.companyName}
                      />
                    )}
                    {(!auditingCompanyData.navLogoURL ||
                      typeof auditingCompanyData.navLogoURL ===
                        "undefined") && (
                      <h1 className="nav-logo-img">
                        {auditingCompanyData.companyName}
                      </h1>
                    )}

                    {/* edit logo button */}
                    <Button
                      onClick={() => setShowLogoCropperModal(true)}
                      variant="primary"
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                {/* end logo section */}
              </div>
            </div>

            {/* address info section */}
            <div className="address-info container">
              <div className="row">
                <label className="address-label col-12 col-md-6 pe-4">
                  Street Address
                  <input className="ps-1" type="text" ref={streetAddressRef} />
                </label>
                <label className="address-label col-12 col-md-6 pe-4">
                  City <input className="ps-1" type="text" ref={cityRef} />
                </label>
              </div>
              <div className="row">
                <label className="address-label col-12 col-md-6 pe-4">
                  State{" "}
                  <Select
                    options={stateOptions}
                    className="ps-1"
                    onChange={(option) => setState(option)}
                    value={state}
                  />
                </label>
                <label className="address-label col-12 col-md-6 pe-4">
                  Zip Code{" "}
                  <input
                    className="ps-1"
                    type="text"
                    ref={zipRef}
                    pattern="[0-9]{5}"
                    title="Must be 5 digits"
                    minLength={5}
                    maxLength={5}
                  />
                </label>
              </div>

              <Button
                variant="primary"
                className="mt-3 me-4"
                onClick={handleUpdateSettings}
                disabled={fireStorePending}
              >
                {" "}
                Update Settings
              </Button>
            </div>

            <hr />

            {/* Position section */}
            <div className="customizable-section">
              <div className="d-flex">
                <h2 className="me-2">Available Positions</h2>
              </div>
              <div className="position-section">
                <PositionSection
                  companyId={auditingCompanyId}
                  availablePositions={auditingCompanyData.availablePositions}
                />
              </div>
            </div>
          </>
        }
      />

      {/* Modals */}
      {showLogoCropperModal && (
        <LogoCropperModal
          show={logoCropperModalShow}
          handleClose={logoCropperModalHide}
          navLogoURL={auditingCompanyData.navLogoURL}
          storageUploadPath={`auditingCompanies/${auditingCompanyData.companyId}/companyNavLogo`}
          firestoreDocumentPath={`auditingCompanies/${auditingCompanyData.companyId}`}
        />
      )}
    </>
  )
}

const useSetupInitialValues = ({
  streetAddressRef,
  cityRef,
  setState,
  zipRef,
  setTimezone,
  auditingCompanyData,
}) => {
  useEffect(() => {
    streetAddressRef.current.value =
      typeof auditingCompanyData.streetAddress !== "undefined"
        ? auditingCompanyData.streetAddress
        : ""
    cityRef.current.value =
      typeof auditingCompanyData.city !== "undefined"
        ? auditingCompanyData.city
        : ""
    setState(() => {
      return typeof auditingCompanyData.state !== "undefined"
        ? { label: auditingCompanyData.state, value: auditingCompanyData.state }
        : ""
    })
    zipRef.current.value =
      typeof auditingCompanyData.zipCode !== "undefined"
        ? auditingCompanyData.zipCode
        : ""
    //timezone may be different since it is a selection type
  }, [])
}

const PositionSection = ({ auditingCompanyId, availablePositions }) => {
  const [formError, setFormError] = useState("")
  const [addingPosition, setAddingPosition] = useState(false)
  const INITIAL_DATA = { title: "", description: "" }
  const [position, setPosition] = useState(INITIAL_DATA)
  const { isPending, error, updateDocument } = useFirestore()

  const updateFields = (fields) => {
    setPosition((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleAddPosition = async () => {
    setFormError("")
    if (
      availablePositions.find(
        (p) =>
          p.title.trim().toLowerCase() === position.title.trim().toLowerCase()
      )
    ) {
      return setFormError("This position is already in use.")
    }
    await updateDocument(`auditingCompanies/${auditingCompanyId}`, {
      availablePositions: [...availablePositions, { ...position }],
    })
    handleCancel()
  }

  const handleDeletePosition = async (title) => {
    setFormError("")
    console.log(title)
    const newPositionList = availablePositions.filter(
      (pos) => pos.title !== title
    )
    await updateDocument(`auditingCompanies/${auditingCompanyId}`, {
      availablePositions: newPositionList,
    })
  }

  const handleCancel = () => {
    setAddingPosition(false)
    setPosition(INITIAL_DATA)
  }

  return (
    <>
      {availablePositions.length === 0 && <p>No Positions Created</p>}
      {availablePositions.length !== 0 && (
        <Table striped hover bordered size="md">
          <thead className="fw-bold text-center">
            <td colSpan={1}>Title</td>
            <td colSpan={5}>Description</td>
            <td colSpan={1}>Delete</td>
          </thead>
          <tbody>
            {availablePositions.map((pos) => (
              <tr className="text-center" key={pos.title}>
                <td colSpan={1}>{pos.title}</td>
                <td colSpan={5}>{pos.description}</td>
                <td
                  colSpan={1}
                  className="clickable text-muted text-decoration-underline"
                  onClick={() => handleDeletePosition(pos.title)}
                >
                  Delete
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {!addingPosition && (
        <Button onClick={() => setAddingPosition(true)}>Add Position</Button>
      )}
      <AddPositionForm
        show={addingPosition}
        isPending={isPending}
        handleCancel={handleCancel}
        addPosition={handleAddPosition}
        updateFields={updateFields}
        data={position}
      />
      {formError && <p className="error">{formError}</p>}
    </>
  )
}

const AddPositionForm = ({
  show,
  handleCancel,
  data,
  isPending,
  addPosition,
  updateFields,
}) => {
  if (!show) return <p></p>
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          addPosition()
        }}
        id="add-position-form"
      >
        <div className="d-flex flex-wrap">
          <TextInput
            label="title"
            value={data.title}
            onChange={(e) => updateFields({ title: e.target.value })}
            horizontal={true}
          />
          <TextInput
            label="description"
            value={data.description}
            onChange={(e) => updateFields({ description: e.target.value })}
            horizontal={true}
          />
        </div>
      </form>
      <div className="d-flex">
        <Button
          variant="primary"
          className="mt-2 me-5"
          type="submit"
          form="add-position-form"
        >
          Submit
        </Button>
        <Button
          disabled={isPending}
          onClick={handleCancel}
          variant="secondary"
          className="mt-2"
        >
          Cancel
        </Button>
      </div>
    </>
  )
}
