import { useEffect, useState } from "react"

import { useNavigate } from "react-router"
import { useAuthContext } from "../../../authentication"

import { Button } from "react-bootstrap"
import Select from "react-select"
import { useGetAuditingCompany } from "../../../auditingCompany"
import formatSelectArray from "../../../../util/formatSelectArray"
import { useAddUsersToAudit, AuditStatusAuditorControls } from "../../index"

export default function AuditorView({ auditData }) {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const [auditorOptions, setAuditorOptions] = useState([])
  const [showAuditorOptions, setShowAuditorOptions] = useState(false)
  const [selectedAuditorOption, setSelectedAuditorOption] = useState(null)
  const { error, isPending, getAuditingCompany } = useGetAuditingCompany()
  const {
    error: addAuditorError,
    isPending: addAuditorPending,
    addAuditor,
  } = useAddUsersToAudit()

  const handleAddAuditor = async () => {
    if (!selectedAuditorOption) alert("no account selected")
    console.log(selectedAuditorOption)

    const { success, error, response } = await addAuditor({
      auditId: auditData.id,
      userToAdd: selectedAuditorOption,
    })
    if (!success) return alert(error)

    // reset
    setSelectedAuditorOption(null)
    setShowAuditorOptions(false)
  }

  useEffect(() => {
    // get the employeeList from the auditing company and format for select options then set auditorOptions state
    getAuditingCompany({ auditingCompanyId: user.auditingCompanyId }).then(
      (response) => {
        const employees = response.employees.filter((e) => {
          return (
            e.uid !== user.uid &&
            !auditData.auditorsPermitted.some((a) => a.uid === e.uid)
          )
        })

        const formattedEmployees = formatSelectArray(
          employees,
          "displayName",
          employees[0]
        )
        setAuditorOptions(formattedEmployees)
      }
    )
  }, [])

  const handleNavigateToAuditingCompany = () => {
    navigate(`/auditing-company/${auditData.auditingCompany.id}`)
  }

  if (isPending) return <p className="loading">...loading options</p>

  return (
    <div>
      <div className="row">
        <div className="col">
          {!showAuditorOptions && (
            <Button
              className="btn-sm mt-3"
              onClick={() => setShowAuditorOptions(true)}
              disabled={
                auditData.status === "complete" ||
                auditData.status === "rejected"
              }
            >
              Add Auditor
            </Button>
          )}

          {showAuditorOptions && (
            <div>
              {auditorOptions.length > 0 && (
                <>
                  <Select
                    options={auditorOptions}
                    onChange={(option) =>
                      setSelectedAuditorOption(option.value)
                    }
                  />
                  <div className="d-flex align-items-baseline">
                    <Button
                      className="btn-sm mt-3"
                      onClick={handleAddAuditor}
                      disabled={!selectedAuditorOption}
                    >
                      Add Auditor
                    </Button>
                  </div>
                </>
              )}{" "}
              <p
                className="clickable text-decoration-underline text-muted ms-4"
                onClick={() => setShowAuditorOptions(false)}
              >
                cancel
              </p>
              {auditorOptions.length === 0 && (
                <p className="error">no options available</p>
              )}
            </div>
          )}
        </div>
        <div className="col"></div>
      </div>
      <hr />

      <AuditStatusAuditorControls
        auditStatus={auditData.status}
        auditId={auditData.id}
        handleNavigateToAuditingCompany={handleNavigateToAuditingCompany}
      />
    </div>
  )
}
