import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useCompanyContext } from "../../../company"
import sortObjects from "../../../../util/sortObjects"

export default function EquipmentTypeCalibrationStatusReportLoader({
  children,
  options,
}) {
  const { companyId } = useParams()
  const { companyData, equipmentListDisplay: equipmentList } =
    useCompanyContext()
  const error = null
  const isPending = false

  const [reportData, setReportData] = useState(null)

  //   console.log(equipmentList)

  useEffect(() => {
    //filter equipment list accordingly
    let reportedList = equipmentList.filter((eq) => {
      let keep = false
      //check if the equipment is of a type the user wants to include in the report
      if (
        options.includedTypes.find((t) => eq.type.prefix === t.value.prefix)
      ) {
        if (options.includeOverdue && eq.calibrationStatus === "overdue")
          keep = true
        else if (
          options.includeUpcoming &&
          (eq.calibrationStatus === "upcoming" ||
            eq.calibrationStatus === "now")
        )
          keep = true
        else if (
          options.includeUpToDate &&
          eq.calibrationStatus === "not upcoming"
        )
          keep = true
      }

      return keep
    })

    //get statuses included in report
    let statusesIncluded = []
    if (options.includeOverdue) statusesIncluded.push("Overdue")
    if (options.includeUpcoming) statusesIncluded.push("Upcoming")
    if (options.includeUpToDate) statusesIncluded.push("Up to date")

    const equipmentTypesIncluded = options.includedTypes.map(
      (t) => t.value.name
    )

    //map to include only needed data
    reportedList = reportedList.map((eq) => {
      return {
        type: eq.type,
        id: eq.id,
        currentCustody: eq.currentCustody.displayName,
        calibrationStatus: eq.calibrationStatus,
        nextCalibrationDue:
          typeof eq.nextCalibration === "undefined" || !eq.nextCalibration
            ? null
            : eq.nextCalibration,
      }
    })

    //sort and accordingly
    reportedList = sortObjects(
      reportedList,
      options.sortBy.value,
      options.order === "asc"
    )

    // console.log(reportedList)

    //set the data
    return setReportData({
      displayData: reportedList,
      options: { ...options, statusesIncluded, equipmentTypesIncluded },
    })
  }, [options])

  //display error or loading
  if (error)
    return (
      <div className="error">
        <p>
          The database could not find the data for the equipment list fitting
          your report criteria. Or, if it did, you do not have permission to
          view the data:
        </p>
        <p>{error}</p>
      </div>
    )

  if (isPending || !reportData || typeof reportData === "undefined")
    return <p>...loading</p>

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return (
            reportData && React.cloneElement(child, { reportData, companyData })
          )
        }
        return child
      })}
    </>
  )
}
