//compatible with firebase 9 and react
import { createContext, useReducer, useMemo, useEffect } from "react"

import { infoLibrary } from "../assets/infoLibrary" //object, not array

export const InfoContext = createContext()

export const InfoReducer = (state, action) => {
  switch (action.type) {
    case "GET_TAG_OPTIONS":
      return { ...state, availableTags: action.payload }
    case "FILTER_BY_TAG":
      return { ...state, tags: action.payload }
    case "SEARCH":
      return { ...state, searchTerm: action.payload }
    case "CHANGE_DISPLAYED":
      return { ...state, displayInfo: action.payload }
    case "FLY_IN":
      return { ...state, show: true }
    case "FLY_OUT":
      return { ...state, show: false, tags: [], searchTerm: "" }
    default:
      return state
  }
}

export const InfoContextProvider = ({ children }) => {
  const info = infoLibrary
  const [state, dispatch] = useReducer(InfoReducer, {
    infoLibrary,
    displayInfo: [],
    tags: [],
    searchTerm: "",
    availableTags: [],
    show: false,
  })

  //when search or tags are selected
  useEffect(() => {
    let displayInfo = []
    Object.keys(infoLibrary).forEach((key) =>
      displayInfo.push(infoLibrary[key])
    )

    //tags
    if (state.tags.length > 0) {
      displayInfo = displayInfo.filter((info) => {
        return info.tags.some((tag) => state.tags.includes(tag))
      })
    }

    //search term
    let searchTerms = []
    if (state.searchTerm) {
      searchTerms = state.searchTerm.toLowerCase().split(" ")
      displayInfo = displayInfo.filter((info) => {
        return searchTerms.some(
          (term) =>
            info.title.toLowerCase().includes(term) ||
            info.keywords.includes(term)
        )
      })
    }

    //TODO: sort by relevance and then get segmensts to highlight.

    dispatch({ type: "CHANGE_DISPLAYED", payload: displayInfo })
  }, [state.tags, state.searchTerm])

  //available tags fire just on render
  useEffect(() => {
    let tags = []
    Object.keys(infoLibrary).forEach((key) => {
      tags.push(...infoLibrary[key].tags)
    })
    tags = tags.sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))

    dispatch({ type: "GET_TAG_OPTIONS", payload: [...new Set(tags)] })
  }, [])

  return (
    <InfoContext.Provider value={{ ...state, dispatch }}>
      {children}
    </InfoContext.Provider>
  )
}
