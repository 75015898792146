import { useState } from "react"
import { useFirestore } from "../../../hooks/useFirestore"
import { useStorage } from "../../../hooks/useStorage"
import { Timestamp } from "firebase/firestore"
import { isBefore, addMonths, format } from "date-fns"
import { getFullDate } from "../../../util/dateUtils"

export function useEvaluate() {
  const [error, setError] = useState(null)
  const {
    setDocument,
    updateDocument,
    getDocument,
    isPending,
    error: firestoreError,
  } = useFirestore()

  const {
    uploadFile,
    isPending: uploadPending,
    error: uploadError,
  } = useStorage()

  //push the users currentEvaluation into history (if applicable) and
  //update currentEvaluation
  const evaluateUser = async ({
    evaluatedUser,
    companyId,
    evaluationId,
    evaluationDate,
    evaluatedBy,
    evaluationDocument,
    comment,
    nextEvaluationDate,
    user,
    documentsOverMaxPermitted,
  }) => {
    setError("")
    const evaluation = await getDocument(
      `companies/${companyId}/evaluations/${evaluationId}`
    )
    // console.log(evaluation)
    // console.log(evaluatedUser)

    //make sure required fields are filled
    if (!evaluationDate)
      return setError("A date is required to log the evaluaiton")

    //add document to storage if there is one
    let docURL = ""
    if (evaluationDocument) {
      if (documentsOverMaxPermitted) {
        alert(
          "the evaluation document could not be uploaded because the maximum permitted number of files stored has been met for your company account. See your primary account manager to increase the allowance."
        )
      } else {
        const uploadPath = `companies/${companyId}/evaluations/${evaluationId}/${evaluatedUser.displayName}/${evaluatedUser.displayName}_${evaluationDate}`
        const metadata = {
          contentType: "application/pdf",
        }
        docURL = await uploadFile(uploadPath, evaluationDocument, metadata)
      }
    }

    //get the current evaluation from firestore before updating
    const currentBeforeUpdate = evaluation[evaluatedUser.uid].currentEvaluation
    //compare dates - push new evaluation to history if older, push old one to history if newer
    let straightToHistory = false
    if (evaluation[evaluatedUser.uid].currentEvaluation.dateOfAction)
      straightToHistory = isBefore(
        getFullDate(evaluationDate),
        evaluation[evaluatedUser.uid].currentEvaluation.dateOfAction.toDate()
      )
    let updateObject = null

    if (straightToHistory) {
      let history = [
        {
          performedBy: evaluatedBy.value,
          dateOfAction: Timestamp.fromDate(getFullDate(evaluationDate)),
          nextEvaluation: Timestamp.fromDate(getFullDate(nextEvaluationDate)),
          enteredAt: Timestamp.fromDate(new Date()),
          enteredBy: { displayName: user.displayName, uid: user.uid },
        },
        ...evaluation[evaluatedUser.uid].history,
      ]
      //add the doc url if there is one
      if (docURL) history[0].docURL = docURL
      //sort the array
      history = history.sort((a, b) => {
        // console.log(b.dateOfAction.seconds)
        return b.dateOfAction.seconds - a.dateOfAction.seconds
      })
      //make the object to update firestore with
      updateObject = {
        [evaluatedUser.uid]: {
          ...evaluation[evaluatedUser.uid],
          history,
        },
      }
    } else {
      //update the firestore object's  currentEvaluation and push the old one to the history
      updateObject = {
        [evaluatedUser.uid]: {
          ...evaluation[evaluatedUser.uid],
          currentEvaluation: {
            performedBy: evaluatedBy.value,
            dateOfAction: Timestamp.fromDate(getFullDate(evaluationDate)),
            nextEvaluation: Timestamp.fromDate(getFullDate(nextEvaluationDate)),
            enteredAt: Timestamp.fromDate(new Date()),
            enteredBy: { displayName: user.displayName, uid: user.uid },
            comment,
          },
        },
      }
      if (docURL)
        updateObject[evaluatedUser.uid].currentEvaluation.docURL = docURL

      //update history and currentEvaluation in Firestore
      if (currentBeforeUpdate.dateOfAction) {
        updateObject[evaluatedUser.uid].history = [
          currentBeforeUpdate,
          ...evaluation[evaluatedUser.uid].history,
        ]
      }
    }
    await updateDocument(`companies/${companyId}/evaluations/${evaluationId}`, {
      ...updateObject,
    })

    if (firestoreError) setError(firestoreError)
  }

  //add a document to the users currentEvaluation
  const addUserEvaluationDocument = async ({
    companyId,
    evaluationId,
    document,
    uploadData,
    documentsOverMaxPermitted,
  }) => {
    setError("")
    if (documentsOverMaxPermitted) {
      return alert(
        "the evaluation document could not be uploaded because the maximum permitted number of files stored has been met for your company account. See your primary account manager to increase the allowance."
      )
    }
    //add document to storage
    let docURL = ""
    const uploadPath = `companies/${companyId}/evaluations/${evaluationId}/${
      uploadData.displayName
    }/${uploadData.displayName}_${format(
      uploadData.currentEvaluation.dateOfAction.toDate(),
      "yyyy-MMM-dd"
    )}.pdf`
    const metadata = {
      contentType: "application/pdf",
    }
    docURL = await uploadFile(uploadPath, document, metadata)

    if (uploadError) return setError(uploadError)

    //update the firestore object
    const updateObject = {
      [uploadData.uid]: {
        ...uploadData,
        currentEvaluation: {
          ...uploadData.currentEvaluation,
          docURL,
        },
      },
    }
    await updateDocument(`companies/${companyId}/evaluations/${evaluationId}`, {
      ...updateObject,
    })
    if (firestoreError) setError(firestoreError)
  }

  return {
    error,
    firestoreError,
    uploadError,
    isPending,
    uploadPending,
    evaluateUser,
    addUserEvaluationDocument,
  }
}
