import { useEffect } from "react"
import { Routes, Route, useParams, Navigate } from "react-router-dom"
import { useCompanyContext } from "../index"
import { useUserContext } from "../../users"
import { useAuthContext } from "../../authentication/index"
import TermsAndConditionsModal from "../../../components/legal/TermsAndConditionsModal"
import TermsAndConditionsForAccountJSX from "../../../components/legal/TermsAndConditionsForAccountJSX"
import { useAgreeToTerms } from "../../../hooks/legal/useAgreeToTerms"

import AccessDenied from "../../../components/AccessDenied"

//subroute page components
import {
  CompanyDashboard,
  CompanyEquipment,
  CompanyEquipmentTypes,
  CompanyEvaluations,
  DocumentStore,
  Staff,
  CompanySettings,
  Billing,
  SignupUser,
  CompanyNavbar,
} from "../index"
import {
  Notifications,
  Messages,
  UserEquipment,
  UserEvaluations,
  UserDetails,
} from "../../users"
import { DocumentsFolder } from "../../documentStorage"
import { Message } from "../../messaging/index"
import { EvaluationType } from "../../evaluations"
import {
  EquipmentType,
  EquipmentPiece,
  EquipmentReportsPage,
  EquipmentListFormPage,
  EquipmentCalibrationStatusFormPage,
  EquipmentListByTypeFormPage,
  EquipmentTypeCalibrationStatusFormPage,
  EquipmentPieceReportFormPage,
} from "../../equipment"
import ProtectedPage from "../../../pages/ProtectedPage"
import { Audits } from "../../audits"

export default function Company({ provideCompanyId }) {
  const { user } = useAuthContext()
  const { userData, userIsReady } = useUserContext()
  const { companyId, auditId } = useParams()

  const isAuditView = typeof auditId !== "undefined"

  const {
    companyData,
    companyIsReady,
    pendingAudits,
    activeAudits,
    completeAudits,
    rejectedAudits,
  } = useCompanyContext()

  //get and reset the companyId for the App
  provideCompanyId(companyId)
  useEffect(() => {
    return () => provideCompanyId(null)
  }, [])

  //if the user has not agreed to the current terms they must before continuing, this function is executed when they do
  const { agreeToUserTerms, error: signTermsError } = useAgreeToTerms()
  const handleAgree = async ({ signedName }) => {
    await agreeToUserTerms({
      uid: user.uid,
      signedNameForAgreementofTermsAndConditions: signedName,
    })

    if (signTermsError)
      alert(
        "there was an error on our end, please contact elabtracker with this errorCode and include your accounts email address in the correspondence: SIGNTERMSERROR001"
      )
  }
  const handleCloseTermsModal = ({ closeSuccessful }) => {
    if (closeSuccessful) console.log("modal to close")
  }

  // no template if no company
  if (!companyIsReady || !userIsReady || !userData.rights)
    return <p>Loading...</p>

  //No access to auditors if not in the audit route
  if (typeof auditId === "undefined" && companyId !== user.company)
    return <Navigate to="/" />

  return (
    <>
      {" "}
      <TermsAndConditionsModal
        show={!userData.agreedToUserTerms}
        TermsJSX={<TermsAndConditionsForAccountJSX />}
        handleAgree={handleAgree}
        handleClose={handleCloseTermsModal}
        requireAgree={true}
      />
      <CompanyNavbar
        companyId={companyId}
        isAuditView={typeof auditId !== "undefined"}
      ></CompanyNavbar>
      <div className="company-container container">
        {/* nested routes */}
        <Routes>
          {/* Default component/route */}
          {/* TODO: Move this to App I think */}
          <Route
            path="/"
            element={
              isAuditView ? (
                <Navigate to="dashboard" replace={true} />
              ) : (
                <Navigate to="notifications" replace={true} />
              )
            }
          />
          <Route path="access-denied" element={<AccessDenied />} />

          {/* Company-ralated nested routes */}
          {/* route gaurd - need to be primary or admin rights */}
          <Route
            path="company-settings"
            element={
              <ProtectedPage
                allowUser={user.admin && user.company === companyId}
                pathIfRejected={
                  isAuditView
                    ? `/lab-audit/${auditId}`
                    : `/company/${user.company}/access-denied`
                }
              >
                <CompanySettings companyData={companyData} />
              </ProtectedPage>
            }
          />

          {/* TODO: needs designed but needs auditview and company view and to be made the initial page instead of notifications if the user is a moderator */}
          <Route path="dashboard" element={<CompanyDashboard />} />

          {/* Equipment Pages */}
          <Route path="company-equipment" element={<CompanyEquipment />} />
          <Route
            path="company-equipment/types"
            element={<CompanyEquipmentTypes />}
          />
          <Route
            path="company-equipment/types/:typePrefix"
            element={<EquipmentType />}
          />
          <Route
            path="company-equipment/:equipmentId"
            element={<EquipmentPiece />}
          />

          {/* equipmentReports routes */}
          <Route
            path="company-equipment/reports"
            element={<EquipmentReportsPage />}
          />
          <Route
            path="company-equipment/reports/equipment-list"
            element={<EquipmentListFormPage />}
          />
          <Route
            path="company-equipment/reports/equipment-calibration-status"
            element={<EquipmentCalibrationStatusFormPage />}
          />
          <Route
            path="company-equipment/reports/equipment-list-by-type"
            element={<EquipmentListByTypeFormPage />}
          />
          <Route
            path="company-equipment/reports/equipment-calibration-status-by-type"
            element={<EquipmentTypeCalibrationStatusFormPage />}
          />
          <Route
            path="company-equipment/reports/asset-status"
            element={<EquipmentPieceReportFormPage />}
          />

          {/* Evaluation Pages */}
          <Route path="company-evaluations" element={<CompanyEvaluations />} />
          <Route
            path="company-evaluations/:evaluationId"
            element={<EvaluationType />}
          />

          {/* Document Storage Pages */}
          <Route
            path="additional-docs"
            element={
              <ProtectedPage
                allowUser={companyData.additionalDocuments}
                pathIfRejected={
                  isAuditView
                    ? `/lab-audit/${auditId}`
                    : `/company/${user.company}/access-denied`
                }
              >
                <DocumentStore />
              </ProtectedPage>
            }
          />
          <Route
            path="additional-docs/:folderId"
            element={
              <ProtectedPage
                allowUser={companyData.additionalDocuments}
                pathIfRejected={
                  isAuditView
                    ? `/lab-audit/${auditId}`
                    : `/company/${user.company}/access-denied`
                }
              >
                <DocumentsFolder />
              </ProtectedPage>
            }
          />

          {/* Audit Pages */}
          <Route
            path="audits"
            element={
              <ProtectedPage
                allowUser={!isAuditView}
                pathIfRejected={`/lab-audit/${auditId}`}
              >
                <Audits
                  pendingAudits={pendingAudits}
                  activeAudits={activeAudits}
                  completeAudits={completeAudits}
                  rejectedAudits={rejectedAudits}
                />
              </ProtectedPage>
            }
          />

          {/* staff pages */}
          <Route path="staff" element={<Staff />} />
          <Route
            path="signup"
            element={
              <ProtectedPage
                allowUser={!isAuditView}
                pathIfRejected={`/lab-audit/${auditId}`}
              >
                <SignupUser />
              </ProtectedPage>
            }
          />

          {/* route gaurd - need primary rights */}
          <Route
            path="billing"
            element={
              <ProtectedPage
                allowUser={user.primary}
                pathIfRejected={
                  isAuditView
                    ? `/lab-audit/${auditId}`
                    : `/company/${user.company}/access-denied`
                }
              >
                <Billing companyData={companyData} />
              </ProtectedPage>
            }
          />

          {/* User related Nested Routes */}
          <Route
            path="notifications"
            element={
              <ProtectedPage
                allowUser={!isAuditView}
                pathIfRejected={`/lab-audit/${auditId}`}
              >
                <Notifications user={user} userData={userData} />
              </ProtectedPage>
            }
          />

          <Route
            path="messages"
            element={
              <ProtectedPage
                allowUser={companyData.messaging && !isAuditView}
                pathIfRejected={
                  isAuditView
                    ? `/lab-audit/${auditId}`
                    : `/company/${user.company}/access-denied`
                }
              >
                <Messages />
              </ProtectedPage>
            }
          />
          <Route
            path="messages/:messageId"
            element={
              <ProtectedPage
                allowUser={companyData.messaging && !isAuditView}
                pathIfRejected={
                  isAuditView
                    ? `/lab-audit/${auditId}`
                    : `/company/${user.company}/access-denied`
                }
              >
                <Message />
              </ProtectedPage>
            }
          />
          <Route path="user/:uniqueId" element={<UserDetails />} />

          <Route path="user/:uniqueId/equipment" element={<UserEquipment />} />

          <Route
            path="user/:uniqueId/evaluations"
            element={<UserEvaluations />}
          />

          {/* Reroute for any non user */}
          {!user && (
            <Route path="/company/*" element={<Navigate to="/company" />} />
          )}
        </Routes>

        {/* footer */}
      </div>
    </>
  )
}
