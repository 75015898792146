import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { format } from "date-fns"
import { useAuthContext } from "../../../authentication/index"
import { EquipmentHistoryLog } from "../../index"
import InfoIcon from "../../../../components/basicComponents/InfoIcon"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"

export default function AuditView({ equipmentTypes, equipmentList }) {
  const navigate = useNavigate()
  const { equipmentId, companyId, auditId } = useParams()
  const { user } = useAuthContext()
  const equipment = equipmentList.find((eq) => eq.id === equipmentId)

  //get type data
  const [type] = useState(() =>
    equipmentTypes.find((t) => t.prefix === equipment.typePrefix)
  )

  const deleteHistory = () => {
    return
  }

  return (
    <ContentOptionalRight
      content={
        <>
          <div className="d-flex justify-content-around flex-wrap">
            <h1 className="page-title">
              {type.name}: <span className="fw-bold">{equipment.id}</span>
            </h1>
          </div>
          <div className="object-data-format mt-3 p-3 container">
            <div className="row">
              <p className="object-data col col-md d-flex text-nowrap">
                <span className="key-name">Type: </span>
                <span className="value">{type.name}</span>
              </p>
              <p className="object-data col-md d-flex text-nowrap">
                <span className="key-name">ID: </span>
                <span className="value">{equipment.id}</span>
              </p>

              <div className="object-data col-md d-flex text-nowrap align-items-middle">
                <span className="key-name">Acquired: </span>
                {equipment.acquisitionDate !== "" &&
                  equipment.acquisitionDate !== "none" && (
                    <span className="value">
                      {format(
                        equipment.acquisitionDate.toDate(),
                        "MMM dd, yyyy"
                      )}
                    </span>
                  )}
              </div>
            </div>

            <div className="row">
              <div className="object-data col-md d-flex text-nowrap">
                <span className="key-name">Make: </span>
                {equipment.make && (
                  <span className="value">{equipment.make}</span>
                )}
              </div>

              <div className="object-data col-md d-flex text-nowrap">
                <span className="key-name">Model: </span>
                {equipment.model && (
                  <span className="value">{equipment.model}</span>
                )}
              </div>

              <div className="object-data col-md d-flex text-nowrap flex-grow-1">
                <span className="key-name">SN: </span>
                {equipment.serialNumber && (
                  <span className="value">{equipment.serialNumber}</span>
                )}
              </div>
            </div>
            <hr />

            {/* Calibration Dates */}
            {(typeof type.calibrationRequired === "undefined" ||
              type.calibrationRequired) && (
              <div className="row">
                <p className="object-data col">
                  <span className="key-name">Last Calibration: </span>
                  {equipment.lastCalibration && (
                    <span className="value">
                      {format(
                        equipment.lastCalibration.toDate(),
                        "MMM dd, yyyy"
                      )}
                    </span>
                  )}
                  {!equipment.lastCalibration && (
                    <span className="value text-muted">none</span>
                  )}
                </p>
                <p className="object-data col">
                  <span className="key-name">Next Calibration Due: </span>
                  {equipment.nextCalibration && (
                    <span className="value">
                      {format(equipment.nextCalibration.toDate(), "MMM, yyyy")}
                    </span>
                  )}
                  {!equipment.nextCalibration && (
                    <span className="value text-muted">N/A</span>
                  )}
                </p>
              </div>
            )}

            <hr />

            <div className="row">
              <InfoIcon infoTitle="Editing Equipment Pieces" />
              {equipment.status !== "discarded" && (
                <>
                  <div className="object-data editable d-flex pt-3">
                    <InfoIcon infoTitle="Equipment Custody Definition" />
                    <span className="key-name ms-3">Custody: </span>
                    <span className="value">
                      {equipment.currentCustody.displayName}
                    </span>
                  </div>

                  <div className="object-data editable d-flex pt-3">
                    <InfoIcon infoTitle="Equipment Default Custody Definition" />
                    <span className="key-name ms-3">Default Custody: </span>
                    <span className="value">
                      {equipment.defaultCustody.displayName}
                    </span>
                  </div>
                </>
              )}

              <div className="object-data editable d-flex pb-3 pt-3">
                <InfoIcon infoTitle="Equipment Status Definition" />
                <span className="key-name ms-3">Status: </span>
                <span className="value">{equipment.status}</span>
              </div>
            </div>
          </div>

          {/* Buttons at bottom of section */}
          <div className="d-flex mt-3 buttons px-4">
            <Link
              className="btn text-bg-primary ms-auto"
              to={`/lab-audit/${auditId}/lab-company/${companyId}/company-equipment/types/${equipment.typePrefix}`}
            >
              Go to {type.name}
            </Link>
          </div>
          <hr />
          <EquipmentHistoryLog
            canDelete={false}
            history={equipment.history}
            equipmentType={type.name}
            equipmentId={equipment.id}
            calibrationRequired={
              typeof type.calibrationRequired === "undefined" ||
              type.calibrationRequired
            }
            deleteHistory={deleteHistory}
          />
        </>
      }
    />
  )
}
