import { useEffect, useRef, useState } from "react"

//components
import { Button } from "react-bootstrap"
import { BasicFormBody } from "../../../components/basicFormComponents"
import { Link } from "react-router-dom"
import { useCompanyContext } from "../../company"
import EquipmentPieceReportLoader from "../reports/EquipmentPieceReport/EquipmentPieceReportLoader"
import EquipmentPieceReportRender from "../reports/EquipmentPieceReport/EquipmentPieceReportRender"
import { addDays, format } from "date-fns"

const FORMDATA = {
  equipmentId: "",
  date: format(new Date(), "yyyy-MM-dd"),
  equipmentObject: null,
}

export default function EquipmentTypeCalibrationStatusFormPage() {
  const { isAuditView, equipmentListDisplay } = useCompanyContext()

  const [formData, setFormData] = useState(FORMDATA)
  const [formError, setFormError] = useState(null)
  const [displayReport, setDisplayReport] = useState(false)
  const reportRef = useRef(null)

  const updateFormData = (updateObject) => {
    setFormData((prev) => {
      return { ...prev, ...updateObject }
    })
  }

  const handleEquipmentIdEntered = (e) => {
    setFormError(null)
    let equipmentObject = null
    //if there is an equipmentId in the update object, want to add the equipmentObject from companyContext that matches that ID
    if (e) {
      equipmentObject = equipmentListDisplay.find(
        (eq) => eq.id.toLowerCase() === e.toLowerCase()
      )
      //if there is no matching ID, set an error and reset the form
      if (!equipmentObject) {
        updateFormData(FORMDATA)
        return setFormError("No equipment with that ID exists.")
      }
      //if there is a match, the update the formData so the Date Picker is enabled and bounds set.
      return updateFormData({ equipmentObject })
    }
    //if the input is blank, reset the form
    return updateFormData(FORMDATA)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(null)
    // console.log("submitted")
    if (!formData.equipmentId)
      return setFormError("Must enter an Equipment ID to run the report")

    setDisplayReport(true)

    //handle scroll
    setTimeout(() => {
      reportRef.current.scrollIntoView({ behavior: "smooth" })
    }, 200)
  }

  useEffect(() => {
    setDisplayReport(false)
  }, [formData])

  if (isAuditView)
    return <p className="error">This report is not yet available in audits</p>

  return (
    <>
      <h1>Asset Report</h1>
      <p>
        <Link to="./../">...back to reports list</Link>
      </p>
      <p>
        This report will give the current status of the entered equipment. If a
        date is entered it will give the status for that date as well as current
        status. A valie equipment ID must be entered before you can select a
        date.
      </p>
      <form onSubmit={handleSubmit}>
        <BasicFormBody
          formData={[
            {
              type: "text",
              label: "ID of Equipment or Asset",
              required: true,
              onChange: (e) =>
                e.target.value
                  ? updateFormData({ equipmentId: e.target.value })
                  : updateFormData(FORMDATA),
              value: formData.equipmentId,
              horizontal: true,
              options: {
                onBlur: (e) => handleEquipmentIdEntered(e.target.value),
              },
            },
            {
              type: "date",
              label: "Date for historical data",
              setValue: (e) => updateFormData({ date: e }),
              max: format(new Date(), "yyyy-MM-dd"),
              value: formData.date,
              options: { disabled: !formData.equipmentObject },
              min: formData.equipmentObject
                ? format(
                    formData.equipmentObject.acquisitionDate?.toDate(),
                    "yyyy-MM-dd"
                  )
                : null,
              horizontal: true,
            },
          ]}
          columns={2}
        >
          {formError && <p className="error">{formError}</p>}
        </BasicFormBody>
        <Button
          disabled={!formData.equipmentObject}
          type="submit"
          variant="primary"
        >
          Run Report
        </Button>
      </form>

      {/* Report */}
      <hr />
      {displayReport && (
        <div ref={reportRef} className="mb-4">
          <EquipmentPieceReportLoader
            equipment={formData.equipmentObject}
            date={addDays(new Date(formData.date), 1)}
          >
            <EquipmentPieceReportRender />
          </EquipmentPieceReportLoader>
        </div>
      )}
    </>
  )
}
