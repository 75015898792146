import { useContext } from "react"
import { AuditorUserContext } from "../context/AuditorUserContext"

export const useAuditorUserContext = () => {
  const context = useContext(AuditorUserContext)

  if (context === undefined) {
    throw new Error(
      "useAuditorUserContext() must be used inside a userProvider"
    )
  }

  return context
}
