import { useState, useEffect } from "react"
import { useFirestore } from "./useFirestore"
import { functions } from "../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useLogin, useLogout, useVerify } from "../features/authentication"
import { serverTimestamp, Timestamp } from "firebase/firestore"

export function useAuditorSetup() {
  const [error, setError] = useState("")
  const [isPending, setIsPending] = useState(false)
  const { login, error: loginError, isPending: loginPending } = useLogin()
  const { logout } = useLogout()
  const {
    verifyByEmail,
    error: verificationError,
    isPending: verificationPending,
  } = useVerify()
  const changeClaims = httpsCallable(functions, "changeUserData-changeClaims")

  const signupNewUser = httpsCallable(functions, "signupUser-signupNewUser")
  const {
    error: firestoreError,
    isPending: firestorePending,
    setDocument,
    getDocument,
    updateDocument,
  } = useFirestore()

  const resetError = () => {
    setError("")
  }

  //error and pending listeners defined below
  useDetermineErrors({ firestoreError, loginError, setError })
  useDeterminePending({
    firestorePending,
    loginPending,
    setIsPending,
  })

  const auditingCompanyNameAvailable = async (companyName) => {
    const nameToCheck = companyName.toLowerCase().trim().replace(" ", "-")
    const auditingCompanies = await getDocument("public/auditingCompanies")
    const labCompanies = await getDocument("public/companies")
    if (auditingCompanies.list.some((company) => company.id === nameToCheck))
      return false

    if (labCompanies.list.some((company) => company.id === nameToCheck))
      return false

    return true
  }

  const getAuditingCompanies = async () => {
    const companies = await getDocument("public/auditingCompanies")
    return companies.list
  }

  //creates a new auditor//create a new user with auditor properties and claims/tokens
  const createAuditor = async ({
    displayName,
    email,
    password,
    isAuditor = true,
    auditingAdmin,
    auditingCompanyId,
    agreedToAuditorTerms = false,
    signedNameForAuditorAgreementofTermsAndConditions = null,
  }) => {
    //signing up user in firebase using firebase function
    const userId = await signupNewUser({
      email,
      password,
      displayName,
      rights: "primary",
    })
    if (userId.data.error) {
      setIsPending(false)
      setError(userId.data.error)
      console.log(userId.data)
      return { error }
    }
    //add tokens to user
    const claimChangeResponse = await changeClaims({
      initial: true,
      rights: "primary",
      uid: userId.data.response.uid,
      email,
      auditingCompanyId,
      newClaimsObject: {
        isAuditor,
        auditingAdmin,
        isConfirmedAuditor: false,
        auditingCompanyId,
      },
    })
    //log that user in
    await login(email, password)

    //update firestore
    const userObject = {
      displayName,
      email,
      isAuditor,
      auditingAdmin,
      isConfirmedAuditor: false,
      auditingCompanyId: auditingCompanyId,
      auditingCompanyRights: auditingAdmin ? "admin" : "user",
      rights: auditingAdmin ? "admin" : "user",
      performedTutorial: false,
      uid: userId.data.response.uid,
      agreedToAuditorTerms,
      signedNameForAuditorAgreementofTermsAndConditions,
      signedAuditorTermsAndConditionsAt: Timestamp.fromDate(new Date()),
    }
    //add new userDocument
    await setDocument(`users/${userId.data.response.uid}`, userObject)

    await verifyByEmail()

    return { user: userId.data.response, email, password }
  }

  //function that adds auditor properties and claims to an existing authenticated user of a lab company when the user's lab company also has a matching auditing company.
  const addAuditingToExistingLabUserWithMatchingAuditingCompany = async ({
    user,
    auditingCompanyData,
    labCompanyData,
  }) => {
    //validate email
    if (
      !user.email
        .toLowerCase()
        .trim()
        .includes(
          auditingCompanyData.emailVerificationText.toLowerCase().trim()
        )
    )
      return setError(
        "the user's data does not contain the requirements to sign up as an auditor for this auditing company."
      )

    //updateUser Claims/tokens
    const claimChangeResponse = await changeClaims({
      initial: false,
      rights: "primary",
      uid: user.uid,
      email: user.email,
      newClaimsObject: {
        isAuditor: true,
        auditingAdmin: user.admin,
        isConfirmedAuditor: false,
        auditingCompanyId: user.company,
      },
      updateFirestoreUserdocObject: {
        isAuditor: true,
        auditingAdmin: user.admin,
        isConfirmedAuditor: false,
        auditingCompanyId: user.company,
        signedNameForAuditorAgreementofTermsAndConditions: null,
        agreedToAuditorTerms: false,
      },
    })

    if (claimChangeResponse.error)
      return {
        error:
          "auditor setup failed upon changing the user's auth tokens and claims",
        message: "not successful",
      }

    const rights = user.admin ? "admin" : "user"

    //add user information to the auditing company doc
    await addEmployeeToAuditingCompany({
      auditingCompanyId: user.company,
      userObject: {
        displayName: user.displayName,
        email: user.email,
        rights,
        uid: user.uid,
        isAuditor: true,
        auditingAdmin: user.admin,
      },
    })

    return {
      error: error || null,
      message: error
        ? error
        : "auditor set up appears to be successful. Please log out and log back in.",
    }
  }

  //creating a new auting company that has nothing to do with an existing lab company.
  const createAuditingCompany = async ({
    name,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    companyId,
    primaryAccount,
    emailVerificationText,
  }) => {
    const auditingCompanies = await getDocument("public/auditingCompanies")
    auditingCompanies.list.push({
      name,
      steetAddress: address,
      streetAddress2: address2,
      state,
      city,
      zipCode: zip,
      companyPhone: phone,
      timezone: "EST",
      id: companyId,
      primaryAccount,
      emailVerificationText,
    })

    await updateDocument("public/auditingCompanies", { ...auditingCompanies })
    //create the company document
    await setDocument(`auditingCompanies/${companyId}`, {
      companyName: name,
      employees: [primaryAccount],
      availablePositions: [],
      createdAt: serverTimestamp(),
      state,
      city,
      companyId,
      streetAddress: address,
      streetAddress2: address2,
      zipCode: zip,
      companyPhone: phone,
      timezone: "EST",
      pendingAuditRequests: [],
      ongoingAudits: [],
      previousAudits: [],
      emailVerificationText,
    })
  }

  const addEmployeeToAuditingCompany = async ({
    auditingCompanyId,
    userObject,
  }) => {
    const companyData = await getDocument(
      `auditingCompanies/${auditingCompanyId}`
    )

    //check if duplicate
    let newEmployeeList = companyData.employees.filter(
      (e) => e.uid !== userObject.uid
    )

    newEmployeeList = [...newEmployeeList, userObject]

    await updateDocument(`auditingCompanies/${auditingCompanyId}`, {
      employees: newEmployeeList,
    })
  }

  return {
    error,
    isPending,
    resetError,
    auditingCompanyNameAvailable,
    getAuditingCompanies,
    createAuditor,
    addAuditingToExistingLabUserWithMatchingAuditingCompany,
    createAuditingCompany,
    addEmployeeToAuditingCompany,
  }
}

//useEffects
const useDetermineErrors = ({ firestoreError, loginError, setError }) => {
  let error = ""
  useEffect(() => {
    if (firestoreError) error = `Firestore error: ${firestoreError}`
    if (loginError) error = `${error}, Signup error: ${loginError}}`

    if (error) setError(`ERROR: ${error}`)
  }, [firestoreError, loginError])
}

const useDeterminePending = ({
  firestorePending,
  loginPending,
  setIsPending,
}) => {
  useEffect(() => {
    setIsPending(firestorePending || loginPending)
  }, [firestorePending, loginPending])
}
