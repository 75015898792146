import { useState } from "react"
import { useAuthContext } from "../../features/authentication"
import { useNavigate } from "react-router-dom"
import { useMultistepForm } from "../../hooks/basicHooks/useMultistepForm"
import { useClientSetup } from "../../hooks/useClientSetup"
//components
import { Button } from "react-bootstrap"
import {
  BasicFormBody,
  CheckboxInput,
  TextInput,
} from "../../components/basicFormComponents/"
import PriceCalculator from "../../components/PriceCalculator/PriceCalculator"
import TermsAndConditionsForAccountJSX from "../../components/legal/TermsAndConditionsForAccountJSX"
import CompanyInformationForm from "../../components/CompanyInformationForm"
import PersonalAccountForm from "../../components/PersonalAccountForm"
//****OTHER FORM COMPONENTS USED IN MULTIFORM ARE DEFINED BELOW*** */

const INITIAL_DATA = {
  companyName: "",
  companyAddress: "",
  companyAddress2: "",
  companyCity: "",
  companyState: "",
  companyZip: "",
  companyPhone: "",
  accountName: "",
  displayName: "",
  accountEmail: "",
  accountPassword: "",
  accountConfirmPassword: "",
  serviceLevelId: "2",
  paymentPlan: "annual",
  agreedToUserTerms: false,
  signedNameForAgreement: "",
}

export default function ClientSetupForm() {
  const navigate = useNavigate()
  const { dispatch } = useAuthContext()
  const [formError, setFormError] = useState("")
  const {
    error,
    isPending,
    resetError,
    companyNameIsAvailable,
    createClientAccount,
    createClientCompany,
  } = useClientSetup()

  //multistep form functionality
  const [data, setData] = useState(INITIAL_DATA)
  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <CompanyInformationForm updateFields={updateFields} {...data} />,
      <ServiceLevelForm updateFields={updateFields} {...data} />,
      <PersonalAccountFormForClient updateFields={updateFields} {...data} />,
    ])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    resetError()
    //on first step, check that the name of the company is available.
    if (currentStepIndex === 0) {
      const nameIsAvailable = await companyNameIsAvailable(data.companyName)
      if (!nameIsAvailable)
        return setFormError(
          "This Company Name is already in use. use an available companyName"
        )
    }
    // on step 3 (index 2), setup the client's account and the company under the sandbox tier automatically.
    if (currentStepIndex === 2) {
      if (!data.agreedToUserTerms)
        return setFormError("you must agree to the terms to continue.")
      //defined below
      await handlePersonalAccountFormSubmit({
        ...data,
        setFormError,
        createClientAccount,
        createClientCompany,
      })
    }

    //console.log(data)
    if (!isLastStep) return next()
    dispatch({ type: "REFRESH" })

    if (data.serviceLevelId !== "0")
      alert(
        "Your invoice has been emailed to the email associated with this new account. failure to pay within 7 days will result in limited or no access to this service. For questions on how to pay this invoice, email payments@elabtracker.com. Thank you!"
      )

    navigate(
      `/company/${data.companyName
        .toLowerCase()
        .trim()
        .replace(" ", "-")}/billing`
    )
  }

  return (
    <>
      <form
        className="multi-step-form in-modal"
        onSubmit={(e) => handleSubmit(e)}
        id="account-setup-form"
        name="account-setup-form"
      >
        <div className="multi-step-tracker">
          {currentStepIndex + 1} / {steps.length}
        </div>
        {/* form content from steps array */}
        {step}

        {error && <div className="error">{error}</div>}
        {formError && <div className="error">{formError}</div>}
      </form>
      {/* control */}
      {!isFirstStep && (
        <Button variant="primary" type="button" onClick={back}>
          Back
        </Button>
      )}
      <Button form="account-setup-form" type="submit" variant="primary">
        {isLastStep ? "Finish" : "Next"}
      </Button>

      {currentStepIndex === 1 && (
        <div className="mt-5">
          <h3>Price Calculator</h3>
          <PriceCalculator />
          <div className="d-flex">
            <p>Go to the </p>
            <span
              className="me-2 ms-2 align-center text-decoration-underline link-primary clickable fw-bold"
              onClick={() => window.open("/pricing", "_blank")}
            >
              Pricing Page
            </span>{" "}
            <p>for more details if needed (opens new tab).</p>
          </div>
        </div>
      )}
    </>
  )
}

//***individual form components used***

const ServiceLevelForm = ({ serviceLevelId, paymentPlan, updateFields }) => {
  const serviceOptions = [
    { label: "Sandbox", value: "0" },
    { label: "Basic", value: "1" },
    { label: "Advanced", value: "2" },
    { label: "Mega", value: "3" },
  ]
  const paymentOptions = [
    { label: "Monthly", value: "monthly" },
    { label: "Yealy", value: "annual" },
  ]
  return (
    <div>
      <h2>Select Your Service Level </h2>
      <span className="fw-4">
        (Use the calculator beklow to help you decide and see what is included)
      </span>
      <BasicFormBody
        formData={[
          {
            type: "radio",
            label: "Service Level",
            value: serviceLevelId,
            groupName: "serviceLevel",
            onChange: (e) => updateFields({ serviceLevelId: e.target.value }),
            options: serviceOptions,
            horizontal: true,
            required: true,
          },
          {
            type: "radio",
            label: "Payment Plan",
            value: paymentPlan,
            groupName: "paymentPlan",
            onChange: (e) => updateFields({ paymentPlan: e.target.value }),
            options: paymentOptions,
            horizontal: true,
            required: true,
          },
        ]}
      />
    </div>
  )
}

const PersonalAccountFormForClient = ({
  displayName,
  accountEmail,
  accountPassword,
  accountConfirmPassword,
  updateFields,
  signedNameForAgreement,
}) => {
  return (
    <PersonalAccountForm
      displayName={displayName}
      accountEmail={accountEmail}
      accountPassword={accountPassword}
      accountConfirmPassword={accountConfirmPassword}
      updateFields={updateFields}
    >
      <div>
        <p>
          Please review our terms and conditions below and accept them before
          continuing with the account setup.
        </p>
        {accountPassword !== "" &&
          accountConfirmPassword !== "" &&
          accountPassword === accountConfirmPassword && (
            <p className="text-muted text-center">Passwords Match</p>
          )}
        {accountPassword !== "" &&
          accountConfirmPassword !== "" &&
          accountPassword !== accountConfirmPassword && (
            <p className="error text-center">Passwords Do Not Match</p>
          )}

        <article className="legal-terms">
          <TermsAndConditionsForAccountJSX />
          <p className="mt-4">
            Signing below also indicates you have read and agreed to eLab
            Tracker's{" "}
            <span
              className="clickable text-muted text-decoration-underline"
              onClick={() => window.open("/legal/privacy")}
            >
              Privacy Policy
            </span>
          </p>
          <CheckboxInput
            label="The undersigned agrees to the terms and conditions contained herein"
            horizontal={true}
            onChange={(e) => {
              updateFields({ agreedToUserTerms: e.target.checked })
            }}
          />

          <TextInput
            label="Name"
            horizontal={true}
            required={true}
            value={signedNameForAgreement}
            onChange={(e) =>
              updateFields({ signedNameForAgreement: e.target.value })
            }
          />
        </article>
      </div>
    </PersonalAccountForm>
  )
}

const handlePersonalAccountFormSubmit = async ({
  setFormError,
  createClientAccount,
  createClientCompany,
  accountConfirmPassword,
  accountPassword,
  displayName,
  accountEmail,
  companyName,
  companyAddress,
  companyAddress2,
  companyCity,
  companyState,
  companyZip,
  companyPhone,
  serviceLevelId,
  paymentPlan,
  agreedToUserTerms,
  signedNameForAgreement,
}) => {
  if (!agreedToUserTerms) return setFormError("You must agree to the terms")
  if (accountConfirmPassword !== accountPassword)
    return setFormError("Passwords do not match")
  // create the client user account
  const { user } = await createClientAccount({
    displayName,
    email: accountEmail,
    password: accountPassword,
    companyName,
    companyId: companyName.toLowerCase().trim().replace(" ", "-"),
    agreedToUserTerms,
    signedNameForAgreementofTermsAndConditions: signedNameForAgreement,
  })
  //create the company in sandbox tier
  await createClientCompany({
    name: companyName,
    address: companyAddress,
    address2: companyAddress2,
    city: companyCity,
    state: companyState,
    zip: companyZip,
    phone: companyPhone,
    primaryAccount: {
      displayName,
      email: accountEmail,
      rights: "primary",
      uid: user.uid,
    },
    companyId: companyName.toLowerCase().trim().replace(" ", "-"),
    serviceLevelId,
    paymentPlan,
  })
}
