export const infoLibrary = {
  primaryrights: {
    id: "2",
    title: "Primary rights",
    tags: ["rights", "primary rights", "definition"],
    text: 'A user with "primary" rights has all permissions of the "admin" rights, and access to billing information',
    keywords: "permissions permission primary rights billing",
  },
  adminrights: {
    id: "3",
    title: "Admin rights",
    tags: ["rights", "admin rights", "definition"],
    text: 'A user with "admin" rights has all permissions of the "moderator" rights, and permissions to add and delete documents as well as edit histories and add users',
    keywords:
      "permissions permission admin rights additional docs documents delete edit add users",
  },
  moderatorrights: {
    id: "4",
    title: "moderator rights",
    tags: ["rights", "moderator rights", "definition"],
    text: 'A user with "moderator" rights has permissions to add and edit equipment and evaluations',
    keywords:
      "permissions permission moderator rights add equipment evaluation",
  },
  userrights: {
    id: "5",
    title: "User rights",
    tags: ["rights", "user rights", "definition"],
    text: 'A user with "user" is essentially a readonly user with the exception of being able to send messages to other staff if the company has at least tier 2 subscription.',
    keywords: "permissions permission user rights",
  },
  equipmentnotification: {
    id: "6",
    title: "Equipment Notifications",
    tags: ["equipment", "notification", "system"],
    text: "The system will send out notifications for upcoming (due this month) and past due calibrations",
    keywords: "equipment notification calibration automatic weekly daily",
  },
  evaluationnotification: {
    id: "7",
    title: "Evaluation Notifications",
    tags: ["evaluation", "notification", "system"],
    text: "The system will send out notifications for upcoming (due this month) and past due evaluations",
    keywords: "evaluation notification compentent automatic weekly daily",
  },
  editingequipmentpieces: {
    id: "8",
    title: "Editing Equipment Pieces",
    tags: ["equipment", "moderator rights", "admin rights", "primary rights"],
    text: "Moderators may edit any unset data, or any custody, calibration, or status data for each individual piece of equipment. However, the status or calibration status of the equipment may not permit the action you are trying to add.",
    keywords: "",
  },
  equipmentcustodydefinition: {
    id: "9",
    title: "Equipment Custody Definition",
    tags: ["equipment", "definition"],
    text: "Equipment custody at any given point is the person or lab that is responsible for that equipment at that time. This cannot be altered if the equipment is out of service or out of calibration.",
    keywords: "equipment custody change",
  },
  equipmentstatusdefinition: {
    id: "10",
    title: "Equipment Status Definition",
    tags: ["equipment", "definition"],
    text: "There are three options for status: in service = usable and in calibration; out of service = 'out of calibration, likely still useable; discarded = broken or thrown out. Status cannot be updated if the equipment is out of calibration.",
    keywords: "equipment status change",
  },
  internaldocumentcategories: {
    id: "11",
    title: "Internal Document Categories",
    tags: ["documents", "definition", "admin rights", "primary rights"],
    text: "Internal documents will not be seen by auditors that have been given access to the company's eLab Tracker for auditing purposes.",
    keywords: "additional docs documents categories category",
  },
  notificationfrequency: {
    id: "12",
    title: "Notification Frequency",
    tags: [
      "settings",
      "system",
      "admin rights",
      "primary rights",
      "notification",
    ],
    text: "Daily will send out notificaions daily at 7:00 AM EST; weekly will sent notifications every Monday at 7:00 AM EST",
    keywords: "daily weekly switch",
  },
  changenavbarlogo: {
    id: "13",
    title: "Changing the Navbar Logo",
    tags: ["settings", "admin rights", "primary rights", "logo"],
    text: "Deleting your Navbar logo will result in the first approximately 14 letters of your compnanyName to appear. The logo image should be a png (preferred) or jpg that is roughtly 250 by 85 pixels",
    keywords: "logo navigation change",
  },
  settingoverageallowances: {
    id: "14",
    title: "Setting Overage Allowances",
    tags: ["billing", "primary rights", "service agreement"],
    text: "Your account will only be charged for units used. Adjsuting the overage allowance will allow admins and/or moderators to add equipment, documents, etc... up to the allowance set.",
    keywords: "billing overage from service agreement allowance no warnings",
  },
  changingpaymentplans: {
    id: "15",
    title: "Changing Payment Plans",
    tags: ["billing", "payment plan", "service agreement"],
    text: "Change how often you pay, prices may vary based on your service agreement - longer term subscriptions generally result in lower overall prices. Changes will occur after next scheduled invoice is issued. To change allowances and other parameters/prices of your service agreement, contact payments@elabtracker.com or sales@elabtracker.com",
    keywords: "billing plan monthly annual annually change service agreement",
  },
  deletingequipment: {
    id: "16",
    title: "Deleting Equipment",
    tags: ["equipment", "admin"],
    keywords: "deleting equipment admin rights charge billing",
    text: "You will not be charged for deleted equipment. The Equipment information will not be deleted from the database. Deleting equipment will make that piece less accessible from the company's portal. The status will be changed to discarded as well as the custody.",
  },
  removingausersrights: {
    id: "17",
    title: "Removing a User's Rights",
    tags: ["equipment", "admin"],
    keywords: "deleting equipment admin rights charge billing",
    text: "You will not be charged for deleted equipment. The Equipment information will not be deleted from the database. Deleting equipment will make that piece less accessible from the company's portal. The status will be changed to discarded as well as the custody.",
  },
  position: {
    id: "18",
    title: "Position",
    tags: ["definition"],
    keywords: "definition user position",
    text: "The positions available for employees and their descriptions are customizable from company settings.",
  },
  positionsthatcanevaluate: {
    id: "19",
    title: "Positions that can Evaluate",
    tags: ["evaluation"],
    keywords: "evaluation user position",
    text: "If your employees are evaluated on certain skills/standards by other employees, the evaluating employee must have a position that can evaluate.",
  },
  deletingevaluations: {
    id: "20",
    title: "Deleting Evaluations",
    tags: ["evaluations", "admin"],
    keywords: "deleting evaluations admin rights",
    text: "The Evaluation information will not be deleted from the database. Deleting evalustions will make that data less accessible from the company's portal and notifications wwill not be sent regarding that evaluation type.",
  },
  upgradefromsandbox: {
    id: "21",
    title: "Upgrade From Sandbox",
    tags: ["service level", "sand box"],
    keywords: "upgrade service level sandbox pricing pay",
    text: "You can upgrade from the Sandbox tier to any of the set pricing tiers available, check the pricing page or pricing calculator for details of each tier.",
  },
  equipmentdefaultcustodydefinition: {
    id: "22",
    title: "Equipment Default Custody Definition",
    tags: ["admin", "equipment", "definition"],
    keywords: "",
    text: "The user set as the default custody will be the one assigned to the equipment by default. For example, when the equipment is taken out of service.",
  },
}
