import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useStorage } from "../../../../hooks/useStorage"
import { useCompanyContext } from "../../../company"
import { useEquipmentHistory } from "../../hooks/useEquipmentHistory"
import { format } from "date-fns"

export default function EquipmentPieceReportLoader({
  children,
  equipment,
  date,
}) {
  const { companyId } = useParams()
  const { companyData } = useCompanyContext()
  //   const { error, isPending, getAuditDocument } = useGetAudit()
  const error = null
  const isPending = false

  const [equipmentData, setEquipmentData] = useState(null)
  const [displayData, setDisplayData] = useState(null)

  //functions to get historical status
  const { getLastCustody, getLastStatus, getLastCalibration } =
    useEquipmentHistory()

  useEffect(() => {
    //determine if thequipment is an equipmentId, or equipmentData
    //if it is an ID need to load equipment if it is the full object, need to just set it in state.
    if (typeof equipment === "String") {
      //TODO: fetchData from document - dont forget a then not await
      console.log("string was passed, need to fetch")
    } else {
      //   console.log("not passed a string for equipment: ", equipment)
      return setEquipmentData(equipment)
    }
  }, [equipment])

  useEffect(() => {
    if (equipmentData) {
      //get the data for the report based on equipmentData and the date
      let historicalStatus,
        historicalCustody,
        historicalCalibration = null
      if (format(date, "yyyy/MM/dd") !== format(new Date(), "yyyy/MM/dd")) {
        const { lastCustody } = getLastCustody(equipmentData.history, date)
        historicalCustody = lastCustody?.custody

        const { lastStatus } = getLastStatus(equipmentData.history, date)
        historicalStatus = lastStatus?.status || "unset"

        const { lastCalibration } = getLastCalibration(
          equipmentData.history,
          date
        )
        historicalCalibration = lastCalibration?.dateOfAction
      }
      return setDisplayData({
        ...equipmentData,
        dateOfReport: date,
        historicalCustody,
        historicalStatus,
        historicalCalibration,
      })
    }

    return setDisplayData(null)
  }, [equipmentData])

  //display error or loading
  if (error)
    return (
      <div className="error">
        <p>
          The database could not find the data for the equipment. Or, if it did,
          you do not have permission to view the data:
        </p>
        <p>{error}</p>
      </div>
    )

  if (isPending || !displayData || typeof displayData === "undefined")
    return <p>...loading</p>

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return (
            displayData &&
            React.cloneElement(child, { displayData, companyData })
          )
        }
        return child
      })}
    </>
  )
}
