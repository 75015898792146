import React, { useEffect, useState } from "react"
import { useFirestore } from "../../../../hooks/useFirestore"

export default function AuditFormLoader({ children }) {
  const { getDocument, error, isPending } = useFirestore()
  const [labCompanies, setLabCompanies] = useState(null)

  useEffect(() => {
    getDocument("public/companies").then((res) => {
      setLabCompanies(res.list)
    })
  }, [])

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return labCompanies && React.cloneElement(child, { labCompanies })
        }
        return child
      })}
    </>
  )
}
