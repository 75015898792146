import { useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useCompanyContext } from "../../../company/index"
import { useAuthContext } from "../../../authentication"
import { useStorage } from "../../../../hooks/useStorage"
import {
  useGetEquipmentType,
  useChangeEquipmentType,
  EquipmentTypeDefaultCustodyModal,
} from "../../index"
import { Timestamp } from "firebase/firestore"
import { verifyPDF } from "../../../../util/verifyFileInput"

//layout
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
//components
import { EquipmentList } from "../../index"
import DownloadFileButton from "../../../../components/basicComponents/DownloadFileButton"
import DocumentList from "../../../../components/DocumentList"
import { Button } from "react-bootstrap"
import ViewPDFButton from "../../../../components/basicComponents/ViewPDFButton"

export default function EquipmentType() {
  const { typePrefix } = useParams()
  const { equipmentTypes, companyData, documentsOverMaxPermitted } =
    useCompanyContext()
  const { user } = useAuthContext()

  const {
    changeEquipmentType,
    isPending: firestorePending,
    error: firestoreError,
  } = useChangeEquipmentType()

  const {
    uploadFile,
    deleteFile,
    downloadPDF,
    isPending: uploadPending,
    error: uploadError,
  } = useStorage()
  const [formError, setFormError] = useState("")

  const { type, error } = useGetEquipmentType(equipmentTypes, typePrefix)

  //control
  const [changingCalForm, setChangingCalForm] = useState(false)
  const [newDocument, setNewDocument] = useState("")

  //changing type name control
  const [changingTypeName, setChangingTypeName] = useState(false)
  const newNameRef = useRef()
  const changeName = async () => {
    if (!user.admin)
      return setFormError("You must be an admin to perform this action")
    if (newNameRef.current.value === "")
      return setFormError("Please enter a value or cancel")
    await changeEquipmentType({
      companyId: companyData.companyId,
      equipmentTypes,
      typePrefix: type.prefix,
      typeChangeObject: { ...type, name: newNameRef.current.value },
    })
    if (firestoreError) return setFormError(firestoreError)
    setChangingTypeName(false)
  }

  //changing the calibrationFrequency
  const [changingCalibrationFrequency, setChangingCalibrationFrequency] =
    useState(false)
  const newCalFrequencyRef = useRef()

  const changeFrequency = async (e) => {
    e.preventDefault()
    if (!user.admin)
      return setFormError("You must be a admin to perform this action")
    if (
      newCalFrequencyRef.current.value === 0 ||
      newCalFrequencyRef.current.value === ""
    )
      return setFormError("Please enter a value or cancel")

    //confirm the action if it is a change of the calibrationRequired value
    let cont = true
    if (!type.calibrationRequired) {
      const confirmMessage =
        "You are making calibration required for this equipment type. Those with custody of this equipment will begin to recieve notifications regarding due and overdue calibrations unless you reverse this action. Do you want to continue?"
      cont = window.confirm(confirmMessage)
    }
    if (!cont) {
      setChangingCalibrationFrequency(false)
      return alert("action aborted - nothing changed")
    }

    await changeEquipmentType({
      companyId: companyData.companyId,
      equipmentTypes,
      typePrefix: type.prefix,
      typeChangeObject: {
        ...type,
        calibrationFrequency: newCalFrequencyRef.current.value,
        calibrationRequired: true,
      },
    })
    if (firestoreError) return setFormError(firestoreError)
    setChangingCalibrationFrequency(false)
  }

  const removeCalibrationScheduling = async (e) => {
    e.preventDefault()
    if (!user.admin)
      return setFormError("You must be a admin to perform this action")
    const confirmMessage =
      "You are removing the requirement of equipment calibration from this type of asset/equipment. This action will make it impossible to calibrate equipment of this type unless this action is reversed. This action will also permanently delete the blank calibration form for this equipment type if there is one uploaded. Do you want to continue?"
    let cont = window.confirm(confirmMessage)
    if (!cont) {
      setChangingCalibrationFrequency(false)
      return alert("the action has been aborted - nothing has changed.")
    }

    if (type.documentURL) await deleteFile(type.documentURL)

    await changeEquipmentType({
      companyId: companyData.companyId,
      equipmentTypes,
      typePrefix: type.prefix,
      typeChangeObject: {
        ...type,
        calibrationFrequency: null,
        calibrationRequired: false,
        documentURL: null,
      },
    })

    if (firestoreError) return setFormError(firestoreError)

    setChangingCalibrationFrequency(false)
  }

  //file change for calibration form
  const handleFileChange = (e) => {
    setNewDocument(null)
    setFormError("")
    const { pdf: selected, error: fileError } = verifyPDF(e.target.files[0])

    if (fileError) return setFormError(fileError)
    setNewDocument(selected)
  }

  //form submitions
  const handleSubmitNewCal = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.admin)
      return setFormError(
        "You need to have admin rights to add a new calibration document to the database"
      )
    if (!newDocument)
      return setFormError("A file needs to be selected before submiting")
    if (documentsOverMaxPermitted)
      return setFormError(
        "Your company's account is at the maximum permitted files stored. See your primary asccount manager to increase the allowance."
      )

    //add document into firebase storage for calibration
    const formPath = `companies/${companyData.companyId}/equipment/${type.prefix}/calibrationForm_Master`
    const metadata = {
      contentType: "application/pdf",
    }
    const documentURL = await uploadFile(formPath, newDocument, metadata)

    //update firestore
    await changeEquipmentType({
      companyId: companyData.companyId,
      equipmentTypes,
      typePrefix: type.prefix,
      typeChangeObject: { ...type, documentURL },
    })

    //set back to default view
    setFormError("")
    setChangingCalForm(false)
    setNewDocument("")
  }

  //other/additional Documents funtionality
  const handleDownload = async (doc) => {
    const fileName = `${type.name}_${doc.name}`
    await downloadPDF(doc.documentURL, `${fileName}.pdf`)
  }

  const handleUpload = async (file, docName, replacingExistingDoc) => {
    if (documentsOverMaxPermitted)
      return setFormError(
        "Your company's account is at the maximum permitted files stored. See your primary asccount manager to increase the allowance."
      )
    //upload to storage
    const fileName = `${type.prefix}_${docName}`
    const formPath = `companies/${companyData.companyId}/equipment/${type.prefix}/${fileName}`
    const metadata = {
      contentType: "application/pdf",
    }
    const documentURL = await uploadFile(formPath, file, metadata)
    if (uploadError) return setFormError(uploadError)

    //update firestore
    const documentObject = {
      documentURL,
      createdBy: { displayName: user.displayName, uid: user.uid },
      name: docName.trim(),
      createdAt: Timestamp.fromDate(new Date()),
    }
    let newList = []
    if (replacingExistingDoc) {
      newList = type.otherDocuments.filter(
        (d) => d.name.toLowerCase().trim() !== docName.toLowerCase().trim()
      )
    } else {
      newList = type.otherDocuments
    }
    //add item, sort list
    newList = [...newList, documentObject].sort((a, b) => {
      const textA = a.name.toUpperCase()
      const textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })

    await changeEquipmentType({
      companyId: companyData.companyId,
      equipmentTypes,
      typePrefix: type.prefix,
      typeChangeObject: { ...type, otherDocuments: newList },
    })
  }

  const handleDelete = async (doc) => {
    //update firestore object

    let newList = type.otherDocuments.filter((d) => doc.name !== d.name)
    await changeEquipmentType({
      companyId: companyData.companyId,
      equipmentTypes,
      typePrefix: type.prefix,
      typeChangeObject: { ...type, otherDocuments: newList },
    })
  }

  //modal control:
  const [
    showChangeTypeDefaultCustodyModal,
    setShowChangeTypeDefaultCustodyModal,
  ] = useState(false)
  const changeDefaultTypeCustodyModalShow = () =>
    setShowChangeTypeDefaultCustodyModal(true)
  const changeDefaultTypeCustodyModalHide = () =>
    setShowChangeTypeDefaultCustodyModal(false)

  if (!type) return <div className="laoding">Loading...</div>
  if (error) return <div className="error">{error}</div>

  return (
    <ContentOptionalRight
      contentTitle={
        <>
          Equipment Type:
          {!changingTypeName && <span className="fw-bold">{type.name}</span>}
          {changingTypeName && <input type="text" ref={newNameRef} />}
          {changingTypeName && (
            <Button
              variant="primary"
              className="mt-2"
              onClick={changeName}
              disabled={firestorePending}
            >
              Save
            </Button>
          )}
          {user.admin && (
            <span
              className="clickable text-decoration-underline text-muted fs-6 ms-4"
              onClick={() => {
                setChangingTypeName((prev) => !prev)
                setFormError("")
              }}
            >
              {!changingTypeName && "change"}
              {changingTypeName && "cancel"}
            </span>
          )}
          {changingTypeName && formError && (
            <p className="error fs-5">{formError}</p>
          )}
        </>
      }
      content={
        <>
          <div className="mb-5">
            <div className="d-flex">
              <p className="fw-bold">Calibration Frequency:</p>
              {typeof type.calibrationRequired === "undefined" ||
              type.calibrationRequired ? (
                <>
                  <p className="ms-2">{type.calibrationFrequency}</p>
                  <p className="ms-2"> months</p>
                </>
              ) : (
                <p className="ms-2">Calibration Not Required</p>
              )}
              {changingCalibrationFrequency && (
                <>
                  <form
                    id="newCalibrationFrequencyForm"
                    onSubmit={changeFrequency}
                  >
                    <input
                      className="ms-3 me-3"
                      style={{ maxWidth: "100px", maxHeight: "2em" }}
                      type="number"
                      ref={newCalFrequencyRef}
                      min="1"
                      required
                    />
                  </form>
                  <Button
                    type="submit"
                    form="newCalibrationFrequencyForm"
                    variant="primary"
                    onClick={changeFrequency}
                    disabled={firestorePending}
                  >
                    Save
                  </Button>
                  {(typeof type.calibrationRequired === "undefined" ||
                    type.calibrationRequired) && (
                    <Button
                      type="submit"
                      form="newCalibrationFrequencyForm"
                      variant="danger"
                      onClick={removeCalibrationScheduling}
                      disabled={firestorePending}
                      className="ms-2"
                    >
                      Not Required
                    </Button>
                  )}
                </>
              )}
              {user.admin && (
                <span
                  className="clickable ms-3 fs-6 text-decoration-underline text-muted"
                  onClick={() => {
                    setChangingCalibrationFrequency((prev) => !prev)
                    setFormError("")
                  }}
                >
                  {!changingCalibrationFrequency && "change"}
                  {changingCalibrationFrequency && "cancel"}
                </span>
              )}
            </div>
            {changingCalibrationFrequency && formError && (
              <p className="error">{formError}</p>
            )}

            <div className="d-flex mt-2">
              <div className="me-5">
                <p>
                  <span className="fw-bold">Equipment Category:</span>{" "}
                  {type.category}
                </p>
              </div>
              <div className="ms-5">
                <p>
                  <span className="fw-bold">Equipment Type Prefix:</span>{" "}
                  {type.prefix}
                </p>
              </div>
            </div>

            <div className="d-flex mt-3">
              <p className="fw-bold">Manager for Equipment Type:</p>
              <p className="ms-2">
                {type.defaultCustody
                  ? type.defaultCustody.displayName
                  : "not set"}
              </p>
              {user.admin && (
                <span
                  className="clickable ms-3 fs-6 text-decoration-underline text-muted"
                  onClick={changeDefaultTypeCustodyModalShow}
                >
                  change
                </span>
              )}
            </div>
            <hr />

            {/* actions for calibration form render if the equipment type requires calibration*/}
            {(typeof type.calibrationRequired === "undefined" ||
              type.calibrationRequired) && (
              <>
                {/* If there is a calibration form */}
                {type.documentURL && (
                  <>
                    <div className="d-flex">
                      <h3 className="fs-4">Blank Calibration Form</h3>
                      {user.admin && (
                        <div className="ms-5">
                          <p
                            className="text-muted clickable text-decoration-underline ms-3"
                            onClick={() => setChangingCalForm(true)}
                          >
                            change
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="d-flex mt-2">
                      <div className="me-5">
                        <DownloadFileButton
                          fileName={`${type.name}_claibration-form_blank.pdf`}
                          docURL={type.documentURL}
                          buttonText="Download"
                          disabled={uploadPending || firestorePending}
                        />
                      </div>
                      <div>
                        <ViewPDFButton
                          buttonText="View in new tab"
                          docURL={type.documentURL}
                          disabled={uploadPending || firestorePending}
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* If there is no calibration form */}
                {!type.documentURL && user.moderator && (
                  <div className="d-flex">
                    <p className="text-muted">
                      No blank calibration form available
                    </p>
                    <button
                      className="btn btn-sm text-bg-primary ms-5"
                      onClick={() => setChangingCalForm(true)}
                    >
                      Add Blank Calibration Form
                    </button>
                  </div>
                )}

                {/* Changing Cal Form form */}
                {changingCalForm && (
                  <>
                    <form
                      onSubmit={(e) => handleSubmitNewCal(e)}
                      className="d-flex mt-2"
                    >
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        className="form-control mb-2"
                      />
                      <button
                        className="btn text-bg-primary ms-4"
                        type="submit"
                      >
                        Submit
                      </button>
                      <p
                        className="ms-3 text-muted text-decoration-underline clickable"
                        onClick={() => {
                          setNewDocument("")
                          setChangingCalForm(false)
                          setFormError("")
                        }}
                      >
                        cancel
                      </p>
                    </form>
                    {formError && <p className="error">{formError}</p>}
                  </>
                )}

                <hr />
              </>
            )}

            <DocumentList
              listTitle="Additional Documents"
              buttonText="Add a new additional document"
              documents={type.otherDocuments}
              rightsToAdd={user.moderator}
              rightsToDelete={user.admin}
              handleUpload={handleUpload}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              documentsOverMaxPermitted={documentsOverMaxPermitted}
            />
          </div>
          <h2 className="section-header">Equipment - {type.name}</h2>
          <EquipmentList selectedType={type.prefix} />
          {showChangeTypeDefaultCustodyModal && (
            <EquipmentTypeDefaultCustodyModal
              show={changeDefaultTypeCustodyModalShow}
              handleClose={changeDefaultTypeCustodyModalHide}
              type={type}
            />
          )}
        </>
      }
    />
  )
}
