import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../authentication/index"

//layout
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
//components
import { FolderView } from "../../documentStorage"
import { CompanyEvaluationList } from "../../evaluations/index"
import { EquipmentList } from "../../equipment/index"
import CollapseIcon from "../../../components/basicComponents/CollapseIcon"
import { useCompanyContext } from "../hooks/useCompanyContext"

export default function DocumentStore() {
  const { companyId } = useParams()
  const { user } = useAuthContext()
  const { isAuditView, auditId } = useCompanyContext()

  //collapseControl
  const [hideEquipment, setHideEquipment] = useState(true)
  const [hideEvaluations, setHideEvaluations] = useState(true)

  return (
    <>
      <ContentOptionalRight
        contentTitle="Company Document Store"
        content={<FolderView user={user} />}
      />
      {/* ***********DEFAULT LISTS************ */}
      {/* Equipment and Evaluation Documents are held within their own pages - accessible from their respective lists */}
      <h4 className="text-center mt-5">Documents stored elsewhere:</h4>
      <hr />
      {/* User Equipment Dropdown*/}
      <h4 className="mb-3 d-flex align-items-baseline">
        <span>Equipment Calibration</span>
        <span className="ms-3 me-3 fs-6">(view equipment for docs)</span>
        <span
          className="clickable"
          onClick={() => setHideEquipment((prev) => !prev)}
        >
          <CollapseIcon collapsed={hideEquipment} />
        </span>
      </h4>
      {!hideEquipment && <EquipmentList embedded />}
      <hr />
      {/* User Evaluations DropDown */}
      <h4 className="mb-3 d-flex align-items-baseline">
        <span>Staff Evaluations</span>
        <span className="ms-3 me-3 fs-6">
          (view evaluation for docs / logs)
        </span>
        <span
          className="clickable"
          onClick={() => setHideEvaluations((prev) => !prev)}
        >
          <CollapseIcon collapsed={hideEvaluations} />
        </span>
      </h4>
      {!hideEvaluations && <CompanyEvaluationList embedded />}{" "}
    </>
  )
}
