import { useState, useEffect } from "react"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { Modal, Button } from "react-bootstrap"

import { useAuthContext } from "../../authentication"
import { useCompanyContext } from "../../company/index"
import { useEvaluation, useEvaluationType } from "../index"

export default function AddEvaluationTypeModal({ show, handleClose }) {
  const { user } = useAuthContext()
  const { evaluationCategories } = useCompanyContext()
  const {
    addEvaluationType,
    isPending: typePending,
    firestoreError: typeError,
  } = useEvaluationType()
  const {
    isPending: categoryPending,
    firestoreError: categoryError,
    addEvaluationCategory,
  } = useEvaluation()

  const [addCat, setAddCat] = useState(false)
  //form entry state
  const [selectedCategory, setSelectedCategory] = useState("")
  const [newCategory, setNewCategory] = useState("")
  const [typeName, setTypeName] = useState("")
  const [standard, setStandard] = useState("")
  const [formError, setFormError] = useState("")

  //format categories to be used by react-select
  const categories = formatSelectArray(evaluationCategories)

  const handleAddCategory = async () => {
    setFormError("")
    //no blank
    if (!newCategory)
      return setFormError(
        "If adding a new category, the field cannot be left blank"
      )

    await addEvaluationCategory({ newCategory })

    setNewCategory("")
    setAddCat(false)
  }

  //format evaluation type name by getting rid of certain special characters
  const getName = (e) => {
    if (e.target.value.includes("#"))
      setFormError('"#" is not allowed, replacing with "No')
    if (e.target.value.includes(".")) setFormError('"." is not allowed')
    if (e.target.value.includes("?")) setFormError('"?" is not allowed')
    if (e.target.value.includes("/"))
      setFormError('"/" is not allowed, replacing with "-"')

    return setTypeName(
      e.target.value
        .replace("/", "-")
        .replace("#", "No")
        .replace("?", "")
        .replace(".", "")
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (newCategory) return
    if (!user.moderator)
      return setFormError(
        "you much have moderator rights to add an evaluation type to the database"
      )
    setFormError("")

    //make sure required fields are filled
    if (!selectedCategory)
      return setFormError("You must select a category from the drop-down menu")
    if (!typeName) return setFormError("Evaluation name is required")
    if (!standard)
      return setFormError(
        "Standard is required - this is the standard which the user has been evaluated on."
      )

    const { error } = await addEvaluationType({
      category: selectedCategory.value,
      name: typeName,
      standard,
      user,
    })
    if (error) return setFormError(error)

    handleModalClose()
  }

  const handleModalClose = () => {
    formReset()
    handleClose()
  }

  const formReset = () => {
    setSelectedCategory("")
    setTypeName("")
    setStandard("")

    setFormError("")
  }

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Evaluation Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to add an evaluation type
            </p>
          )}
          <form className="" onSubmit={handleSubmit}>
            <span>Select Evaluation Category*: </span>
            <Select
              onChange={(option) => setSelectedCategory(option)}
              options={categories}
            />
            {!addCat && (
              <div
                className="text-muted"
                role="button"
                onClick={() => setAddCat(true)}
              >
                add other category
              </div>
            )}

            {addCat && (
              <div className="form-group">
                <label>
                  <span>New Category*: </span>
                  <input
                    required
                    type="text"
                    onChange={(e) => setNewCategory(e.target.value)}
                    value={newCategory}
                    className="form-control"
                  />
                </label>

                <button
                  className="btn text-bg-primary"
                  onClick={() => handleAddCategory()}
                >
                  Add Category
                </button>
                <button
                  className="btn text-bg-secondary"
                  onClick={() => {
                    setAddCat(false)
                    setNewCategory("")
                    setFormError("")
                  }}
                >
                  Cancel
                </button>
              </div>
            )}

            <label>
              <span>Evaluation Name*: </span>
              <input
                required
                type="text"
                disabled={addCat}
                onKeyDown={(e) => {
                  if (e.key === "/") {
                    setFormError(" '/' characters will be replayed with '-'")
                  }
                }}
                onBlur={() => setFormError("")}
                onChange={(e) => getName(e)}
                value={typeName}
                className="form-control"
              />
            </label>

            <label>
              <span>Testing Standard*: </span>
              <input
                required
                type="text"
                disabled={addCat}
                onChange={(e) => setStandard(e.target.value)}
                value={standard}
                className="form-control"
              />
            </label>

            {typeError && <div className="error">{typeError}</div>}
            {categoryError && <div className="error">{categoryError}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={typePending || categoryPending || addCat}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={typePending || categoryPending || addCat}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
