import { useState } from "react"

//layout
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
//components
import EquipmentTypeList from "../../../equipment/components/EquipmentTypeList"

export default function LabView() {
  //search the equipment
  const [searchTerm, setSearchTerm] = useState("")

  return (
    <>
      <ContentOptionalRight
        contentTitle="Equipment/Asset Types"
        content={
          <>
            <input
              className="ms-5 mb-2"
              placeholder="Search by name, id prefix, or category..."
              style={{ height: "2.5em", width: "40%" }}
              type="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <EquipmentTypeList searchTerm={searchTerm} />
          </>
        }
      />
    </>
  )
}
