import Select from "react-select"
import DateInput from "../../../../components/basicFormComponents/DateInput"
import { format } from "date-fns"

export default function EquipmentPieceForm({
  types,
  pieceNumber,
  pieceID,
  serialNumber,
  make,
  model,
  selectedType,
  acquisitionDate,
  updateFields,
}) {
  const updateAcquisitionDate = (newDate) =>
    updateFields({ acquisitionDate: newDate })

  return (
    <div>
      <span>Equipment Type*:</span>
      <Select
        onChange={(option) =>
          updateFields({
            selectedType: option,
            equipmentCategory: option.value.category,
          })
        }
        options={types}
        value={selectedType}
      />

      <label>
        <span>equipment number*: </span>
        <input
          required
          type="text"
          onChange={(e) => updateFields({ pieceNumber: e.target.value })}
          value={pieceNumber}
          className="form-control"
        />
      </label>
      <span className="text-muted">equiment ID: {pieceID}</span>

      <label>
        <span>serial number:</span>
        <input
          type="text"
          onChange={(e) => updateFields({ serialNumber: e.target.value })}
          value={serialNumber}
          className="form-control"
        />
      </label>

      <label>
        <span>equipment make:</span>
        <input
          type="text"
          onChange={(e) => updateFields({ make: e.target.value })}
          value={make}
          className="form-control"
        />
      </label>

      <label>
        <span>model: </span>
        <input
          type="text"
          onChange={(e) => updateFields({ model: e.target.value })}
          value={model}
          className="form-control"
        />
      </label>

      <DateInput
        label="acquisition date"
        value={acquisitionDate}
        setValue={updateAcquisitionDate}
        max={format(new Date(), "yyyy-MM-dd")}
      />
    </div>
  )
}
