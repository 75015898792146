import { useFirestore } from "../../../hooks/useFirestore"

export const useGetAudit = () => {
  const { isPending, error, getDocument } = useFirestore()

  const getAuditDocument = async ({ auditId }) => {
    const auditData = await getDocument(`audits/${auditId}`)

    return auditData
  }

  //   also need to get other audit information here

  return { error, isPending, getAuditDocument }
}
