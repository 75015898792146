import { getHours, getMinutes, getMonth, getYear } from "date-fns"

//takes a date in yyyy-mm-dd form and returned a time within that day when the function is fired
const getFullDate = (dateString) => {
  let currentHour = getHours(Date.now()).toString()
  let currentMinutes = getMinutes(Date.now()).toString()
  if (currentHour.length === 1) currentHour = `0${currentHour}`
  if (currentMinutes.length === 1) currentMinutes = `0${currentMinutes}`
  const currentTime = `${currentHour}:${currentMinutes}:00.00`

  const fullDate = `${dateString}T${currentTime}`
  //console.log(fullDate, Date.parse(fullDate))

  return new Date(Date.parse(fullDate))
}

const isAfterThisMonth = (dateString) => {
  const thisYear = getYear(Date.now())
  const thisMonth = getMonth(Date.now())
  const yearToCheck = getYear(dateString)
  const monthToCheck = getMonth(dateString)

  return (
    yearToCheck < thisYear ||
    (yearToCheck === thisYear && monthToCheck < thisMonth)
  )
}

const isAfterSpecificMonth = (dateToCheckAgainst, dateToCheck, inclusive) => {
  inclusive = inclusive || true
  const yearToCheckAgainst = getYear(dateToCheckAgainst)
  const monthToCheckAgainst = getMonth(dateToCheckAgainst)
  const yearToCheck = getYear(dateToCheck)
  const monthToCheck = getMonth(dateToCheck)

  if (inclusive) {
    return (
      yearToCheck > yearToCheckAgainst ||
      (yearToCheck === yearToCheckAgainst && monthToCheck > monthToCheckAgainst)
    )
  }
  return (
    yearToCheck > yearToCheckAgainst ||
    (yearToCheck === yearToCheckAgainst && monthToCheck >= monthToCheckAgainst)
  )
}

const determineOverdueStatus = (dateNeeded) => {
  if (typeof dateNeeded === "undefined") return null
  //get year and month and check if overdue, due or not due (return null)
  const currentMonth = new Date().getUTCMonth()
  const monthNeeded = dateNeeded.getUTCMonth()
  const currentYear = new Date().getUTCFullYear()
  const yearNeeded = dateNeeded.getUTCFullYear()

  if (
    yearNeeded < currentYear ||
    (monthNeeded < currentMonth && yearNeeded === currentYear)
  ) {
    return "overdue"
  }
  if (yearNeeded === currentYear && monthNeeded === currentMonth) {
    return "now"
  }

  return null
}

export {
  getFullDate,
  isAfterThisMonth,
  isAfterSpecificMonth,
  determineOverdueStatus,
}
