import { serverTimestamp } from "firebase/firestore"
import { useFirestore } from "../../../hooks/useFirestore"

const useAddUsersToAudit = () => {
  const {
    error: firestoreError,
    isPending: firestorePending,
    updateDocument,
    getDocument,
  } = useFirestore()

  const addLabRep = async ({ auditId, userToAdd }) => {
    //get audit document
    const auditData = await getDocument(`audits/${auditId}`)

    //create an update object for audit - add the userToAdd's uid to uidsPermitted and the userToAdd to the auditorsPermitted
    const updateObject = {
      uidsPermitted: [...auditData.uidsPermitted, userToAdd.uid],
      labStaffPermitted: [...auditData.labStaffPermitted, userToAdd],
      updatedLast: serverTimestamp(),
    }

    await updateDocument(`audits/${auditId}`, updateObject)
    if (firestoreError)
      return {
        success: false,
        error: `there was a problem updating the audit data in the firestore. Firestore says: ${firestoreError}`,
      }

    //userDoc will *should* then automagically be updated (firebase function)
    return { success: true, error: null, response: "success" }
  }

  const addAuditor = async ({ auditId, userToAdd }) => {
    //get audit document
    const auditData = await getDocument(`audits/${auditId}`)

    console.log(auditData, userToAdd)

    //create an update object for audit - add the userToAdd's uid to uidsPermitted and the userToAdd to the auditorsPermitted
    const updateObject = {
      uidsPermitted: [...auditData.uidsPermitted, userToAdd.uid],
      auditorsPermitted: [...auditData.auditorsPermitted, userToAdd],
      updatedLast: serverTimestamp(),
    }

    await updateDocument(`audits/${auditId}`, updateObject)
    if (firestoreError)
      return {
        success: false,
        error: `there was a problem updating the audit data in the firestore. Firestore says: ${firestoreError}`,
      }

    //userDoc will *should* then automagically be updated (firebase function)
    return { success: true, error: null, response: "success" }
  }

  return { addAuditor, addLabRep, firestoreError, firestorePending }
}

export { useAddUsersToAudit }
