import React from "react"
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer"
import ReportTemplate from "../ReportTemplate"
import ReportHeader from "../ReportHeader"
import ReportFooter from "../ReportFooter"
import EquipmentPieceReportBody from "./EquipmentPieceReportBody"

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
  body: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
})

export default function EquipmentPieceReportRender({
  companyData,
  displayData,
}) {
  //   console.log(displayData)
  return (
    //ReportTemplate provides Header and Footer
    <ReportTemplate>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <ReportHeader
          title="Equipment Report"
          navLogoURL={companyData.navLogoURL}
          companyName={companyData.companyName}
        />

        {/* Body */}
        <View style={styles.body}>
          <EquipmentPieceReportBody
            displayData={displayData}
            companyData={companyData}
          />
        </View>

        {/* Footer */}
        <ReportFooter companyId={companyData.companyName} />
      </Page>
    </ReportTemplate>
  )
}

// export default function EquipmentPieceReportRender({ children, displayData }) {
//   return (
//     <div className="d-block">
//       <PDFViewer style={styles.documentContainer}>
//         <EquipmentPieceReportDocument displayData={displayData} />
//       </PDFViewer>{" "}
//     </div>
//   )
// }
