import { useEffect, useState } from "react"

const useGetFilteredEmployeeListData = (
  employeeList,
  currentFilter,
  searchBy,
  setSelectedData
) => {
  const [filteredData, setFilteredData] = useState([])

  //switch statement for filter followed by filtering the searce term which is an email or name
  useEffect(() => {
    let newList = []
    setSelectedData("")

    switch (currentFilter) {
      case "moderator":
      case "user":
        newList = employeeList.filter((e) => e.rights.includes(currentFilter))
        break
      case "admin":
        newList = employeeList.filter(
          (e) => e.rights.includes(currentFilter) || e.rights === "primary"
        )
        break
      case "needs evaluation":
        newList = employeeList.filter((e) => e.needsEvaluation)
        break
      case "all":
      default:
        newList = employeeList
    }

    newList = newList.filter((e) => {
      return (
        e.displayName
          .trim()
          .toLowerCase()
          .includes(searchBy.trim().toLowerCase()) ||
        e.email.trim().toLowerCase().includes(searchBy.trim().toLowerCase())
      )
    })

    setFilteredData(newList)
  }, [employeeList, currentFilter, searchBy])

  return filteredData
}

export { useGetFilteredEmployeeListData }
