import { useState } from "react"
import { useAuthContext } from "../features/authentication"
import { useCompanyContext } from "../features/company/hooks/useCompanyContext"
import { useStorage } from "../hooks/useStorage"
import { useFirestore } from "../hooks/useFirestore"
//components
import { Modal, Button } from "react-bootstrap"
import PhotoCropper from "./basicComponents/PhotoCropper"

export default function LogoCropperModal({
  show,
  handleClose,
  navLogoURL,
  storageUploadPath,
  firestoreDocumentPath,
}) {
  const { user } = useAuthContext()
  const [formError, setFormError] = useState("")

  const {
    uploadFile,
    deleteFile,
    error: uploadError,
    isPending: uploadPending,
  } = useStorage()
  const {
    updateDocument,
    error: firestoreError,
    isPending: firestorePending,
  } = useFirestore()

  const handleSubmit = async (blob, fileExtension) => {
    setFormError("")
    if (!user.admin)
      return setFormError(
        "must have admin rights to add a calibration to the database"
      )

    //add document to storage
    let docURL = ""
    const uploadPath = `${storageUploadPath}.${fileExtension}`
    const metadata = {
      contentType: blob.type,
    }
    docURL = await uploadFile(uploadPath, blob, metadata)
    //update the firestore object
    const updateObject = {
      navLogoURL: docURL,
    }
    await updateDocument(firestoreDocumentPath, {
      ...updateObject,
    })

    handleClose()
  }

  const removeCompanyNavLogo = async () => {
    if (
      window.confirm(
        "Are you sure you want to remove this logo? It will be replaced with the company name on file."
      )
    ) {
      //if there is no nav logo, return
      if (typeof navLogoURL === "undefined" || !navLogoURL) {
        console.log("no logo found")
        handleClose()
        return
      }
      //delete the file in storage
      await deleteFile(navLogoURL)
      //remove the url from the company data in firestore
      await updateDocument(firestoreDocumentPath, {
        navLogoURL: "",
      })
    }
    handleClose(false)
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Setting Navbar Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.admin && (
            <p className="error">
              Must have admin rights to add an equipment calibration
            </p>
          )}

          <PhotoCropper
            handleSubmit={handleSubmit}
            setLogoError={setFormError}
            initialAspectRatio={200 / 85}
            imageSize={{ width: 200, height: 85 }}
          />
          {firestoreError && <div className="error">{firestoreError}</div>}
          {formError && <div className="error">{formError}</div>}
          {uploadError && <div className="error">{uploadError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-auto"
            type="button"
            form="calibration-form"
            variant="danger"
            onClick={removeCompanyNavLogo}
            disabled={firestorePending || uploadPending}
          >
            Remove Logo
          </Button>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={firestorePending || uploadPending}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
