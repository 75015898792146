//compatible with firebase 9 and react
import { auth } from "../../../firebase/config"
import { sendEmailVerification, sendSignInLinkToEmail } from "firebase/auth"
import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useFirestore } from "../../../hooks/useFirestore"

import { useState } from "react"

export const useVerify = () => {
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)

  const {
    getDocument,
    updateDocument,
    error: firestoreError,
    isPending: firestorePending,
  } = useFirestore()

  const getUserObject = httpsCallable(functions, "getUserObject-getUserObject")

  const verifyByEmail = async () => {
    setIsPending(true)
    try {
      await sendEmailVerification(auth.currentUser)
      setIsPending(false)
      window.alert("verification email sent.")
    } catch (err) {
      setError(err)
      console.error(err)
      setIsPending(false)
    }
  }

  const sendVerificationEmail = async ({ companyId, userUid }) => {
    setError("")
    setIsPending(true)
    const { data } = await getUserObject({
      companyId,
      uid: userUid,
    })

    const fetchUserError = data.error
    const fetchUserResponse = data.response

    if (fetchUserError) {
      setIsPending(false)
      return setError(fetchUserError)
    }

    //send the email
    //TODO: need to use a cloud function thjat gets a verification link and then send s the email using the email plugin once the plugin is added
  }

  //NOT TESTED
  //returns a boolean if the email is verified or not
  //also, if is verified but not reflected in firestore, it will update the firestore to reflect that.
  //TODO: once can send verifications emails to accounts other than current users, send the verification email if the flag is on.
  const checkEmailVerified = async ({
    account,
    companyId,
    sendEmail = false,
  }) => {
    setIsPending(true)
    //check if the user's email is verified
    const firestoreUserData = await getDocument(`users/${account.uid}`)
    console.log(firestoreUserData)
    if (
      typeof firestoreUserData.emailVerified === "undefined" ||
      !firestoreUserData.emailVerified
    ) {
      const userObject = await getUserObject({
        uid: account.uid,
        companyId,
      })
      if (userObject.data.error) {
        return setError(userObject.data.error)
      }
      console.log(userObject)
      if (!userObject.data.response.emailVerified) {
        setIsPending(false)
        return false
      }

      //if it is actually verified, update the firestore and move on
      await updateDocument(`users/${account.uid}`, {
        emailVerified: true,
      })
    }
    //return true
    setIsPending(false)
    return true
  }

  return {
    verifyByEmail,
    sendVerificationEmail,
    checkEmailVerified,
    isPending,
    error,
  }
}
