import { Text, StyleSheet, View } from "@react-pdf/renderer"
import { format } from "date-fns"

// Create styles
const styles = StyleSheet.create({
  reportTitle: {
    fontSize: "20pt",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  boxedSection: {
    display: "block",
    marginTop: "15px",
    marginBottom: "15px",
    padding: "5px",
    border: "2px grooved darkgray",
  },
  sectionTitle: {
    fontSize: "18pt",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  infoBlock: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "space-between",
    // border: "1px solid orange",
  },
  labelValuePair: {
    minWidth: "100ch",
    flexGrow: "1",
    marginBottom: "10px",
    // border: "1px solid red",
  },
  footnote: {
    fontSize: "8pt",
    marginTop: "10px",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
  },
  header: {
    borderTop: "none",
    backgroundColor: "#eee",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  col1: {
    width: "25%",
  },
  col2: {
    width: "20%",
  },
  col3: {
    width: "20%",
  },
  col4: {
    width: "20%",
  },
  col5: {
    width: "15%",
  },
  hilightRed: {
    backgroundColor: "red",
  },
  hilightYellow: {
    backgroundColor: "yellow",
  },
})

export default function EquipmentTypeCalibrationStatusReportBody({
  companyData,
  reportData,
}) {
  return (
    <div style={{ fontSize: "12pt" }}>
      <Text style={styles.reportTitle}>
        Equipment Calibration Status Report
      </Text>
      <Text>Report run on: {format(new Date(), "yyyy/MM/dd")}</Text>
      <Text>Report run for: {companyData.companyName}</Text>

      <div style={styles.boxedSection}>
        <Text style={styles.sectionTitle}>Report Options</Text>

        <View style={styles.infoBlock}>
          <Text style={styles.labelValuePair}>
            Sorted by: {reportData.options.sortBy.label}{" "}
          </Text>
          <Text style={styles.labelValuePair}>
            Order: {reportData.options.order}
          </Text>
          <Text style={styles.labelValuePair}>
            Statuses included: {reportData.options.statusesIncluded.join(", ")}
          </Text>
        </View>
        <View style={styles.infoBlock}>
          <Text style={styles.labelValuePair}>
            Statuses included:{" "}
            {reportData.options.equipmentTypesIncluded.join(", ")}
          </Text>
        </View>
      </div>

      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header]} fixed={true}>
          <Text style={styles.col1}>Apparatus</Text>
          <Text style={styles.col2}>ID</Text>
          <Text style={styles.col3}>Custody</Text>
          <Text style={styles.col4}>Cal Status</Text>
          <Text style={styles.col5}>Next Cal</Text>
        </View>

        {reportData.displayData.map((row, i) => (
          <View
            key={i}
            style={
              row.calibrationStatus === "overdue"
                ? [styles.row, styles.hilightRed]
                : row.calibrationStatus === "upcoming" ||
                  row.calibrationStatus === "now"
                ? [styles.row, styles.hilightYellow]
                : [styles.row]
            }
            wrap={false}
          >
            <Text style={styles.col1}>{row.type.name}</Text>
            <Text style={styles.col2}>{row.id}</Text>
            <Text style={styles.col3}>{row.currentCustody}</Text>
            <Text style={styles.col4}>{row.calibrationStatus}</Text>
            <Text style={styles.col5}>
              {row.nextCalibrationDue
                ? format(row.nextCalibrationDue.toDate(), "MM/dd/yy")
                : "no data"}
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.footnote}>
        <Text style={{ ...styles.hilightRed, width: "40ch" }}>overdue</Text>
        <Text style={{ ...styles.hilightYellow, width: "125ch" }}>
          upcoming (this calendar month)
        </Text>
        <Text>
          *This report type only shows equipment that is marked as in service.
        </Text>
      </View>
    </div>
  )
}
