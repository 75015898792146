import { useEffect, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useSortData } from "../../../../hooks/basicHooks/useSortList"
import { useAuthContext } from "../../../authentication/index"
import { useCompanyContext } from "../../../company/index"
//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
import { useFilterEvaluations } from "../../index"
import ListFilter from "../../../../components/basicComponents/ListFilter"

//styles
import "../../../../DataList.css"

export default function AuditView({ searchTerm }) {
  const { companyId, auditId } = useParams()
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [selectedItem, setSelectedItem] = useState("")

  const { evaluationListDisplay, evaluationCategories } = useCompanyContext()

  //filtering and sorting state using search params
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    category: searchParams.get("category") || "all",
    sortBy: searchParams.get("sortBy") || "name",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  //custom hooks defined below
  const evaluationFilters = useGetEvaluationCategories(evaluationCategories)
  const filteredList = useFilterEvaluations(
    evaluationListDisplay,
    allSearchParams.category,
    searchTerm,
    setSelectedItem
  )
  //sortData
  const displayedList = useSortData(
    filteredList,
    allSearchParams.sortBy,
    allSearchParams.order === "asc" ? true : false
  )

  const tableHeaderArray = [
    { sortable: true, title: "Name", changeSortByTo: "name" },
    { sortable: true, title: "Category", changeSortByTo: "category" },
    { sortable: true, title: "Standard", changeSortByTo: "standard" },
  ]

  return (
    <div>
      <div className="data-list">
        <ListFilter
          filterOptions={evaluationFilters}
          currentFilter={allSearchParams.category}
          changeFilter={(newFilter) =>
            updateSearchParams({ newParams: { category: newFilter } })
          }
        />

        {/* Evaluation data table */}
        {displayedList.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table" size="md">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
              />
              <tbody className="text-center">
                {displayedList.map((e) => (
                  <tr
                    key={e.name}
                    onClick={() => setSelectedItem(e.name)}
                    onDoubleClick={() => {
                      navigate(
                        `/lab-audit/${auditId}/lab-company/${companyId}/company-evaluations/${e.name}`
                      )
                    }}
                    className={selectedItem === e.name ? "active" : ""}
                  >
                    <td>{e.name}</td>
                    <td>{e.category}</td>
                    <td>{e.standard}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedList.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        <div className="d-flex mt-3">
          {selectedItem && (
            <Link
              className="btn text-bg-secondary ms-2"
              to={`/lab-audit/${auditId}/lab-company/${companyId}/company-evaluations/${selectedItem}`}
            >
              Select
            </Link>
          )}

          {selectedItem && (
            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedItem("")}
            >
              Unselect
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const useGetEvaluationCategories = (categories) => {
  //setting the categories as the filter list
  const [filters, setFilters] = useState(["all"])
  useEffect(() => {
    if (categories && categories.length) {
      setFilters(["all", ...categories])
    }
  }, [categories])

  return filters
}
