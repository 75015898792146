//compatible with firebase 9 and react
import { useState } from "react"
import { useAuthContext } from "../index"

//firebase imports
import { auth } from "../../../firebase/config"
import { signInWithEmailAndPassword } from "firebase/auth"

export const useLogin = () => {
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)
  const { dispatch } = useAuthContext()

  const login = async (email, password) => {
    setIsPending(true)
    setError(null)
    try {
      const res = await signInWithEmailAndPassword(auth, email, password)
      dispatch({ type: "LOGIN", payload: res.user })
      setIsPending(false)
    } catch (err) {
      setError(err.message)
      setIsPending(false)
    }
  }

  return { error, isPending, login }
}
