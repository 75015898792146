import { useState } from "react"

import { useStorage } from "../hooks/useStorage"

import { Table } from "react-bootstrap"
import { verifyPDF } from "../util/verifyFileInput"
import checkDuplicateInArray from "../util/checkDuplicateInArray"
import ViewPDFButton from "./basicComponents/ViewPDFButton"

export default function DocumentList({
  listTitle,
  buttonText,
  documents,
  rightsToAdd,
  rightsToDelete,
  handleUpload,
  handleDownload,
  handleDelete,
  documentsOverMaxPermitted,
}) {
  const { deleteFile } = useStorage()
  documentsOverMaxPermitted = documentsOverMaxPermitted || false

  const [formError, setFormError] = useState("")
  const [newDocument, setNewDocument] = useState("")
  const [addingDoc, setAddingDoc] = useState(false)
  const [newDocName, setNewDocName] = useState("")

  //file change
  const handleFileChange = (e) => {
    setNewDocument(null)
    setFormError("")
    const { pdf: selected, error: fileError } = verifyPDF(e.target.files[0])

    if (fileError) return setFormError(fileError)
    setNewDocument(selected)
  }

  const startDelete = async (doc) => {
    //confirm delete
    const confirming = window.confirm(
      `Are you sure you want to delete ${doc.name}`
    )
    if (!confirming) return

    //delete the file from Storage
    await deleteFile(doc.documentURL)

    //call handleDelete to take care of the firestore update
    await handleDelete(doc)
  }

  const startUpload = async (e) => {
    e.preventDefault()
    if (documentsOverMaxPermitted)
      return setFormError(
        "Your company's account is at the maximum permitted files stored. See your primary asccount manager to increase the allowance."
      )
    if (!rightsToAdd)
      return setFormError(
        "you need to have manager rights to add or change documents on the database"
      )

    //see if required fields are filled
    if (!newDocument)
      return setFormError("you need to select a pdf file to upload")
    if (!newDocName) return setFormError("a document name is required")

    //check the document name to see if it exists
    const { exists: match } = checkDuplicateInArray(
      documents,
      newDocName,
      "name"
    )
    if (match) {
      const cont = window.confirm(
        "this document name is already in user, do you want to replace it?"
      )
      if (!cont) return
    }

    //call handlupload to store document in right place and update the firestore
    await handleUpload(newDocument, newDocName, match)

    setNewDocName("")
    setNewDocument("")
    setAddingDoc(false)
  }

  return (
    <div>
      {/* Additional Documents and actions */}
      <h4 className="mb-2">{listTitle}</h4>
      {documents.length !== 0 && (
        <Table striped bordered size="md">
          <tbody className="text-center">
            {documents.map((doc) => (
              <tr key={doc.name}>
                <td width="60%" className="text-start ps-3">
                  {doc.name}
                </td>
                <td
                  width="15%"
                  className="clickable text-decoration-underline"
                  onClick={() => handleDownload(doc)}
                >
                  download
                </td>
                <td>
                  <a href={doc.documentURL} target="_blank">
                    view
                  </a>
                </td>
                {rightsToAdd && (
                  <td
                    width="10%"
                    className="clickable text-decoration-underline"
                    onClick={() => {
                      setNewDocument("")
                      setFormError("")
                      setAddingDoc(true)
                      setNewDocName(doc.name)
                    }}
                  >
                    edit
                  </td>
                )}
                {rightsToDelete && (
                  <td
                    width="10%"
                    className="clickable text-decoration-underline"
                    onClick={() => startDelete(doc)}
                  >
                    delete
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {rightsToAdd && (
        <button
          className="btn text-bg-primary"
          disabled={addingDoc}
          onClick={() => {
            setAddingDoc(true)
            setNewDocument("")
          }}
        >
          {buttonText}
        </button>
      )}

      {/* adding additionalDoc Form form */}
      {addingDoc && (
        <>
          <form onSubmit={(e) => startUpload(e)} className="mt-2">
            <label className="d-flex">
              <span>document name*: </span>
              <input
                style={{ width: 300 + "px" }}
                type="text"
                required
                className="form-control ms-3"
                onKeyDown={(e) => {
                  if (e.key === "/")
                    setFormError(
                      "all '/' characters will be replaced with '-'."
                    )
                }}
                onChange={(e) =>
                  setNewDocName(e.target.value.replace("/", "-"))
                }
                value={newDocName}
              />
            </label>
            <div className="d-flex">
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="form-control mb-2"
              />
              <button className="btn text-bg-primary ms-4" type="submit">
                Submit
              </button>
              <p
                className="ms-3 text-muted text-decoration-underline clickable"
                onClick={() => {
                  setNewDocument("")
                  setNewDocName("")
                  setAddingDoc(false)
                  setFormError("")
                }}
              >
                cancel
              </p>
            </div>
          </form>
          {formError && <p className="error">{formError}</p>}
        </>
      )}
    </div>
  )
}
