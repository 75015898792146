import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useUpdateAccount } from '../index'


export default function ResetPassword() {
    const [email, setEmail] = useState('')
    const { resetForgottenPassword, isPending, error, message } = useUpdateAccount()

    const handleSubmit = async (e) => {
        e.preventDefault()
        await resetForgottenPassword(email)
    }

    return (
        <form className="auth-form m-auto text-center" onSubmit={handleSubmit}>
            <h2>Password Reset</h2>
            <div className="form-group">
                <label>
                    <input
                        required
                        type="email"
                        placeholder='email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="form-control"
                    />
                </label>
            </div>

            {!isPending && <button type="submit" className="btn text-bg-primary ">Reset Password</button>}
            {isPending && <button disabled className="btn text-bg-primary">Loading...</button>}
            {error && <div className="error">{error}</div>}
            {message && <div className="auth-message">{message}</div>}
            <p className="auth-options"><Link to='/login'>Go to Login</Link></p>
        </form>
    )
}
