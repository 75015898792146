import { useState } from "react"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { format } from "date-fns"
import { Modal, Button } from "react-bootstrap"

import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { useChangeEquipment } from "../index"
import { Timestamp } from "firebase/firestore"
import DateInput from "../../../components/basicFormComponents/DateInput"

export default function MaintenanceModal({ show, handleClose, equipment }) {
  //   const { updateDocument, isPending, error } = useFirestore()
  const { user } = useAuthContext()
  const { companyData } = useCompanyContext()

  const { maintainEquipment, error, isPending } = useChangeEquipment()
  //form entry state
  const [maintenanceDate, setMaintenanceDate] = useState("")
  const [comment, setComment] = useState("")
  const [maintainedBy, setMaintainedBy] = useState(null)
  const [tempUser, setTempUser] = useState(null)
  const [formError, setFormError] = useState("")

  //format emplopyees to be used by react-select
  const employees = formatSelectArray(
    [
      ...companyData.employees,
      {
        displayName: "others",
        rights: "third party",
        uid: "none",
        email: "none",
      },
    ],
    "displayName",
    {
      ...companyData.employees[0],
    }
  )

  const handleMaintainedBydBy = (option) => {
    setTempUser(null)
    if (option.value.displayName !== "others") return setMaintainedBy(option)
    const tempUser = {
      value: { displayName: option.label, uid: "none", role: "third party" },
      label: "others",
    }

    return setTempUser(tempUser)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")

    if (!user.moderator)
      return setFormError(
        "must have moderator rights to add a maintainence log item to the database"
      )
    const maintainanceObject = await maintainEquipment({
      companyId: companyData.companyId,
      user,
      equipment,
      maintenanceDate,
      maintainedBy,
      comment,
    })
    if (typeof maintainanceObject !== "undefined")
      return setFormError(maintainanceObject.error)

    handleModalClose()
  }

  const handleModalClose = () => {
    formReset()
    handleClose()
  }

  const formReset = () => {
    setMaintenanceDate("")
    setComment("")
    setMaintainedBy(null)

    setFormError("")
  }

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Maintenance of {equipment.type.name} {equipment.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to add an a maintainence log item
            </p>
          )}

          <form className="" onSubmit={handleSubmit}>
            <DateInput
              label="date of maintenance*"
              required={true}
              value={maintenanceDate}
              setValue={setMaintenanceDate}
              min={
                equipment.acquisitionDate instanceof Timestamp
                  ? format(equipment.acquisitionDate.toDate(), "yyyy-MM-dd")
                  : null
              }
              max={format(new Date(), "yyyy-MM-dd")}
            />

            <span>performed by*: </span>
            <Select
              onChange={(option) => handleMaintainedBydBy(option)}
              options={employees}
              value={tempUser ? tempUser : maintainedBy}
            />

            {tempUser && (
              <label>
                <span>Other Party: </span>
                <input
                  type="text"
                  onChange={(e) =>
                    setMaintainedBy({
                      value: {
                        ...tempUser.value,
                        displayName: e.target.value,
                      },
                      label: e.target.value,
                    })
                  }
                  className="form-control"
                />
              </label>
            )}

            <label>
              <span>comment: </span>
              <input
                required
                type="text-area"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                className="form-control"
              />
            </label>

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isPending}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

/* ***Equipment Object in Firestore:***
companies collection -> company doc -> equipment collection -> equipmentData doc -> 
EquipmentList: [
    {
        type: '',
        id: '',
        status: '',
        currentCustody: '',
        lastCalibration: Timestamp,
        nextCalibration: Timestamp
    }, ...

companies collection -> company doc -> equipment collection -> {equipemntID} doc ->
    {
        type: {
            everything in equipmentTypes object from EquipmentClassification Doc
        },
        id: '',
        status: '',
        make: '',
        model: '',
        serialNumber: '',
        currentCustody: {
            uid: '',
            name: '',
            location: ''
        },
        createdAt: Timestamp,
        history: [
            {
                historyEntryID: NUMBER,
                entryCategory: '', //[equipment created, maintenance, custody change, calibration, status change]
                enteredAt: Timestamp,
                dateOfAction: Timestamp,
                loggedBy: {
                    uid: '',
                    name: ''
                }, // user's uid
                comment: '',
                    //other fields pending on entryCategory 
                custodyFrom: {
                    uid: '',
                    name: ''
                },
                custodyTo: {
                    uid: '',
                    name: ''
                },
                calibratedBy: {
                    uid: '',
                    name: ''
                },
                calibrationURL: '',
                maintenancePerformedBy: {
                    uid: '',
                    name: ''
                }
            }
        ]
    }
]
*/
