import { useState, useEffect } from "react"

const useGetEquipmentPiece = (equipment, equipmentTypes) => {  
    const [hasDocument, setHasDocument] = useState(false)
    const [type, setType] = useState(null) 

    useEffect(() => {
        if(equipment){
            equipmentTypes.forEach(t => {
                if(t.prefix === equipment.typePrefix){
                    setType(t)
                    if(t.documentURL !== '') setHasDocument(true)
                }
            })
        }
    },[equipment])

    return { type, hasDocument }
}



export { useGetEquipmentPiece }