//uses bootstrap classes
//content for billing page
import { useState, useEffect } from "react"

//styles
import "./layout.css"

//tabs is an array of objects that contain titles (string) and content(react component)
export default function TabbedContent({ tabs, pageTitle }) {
  const [activeTab, setActiveTab] = useState(tabs[0].title)
  const [activeContent, setActiveContent] = useState(tabs[0].content)

  return (
    <>
      {pageTitle && <h2 className="page-title mb-4">{pageTitle}</h2>}
      <div className="tab-headers d-flex ms-3">
        {tabs.map((tab) => (
          <div
            className={
              activeTab === tab.title
                ? "tab-header p-2 ms-3 clickable active"
                : "tab-header p-2 ms-3 clickable "
            }
            key={tab.title}
            onClick={() => setActiveTab(tab.title)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="page-container tabbed">
        {tabs
          .filter((tab) => tab.title === activeTab)
          .map((tab) => (
            <div key={tab.title}>{tab.content}</div>
          ))}
      </div>
    </>
  )
}
