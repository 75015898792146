import { useCompanyContext } from "../../hooks/useCompanyContext"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function CompanyEquipmentTypes() {
  const { isAuditView, auditId, companyData, equipment, evaluations } =
    useCompanyContext()

  if (isAuditView) {
    return <AuditView />
  }

  return <LabView />
}
