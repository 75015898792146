import { useState } from "react"
import { useFirestore } from "../../../hooks/useFirestore"
import { Timestamp } from "firebase/firestore"
import { useMessages } from "../index"

export function useMessaging() {
  const {
    error: firestoreError,
    isPending,
    updateDocument,
    getDocument,
    setDocument,
  } = useFirestore()
  const {
    getMessageFromId: getMessage,
    error: getMessagesError,
    isPending: getMessagesPending,
  } = useMessages()
  const [formError, setFormError] = useState("")

  const sendMessage = async ({ to, from, tag, content }) => {
    //check if required data is passed
    if (!to || !from || !content)
      return setFormError(
        "not enough data to send meesage, the system either does not know who you are, who you are messaging, or the content of the message"
      )
    //check to make sure the two accoundts can communicate
    if (to.companyName !== from.companyName)
      return setFormError(
        "messages can only be sent between users in the same company"
      )

    console.log(to.displayName)
    console.log(from.displayName)

    //send the message to the reciever
    const messageObject = {
      from,
      unread: true,
      recieptSent: false,
      lastMessageSeen: true,
      messages: [
        {
          content,
          tag,
          incoming: true,
          createdAt: Timestamp.fromDate(new Date()),
        },
      ],
    }

    const recieverMessages = await getMessage(to.uid, from.uid)
    if (recieverMessages) {
      messageObject.messages.push(...recieverMessages.messages)
      await updateDocument(
        `users/${to.uid}/messages/${from.uid}`,
        messageObject
      )
    } else {
      await setDocument(`users/${to.uid}/messages/${from.uid}`, messageObject)
    }

    if (firestoreError || getMessagesError)
      return setFormError(firestoreError, getMessagesError)
    //log the message for the sender
    const messageLogObject = {
      from: to,
      recieptSent: true,
      lastMessageSeen: false,
      messages: [
        {
          content,
          tag,
          incoming: false,
          createdAt: Timestamp.fromDate(new Date()),
        },
      ],
    }
    const senderMessages = await getMessage(from.uid, to.uid)
    if (senderMessages) {
      messageLogObject.messages.push(...senderMessages.messages)
      await updateDocument(
        `users/${from.uid}/messages/${to.uid}`,
        messageLogObject
      )
    } else {
      await setDocument(
        `users/${from.uid}/messages/${to.uid}`,
        messageLogObject
      )
    }

    if (firestoreError || getMessagesError)
      return setFormError(firestoreError, getMessagesError)
  }

  const markUnread = async ({ uid, messageId }) => {
    await updateDocument(`users/${uid}/messages/${messageId}`, { unread: true })
  }

  const markRead = async ({ uid, message }) => {
    await updateDocument(`users/${uid}/messages/${message.from.uid}`, {
      unread: false,
      recieptSent: true,
    })

    //send read recipt if applicable - should probably be done in a cloud function
    if (message.from.uid === "system" || message.recieptSent) return

    await updateDocument(`users/${message.from.uid}/messages/${uid}`, {
      lastMessageSeen: true,
    })
  }

  return {
    formError,
    firestoreError,
    isPending,
    sendMessage,
    markRead,
    markUnread,
  }
}
