import React from "react"
import { Modal, Button } from "react-bootstrap"
import EquipmentPieceReportLoader from "./EquipmentPieceReportLoader"
import EquipmentPieceReportRender from "./EquipmentPieceReportRender"

export default function EquipmentPieceReport({
  equipment,
  date,
  handleModalClose,
  show,
}) {
  return (
    <Modal show={show} onHide={handleModalClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Equipment Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EquipmentPieceReportLoader equipment={equipment} date={date}>
          <EquipmentPieceReportRender />
        </EquipmentPieceReportLoader>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
