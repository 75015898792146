import { Table } from "react-bootstrap"
import { useServiceAgreement } from "../features/company"
import { useEffect, useState } from "react"
import { formatUSD } from "../util/formatCurrency"

export default function TierIncludesComparison() {
  const { getServiceAgreement, error, isPending } = useServiceAgreement()
  const [isReady, setIsReady] = useState(false)
  const [sandboxData, setSandboxData] = useState(null)
  const [basicData, setBasicData] = useState(null)
  const [advancedData, setAdvancedData] = useState(null)
  const [megaData, setMegaData] = useState(null)

  useEffect(() => {
    getServiceAgreement({ serviceLevelId: 0 }).then((res) =>
      setSandboxData(res)
    )
    getServiceAgreement({ serviceLevelId: 1 }).then((res) => setBasicData(res))
    getServiceAgreement({ serviceLevelId: 2 }).then((res) =>
      setAdvancedData(res)
    )
    getServiceAgreement({ serviceLevelId: 3 }).then((res) => setMegaData(res))
  }, [])

  if (!sandboxData || !basicData || !advancedData || !megaData)
    return <p>...loading</p>

  return (
    <div>
      <Table striped hover bordered className="data-list-table" size="md">
        <thead className="text-center">
          <th>Tier</th>
          <th>Sandbox</th>
          <th>Basic</th>
          <th>Advanced</th>
          <th>Mega</th>
        </thead>
        <tbody className="text-center">
          <tr>
            <td>
              Users Included * <sup>-</sup> **
            </td>
            <td>{sandboxData.usersIncluded}</td>
            <td>{basicData.usersIncluded}</td>
            <td>{advancedData.usersIncluded}</td>
            <td>{megaData.usersIncluded}</td>
          </tr>
          <tr>
            <td>
              Moderators Included * <sup>-</sup> **
            </td>
            <td>{sandboxData.moderatorsIncluded}</td>
            <td>{basicData.moderatorsIncluded}</td>
            <td>{advancedData.moderatorsIncluded}</td>
            <td>{megaData.moderatorsIncluded}</td>
          </tr>
          <tr>
            <td>
              Admins Included * <sup>-</sup> **
            </td>
            <td>{sandboxData.adminsIncluded}</td>
            <td>{basicData.adminsIncluded}</td>
            <td>{advancedData.adminsIncluded}</td>
            <td>{megaData.adminsIncluded}</td>
          </tr>
          <tr>
            <td>Equipment Included *</td>
            <td>{sandboxData.equipmentIncluded}</td>
            <td>{basicData.equipmentIncluded}</td>
            <td>{advancedData.equipmentIncluded}</td>
            <td>{megaData.equipmentIncluded}</td>
          </tr>
          <tr>
            <td>Documents Included *</td>
            <td>{sandboxData.documentsIncluded}</td>
            <td>{basicData.documentsIncluded}</td>
            <td>{advancedData.documentsIncluded}</td>
            <td>{megaData.documentsIncluded}</td>
          </tr>
          <tr>
            <td>Document Store Included</td>
            <td>{sandboxData.additionalDocs ? "X" : "-"}</td>
            <td>{basicData.additionalDocs ? "X" : "-"}</td>
            <td>{advancedData.additionalDocs ? "X" : "-"}</td>
            <td>{megaData.additionalDocs ? "X" : "-"}</td>
          </tr>
          <tr>
            <td>Messaging Included</td>
            <td>{sandboxData.messaging ? "X" : "-"}</td>
            <td>{basicData.messaging ? "X" : "-"}</td>
            <td>{advancedData.messaging ? "X" : "-"}</td>
            <td>{megaData.messaging ? "X" : "-"}</td>
          </tr>
          <tr>
            <td>Setup Fee (1-time)</td>
            <td>{formatUSD(sandboxData.setupFee)}</td>
            <td>{formatUSD(basicData.setupFee)}</td>
            <td>{formatUSD(advancedData.setupFee)}</td>
            <td>{formatUSD(megaData.setupFee)}</td>
          </tr>
          <tr>
            <td>Subscription Fee (monthly)</td>
            <td>{formatUSD(sandboxData.monthlyFee)}</td>
            <td>{formatUSD(basicData.monthlyFee)}</td>
            <td>{formatUSD(advancedData.monthlyFee)}</td>
            <td>{formatUSD(megaData.monthlyFee)}</td>
          </tr>
          <tr>
            <td>Subscription Fee (annual)</td>
            <td>{formatUSD(sandboxData.annualFee)}</td>
            <td>{formatUSD(basicData.annualFee)}</td>
            <td>{formatUSD(advancedData.annualFee)}</td>
            <td>{formatUSD(megaData.annualFee)}</td>
          </tr>
          <tr>
            <td colSpan={5}></td>
          </tr>
          <tr>
            <td colSpan={5} className="fs-6">
              *Included quantities are not limited, overages are charged monthly
              - see pricing calculator for overage fees, overage fees vary
              between annual and monthly subcriptions.
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="fs-6">
              **Total number of accounds included in each tier is users +
              moderators + admins + 1 (the primary account). i.e. Advacned Tier
              includes 20 accounts
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="fs-6">
              <i>
                If none of these tiers seem right for you lab or if you have any
                questions, please email sales@elabtracker.com
              </i>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
