import { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useMessages, MessageModal } from "../../../messaging"
import { useAuthContext } from "../../../authentication"
import { useSortData } from "../../../../hooks/basicHooks/useSortList"
//components
import { Table } from "react-bootstrap"
import ListFilter from "../../../../components/basicComponents/ListFilter"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
//styles
import "../../../../DataList.css"
import { useUserContext } from "../../../users"
import { useCompanyContext } from "../../hooks/useCompanyContext"
import { useGetFilteredEmployeeListData } from "../../hooks/useGetFilteredEmployeeListData"

const rightsFilter = ["all", "user", "moderator", "admin"]

export default function EmployeeList({ searchBy }) {
  const navigate = useNavigate()
  const { companyData } = useCompanyContext()
  const { user } = useAuthContext()
  const { messages } = useUserContext()
  const { getMessageFromList } = useMessages()
  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [sendTo, setSendTo] = useState(null)

  //filtering and sorting state using search params
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    role: searchParams.get("role") || "all",
    sortBy: searchParams.get("sortBy") || "displayName",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const changeRoleFilter = (newFilter) =>
    updateSearchParams({ newParams: { role: newFilter } })

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  const filteredEmployees = useGetFilteredEmployeeListData(
    companyData.employees,
    allSearchParams.role,
    searchBy,
    setSelectedEmployee
  )

  const displayedEmployees = useSortData(
    filteredEmployees,
    allSearchParams.sortBy,
    allSearchParams.order === "asc" ? true : false
  )

  const tableHeaderArray = [
    { sortable: true, title: "Name", changeSortByTo: "displayName" },
    { sortable: true, title: "Email", changeSortByTo: "email" },
    { sortable: true, title: "Position", changeSortByTo: "position" },
    // { sortable: false, title: "Needs Evaluation", changeSortByTo: "" },
  ]
  if (companyData.messaging) {
    tableHeaderArray.push({
      sortable: false,
      title: "Message",
      changeSortByTo: "",
    })
  }

  const handleMessage = async (employee) => {
    setSendTo({ uid: employee.uid, displayName: employee.displayName })
    const message = getMessageFromList(messages, employee.uid)
    //console.log(message)
    if (message)
      return navigate(
        `/company/${companyData.companyId}/messages/${employee.uid}`
      )
    console.log("no message")
    //show first message modal
    setShowMessageModal(true)
  }

  //modal control
  const [showMessageModal, setShowMessageModal] = useState(false)

  const messageModalShow = () => setShowMessageModal(true)
  const messageModalHide = () => setShowMessageModal(false)

  return (
    <div>
      <div className="data-list">
        <ListFilter
          filterOptions={rightsFilter}
          currentFilter={allSearchParams.role}
          changeFilter={changeRoleFilter}
        />

        {/* Employee data table */}
        {displayedEmployees.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table" size="md">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
                classes="text-center text-wrap"
              />
              <tbody className="text-center">
                {displayedEmployees.map((e) => (
                  <tr
                    key={e.uid}
                    onClick={() => setSelectedEmployee(e.uid)}
                    onDoubleClick={() => {
                      navigate(
                        `/company/${companyData.companyId}/user/${e.uid}`
                      )
                    }}
                    className={selectedEmployee === e.uid ? "active" : ""}
                  >
                    <td>{e.displayName}</td>
                    <td>{e.email}</td>
                    <td>{e.position}</td>
                    {/* <td>{e.needsEvaluation ? "true" : "false"}</td> */}
                    {companyData.messaging && (
                      <td>
                        <button
                          disabled={user.uid === e.uid}
                          onClick={() => handleMessage(e)}
                          className="btn text-bg-primary"
                        >
                          Message
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedEmployees.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        <div className="d-flex mt-3">
          {selectedEmployee && (
            <Link
              className="btn text-bg-secondary ms-2"
              to={`/company/${companyData.companyId}/user/${selectedEmployee}`}
            >
              Select
            </Link>
          )}

          {selectedEmployee && (
            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedEmployee("")}
            >
              Unselect
            </button>
          )}
        </div>
      </div>

      {/* Modals */}
      {showMessageModal && companyData.messaging && (
        <MessageModal
          show={messageModalShow}
          handleClose={messageModalHide}
          sendTo={sendTo}
          messageId={user.uid}
          companyName={companyData.companyId}
        />
      )}
    </div>
  )
}
