import React, { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useCompanyContext } from "../../../company"
import FolderSubfolderList from "./FolderSubfolderList"
import FolderDocumentList from "./FolderDocumentList"

export default function FolderView({ user, useGetFilteredData }) {
  const navigate = useNavigate()
  const { folderId } = useParams()
  const { documentFolders, additionalDocuments } = useCompanyContext()

  const thisFolderId = typeof folderId !== "undefined" ? folderId : "root"

  //displayData control
  const [searchBy, setSearchBy] = useState("")

  //folders
  const subfolders = documentFolders.filter(
    (folder) =>
      `${folder.parentFolderId}` === `${thisFolderId}` && !folder.isInternal
  )
  const thisFolder =
    thisFolderId !== "root"
      ? documentFolders.find((folder) => `${folder.id}` === `${thisFolderId}`)
      : { name: "root", id: "root", isInternal: false, parentFolderId: null }

  const handleSubfolderNav = (subfolderId) => {
    if (folderId === "root") navigate(`${subfolderId}`)
    else navigate(`../additional-docs/${subfolderId}`)
  }

  const filteredDocuments = useGetFilteredData(
    additionalDocuments,
    thisFolderId,
    searchBy
  )

  return (
    <>
      <div className="d-flex mb-3 flex-wrap">
        {/* Searchbar */}
        <input
          style={{ width: "300px", margin: "0" }}
          className="me-5 mt-2"
          type="search"
          onChange={(e) => setSearchBy(e.target.value)}
          value={searchBy}
          placeholder="search folder"
        />
      </div>

      {/* List of Subfolders in folder */}
      <FolderSubfolderList
        subfolders={subfolders}
        folderId={thisFolderId}
        handleDoubleClick={handleSubfolderNav}
        callStartMove={() => {
          return
        }}
        isAuditView={true}
        showIcons={false}
      />
      {/* List of Documents in folder */}
      <FolderDocumentList
        documents={filteredDocuments}
        callStartMove={() => {
          return
        }}
        callRevise={() => {
          return
        }}
        callDeleteCurrent={() => {
          return
        }}
        callDeleteAll={() => {
          return
        }}
        callDeleteHistoricalDocument={() => {
          return
        }}
        user={user}
        isAuditView={true}
      />
    </>
  )
}
