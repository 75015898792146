import { useEffect, useRef, useState } from "react"

//components
import { Button } from "react-bootstrap"
import { BasicFormBody } from "../../../components/basicFormComponents"
import { Link } from "react-router-dom"
import { useCompanyContext } from "../../company"
import EquipmentListReportLoader from "../reports/EquipmentListReport/EquipmentListReportLoader"
import EquipmentListReportRender from "../reports/EquipmentListReport/EquipmentListReportRender"

const FORMDATA = {
  sortBy: { label: "Type Name", value: "type.name" },
  order: "asc",
  includeInService: true,
  includeOutOfService: false,
  includeDiscarded: false,
}

const SORT_OPTIONS = [
  { label: "Type Name", value: "type.name" },
  { label: "Type Prefix", value: "type.prefix" },
  { label: "Status", value: "status" },
]

export default function EquipmentListFormPage() {
  const { isAuditView } = useCompanyContext()

  const [formData, setFormData] = useState(FORMDATA)
  const [formError, setFormError] = useState(null)
  const [displayReport, setDisplayReport] = useState(false)
  const reportRef = useRef(null)

  const updateFormData = (updateObject) => {
    setFormData((prev) => {
      return { ...prev, ...updateObject }
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(null)
    // console.log("submitted")
    if (
      !formData.includeInService &&
      !formData.includeOutOfService &&
      !formData.includeDiscarded
    )
      return setFormError("Must include at least one status in your report.")
    // console.log(formData)

    setDisplayReport(true)

    //handle scroll
    setTimeout(() => {
      reportRef.current.scrollIntoView({ behavior: "smooth" })
    }, 200)
  }

  useEffect(() => {
    setDisplayReport(false)
  }, [formData])

  if (isAuditView)
    return <p className="error">This report is not yet available in audits</p>

  return (
    <>
      <h1>EquipmentList Report</h1>
      <p>
        <Link to="./../">...back to reports list</Link>
      </p>
      <form onSubmit={handleSubmit}>
        Sort By:
        <BasicFormBody
          formData={[
            {
              type: "select",
              onChange: (option) => updateFormData({ sortBy: option }),
              label: "order by",
              options: SORT_OPTIONS,
              defaultValue: formData.sortBy,
            },
            {
              type: "radio",
              onChange: (e) => updateFormData({ order: e.target.value }),
              label: "order",
              options: [
                { value: "asc", label: "ascending" },
                { value: "desc", label: "descending" },
              ],
              value: formData.order,
              horizontal: true,
            },
            {
              type: "checkbox",
              label: "status - in service",
              horizontal: true,
              value: formData.includeInService,
              defaultChecked: formData.includeInService,
              onChange: (e) =>
                updateFormData({ includeInService: e.target.checked }),
            },
            {
              type: "checkbox",
              label: "status - out of service",
              horizontal: true,
              value: formData.includeOutOfService,
              defaultChecked: formData.includeOutOfService,
              onChange: (e) =>
                updateFormData({ includeOutOfService: e.target.checked }),
            },
            {
              type: "checkbox",
              label: "status - discarded",
              horizontal: true,
              value: formData.includeDiscarded,
              defaultChecked: formData.includeDiscarded,
              onChange: (e) =>
                updateFormData({ includeDiscarded: e.target.checked }),
            },
          ]}
          columns={2}
        >
          {formError && <p className="error">{formError}</p>}
        </BasicFormBody>
        <Button type="submit" variant="primary">
          Run Report
        </Button>
      </form>

      {/* Report */}
      <hr />
      {displayReport && (
        <div ref={reportRef} className="mb-4">
          <EquipmentListReportLoader options={formData}>
            <EquipmentListReportRender />
          </EquipmentListReportLoader>
        </div>
      )}
    </>
  )
}
