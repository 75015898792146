import React from "react"
import { useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { format } from "date-fns"
import { useSortData } from "../../../../hooks/basicHooks/useSortList"

//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
import ListFilter from "../../../../components/basicComponents/ListFilter"
import CollapseIcon from "../../../../components/basicComponents/CollapseIcon"
import { useGetFilteredUsersListData } from "../../index"
import DownloadFileButton from "../../../../components/basicComponents/DownloadFileButton"

//styles
import "../../../../DataList.css"
import ViewPDFButton from "../../../../components/basicComponents/ViewPDFButton"

export default function EvaluationUserList({ evalData }) {
  const { companyId, evaluationId, auditId } = useParams()

  const evalFilter = ["all required", "needs evaluated", "overdue"]
  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [expandedData, setExpandedData] = useState("")
  const [searchBy, setSearchBy] = useState("")
  const [pageError, setPageError] = useState("")

  //filtering and sorting state using search params
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    status: searchParams.get("status") || "all required",
    sortBy: searchParams.get("sortBy") || "displayName",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  //Non-imported Custom hooks defined below.
  const filteredData = useGetFilteredUsersListData(
    evalData,
    allSearchParams.status,
    searchBy,
    setSelectedEmployee
  )
  const displayedData = useSortData(
    filteredData,
    allSearchParams.sortBy,
    allSearchParams.order === "asc" ? true : false
  )

  const tableHeaderArray = [
    { sortable: true, title: "Name", changeSortByTo: "displayName" },
    { sortable: true, title: "Role", changeSortByTo: "rights" },
    {
      sortable: true,
      title: "date",
      changeSortByTo: "currentEvaluation.dateOfAction",
    },
    { sortable: false, title: "Performed By", changeSortByTo: "" },
    { sortable: false, title: "Document", changeSortByTo: "" },
    { sortable: false, title: "Default Frequency", changeSortByTo: "" },
    {
      sortable: true,
      title: "Next Due",
      changeSortByTo: "currentEvaluation.nextEvaluation",
    },
  ]
  return (
    <div>
      <div className="data-list">
        <div className="d-flex mt-3 mb-3">
          <div>
            <ListFilter
              filterOptions={evalFilter}
              currentFilter={allSearchParams.status}
              changeFilter={(newFilter) =>
                updateSearchParams({ newParams: { status: newFilter } })
              }
            />
          </div>
          <div className="ms-auto me-4">
            <input
              type="search"
              onChange={(e) => setSearchBy(e.target.value)}
              value={searchBy}
              placeholder="search employee"
            />
          </div>
        </div>

        {/* Employee data table */}
        {displayedData.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table" size="md">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
              />
              <tbody className="text-center">
                {displayedData.map((e) => (
                  <React.Fragment key={e.uid}>
                    <tr
                      onClick={() =>
                        setSelectedEmployee({
                          uid: e.uid,
                          displayName: e.displayName,
                          rights: e.rights,
                        })
                      }
                      className={selectedEmployee.uid === e.uid ? "active" : ""}
                    >
                      <td className="d-flex">
                        {e.displayName}
                        {e.history.length !== 0 && expandedData !== e.uid && (
                          <h4
                            className="ms-2"
                            onClick={() => setExpandedData(e.uid)}
                          >
                            <CollapseIcon collapsed={true} />
                          </h4>
                        )}
                        {e.history.length !== 0 && expandedData === e.uid && (
                          <h4
                            className="ms-2"
                            onClick={() => setExpandedData("")}
                          >
                            <CollapseIcon collapsed={false} />
                          </h4>
                        )}
                      </td>
                      <td>{e.rights}</td>

                      {e.currentEvaluation.dateOfAction && (
                        <td>
                          {format(
                            e.currentEvaluation.dateOfAction.toDate(),
                            "MMM, yyyy"
                          )}
                        </td>
                      )}
                      {!e.currentEvaluation.dateOfAction && <td>no data</td>}

                      {e.currentEvaluation.performedBy && (
                        <td>{e.currentEvaluation.performedBy.displayName}</td>
                      )}
                      {!e.currentEvaluation.performedBy && <td>no data</td>}

                      {e.currentEvaluation.docURL && (
                        <td className="d-flex justify-content-around">
                          <DownloadFileButton
                            docURL={e.currentEvaluation.docURL}
                            fileName={`${format(
                              e.currentEvaluation.dateOfAction.toDate(),
                              "MMM, yyyy"
                            )}_${evaluationId}_${e.displayName}.pdf`}
                            icon={true}
                          />
                          <ViewPDFButton
                            docURL={e.currentEvaluation.docURL}
                            icon={true}
                            className=""
                          />
                        </td>
                      )}

                      {!e.currentEvaluation.docURL &&
                        !e.currentEvaluation.dateOfAction && <td>no doc</td>}

                      <td>
                        {e.frequencyOfEvaluation && (
                          <span>{e.frequencyOfEvaluation} months</span>
                        )}
                        {!e.frequencyOfEvaluation && <span>not set</span>}
                      </td>

                      {e.currentEvaluation.nextEvaluation && (
                        <td>
                          {format(
                            e.currentEvaluation.nextEvaluation.toDate(),
                            "MMM, yyyy"
                          )}
                        </td>
                      )}
                      {!e.currentEvaluation.nextEvaluation && <td>no data</td>}
                    </tr>
                    {/* expandable evaluation history*/}
                    {expandedData === e.uid &&
                      e.history.map((h, i) => (
                        <tr
                          className="fs-7 text-muted expanded-row"
                          key={i.toString()}
                        >
                          <td></td>
                          <td></td>
                          <td>
                            {format(h.dateOfAction.toDate(), "MMM, yyyy")}
                          </td>
                          <td>{h.performedBy.displayName}</td>

                          {h.docURL && (
                            <td classname="d-flex justify-content-around">
                              <DownloadFileButton
                                docURL={h.docURL}
                                fileName={`${format(
                                  h.dateOfAction.toDate(),
                                  "MMM, yyyy"
                                )}_${evaluationId}_${e.displayName}.pdf`}
                                buttonText="Download"
                              />
                              <ViewPDFButton docURL={h.docURL} icon={true} />
                            </td>
                          )}
                          {!h.docURL && <td>no doc</td>}

                          <td></td>
                          <td></td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedData.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        {pageError && (
          <p className="error d-flex">
            <span>{pageError}</span>
            <span
              className="ms-auto me-4 clickable"
              onClick={() => setPageError("")}
            >
              X
            </span>
          </p>
        )}

        {/* Buttons that appear when a selection is made */}
        <div className="d-flex mt-3 flex-wrap text-wrap">
          {selectedEmployee && (
            <Link
              className="btn text-bg-secondary ms-2"
              to={`/lab-audit/${auditId}/lab-company/${companyId}/user/${selectedEmployee.uid}/evaluations`}
            >
              Go to user's evaluations
            </Link>
          )}

          {selectedEmployee && (
            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedEmployee("")}
            >
              Unselect
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
