import { useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../authentication"
import { useUserContext } from "../../users"
//layout
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
//components
import {
  MessageForm,
  MessageContainer,
  useMessaging,
  useMessages,
} from "../index"
import { Button } from "react-bootstrap"

import sendMessageIcon from "../../../assets/send-message-icon.svg"

const INITIAL_DATA = {
  tag: null,
  content: "",
}

export default function Message() {
  const { messageId } = useParams()
  const { user } = useAuthContext()
  const { messages } = useUserContext()
  const [formData, setFormData] = useState(INITIAL_DATA)
  const {
    formError,
    firestoreError: sendError,
    isPending: sendPending,
    sendMessage,
  } = useMessaging()
  const { getMessageFromList } = useMessages()

  const message = getMessageFromList(messages, messageId)

  function updateFields(fields) {
    setFormData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await sendMessage({
      to: message.from,
      from: { displayName: user.displayName, uid: user.uid },
      tag: formData.tag ? formData.tag.value : null,
      content: formData.content,
    })

    updateFields(INITIAL_DATA)
  }

  return (
    <ContentOptionalRight
      contentTitle={
        <>
          Conversation with{" "}
          <span className="fw-bold">{message.from.displayName}</span>
        </>
      }
      content={
        <>
          {message && <MessageContainer message={message} />}

          <form
            className=""
            id="message-form"
            onSubmit={(e) => handleSubmit(e)}
          >
            <MessageForm {...formData} updateFields={updateFields}>
              <Button
                variant="outline-secondary"
                type="submit"
                disabled={sendPending}
                className="mt-auto me-auto ms-auto mb-4 me-5"
                style={{ height: "50px", width: "100px" }}
              >
                <img
                  src={sendMessageIcon}
                  alt="sent message"
                  className="icon"
                />
              </Button>
            </MessageForm>

            {sendError && <div className="error">{sendError}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </>
      }
    />
  )
}
