import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useAuthContext } from "../../../authentication/index"
import { useCompanyContext } from "../../index"

//layout
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
//components
import { EquipmentList, AddEquipmentTypeModal } from "../../../equipment/index"
import sortObjects from "../../../../util/sortObjects"
import ListFilter from "../../../../components/basicComponents/ListFilter"

export default function LabView() {
  const { companyId } = useParams()
  const { user } = useAuthContext()
  const { equipmentTypes, equipmentCategories, isAuditView, auditId } =
    useCompanyContext()

  //search the equipment
  const [typeSearch, setTypeSearch] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("all")
  const [typeFilter, setTypeFilter] = useState("")

  //filter the displayed equipment types - custom hook defined below
  const displayedTypes = useGetTypes(
    equipmentTypes,
    typeSearch,
    setTypeFilter,
    categoryFilter
  )

  //modal control
  const [showAddTypeModal, setShowAddTypeModal] = useState(false)

  const addTypeModalShow = () => setShowAddTypeModal(true)
  const addTypeModalHide = () => setShowAddTypeModal(false)

  return (
    <>
      <ContentOptionalRight
        contentTitle="Company Equipment"
        content={
          <>
            {/* filter by category */}
            <ListFilter
              filterOptions={["all", ...equipmentCategories]}
              currentFilter={categoryFilter}
              changeFilter={setCategoryFilter}
              transparent={true}
            />
            <EquipmentList
              selectedType={typeFilter}
              selectedCategory={categoryFilter}
            />
          </>
        }
        rightTitle="Equipment Types"
        right={
          <>
            <input
              type="text"
              placeholder="Search type..."
              value={typeSearch}
              onChange={(e) => setTypeSearch(e.target.value)}
            />
            {typeFilter && (
              <>
                <span
                  className="filter-statement mt-4 me-1 text-muted"
                  onClick={() => setTypeFilter("")}
                >
                  Remove Filter
                </span>
                |
                <Link
                  className="filter-statement mt-4 ms-1 text-muted"
                  to={`types/${typeFilter}`}
                >
                  Go to Page
                </Link>
              </>
            )}
            <ul className="data-list-container">
              {displayedTypes &&
                displayedTypes.map((t) => (
                  <li
                    key={t.name}
                    onClick={() => setTypeFilter(t.prefix)}
                    className={
                      typeFilter === t.prefix
                        ? "eq-type-link mt-2 m-auto active"
                        : "eq-type-link mt-2 m-auto"
                    }
                  >
                    <span>{t.name} - </span>
                    <span className="text-muted">{t.category}</span>
                  </li>
                ))}
            </ul>

            {user.moderator && (
              <button
                className="btn text-bg-primary mt-3"
                onClick={addTypeModalShow}
              >
                Add Type
              </button>
            )}
          </>
        }
      />

      {/* Modals */}

      <AddEquipmentTypeModal
        show={showAddTypeModal}
        handleClose={addTypeModalHide}
        companyId={companyId}
      />
    </>
  )
}

const useGetTypes = (
  equipmentTypes,
  searchTerm,
  setSelectedData,
  selectedCategory
) => {
  const [types, setTypes] = useState([])

  useEffect(() => {
    let newList = []
    setSelectedData("")
    if (equipmentTypes && equipmentTypes.length) {
      newList = equipmentTypes.filter((t) => {
        return (
          t.name
            .trim()
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          t.category
            .trim()
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase()) ||
          t.prefix
            .trim()
            .toLowerCase()
            .includes(searchTerm.trim().toLowerCase())
        )
      })

      if (
        selectedCategory &&
        selectedCategory !== "all" &&
        equipmentTypes.length
      ) {
        newList = newList.filter(
          (t) =>
            t.category.trim().toLowerCase() ===
            selectedCategory.trim().toLowerCase()
        )
      }

      //sort the list alphabetically by name
      newList = sortObjects(newList, "name", true)
    }
    setTypes(newList)
  }, [searchTerm, selectedCategory])

  return types
}
