//accepts styles module from MessageContainer
import { formatDistance } from "date-fns"
export default function MessageBubble({ message, from, styles, prevMessage }) {
  const initials = (displayName) => {
    let name = displayName.split(" ")
    let initials = ""
    name.forEach((word) => {
      let firstChar = word[0]
      initials += firstChar.toUpperCase()
    })

    return initials
  }

  // Incoming message bubble render
  if (message.incoming)
    return (
      <div>
        {(!prevMessage ||
          (prevMessage &&
            (prevMessage.incoming !== message.incoming ||
              (message.tag && prevMessage.tag !== message.tag)))) && (
          <div className={`${styles["sender-incoming"]} ${styles["sender"]}`}>
            <p>{initials(from.displayName)}</p>
            <p>
              {message.tag && message.tag !== "none" ? `(${message.tag})` : ""}
            </p>
          </div>
        )}

        <div className={`${styles["bubble-incoming"]} ${styles["bubble"]}`}>
          <div className={`${styles["content-incoming"]} ${styles["content"]}`}>
            <p>{message.content}</p>
            <p className={styles["sent-time"]}>
              {formatDistance(message.createdAt.toDate(), new Date(), {
                addSuffix: true,
              })}
            </p>
          </div>
        </div>
      </div>
    )

  //outoing message bubble render
  return (
    <div>
      {(!prevMessage ||
        (prevMessage &&
          (prevMessage.incoming !== message.incoming ||
            (message.tag && prevMessage.tag !== message.tag)))) && (
        <div className={`${styles["sender-outgoing"]} ${styles["sender"]}`}>
          <p>
            {message.tag && message.tag !== "none" ? `(${message.tag})` : ""}
          </p>
          <p>you</p>
        </div>
      )}

      <div className={`${styles["bubble-outgoing"]} ${styles["bubble"]}`}>
        <div className={`${styles["content-outgoing"]} ${styles["content"]}`}>
          <p>{message.content}</p>
          <p className={styles["sent-time"]}>
            {formatDistance(message.createdAt.toDate(), new Date(), {
              addSuffix: true,
            })}
          </p>
        </div>
      </div>
    </div>
  )
}
