import React, { useEffect, useRef } from "react"
import sortObjects from "../../../util/sortObjects"
//components
import { MessageBubble } from "../index"

//styles
import styles from "./MessageContainer.module.css"

export default function MessageContainer({ message }) {
  const displayMessages = sortObjects(message.messages, "createdAt", false)
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [message])

  return (
    <div className={styles["message-container"]}>
      {displayMessages.map((m, i) => (
        <React.Fragment key={m.createdAt}>
          <MessageBubble
            styles={styles}
            message={m}
            from={message.from}
            prevMessage={i === 0 ? null : displayMessages[i - 1]}
          />
        </React.Fragment>
      ))}

      <div ref={messagesEndRef} />
    </div>
  )
}
