import { Route, Routes, useParams } from "react-router-dom"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
import AuditLandingPageInfo from "./AuditLandingPageInfo"
import AuditLandingPageLoader from "./AuditLandingPageLoader"

// TODO: Split this into 2 different components to control for whether it is an auditor or lab person viewing.
//Do this by checking the user here and then rendering the appropriate component based on the user.
//if the user is an auditor  and it is an internal audit, have a toggle for views? Maybe down the line.
export default function AuditLandingPage({}) {
  const { auditId } = useParams()

  return (
    <>
      <ContentOptionalRight
        content={
          <AuditLandingPageLoader auditId={auditId}>
            <AuditLandingPageInfo />
          </AuditLandingPageLoader>
        }
      />

      {/* Routes, if needed, go here */}
    </>
  )
}
