import { useParams } from "react-router-dom"
import { useAuthContext, useVerify } from "../../../authentication"
import { Button } from "react-bootstrap"
import { useState } from "react"
import RemoveAuditorRightsModal from "../../components/RemoveAuditorRightsModal"
import { functions } from "../../../../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useFirestore } from "../../../../hooks/useFirestore"

export default function UserInfo({ thisUser = {}, auditingCompanyData = {} }) {
  const { userId } = useParams()
  const { user } = useAuthContext()
  let ownsPage = user.uid === userId
  const {
    updateDocument,
    error: firestoreError,
    isPending: firestorePending,
  } = useFirestore()

  //verifying email
  const {
    error: verificationError,
    isPending: verificationSendPending,
    verifyByEmail,
    checkEmailVerified,
  } = useVerify()
  const changeClaims = httpsCallable(functions, "changeUserData-changeClaims")

  //User data change control
  const handleChangeRights = async (newRights) => {
    const confirmMessage = "Are you sure you want to change this user's rights?"
    if (!window.confirm(confirmMessage))
      return alert("The action was successfully aborted")
    //remove the rights from the user using cloud function - this also updates the user doc in firestore
    const claimsResponse = await changeClaims({
      email: thisUser.email,
      uid: thisUser.uid,
      newClaimsObject: newRights,
      updateFirestoreUserdocObject: newRights,
      initial: false,
    })

    if (claimsResponse.data.error)
      return alert(`something went wrong - ${claimsResponse.error}`)

    //update the company list
    let thisEmployeeListItem = auditingCompanyData.employees.find(
      (e) => e.uid === thisUser.uid
    )
    thisEmployeeListItem = { ...thisEmployeeListItem, ...newRights }
    const newEmployeeList = auditingCompanyData.employees.map((e) => {
      if (e.uid === thisUser.uid) return thisEmployeeListItem
      return e
    })
    await updateDocument(`auditingCompanies/${auditingCompanyData.companyId}`, {
      employees: newEmployeeList,
    })

    if (firestoreError)
      alert(
        `something went wrong with updating the company's employee list - ${firestoreError}`
      )

    alert(`success - ${claimsResponse.data.message}`)
  }

  //modal control
  const [showRemoveRightsModal, setShowRemoveRightsModal] = useState(false)
  const removeRightsModalShow = () => setShowRemoveRightsModal(true)
  const removeRightsModalHide = () => setShowRemoveRightsModal(false)

  return (
    <>
      <div className="d-flex">
        <h2>User Details</h2>
        {user.auditingAdmin && !ownsPage && (
          <Button
            className="ms-auto"
            variant="danger"
            onClick={() => removeRightsModalShow()}
          >
            Remove User's Access
          </Button>
        )}
      </div>

      <div className="d-flex flex-wrap">
        <p className="me-5">Hello, {thisUser.displayName}</p>
        <p className="ms-5 me-5">Email: {thisUser.email}</p>
      </div>

      <div className="ms-5 mt-2">
        <div className="d-flex align-items-baseline mt-2">
          {thisUser.isAuditor && (
            <>
              <p>This user is an auditor.</p>
              {user.auditingAdmin && !ownsPage && (
                <Button
                  variant="danger"
                  className="btn-sm ms-4"
                  onClick={() =>
                    handleChangeRights({
                      auditingAdmin: false,
                      isAuditor: false,
                    })
                  }
                >
                  Remove
                </Button>
              )}
            </>
          )}
          {!thisUser.isAuditor && user.auditingAdmin && (
            <Button
              variant="primary"
              className="btn-sm ms-4"
              onClick={() => handleChangeRights({ isAuditor: true })}
            >
              Make this User an Auditor
            </Button>
          )}
        </div>

        <div className="d-flex align-items-baseline mt-2">
          {thisUser.auditingAdmin && (
            <>
              <p>This user is an admin for this company account.</p>
              {user.auditingAdmin && !ownsPage && (
                <Button
                  variant="danger"
                  className="btn-sm ms-4"
                  onClick={() => handleChangeRights({ auditingAdmin: false })}
                >
                  Remove
                </Button>
              )}
            </>
          )}
          {!thisUser.auditingAdmin && user.auditingAdmin && (
            <Button
              variant="primary"
              className="btn-sm ms-4"
              onClick={() => handleChangeRights({ auditingAdmin: true })}
            >
              Make this User an Admin
            </Button>
          )}
        </div>
        {thisUser.confirmedAuditor && (
          <div className="d-flex align-items-baseline mt-2">
            <p>This user is a confirmed auditor.</p>
            {/* TODO: info icon here */}
          </div>
        )}
      </div>

      {!thisUser.emailVerified && ownsPage && (
        <div className="mt-4">
          Your eMail is Not Verified.
          <Button
            variant="primary"
            className="ms-4"
            onClick={() =>
              handleVerifyEmail({
                checkEmailVerified,
                verifyByEmail,
                accountData: thisUser,
              })
            }
            disabled={verificationSendPending}
          >
            {" "}
            Send Verification Email
          </Button>
        </div>
      )}

      {/* TODO: add audits once they are a thing */}

      {/* Modals */}
      {showRemoveRightsModal && (
        <RemoveAuditorRightsModal
          show={removeRightsModalShow}
          handleClose={removeRightsModalHide}
          userToRemove={thisUser}
        />
      )}
    </>
  )
}

const handleVerifyEmail = async ({
  verifyByEmail,
  checkEmailVerified,
  accountData,
}) => {
  const emailVerified = await checkEmailVerified({
    account: accountData,
    companyId: accountData.auditingCompanyId,
  })
  if (emailVerified)
    return window.alert("This account's email has already been verified.")

  verifyByEmail()
}
