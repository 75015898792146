import { BasicFormBody } from "./basicFormComponents"

export default function CompanyInformationForm({
  companyName,
  companyAddress,
  companyAddress2,
  companyCity,
  companyState,
  companyZip,
  companyPhone,
  updateFields,
  children,
}) {
  function handlePhoneNumberChange(e) {
    if (e.code === "Backspace" || e.code === "Delete" || e.length < 3) return
    let numbers = e.target.value.split("")
    if (numbers.length >= 7) {
      numbers = numbers.filter((n) => n !== "-")
      numbers.splice(6, 0, "-")
      numbers.splice(3, 0, "-")
      e.target.value = numbers.join("")
      return
    } else if (numbers.length >= 3 && numbers.length <= 6) {
      numbers = numbers.filter((n) => n !== "-")
      numbers.splice(3, 0, "-")
      e.target.value = numbers.join("")
      return
    }
  }
  return (
    <div>
      <h2>Company Account Setup</h2>
      <BasicFormBody
        columns={2}
        formData={[
          {
            label: "Company",
            required: true,
            value: companyName,
            onChange: (e) => updateFields({ companyName: e.target.value }),
            horizontal: true,
          },
          {
            label: "Street Address",
            options: { autocomplete: "street-address" },
            required: true,
            value: companyAddress,
            onChange: (e) => updateFields({ companyAddress: e.target.value }),
            horizontal: true,
          },
          {
            label: "Stereet Adress 2",
            autocomplete: "street-address2",
            value: companyAddress2,
            onChange: (e) => updateFields({ companyAddress2: e.target.value }),
            horizontal: true,
          },
          {
            label: "City",
            options: { autocomplete: "city" },
            required: true,
            value: companyCity,
            onChange: (e) => updateFields({ companyCity: e.target.value }),
            horizontal: true,
          },
          {
            label: "State",
            required: true,
            value: companyState,
            onChange: (e) => updateFields({ companyState: e.target.value }),
            horizontal: true,
          },
          {
            label: "Zipcode",
            options: { autocomplete: "postal-code" },
            required: true,
            value: companyZip,
            options: { pattern: "[0-9]{5}", maxLength: "5" },
            onChange: (e) => updateFields({ companyZip: e.target.value }),
            horizontal: true,
          },
          {
            label: "Company Phone",
            type: "tel",
            options: {
              autocomplete: "phone",
              pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
              maxLength: "12",
              onKeyDown: (e) => handlePhoneNumberChange(e),
            },
            required: true,
            value: companyPhone,
            onChange: (e) => updateFields({ companyPhone: e.target.value }),
            horizontal: true,
          },
        ]}
      />

      {children}
    </div>
  )
}
