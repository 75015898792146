import { useParams } from "react-router-dom"
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
import { useAuthContext } from "../../authentication"
import { Button } from "react-bootstrap"
import { useRef, useState } from "react"
import { AddEmployeeModal, EmployeeList } from "../index"
import { Audits } from "../../audits/index"

export default function Staff() {
  const { user } = useAuthContext()
  const searchTermRef = useRef("")

  //   Modal Control
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false)
  const addEmployeeModalShow = () => setShowAddEmployeeModal(true)
  const addEmployeeModalHide = () => setShowAddEmployeeModal(false)

  return (
    <>
      <ContentOptionalRight
        contentTitle="Staff"
        content={
          <>
            {user.auditingAdmin && (
              <Button
                variant="primary"
                className="btn-sm ms-3 mb-2"
                onClick={addEmployeeModalShow}
              >
                Add User
              </Button>
            )}
            <input
              className="ms-5 mb-2"
              placeholder="Search by name or email"
              style={{ height: "2.5em", width: "45%" }}
              type="search"
              ref={searchTermRef}
              onChange={(e) => (searchTermRef.current.value = e.target.value)}
            />

            <EmployeeList searchTerm={searchTermRef.current.value || ""} />
          </>
        }
      />
      {/* Modals */}
      {showAddEmployeeModal && (
        <AddEmployeeModal
          show={addEmployeeModalShow}
          handleClose={addEmployeeModalHide}
        />
      )}
    </>
  )
}
