import React from "react"
import { useCompanyContext } from "../../../company"
import AuditView from "./AuditView"
import LabView from "./LabView"

export default function CompanyEvaluationList({ searchTerm, embedded }) {
  const { isAuditView } = useCompanyContext()

  if (isAuditView) return <AuditView searchTerm={searchTerm} />

  return <LabView searchTerm={searchTerm} embedded={embedded} />
}
