import { useEffect, useState } from "react"
import { Timestamp } from "firebase/firestore"
import { isAfter, getMonth, getYear } from "date-fns"

const useGetFilteredUsersListData = (
  evaluationData,
  filter,
  searchBy,
  setSelectedData
) => {
  const [filteredData, setFilteredData] = useState([])
  //   console.log(evaluationData)

  useEffect(() => {
    let newList = []
    setSelectedData("")

    //switch statement for filter followed by filtering the searce term which is an email or name
    switch (filter) {
      case "needs evaluated":
        newList = evaluationData.filter((e) => e.notification)
        break
      case "overdue":
        newList = evaluationData.filter((e) => e.evaluationStatus === "overdue")
        break
      case "all required":
        newList = evaluationData.filter((e) => e.required)
        break
      case "all":
      default:
        newList = evaluationData
    }

    newList = newList.filter((e) => {
      return (
        e.displayName
          .trim()
          .toLowerCase()
          .includes(searchBy.trim().toLowerCase()) ||
        e.email.trim().toLowerCase().includes(searchBy.trim().toLowerCase())
      )
    })

    setFilteredData(newList)
  }, [evaluationData, filter, searchBy])

  return filteredData
}
export { useGetFilteredUsersListData }
