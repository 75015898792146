//this useSignup signs a new user up without signing them in by calling cloud function.
//This is used in the company's SignupUser Page component
import { useState } from "react"
import { useCompanyContext } from "../index"
import { useAuthContext } from "../../authentication/index"
import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useFirestore } from "../../../hooks/useFirestore"
import { Timestamp } from "firebase/firestore"

export function useSignup() {
  const { companyData } = useCompanyContext()
  const { user } = useAuthContext()
  const [error, setError] = useState("")
  const [isPending, setIsPending] = useState(false)
  //cloud functions
  const signupNewUser = httpsCallable(functions, "signupUser-signupNewUser")
  const updateDisplayName = httpsCallable(functions, "changeDisplayName")
  const changeClaims = httpsCallable(functions, "changeUserData-changeClaims")
  //firestore
  const {
    error: firestoreError,
    isPending: firestorePending,
    updateDocument,
    setDocument,
  } = useFirestore()

  //function to add a new user to firebase and associate that user with the company of the user signing them up
  const addNewUser = async ({ newUser, password }) => {
    console.log("signupNewUser fired")

    if (!user.admin)
      return setError(
        "you must have admin rights to add a user to the company roster"
      )
    setIsPending(true)
    setError("")
    const userObject = {
      ...newUser,
      companyId: companyData.companyId,
      companyName: companyData.companyName,
      createdAt: Timestamp.fromDate(new Date()),
      settings: {},
      doneTutorial: false,
    }
    console.log(userObject)
    const systemMessage = `Hello ${userObject.displayName}, you have been signed up to eLab Tracker by ${user.displayName} to access the ${companyData.companyName} lab tracking appplication. Welcome.`

    //call cloud function for admin to sign up, get new user data as response
    const functionData = await signupNewUser({
      email: userObject.email,
      rights: userObject.rights,
      displayName: userObject.displayName,
      password,
    })
    console.log(functionData)
    if (functionData.data.error) {
      setIsPending(false)
      console.log(functionData.data.error)
      return setError(functionData.data.error)
    }

    //*** Firestore *** */
    //add new userDocument
    await setDocument(`users/${functionData.data.response.uid}`, userObject)
    //add messages subcollection
    await setDocument(
      `users/${functionData.data.response.uid}/messages/system`,
      {
        messages: [
          {
            createdAt: Timestamp.fromDate(new Date()),
            incoming: true,
            content: systemMessage,
          },
        ],
        unread: true,
        from: { uid: "system", displayName: "System", rights: "system" },
      }
    )
    await updatecompanyEmployeeList({
      displayName: userObject.displayName,
      uid: functionData.data.response.uid,
      rights: userObject.rights,
      email: userObject.email,
    })
    if (firestoreError) {
      setIsPending(false)
      setError(firestoreError)
      return
    }

    //*** claims *** */
    //change the claims of the new user
    const moderator =
      userObject.rights === "moderator" ||
      userObject.rights === "admin" ||
      userObject.rights === "primary"
    const admin =
      userObject.rights === "admin" || userObject.rights === "primary"
    const primary = userObject.rights === "primary"
    const claimChangeResponse = await changeClaims({
      initial: true,
      rights: userObject.rights,
      companyId: companyData.companyId,
      uid: functionData.data.response.uid,
      email: functionData.data.response.email,
      newClaimsObject: {
        moderator,
        admin,
        primary,
        company: companyData.companyId,
      },
    })
    if (claimChangeResponse.data.error) {
      setError(claimChangeResponse.data.error)
      setIsPending(false)
      console.log(claimChangeResponse.data.fullError)
    }

    // const verificationEmailRes = await sendVerificationEmail({
    //   uid: functionData.data.response.uid,
    //   email: functionData.data.response.email,
    //   displayName: userObject.displayName,
    // })
    // if (verificationEmailRes.data.error) {
    //   setError(verificationEmailRes.data.error)
    //   setIsPending(false)
    //   console.log(verificationEmailRes.data.error)
    //   return functionData.data.response.uid
    // }

    setIsPending(false)
    return functionData.data.response.uid
  }

  //add the new User to the company employee list
  const updatecompanyEmployeeList = async (newUser) => {
    await updateDocument(`companies/${companyData.companyId}`, {
      employees: [...companyData.employees, newUser],
    })
  }

  return { addNewUser, error, isPending }
}
