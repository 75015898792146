import React from "react"
import { useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { useAuthContext } from "../../../authentication"
import { useGetFilteredEvalualtingListData, useUserContext } from "../../index"
import { useCompanyContext } from "../../../company"
import { format } from "date-fns"
import { verifyPDF } from "../../../../util/verifyFileInput"
import { useSortData } from "../../../../hooks/basicHooks/useSortList"
//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
import ListFilter from "../../../../components/basicComponents/ListFilter"
import CollapseIcon from "../../../../components/basicComponents/CollapseIcon"
import DownloadFileButton from "../../../../components/basicComponents/DownloadFileButton"
import {
  ChangeFrequencyOfEvaluationModal,
  EvaluateUserModal,
  useEvaluate,
  useEvaluation,
} from "../../../evaluations/index"
import { limitStringLength } from "../../../../util/stringUtils"
import ViewPDFButton from "../../../../components/basicComponents/ViewPDFButton"

export default function LabView({ userDisplayName }) {
  const { companyId, uniqueId: userId } = useParams()
  const { user } = useAuthContext()
  const { userData } = useUserContext()
  const { documentsOverMaxPermitted } = useCompanyContext()
  const {
    useGetUserEvaluations,
    toggleRequireUserEvaluation,
    isPending: firestorePending,
    firestoreError,
    error,
  } = useEvaluation()
  const { uploadPending, uploadError, addUserEvaluationDocument } =
    useEvaluate()
  const companyEvalDocs = useGetUserEvaluations({ companyId, uid: userId })
  //   console.log(companyEvalDocs)

  const evalFilter = ["all", "all required", "needs evaluated", "overdue"]
  const [selectedEvaluation, setSelectedEvaluation] = useState("")
  const [expandedData, setExpandedData] = useState("")
  const [searchBy, setSearchBy] = useState("")
  const [pageError, setPageError] = useState("")

  //filtering and sorting state using search params
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    status: searchParams.get("status") || "all required",
    sortBy: searchParams.get("sortBy") || "name",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  //custom hooks defined below
  const filteredData = useGetFilteredEvalualtingListData(
    companyEvalDocs,
    userId,
    allSearchParams.status,
    searchBy,
    setSelectedEvaluation
  )

  const displayedData = useSortData(
    filteredData,
    allSearchParams.sortBy,
    allSearchParams.order === "asc" ? true : false
  )

  //   console.log(displayedData)

  const tableHeaderArray = [
    { sortable: true, title: "Name", changeSortByTo: "name" },
    { sortable: true, title: "Category", changeSortByTo: "category" },
    { sortable: false, title: "Date", changeSortByTo: "" },
    { sortable: false, title: "Performed By", changeSortByTo: "" },
    { sortable: false, title: "Document", changeSortByTo: "" },
    { sortable: false, title: "Default Frequency", changeSortByTo: "" },
    { sortable: false, title: "Next Due", changeSortByTo: "" },
    {
      sortable: false,
      title: "Required",
      changeSortByTo: "",
      hide: !user.moderator,
    },
  ]

  //fileChange fired when uploading doc - not li
  const handleFileChangeAndUpload = async (event, evaluationId, uploadData) => {
    setPageError("")
    const { pdf: selected, error: fileError } = verifyPDF(event.target.files[0])
    if (fileError) return setPageError(fileError)

    await addUserEvaluationDocument({
      companyId,
      evaluationId,
      document: selected,
      uploadData,
      documentsOverMaxPermitted,
    })

    if (uploadError || firestoreError || error)
      return setPageError(
        `uploadError: ${uploadError}; database error: ${firestoreError}; general error: ${error}`
      )
  }

  const toggleRequired = async (evaluationId) => {
    let userEvalInfo = null
    companyEvalDocs.forEach((e) => {
      if (e.name === evaluationId) {
        userEvalInfo = e
      }
    })
    if (userEvalInfo) {
      await toggleRequireUserEvaluation({
        companyId,
        evaluationId,
        userId,
        userEvalInfo,
      })
    }
    if (firestoreError || error)
      return setPageError(
        `database error: ${firestoreError}; general error: ${error}`
      )
  }

  //modal control
  const [showEvaluateUserModal, setShowEvaluateUserModal] = useState(false)
  const [
    showChangeFrequencyOfEvaluationModal,
    setShowChangeFrequencyOfEvaluationModal,
  ] = useState(false)

  const evaluateUserModalShow = () => setShowEvaluateUserModal(true)
  const evaluateUserModalHide = () => setShowEvaluateUserModal(false)
  const changeFrequencyOfEvaluationModalShow = () =>
    setShowChangeFrequencyOfEvaluationModal(true)
  const changeFrequencyOfEvaluationModalHide = () =>
    setShowChangeFrequencyOfEvaluationModal(false)

  if (!displayedData) return <p className="error">Loading...</p>
  return (
    <div>
      <div className="data-list">
        <div className="d-flex mt-3 mb-3">
          <div>
            <ListFilter
              filterOptions={evalFilter}
              currentFilter={allSearchParams.status}
              changeFilter={(newFilter) => {
                updateSearchParams({ newParams: { status: newFilter } })
              }}
            />
          </div>
          <div className="ms-auto me-4">
            <input
              type="search"
              onChange={(e) => setSearchBy(e.target.value)}
              value={searchBy}
              placeholder="search evaluations"
            />
          </div>
        </div>

        {/* Employee data table */}
        {displayedData.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table" size="md">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
              />
              <tbody className="text-center">
                {displayedData.map((e) => (
                  <React.Fragment key={e.name}>
                    <tr
                      onClick={() => setSelectedEvaluation(e.name)}
                      className={selectedEvaluation === e.name ? "active" : ""}
                      data-notification={e.notification ? true : false}
                    >
                      <td
                        className="d-flex"
                        data-notification={e.notification ? true : false}
                      >
                        {limitStringLength(
                          limitStringLength(e.name, 30) + ": " + e.standard,
                          50
                        )}

                        {e.history.length !== 0 && expandedData !== e.name && (
                          <h4
                            className="ms-2"
                            onClick={() => setExpandedData(e.name)}
                          >
                            <CollapseIcon collapsed={true} />
                          </h4>
                        )}
                        {e.history.length !== 0 && expandedData === e.name && (
                          <h4
                            className="ms-2"
                            onClick={() => setExpandedData("")}
                          >
                            <CollapseIcon collapsed={false} />
                          </h4>
                        )}
                      </td>

                      <td>{e.category}</td>

                      {e.currentEvaluation.dateOfAction && (
                        <td>
                          {format(
                            e.currentEvaluation.dateOfAction.toDate(),
                            "yyyy-MMM-dd"
                          )}
                        </td>
                      )}
                      {!e.currentEvaluation.dateOfAction && <td>no data</td>}

                      {e.currentEvaluation.performedBy && (
                        <td>{e.currentEvaluation.performedBy.displayName}</td>
                      )}
                      {!e.currentEvaluation.performedBy && <td>no data</td>}

                      {e.currentEvaluation.docURL && (
                        <td className="d-flex justify-content-around">
                          <DownloadFileButton
                            docURL={e.currentEvaluation.docURL}
                            fileName={`${format(
                              e.currentEvaluation.dateOfAction.toDate(),
                              "yyyy-MMM-dd"
                            )}_${e.name}_${e.displayName}`}
                            disabled={firestorePending || uploadPending}
                            icon={true}
                          />
                          <ViewPDFButton
                            icon={true}
                            docURL={e.currentEvaluation.docURL}
                            disabled={firestorePending || uploadPending}
                          />
                        </td>
                      )}
                      {e.currentEvaluation.dateOfAction &&
                        !e.currentEvaluation.docURL &&
                        user.moderator && (
                          <td className="clickable text-decoration-underline">
                            <label
                              htmlFor={`upload${userId}doc`}
                              className="clickable text-muted hidden-input"
                            >
                              upload
                            </label>
                            <input
                              type="file"
                              onChange={(event) => {
                                console.log(e)
                                handleFileChangeAndUpload(event, e.name, e)
                              }}
                              id={`upload${userId}doc`}
                              className="hidden-input"
                            />
                          </td>
                        )}
                      {(!e.currentEvaluation.dateOfAction ||
                        (!e.currentEvaluation.docURL && !user.moderator)) && (
                        <td>no doc</td>
                      )}

                      <td>
                        {e.frequencyOfEvaluation && (
                          <span>{e.frequencyOfEvaluation} months</span>
                        )}
                        {!e.frequencyOfEvaluation && <span>not set</span>}
                      </td>

                      {e.currentEvaluation.nextEvaluation && (
                        <td>
                          {format(
                            e.currentEvaluation.nextEvaluation.toDate(),
                            "MMM, yyyy"
                          )}
                        </td>
                      )}
                      {!e.currentEvaluation.nextEvaluation && <td>no data</td>}

                      {user.moderator && (
                        <td>
                          <input
                            type="checkbox"
                            disabled={firestorePending || uploadPending}
                            checked={e.required}
                            readOnly
                            onClick={() => {
                              if (!firestorePending || uploadPending)
                                toggleRequired(e.name)
                            }}
                          />
                        </td>
                      )}
                    </tr>
                    {/* expandable evaluation history*/}
                    {expandedData === e.name &&
                      e.history.map((h, i) => (
                        <tr
                          className="fs-7 text-muted expanded-row"
                          key={i.toString()}
                        >
                          <td></td>
                          <td></td>
                          <td>
                            {format(h.dateOfAction.toDate(), "MMM, yyyy")}
                          </td>
                          <td>{h.performedBy.displayName}</td>

                          {h.docURL && (
                            <td className="d-flex justify-content-around">
                              <DownloadFileButton
                                docURL={h.docURL}
                                fileName={`${format(
                                  h.dateOfAction.toDate(),
                                  "yyyy-MMM-dd"
                                )}_${e.name}_${e.displayName}.pdf`}
                                disabled={firestorePending || uploadPending}
                                icon={true}
                              />
                              <ViewPDFButton
                                icon={true}
                                docURL={h.docURL}
                                disabled={firestorePending || uploadPending}
                              />
                            </td>
                          )}
                          {!h.docURL && <td>no doc</td>}

                          <td></td>
                          {user.moderator && <td></td>}
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedData.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        {pageError && (
          <p className="error d-flex">
            <span>{pageError}</span>
            <span
              className="ms-auto me-4 clickable"
              onClick={() => setPageError("")}
            >
              X
            </span>
          </p>
        )}

        <div className="d-flex mt-3">
          {selectedEvaluation && (
            <Link
              className="btn text-bg-secondary ms-2"
              to={`/company/${companyId}/company-evaluations/${selectedEvaluation}`}
            >
              Go to {selectedEvaluation}
            </Link>
          )}

          {selectedEvaluation && user.moderator && (
            <button
              className="btn text-bg-secondary ms-2"
              disabled={firestorePending || uploadPending}
              onClick={evaluateUserModalShow}
            >
              Evaluate User
            </button>
          )}

          {selectedEvaluation && user.moderator && (
            <button
              className="btn text-bg-secondary ms-2"
              disabled={firestorePending || uploadPending}
              onClick={changeFrequencyOfEvaluationModalShow}
            >
              Change Default Frequency User
            </button>
          )}

          {selectedEvaluation && (
            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedEvaluation("")}
            >
              Unselect
            </button>
          )}
        </div>
      </div>

      {/* modals */}
      {showEvaluateUserModal && (
        <EvaluateUserModal
          show={evaluateUserModalShow}
          handleClose={evaluateUserModalHide}
          companyId={companyId}
          evaluationId={selectedEvaluation}
          selectedEvaluated={{
            uid: userId,
            displayName: userDisplayName,
          }}
        />
      )}

      {showChangeFrequencyOfEvaluationModal && (
        <ChangeFrequencyOfEvaluationModal
          show={changeFrequencyOfEvaluationModalShow}
          handleClose={changeFrequencyOfEvaluationModalHide}
          companyId={companyId}
          evaluation={{
            name: selectedEvaluation,
            id: selectedEvaluation,
          }}
          userToChange={{
            uid: userId,
            displayName: userDisplayName,
          }}
        />
      )}
    </div>
  )
}
