import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
//components
import { Button } from "react-bootstrap"
import { BasicFormBody } from "../../../components/basicFormComponents"
import makeAnimated from "react-select/animated"
import EquipmentTypeEquipmentListReportLoader from "../reports/EquipmentTypeEquipmentListReport/EquipmentTypeEquipmentListReportLoader"
import EquipmentTypeEquipmentListReportRender from "../reports/EquipmentTypeEquipmentListReport/EquipmentTypeEquipmentListReportRender"
//hooks
import { useCompanyContext } from "../../company"
import formatSelectArray from "../../../util/formatSelectArray"
import checkDuplicateInArray from "../../../util/checkDuplicateInArray"
import removeDuplicatesInArray from "../../../util/removeDuplicatesInArray"

const FORMDATA = {
  sortBy: { label: "Type Name", value: "type.name" },
  order: "asc",
  includeInService: true,
  includeOutOfService: false,
  includeDiscarded: false,
  includedTypes: [],
}

export default function EquipmentListByTypeFormPage() {
  const { isAuditView, equipmentTypes } = useCompanyContext()

  const typeOptions = formatSelectArray(equipmentTypes, "name", {
    name: "",
    prefix: "",
    calibrationRequired: false,
  })
  //this is used for the react-select report for equipment types - makes it to where the selkected options disappear from the list and get re-added if they are removed from the selection.
  const animatedComponents = makeAnimated()

  const [formData, setFormData] = useState(FORMDATA)
  const [formError, setFormError] = useState(null)
  const [displayReport, setDisplayReport] = useState(false)
  const reportRef = useRef(null)

  const updateFormData = (updateObject) => {
    // console.log(updateObject)
    setFormData((prev) => {
      return { ...prev, ...updateObject }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(null)
    // console.log("submitted")
    if (
      !formData.includeInService &&
      !formData.includeOutOfService &&
      !formData.includeDiscarded
    )
      return setFormError("Must include at least one status in your report.")

    if (formData.includedTypes.length === 0)
      return setFormError(
        "You must select at least one EquipmentType to include."
      )
    // console.log(formData)

    setDisplayReport(true)

    //handle scroll
    setTimeout(() => {
      reportRef.current.scrollIntoView({ behavior: "smooth" })
    }, 200)
  }

  useEffect(() => {
    setDisplayReport(false)
  }, [formData])

  if (isAuditView)
    return <p className="error">This report is not yet available in audits</p>

  return (
    <>
      <h1>EquipmentList Report</h1>
      <p>
        <Link to="./../">...back to reports list</Link>
      </p>
      <form onSubmit={handleSubmit}>
        Equipment to Include:
        <BasicFormBody
          formData={[
            {
              type: "select",
              isMulti: true,
              components: animatedComponents,
              onChange: (newValue, actionMeta) => {
                const uniqueTypesTypesToInclude = removeDuplicatesInArray(
                  newValue,
                  "label"
                )
                updateFormData({ includedTypes: uniqueTypesTypesToInclude })
              },
              label: "Equipment Types",
              options: typeOptions,
              name: "equipmentTypes",
              closeMenuOnSelect: false,
            },
            {
              type: "checkbox",
              label: "status - in service",
              horizontal: true,
              value: formData.includeInService,
              defaultChecked: formData.includeInService,
              onChange: (e) =>
                updateFormData({ includeInService: e.target.checked }),
            },
            {
              type: "checkbox",
              label: "status - out of service",
              horizontal: true,
              value: formData.includeOutOfService,
              defaultChecked: formData.includeOutOfService,
              onChange: (e) =>
                updateFormData({ includeOutOfService: e.target.checked }),
            },
            {
              type: "checkbox",
              label: "status - discarded",
              horizontal: true,
              value: formData.includeDiscarded,
              defaultChecked: formData.includeDiscarded,
              onChange: (e) =>
                updateFormData({ includeDiscarded: e.target.checked }),
            },
          ]}
          columns={2}
        >
          {formError && <p className="error">{formError}</p>}
        </BasicFormBody>
        <Button type="submit" variant="primary">
          Run Report
        </Button>
      </form>

      {/* Report */}
      <hr />
      {displayReport && (
        <div ref={reportRef} className="mb-4">
          <EquipmentTypeEquipmentListReportLoader options={formData}>
            <EquipmentTypeEquipmentListReportRender />
          </EquipmentTypeEquipmentListReportLoader>
        </div>
      )}
    </>
  )
}
