import { useRef, useState } from "react"
import { Modal, Button } from "react-bootstrap"

import { useAuthContext } from "../../authentication"
import InfoIcon from "../../../components/basicComponents/InfoIcon"
import { useEvaluationType } from "../index"

export default function DeleteEvaluationModal({
  show,
  handleClose,
  evaluation,
}) {
  const { user } = useAuthContext()
  const [formError, setFormError] = useState("")
  const { deleteEvaluationType, isPending, firestoreError } =
    useEvaluationType()

  //form entry state
  const inputId = useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")

    if (!user.admin)
      return setFormError("must have admin rights to delete evaluations")

    //check the validity of the input
    if (inputId.current.value !== evaluation.name) {
      return setFormError(
        "You must enter the evaluation name exactly as it appears in the header to validate the deletion."
      )
    }

    await deleteEvaluationType({
      evaluationId: evaluation.name,
      user,
    })

    if (firestoreError) return

    handleClose()
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete / inactivate This Evaluation Type - {evaluation.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.admin && (
            <p className="error">
              Must have admin rights to delete Evaluations
            </p>
          )}

          <form className="" onSubmit={handleSubmit}>
            <label>
              <div className="d-flex">
                <span className="me-2">
                  Validate deletion by typing "{evaluation.name}"
                </span>
                <InfoIcon infoTitle="deletingevaluations" />
              </div>
              <input
                required
                ref={inputId}
                type="text-area"
                className="form-control"
              />
            </label>

            {firestoreError && <div className="error">{firestoreError}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isPending}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
