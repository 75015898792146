import { createContext, useReducer, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useCollection } from "../../../hooks/useCollection"
import { useDocument } from "../../../hooks/useDocument"

export const AuditingCompanyContext = createContext()

//may need cases for equipment subcollection or other subcollections that are needed later
export const auditingCompanyReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_AUDITING_COMPANY":
      return {
        ...state,
        auditingCompanyIsReady: true,
        auditingCompanyData: action.payload,
      }
    case "UPDATE_AUDITS":
      return {
        ...state,
        pendingAudits: action.payload.filter(
          (audit) => audit.status === "pending"
        ),
        activeAudits: action.payload.filter(
          (audit) => audit.status === "active" || audit.status === "on hold"
        ),
        completeAudits: action.payload.filter(
          (audit) => audit.status === "complete"
        ),
        rejectedAudits: action.payload.filter(
          (audit) => audit.status === "rejected"
        ),
      }
    // case "UPDATE_DOCUMENT_FOLDERS":
    //   return { ...state, documentFolders: action.payload }
    // case "UPDATE_ADDITIONAL_DOCUMENT_LIST":
    //   return { ...state, additionalDocuments: action.payload }
    default:
      return state
  }
}

export const AuditingCompanyContextProvider = ({ children }) => {
  const { auditingCompanyId } = useParams()
  const [state, dispatch] = useReducer(auditingCompanyReducer, {
    auditingCompanyData: null,
    auditingCompanyIsReady: false,
    // documentFolders: [],
    // additionalDocuments: [],
    pendingAudits: [],
    activeAudits: [],
    completeAudits: [],
    rejectedAudits: [],
  })

  const { docData: companyData } = useDocument(
    `auditingCompanies/${auditingCompanyId}`
  )
  //   const { docData: documentStoreDocuments } = useDocument(
  //     `auditingCompanies/${auditingCompanyId}/additionalDocuments/documentClassification`
  //   )
  //   const { documents: additionalDocuments } = useCollection(
  //     `auditingCompanies/${auditingCompanyId}/additionalDocuments`
  //   )

  const { documents: auditDocuments } = useCollection("audits", [
    "auditingCompanyId",
    "==",
    auditingCompanyId,
  ])

  useEffect(() => {
    if (companyData) {
      dispatch({ type: "CHANGE_AUDITING_COMPANY", payload: companyData })
    }
  }, [auditingCompanyId, companyData])

  useEffect(() => {
    if (auditDocuments) {
      dispatch({ type: "UPDATE_AUDITS", payload: auditDocuments })
    }
  }, [auditDocuments])

  //   useEffect(() => {
  //     if (documentStoreDocuments) {
  //       dispatch({
  //         type: "UPDATE_DOCUMENT_FOLDERS",
  //         payload: documentStoreDocuments.folders,
  //       })
  //     }
  //   }, [documentStoreDocuments])

  //   useEffect(() => {
  //     if (additionalDocuments.length) {
  //       const actualDocs = additionalDocuments.filter(
  //         (document) => typeof document.name !== "undefined"
  //       )
  //       dispatch({
  //         type: "UPDATE_ADDITIONAL_DOCUMENT_LIST",
  //         payload: actualDocs,
  //       })
  //     }
  //   }, [additionalDocuments])

  return (
    <AuditingCompanyContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuditingCompanyContext.Provider>
  )
}
