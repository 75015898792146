//uses bootstrap and react-bootstrap
import { useRef } from "react"
import { CloseButton, Button } from "react-bootstrap"
import { useInfoContext } from "../../hooks/useInfoContext"

//styles
import "./flyin.css"

export default function InfoFlyin() {
  const { show, displayInfo, dispatch, availableTags, tags } = useInfoContext()
  const searchTermRef = useRef()

  const handleSearchSubmit = (e) => {
    e.preventDefault()

    dispatch({ type: "SEARCH", payload: searchTermRef.current.value })
  }

  const handleTagClick = (tagName) => {
    if (tags.includes(tagName)) {
      dispatch({
        type: "FILTER_BY_TAG",
        payload: tags.filter((tag) => tag != tagName),
      })
    } else {
      dispatch({ type: "FILTER_BY_TAG", payload: [...tags, tagName] })
    }
  }

  if (!show) {
    return <div></div>
  }
  if (show) {
    return (
      <>
        <div
          className="flyin-background"
          onClick={() => dispatch({ type: "FLY_OUT" })}
        ></div>
        <div
          id="info-flyin"
          className="flyin"
          style={{ backgroundColor: "lightskyblue" }}
        >
          <div className="close">
            <CloseButton
              onClick={() => {
                dispatch({ type: "FLY_OUT" })
              }}
            />
          </div>

          <div className="header">eLabTracker Help</div>
          <div className="input">
            <form
              id="help-search"
              className="search-form"
              onSubmit={(e) => handleSearchSubmit(e)}
            >
              <input
                ref={searchTermRef}
                type="search"
                placeholder="Search..."
              />
              <Button type="submit" variant="primary" className="submit-button">
                Search
              </Button>
            </form>

            <div className="tags">
              {/* display tags and light up the active ones. */}
              {availableTags.map((t) => (
                <span
                  key={t}
                  className={tags.includes(t) ? "tag pill active" : "tag pill"}
                  onClick={() => handleTagClick(t)}
                >
                  {t}
                </span>
              ))}
              <span
                className="remove-tags"
                onClick={() => dispatch({ type: "FILTER_BY_TAG", payload: [] })}
              >
                remove all
              </span>
            </div>
          </div>

          {displayInfo.length === 0 && <p className="error"> No Results</p>}
          {displayInfo.length > 0 && (
            <div className="results">
              {displayInfo.map((result) => (
                <div key={result.id} className="result">
                  <h4>{result.title}</h4>
                  <span>Tags: </span>
                  {result.tags.map((tag) => (
                    <span key={`${result.id}-${tag}`} className="result-tag">
                      {tag}
                    </span>
                  ))}
                  {/* TODO: highlight */}
                  <div>{result.text}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    )
  }
}
