import { useEffect, useState } from "react"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { Modal, Button } from "react-bootstrap"

import { useAuthContext } from "../../authentication"
import { useCompanyContext } from "../../company/index"
import { useFirestore } from "../../../hooks/useFirestore"
import { useStorage } from "../../../hooks/useStorage"
import { verifyPDF } from "../../../util/verifyFileInput"
import checkDuplicateInArray from "../../../util/checkDuplicateInArray"

import {
  CheckboxInput,
  TextInput,
} from "../../../components/basicFormComponents"

export default function AddEquipmentTypeModal({
  show,
  handleClose,
  companyId,
}) {
  const { user } = useAuthContext()
  const {
    equipmentCategories,
    equipmentTypes,
    documentsOverMaxPermitted,
    companyData,
  } = useCompanyContext()
  const { updateDocument, isPending, error } = useFirestore()
  const {
    uploadFile,
    isPending: uploadPending,
    error: uploadError,
  } = useStorage()
  const [addCat, setAddCat] = useState(false)

  //only allow moderators, admins and primary, not users to have default custody
  const moderatorList = formatSelectArray(
    companyData.employees.filter(
      (e) =>
        e.rights === "primary" ||
        e.rights === "admin" ||
        e.rights === "moderator"
    ),
    "displayName",
    companyData.employees[0]
  )
  //form entry state
  const [selectedCategory, setSelectedCategory] = useState("")
  const [newCategory, setNewCategory] = useState("")
  const [typeName, setTypeName] = useState("")
  const [typeIDPrefix, setTypeIDPrefix] = useState("")
  const [calibrationRequired, setCalibrationRequired] = useState(true)
  const [calibrationFrequency, setCalibrationFrequency] = useState("")
  const [calibrationDocument, setCalibrationDocument] = useState("")
  const [typeDefaultCustody, setTypeDefaultCustody] = useState(null)
  const [formError, setFormError] = useState("")

  //format categories to be used by react-select
  const categories = formatSelectArray(equipmentCategories)

  const handleFileChange = (e) => {
    setCalibrationDocument(null)
    setFormError("")
    const { pdf: selected, error: fileError } = verifyPDF(e.target.files[0])

    if (fileError) return setFormError(fileError)
    setCalibrationDocument(selected)
  }

  const handleAddCategory = async () => {
    setFormError("")
    //no blank
    if (!newCategory)
      return setFormError(
        "If adding a new category, the field cannot be left blank"
      )
    //make sure not duplicate
    const { exists } = checkDuplicateInArray(equipmentCategories, newCategory)
    if (exists) return setFormError("The category is already in use")

    //addCategory by updating the equipmentClassification doc
    await updateDocument(
      `companies/${companyId}/equipment/equipmentClassification`,
      {
        equipmentCategories: [...equipmentCategories, newCategory.trim()],
      }
    )
    setNewCategory("")
    setAddCat(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (newCategory) return
    if (!user.moderator)
      return setFormError(
        "you much have moderator rights to add an equipment type to the database"
      )
    setFormError("")

    //make sure required fields are filled
    if (!selectedCategory)
      return setFormError("You must select a category from the drop-down menu")
    if (!typeName) return setFormError("Equipment Type Name is required")
    if (!typeIDPrefix)
      return setFormError(
        "Type ID Prefix is required - this will let you know what a piece of equipment is based on ID (ex: a hat would have an ID of HAT-1) with 'HAT' being the Prefix"
      )
    if (!calibrationFrequency && calibrationRequired)
      return setFormError(
        "Calibration Frequency is required; if the equipment doesn't require calibration, uncheck the calibration scheduling required checkbox."
      )

    //check if prefix or equipment name exists in the companies equipment types yet
    if (equipmentTypes) {
      const { exists: prefixExists, duplicateData: duplicatePrefix } =
        checkDuplicateInArray(equipmentTypes, typeIDPrefix, "prefix")
      const { exists: nameExists, duplicateData: duplicateName } =
        checkDuplicateInArray(equipmentTypes, typeName, "name")

      if (prefixExists)
        return setFormError(
          `this prefix is already being used by ${duplicatePrefix}.`
        )
      if (nameExists)
        return setFormError(
          `this name is already being used by ${duplicateName}.`
        )
    }

    //add document into firebase storage for calibration
    let documentURL = ""
    // console.log(calibrationDocument)
    if (calibrationDocument) {
      if (documentsOverMaxPermitted) {
        alert(
          "The blank calibration document could not be added because your company account is over the permitted file storage limit, see your primary account manager to increase the allowance."
        )
      } else {
        const uploadPath = `companies/${companyId}/equipment/${typeName}/calibrationForm_Master`
        const metadata = { contentType: "application/pdf" }
        documentURL = await uploadFile(
          uploadPath,
          calibrationDocument,
          metadata
        )
      }
    }

    const defaultCustodyObject = typeDefaultCustody
      ? {
          displayName: `Default - (${typeDefaultCustody.value.displayName})`,
          rights: "lab",
          uid: typeDefaultCustody.value.uid,
          userDisplayName: typeDefaultCustody.value.displayName,
        }
      : null

    // return console.log(defaultCustodyObject)

    //update firestore:
    const typeObject = {
      name: typeName,
      category: selectedCategory.value,
      calibrationRequired,
      calibrationFrequency: calibrationRequired
        ? Number(calibrationFrequency)
        : null,
      documentURL: calibrationRequired ? documentURL : null,
      prefix: typeIDPrefix,
      otherDocuments: [],
      defaultCustody: defaultCustodyObject,
    }
    if (equipmentTypes) {
      await updateDocument(
        `companies/${companyId}/equipment/equipmentClassification`,
        {
          equipmentTypes: [...equipmentTypes, typeObject],
        }
      )
    } else {
      await updateDocument(
        `companies/${companyId}/equipment/equipmentClassification`,
        {
          equipmentTypes: [typeObject],
        }
      )
    }

    handleModalClose()

    if (!calibrationDocument && calibrationRequired)
      alert(
        "you may continue without uploading a blank calibration form, a form can be entered later from the equipment type page. Calibrations cannot be performed without a calibration form."
      )
  }

  const handleModalClose = () => {
    formReset()
    handleClose()
  }

  const formReset = () => {
    setSelectedCategory("")
    setTypeName("")
    setTypeIDPrefix("")
    setCalibrationRequired(true)
    setCalibrationFrequency("")
    setCalibrationDocument("")
    setTypeDefaultCustody(null)

    setFormError("")
  }

  //reset form if unmounted using return in useEffect
  useEffect(() => {
    return formReset()
  }, [])

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Equipment Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to add an equipment type
            </p>
          )}
          <form className="" onSubmit={handleSubmit}>
            <span>equipment category*:</span>
            <Select
              onChange={(option) => setSelectedCategory(option)}
              options={categories}
            />
            {!addCat && (
              <div
                className="text-muted"
                role="button"
                onClick={() => setAddCat(true)}
              >
                add other category
              </div>
            )}

            {addCat && (
              <div className="form-group">
                <label>
                  <span>new category*: </span>
                  <input
                    required
                    type="text"
                    onChange={(e) => setNewCategory(e.target.value)}
                    value={newCategory}
                    className="form-control"
                  />
                </label>

                <button
                  className="btn text-bg-primary"
                  onClick={() => handleAddCategory()}
                >
                  Add Category
                </button>
                <button
                  className="btn text-bg-secondary"
                  onClick={() => {
                    setAddCat(false)
                    setNewCategory("")
                    setFormError("")
                  }}
                >
                  Cancel
                </button>
              </div>
            )}

            <label>
              <span>equipment type name*: </span>
              <input
                required
                type="text"
                disabled={addCat}
                onChange={(e) => setTypeName(e.target.value)}
                value={typeName}
                className="form-control"
              />
            </label>

            <label>
              <span>type ID pre-fix*: </span>
              <input
                required
                type="text"
                disabled={addCat}
                onKeyDown={(e) => {
                  if (e.key === "/" || e.key === "/")
                    setFormError(
                      " '/' and '.' characters will be replayed with '-'"
                    )
                }}
                onBlur={() => setFormError("")}
                onChange={(e) =>
                  setTypeIDPrefix(
                    e.target.value.replace("/", "-").replace(".", "-")
                  )
                }
                value={typeIDPrefix}
                className="form-control"
              />
            </label>

            <CheckboxInput
              label="calibration scheduling required?"
              value={calibrationRequired}
              defaultChecked={calibrationRequired}
              onChange={(e) => {
                setCalibrationFrequency("")
                setCalibrationDocument("")
                setCalibrationRequired(e.target.checked)
              }}
              horizontal={true}
            />

            <TextInput
              label="calibration frequency (months)"
              required={calibrationRequired}
              type="number"
              options={{ disabled: addCat || !calibrationRequired }}
              onChange={(e) => setCalibrationFrequency(e.target.value)}
              value={calibrationFrequency}
            />

            <label>
              <span>blank calibration form (doc-type .pdf): </span>
              <input
                type="file"
                disabled={addCat || !calibrationRequired}
                onChange={(e) => handleFileChange(e)}
                className="form-control"
              />
            </label>
            <label>
              <span>This Equipment's default manager:</span>
              <Select
                options={moderatorList}
                value={typeDefaultCustody}
                onChange={(option) => setTypeDefaultCustody(option)}
              />
            </label>

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
            {uploadError && <div className="error">{uploadError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending || uploadPending || addCat}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isPending || uploadPending || addCat}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

/* ***Equipment Object in Firestore:***
companies collection -> company doc -> equipment collection -> equipmentClassification doc -> 
EquipmentTypes: [
    {
        name: '',
        category:'',
        calibrationFrequency: #, (this is in months - type integer)
        documentURL: '{URL}', (goes to storage doc)
        prefix:''
        ];
    }, ...
]
*/
