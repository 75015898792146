import { useState } from "react"
import { useParams } from "react-router-dom"
import { useCompanyContext } from "../../../company/index"
import { useAuthContext } from "../../../authentication"
import { useStorage } from "../../../../hooks/useStorage"
import {
  AddUserToEvaluationListModal,
  EvaluationUserList,
  useEvaluationType,
  useGetEmployeeEvaluationData,
} from "../../index"
import DocumentList from "../../../../components/DocumentList"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
import { Button } from "react-bootstrap"
import DeleteEvaluationModal from "../../components/DeleteEvaluationModal"
import sortObjects from "../../../../util/sortObjects"

export default function EvaluationType() {
  const { evaluationId } = useParams()
  const { user } = useAuthContext()
  const { companyData, documentsOverMaxPermitted, evaluationList } =
    useCompanyContext()

  const evaluation = evaluationList.find((e) => e.name === evaluationId)

  const {
    isPending,
    error,
    updateEvaluationType,
    deleteDocumentFromEvaluationType,
    addDocumentToEvaluationType,
  } = useEvaluationType()

  const {
    downloadPDF,
    isPending: storagePending,
    error: uploadError,
  } = useStorage()

  //delete Evaluation control
  const [showDeleteEquipmentModal, setShowDeleteEquipmentModal] =
    useState(false)
  const deleteEvaluationModalShow = () => setShowDeleteEquipmentModal(true)
  const deleteEvaluationModalHide = () => setShowDeleteEquipmentModal(false)

  //delete Evaluation control
  const [
    showAddUserToEvaluationListEquipmentModal,
    setShowAddUserToEvaluationListEquipmentModal,
  ] = useState(false)
  const addUserToEvaluationListEquipmentModalShow = () =>
    setShowAddUserToEvaluationListEquipmentModal(true)
  const addUserToEvaluationListEquipmentModalHide = () =>
    setShowAddUserToEvaluationListEquipmentModal(false)

  //getting the data from companyEmployees and those who have been evaluated
  const { usersEvaluationData: usersEvalData, employeeOptions } =
    useGetEmployeeEvaluationData(companyData, evaluation)

  const [standard, setStandard] = useState("")
  const displayedEmployeeOptions = sortObjects(
    employeeOptions,
    "value.displayName",
    true
  )

  //control
  const [changingStandard, setChangingStandard] = useState(false)

  //changing standard, in the database based on input
  const updateEvaluation = async () => {
    const updateObject = {
      standard: standard ? standard : evaluation.standard,
    }

    await updateEvaluationType({ evaluationId, updateObject })

    setChangingStandard(false)
    setStandard("")
  }

  //adding Documents funtionality
  const handleDownload = async (doc) => {
    const fileName = `${evaluation.name}_Evaluation_${doc.name}`
    await downloadPDF(doc.documentURL, `${fileName}.pdf`)
  }

  const handleUpload = async (file, docName, replacingExistingDoc) => {
    await addDocumentToEvaluationType({
      user,
      docName,
      file,
      replacingExistingDoc,
      evaluation,
    })
  }

  const handleDelete = async (doc) => {
    await deleteDocumentFromEvaluationType({
      evaluation,
      documentToDelete: doc,
    })
  }

  //render loading while fetching evaluation docData from firestore
  if (!evaluation) return <p className="pending">Loading...</p>

  return (
    <ContentOptionalRight
      contentTitle={
        <div className="d-flex">
          <div className="me-5">
            Evaluation - <span className="fw-bold">{evaluation.name}</span>{" "}
            <span className="text-muted">({evaluation.category})</span>
          </div>
          <div className="ms-5">
            {user.admin && !evaluation.deleted && (
              <Button
                className="bg-secondary"
                onClick={deleteEvaluationModalShow}
              >
                Delete Evaluation Type
              </Button>
            )}
          </div>
        </div>
      }
      content={
        <>
          {/* Changable data -can probably be changed to refs instead of state */}
          <div className="mb-5">
            <p className="mb-3 d-flex">
              <span>Performed as per: {evaluation.standard}</span>
              {user.admin && !changingStandard && (
                <span
                  className="ms-3 text-muted clickable text-decoration-underline"
                  onClick={() => setChangingStandard(true)}
                >
                  change
                </span>
              )}
              {changingStandard && (
                <>
                  <input
                    className="ms-4"
                    type="text"
                    onChange={(e) => setStandard(e.target.value)}
                    value={standard}
                    style={{ width: "300px", fontSize: "1em" }}
                  />
                  <span
                    className="ms-3 text-muted clickable text-decoration-underline"
                    onClick={updateEvaluation}
                  >
                    save
                  </span>
                  <span
                    className="ms-3 text-muted clickable text-decoration-underline"
                    onClick={() => {
                      setStandard("")
                      setChangingStandard(false)
                    }}
                  >
                    cancel
                  </span>
                </>
              )}
            </p>

            <hr />

            <DocumentList
              listTitle="Related Documents"
              buttonText="Add a document"
              documents={evaluation.docs}
              rightsToAdd={user.moderator}
              rightsToDelete={user.admin}
              handleUpload={handleUpload}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              documentsOverMaxPermitted={documentsOverMaxPermitted}
            />
          </div>
          <h2 className="section-header">
            Users required to be evaluated on {evaluation.name}
          </h2>
          <EvaluationUserList
            evaluationName={evaluation.name}
            evalData={usersEvalData}
            documentsOverMaxPermitted={documentsOverMaxPermitted}
          />

          <div className="mt-3">
            {user.moderator && (
              <button
                className="btn text-bg-primary ms-2"
                disabled={isPending || storagePending}
                onClick={addUserToEvaluationListEquipmentModalShow}
              >
                Add user to required list
              </button>
            )}
          </div>

          {/* Modals */}
          <DeleteEvaluationModal
            show={showDeleteEquipmentModal}
            handleClose={deleteEvaluationModalHide}
            evaluation={evaluation}
          />

          <AddUserToEvaluationListModal
            show={showAddUserToEvaluationListEquipmentModal}
            handleClose={addUserToEvaluationListEquipmentModalHide}
            evaluation={evaluation}
            employeeOptions={displayedEmployeeOptions}
          />
        </>
      }
    />
  )
}
