import React, { useState, useEffect } from "react"
import { useCompanyContext } from "../../../company"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function FolderView({ user }) {
  const { isAuditView } = useCompanyContext()

  if (isAuditView)
    return <AuditView user={user} useGetFilteredData={useGetFilteredData} />

  return <LabView user={user} useGetFilteredData={useGetFilteredData} />
}

const useGetFilteredData = (items, folderId, searchBy) => {
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    let newList = []
    //create arrray of documents
    if (items.length) {
      newList = items.filter(
        (item) =>
          `${item.folderId}` === `${folderId}` &&
          item.name.trim().toLowerCase().includes(searchBy.trim().toLowerCase())
      )
    }

    setFilteredData(newList)
  }, [items, folderId, searchBy])

  return filteredData
}
