import { useState } from "react"
import { verifyImage } from "../../util/verifyFileInput"
//components
import Cropper from "react-cropper"
import { Button } from "react-bootstrap"
//styles
import "./PhotoCropper.css"

export default function PhotoCropper({
  handleSubmit,
  setLogoError,
  cropperSize,
  imageSize,
  initImage,
  initialAspectRatio,
  modal,
  dragMode,
  viewMode,
  zoomTo,
  cropBoxResizable,
  toggleDragModeOnDblclick,
}) {
  const [cropping, setCropping] = useState(false)
  const [image, setImage] = useState(initImage ? initImage : null)
  const [extension, setExtension] = useState("")
  const [cropData, setCropData] = useState("")
  const [cropper, setCropper] = useState(null)

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL())
    }
  }

  const saveCropData = () => {
    cropper.getCroppedCanvas().toBlob((blob) => {
      saveImage(blob)
    })
  }

  const saveImage = async (blob) => {
    //Do things
    handleSubmit(blob, extension)
    reset()
  }

  const onChange = (e) => {
    e.preventDefault()
    setCropping(true)
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    //verify the file type
    const { error: fileError, fileExtension } = verifyImage(files[0])
    setExtension(fileExtension)
    if (fileError) return setLogoError(fileError)

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(files[0])
  }

  const reset = () => {
    setCropData("")
    setImage(initImage ? initImage : null)
    cropper.replace(null)
    setCropping(false)
  }

  return (
    <div>
      <div className="splitdiv" id="leftdiv">
        <div id="text-center">
          <input type="file" onChange={onChange} />
          {cropping && (
            <>
              <Button
                variant="secondary"
                type="button"
                id="leftbutton"
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="button"
                id="leftbutton"
                onClick={getCropData}
                className="ms-3"
              >
                Crop Image
              </Button>
            </>
          )}
          {cropping && (
            <Cropper
              style={cropperSize ? cropperSize : { height: 200, width: 400 }}
              className="cropper"
              zoomTo={zoomTo ? zoomTo : 0.5}
              rotatable={true}
              scalable={true}
              initialAspectRatio={initialAspectRatio ? initialAspectRatio : 2}
              src={image}
              viewMode={viewMode ? viewMode : 1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={0.8}
              checkOrientation={true}
              dragMode={dragMode ? dragMode : "move"}
              onInitialized={(instance) => {
                setCropper(instance)
              }}
              guides={true}
              modal={modal ? modal : false}
              cropBoxResizable={cropBoxResizable ? cropBoxResizable : false}
              toggleDragModeOnDblclick={
                toggleDragModeOnDblclick ? toggleDragModeOnDblclick : false
              }
            />
          )}
        </div>
      </div>
      <div className="mt-4 text-center">
        <h4>preview:</h4>
        {cropData && (
          <>
            <img
              style={imageSize ? imageSize : { height: 200, width: 400 }}
              src={cropData}
              alt="cropped"
            />
            {cropData && (
              <Button
                variant="primary"
                type="button"
                id="leftbutton"
                onClick={saveCropData}
                className="ms-5"
              >
                Save Image
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
