import { getMonth, getYear, isAfter } from "date-fns"
import { useEffect, useState } from "react"
import { Timestamp } from "firebase/firestore"

const useGetFilteredEvalualtingListData = (
  evalDocs,
  userId,
  currentFilter,
  searchBy,
  setSelectedData
) => {
  const [filteredData, setFilteredData] = useState([])

  //switch statement for filter followed by filtering the searce term which is an email or name
  useEffect(() => {
    //create arrray of evaluation data for the user
    let newList = evalDocs
    setSelectedData("")

    switch (currentFilter) {
      case "needs evaluated":
        newList = newList.filter((e) => e.notification)
        break
      case "overdue":
        newList = newList.filter((e) => e.evaluationStatus === "overdue")
        break
      case "all required":
        newList = newList.filter((e) => e.required)
        break
      case "all":
      default:
    }

    newList = newList.filter((e) => {
      return (
        e.name.trim().toLowerCase().includes(searchBy.trim().toLowerCase()) ||
        e.category
          .trim()
          .toLowerCase()
          .includes(searchBy.trim().toLowerCase()) ||
        e.standard.trim().toLowerCase().includes(searchBy.trim().toLowerCase())
      )
    })

    setFilteredData(newList)
  }, [evalDocs, currentFilter, searchBy])

  return filteredData
}
export { useGetFilteredEvalualtingListData }
