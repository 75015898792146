import { View, Text, StyleSheet, Image } from "@react-pdf/renderer"
import React from "react"

// Create styles
const styles = StyleSheet.create({
  header: {
    position: "relative",
    flexDirection: "row",
    width: "90vw",
    height: "50px",
    marginHorizontal: "auto",
    marginVertical: "15px",
  },
  headerBrand: {
    height: "100%",
    width: "50%",
  },
  headerImage: {
    objectFit: "cover",
    aspectRatio: 1 / 1,
  },
  companyName: {
    fontSize: "30pt",
    fontWeight: "bold",
  },
  headerTitle: {
    fontSize: "18pt",
    fontWeight: "bold",
    marginLeft: "auto",
  },
  headerInfo: {
    top: "10px",
    textAlign: "center",
    fontSize: "12pt",
  },
})

export default function ReportHeader({
  navLogoURL = null,
  companyName,
  title,
}) {
  return (
    <View fixed style={styles.header}>
      {/* branding */}
      <div style={styles.headerBrand}>
        {navLogoURL ? (
          <Image src={navLogoURL} style={styles.headerImage} />
        ) : (
          <Text style={styles.companyName}>{companyName}</Text>
        )}
      </div>
      <Text style={styles.headerTitle}>{title}</Text>
    </View>
  )
}
