import { BasicFormBody } from "./basicFormComponents"

export default function PersonalAccountForm({
  displayName,
  accountEmail,
  accountPassword,
  accountConfirmPassword,
  updateFields,
  children,
}) {
  return (
    <div>
      <h2>Your Personal eLabTracker Account Setup</h2>
      <BasicFormBody
        formData={[
          {
            label: "Display Name",
            value: displayName,
            autocomplete: "none",
            required: true,
            onChange: (e) => {
              updateFields({ displayName: e.target.value })
            },
            horizontal: true,
          },
          {
            label: "Email",
            value: accountEmail,
            autocomplete: "email",
            type: "email",
            required: true,
            onChange: (e) => {
              updateFields({ accountEmail: e.target.value })
            },
            horizontal: true,
          },
          {
            label: "Password",
            type: "password",
            value: accountPassword,
            required: true,
            onChange: (e) => {
              updateFields({ accountPassword: e.target.value })
            },
            horizontal: true,
          },
          {
            label: "Confirm Password",
            type: "password",
            value: accountConfirmPassword,
            autocomplete: "postal-code",
            required: true,
            onChange: (e) => {
              updateFields({ accountConfirmPassword: e.target.value })
            },
            horizontal: true,
          },
        ]}
      >
        {children}
      </BasicFormBody>
    </div>
  )
}
