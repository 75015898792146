import { useContext } from "react"
import { UserContext } from "../context/UserContext"

export const useUserContext = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error("useUserContext() must be used inside a userProvider")
  }

  return context
}