import React from "react"
import { Page, View, StyleSheet } from "@react-pdf/renderer"
import ReportTemplate from "../ReportTemplate"
import ReportHeader from "../ReportHeader"
import ReportFooter from "../ReportFooter"
import EquipmentListReportBody from "./EquipmentListReportBody"

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
  body: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
})

export default function EquipmentListReportRender({ companyData, reportData }) {
  //   console.log(reportData)
  return (
    //ReportTemplate provides Header and Footer
    <ReportTemplate>
      <Page size="A4" style={styles.page} orientation="landscape">
        {/* Header */}
        <ReportHeader
          title="Equipment List Report"
          navLogoURL={companyData.navLogoURL}
          companyName={companyData.companyName}
        />

        {/* Body */}
        <View style={styles.body} wrap={true}>
          <EquipmentListReportBody
            reportData={reportData}
            companyData={companyData}
          />
        </View>

        {/* Footer */}
        <ReportFooter companyId={companyData.companyName} />
      </Page>
    </ReportTemplate>
  )
}
