import React from "react"

export default function RadioInput({
  label,
  groupName,
  options,
  onChange,
  value,
  required,
  horizontal,
  style,
  attributeOptions,
}) {
  let appliedStyle = {}
  if (typeof style !== "undefined") appliedStyle = { ...appliedStyle, ...style }
  if (horizontal)
    appliedStyle = {
      display: "flex",
      justifyContent: "flex-start",
      alignContent: "baseline",
      alignTracks: "baseline",
      maxWidth: "80%",
      ...appliedStyle,
    }

  const renderOption = (option) => {
    return (
      <label
        key={option.value}
        style={{
          display: "flex",
          marginRight: "auto",
          gap: "1rem .5rem",
          justifyContent: "flex-start",
          maxHeight: "1em",
          width: "auto",
        }}
        className="align-left"
      >
        <input
          type="radio"
          checked={option.value === value}
          onChange={onChange}
          value={option.value}
          name={groupName}
          className="radio-input"
        />
        <span>{option.label}</span>
      </label>
    )
  }

  return (
    <div style={appliedStyle}>
      {label}
      {required ? "*" : ""}:{options.map((option) => renderOption(option))}
    </div>
  )
}
