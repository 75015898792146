//takes an array and removes any duplicates.
//if it is an array of objects the unique proprty or "key" also needs passed

export default function removeDuplicatesInArray(arr, uniqueKey) {
  //error handling
  if (arr.length === 0) return []
  if (
    arr.some((value) => {
      return typeof value == "object"
    }) &&
    typeof uniqueKey === "undefined"
  )
    throw new Error(
      "removeDuplicatesInArray was called with an array of objects but no kewy was passed."
    )

  //handling if there are no objects (i.e. all strings, nums, whatever)
  if (
    !arr.some((value) => {
      return typeof value == "object"
    })
  ) {
    const uniqueArray = arr.filter(
      (v, i, a) => a.findIndex((t) => t === v) === i
    )
    // console.log(uniqueArray)
    return uniqueArray
  }

  //handing array of objects using the uniqueKey
  const uniqueArray = arr.filter(
    (v, i, a) => a.findIndex((t) => t[uniqueKey] === v[uniqueKey]) === i
  )
  //   console.log(uniqueArray)
  return uniqueArray
}
