import { useParams } from "react-router-dom"
import { useCompanyContext } from "../../../company/index"
import { useAuthContext } from "../../../authentication"
import { useStorage } from "../../../../hooks/useStorage"
import { useGetEquipmentType } from "../../index"

//layout
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
//components
import { EquipmentList } from "../../index"
import DownloadFileButton from "../../../../components/basicComponents/DownloadFileButton"
import DocumentList from "../../../../components/DocumentList"
import ViewPDFButton from "../../../../components/basicComponents/ViewPDFButton"

export default function AuditView() {
  const { typePrefix, auditId } = useParams()
  const { equipmentTypes, documentsOverMaxPermitted } = useCompanyContext()
  const { user } = useAuthContext()

  const { downloadPDF, isPending: downloadPending } = useStorage()

  const { type, error } = useGetEquipmentType(equipmentTypes, typePrefix)

  //other/additional Documents funtionality
  const handleDownload = async (doc) => {
    const fileName = `${type.name}_${doc.name}`
    await downloadPDF(doc.documentURL, `${fileName}.pdf`)
  }

  const handleUpload = async () => {
    return
  }

  const handleDelete = async () => {
    return
  }

  if (!type) return <div className="laoding">Loading...</div>
  if (error) return <div className="error">{error}</div>

  return (
    <ContentOptionalRight
      contentTitle={
        <>
          Equipment Type:<span className="fw-bold">{type.name}</span>
        </>
      }
      content={
        <>
          <div className="mb-5">
            <div className="d-flex">
              <p className="fw-bold">Calibration Frequency:</p>
              {typeof type.calibrationRequired === "undefined" ||
              type.calibrationRequired ? (
                <>
                  <p className="ms-2">{type.calibrationFrequency}</p>
                  <p className="ms-2"> months</p>
                </>
              ) : (
                <p className="ms-2">Calibration Not Required</p>
              )}
            </div>

            <div className="d-flex mt-2">
              <div className="me-5">
                <p>
                  <span className="fw-bold">Equipment Category:</span>{" "}
                  {type.category}
                </p>
              </div>
              <div className="ms-5">
                <p>
                  <span className="fw-bold">Equipment Type Prefix:</span>{" "}
                  {type.prefix}
                </p>
              </div>
            </div>

            <div className="d-flex mt-3">
              <p className="fw-bold">Manager for Equipment Type:</p>
              <p className="ms-2">
                {type.defaultCustody
                  ? type.defaultCustody.displayName
                  : "not set"}
              </p>
            </div>
            <hr />

            {/* actions for calibration form render if the equipment type requires calibration*/}
            {(typeof type.calibrationRequired === "undefined" ||
              type.calibrationRequired) && (
              <>
                {/* If there is a calibration form */}
                {type.documentURL && (
                  <>
                    <h3 className="fs-4">Blank Calibration Form</h3>

                    <div className="d-flex mt-2">
                      <div className="me-5">
                        <DownloadFileButton
                          fileName={`${type.name}_claibration-form_blank.pdf`}
                          docURL={type.documentURL}
                          buttonText="Download"
                        />
                      </div>
                      <div>
                        <ViewPDFButton
                          buttonText="View in new tab"
                          docURL={type.documentURL}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* If there is no calibration form */}
                {!type.documentURL && (
                  <div className="d-flex">
                    <p className="text-muted">
                      No blank calibration form available
                    </p>
                  </div>
                )}
                <hr />
              </>
            )}

            <DocumentList
              listTitle="Additional Documents"
              buttonText="Add a new additional document"
              documents={type.otherDocuments}
              rightsToAdd={false}
              rightsToDelete={false}
              handleUpload={handleUpload}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              documentsOverMaxPermitted={documentsOverMaxPermitted}
            />
          </div>
          <h2 className="section-header">Equipment - {type.name}</h2>
          <EquipmentList
            selectedType={type.prefix}
            auditId={auditId}
            isAuditView={true}
          />
        </>
      }
    />
  )
}
