import React, { useEffect, useState } from "react"
import { useFirestore } from "../../../../hooks/useFirestore"
import { useAuditingCompanyContext } from "../../hooks/useAuditingCompanyContext"

export default function UserLoader({ userId, children }) {
  const [thisUser, setThisUser] = useState(null)
  const { error, isPending, getDocument } = useFirestore()
  const { auditingCompanyData } = useAuditingCompanyContext()

  useEffect(() => {
    //get user Data
    getDocument(`users/${userId}`).then((res) => {
      //TODO: Once audits are set up grab any audits that this user is in and add them to the user object
      const userAudits = {}
      setThisUser({ ...res, ...userAudits })
    })
  }, [userId])

  //display error or loading
  if (error)
    return (
      <div className="error">
        <p>
          The database could not find this user or, if it did, you do not have
          permission to view this user's details:
        </p>
        <p>{error}</p>
      </div>
    )
  if (isPending) return <p>...loading</p>

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return (
            thisUser &&
            React.cloneElement(child, { thisUser, auditingCompanyData })
          )
        }
        return child
      })}
    </>
  )
}
