import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

import "./legal.css"
import { CheckboxInput, TextInput } from "../basicFormComponents"

const TermsAndConditionsModal = ({
  show,
  handleClose,
  handleAgree,
  TermsJSX,
  requireAgree = true,
}) => {
  const [agreed, setAgreed] = useState(false)
  const [signedNameForAgreement, setSignedNameForAgreement] = useState("")
  const [error, setError] = useState(false)

  const handleSubmit = () => {
    setError("")
    if (!agreed && requireAgree) {
      setError("You must agree to the terms to continue")
    }
    if (agreed) {
      handleAgree({ signedName: signedNameForAgreement })
      handleClose({ closeSuccessful: true })
    }
    if (!requireAgree) handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton={!requireAgree}>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="legal-terms">
          {TermsJSX}

          {requireAgree && (
            <>
              <CheckboxInput
                label="The undersigned agrees to the terms and conditions contained herein"
                horizontal={true}
                onChange={(e) => {
                  setAgreed(e.target.checked)
                }}
              />

              <TextInput
                label="Name"
                horizontal={true}
                required={true}
                value={signedNameForAgreement}
                onChange={(e) => setSignedNameForAgreement(e.target.value)}
              />
            </>
          )}
        </div>
        {error && <p className="error">{error}</p>}
      </Modal.Body>
      <Modal.Footer>
        {!requireAgree && (
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        )}
        {requireAgree && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!agreed || !signedNameForAgreement}
          >
            Agree and Sign
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default TermsAndConditionsModal
