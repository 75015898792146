import { useFirestore } from "../../../hooks/useFirestore"

export function useMessages() {
  const { error, isPending, getDocument } = useFirestore()

  const getMessageFromId = async (userId, messageId) => {
    const messageThread = await getDocument(
      `users/${userId}/messages/${messageId}`
    )

    return messageThread
  }

  const getMessageFromList = (messageList, messageId) => {
    let message = null
    if (messageList.length) {
      messageList.forEach((m) => {
        if (m.from.uid === messageId) {
          message = m
        }
      })
    }

    return message
  }

  return { error, isPending, getMessageFromId, getMessageFromList }
}
