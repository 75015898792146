import { useEffect, useState } from "react"

const useFilterEquipment = (
  equipmentListDisplay,
  currentFilter,
  selectedType,
  selectedCustody,
  selectedCategory,
  equipmentTypes
) => {
  const [filteredData, setFilteredData] = useState([])
  //switch statement for filter followed by filtering the selected type which is a uid or displayName string
  useEffect(() => {
    let newList = []

    switch (currentFilter) {
      case "out of service":
      case "in service":
        newList = equipmentListDisplay.filter((e) => e.status === currentFilter)
        break
      case "overdue":
        newList = equipmentListDisplay.filter(
          (e) => e.calibrationStatus === "overdue"
        )
        break
      case "needs calibrated":
        newList = equipmentListDisplay.filter((e) => e.notification)
        break
      case "all":
        newList = equipmentListDisplay
      default:
        newList = equipmentListDisplay
    }

    if (selectedType)
      newList = newList.filter(
        (e) =>
          e.typePrefix.toLowerCase().trim() ===
          selectedType.toLowerCase().trim()
      )

    if (selectedCategory && selectedCategory !== "all") {
      newList = newList.filter((t) => {
        //getting categories of the types
        const tCategory = equipmentTypes.filter(
          (type) => type.prefix === t.typePrefix
        )[0].category

        return (
          tCategory.toLowerCase().trim() ===
          selectedCategory.toLowerCase().trim()
        )
      })
    }

    if (selectedCustody) {
      newList = newList.filter(
        (t) =>
          t.currentCustody.displayName.toLowerCase().trim() ===
            selectedCustody.toLowerCase().trim() ||
          t.currentCustody.uid === selectedCustody
      )
    }

    setFilteredData(newList)
  }, [
    selectedType,
    selectedCategory,
    selectedCustody,
    currentFilter,
    equipmentListDisplay,
  ])

  return filteredData
}

const useFilterEquipmentTypes = ({
  equipmentTypeListDisplay,
  selectedCategory = "all",
  calibrationRequired = "both",
  searchTerm = "",
}) => {
  const [filteredData, setFilteredData] = useState([])
  //switch statement for filter followed by filtering the selected type which is a uid or displayName string
  useEffect(() => {
    let newList = equipmentTypeListDisplay

    if (selectedCategory && selectedCategory !== "all") {
      newList = newList.filter(
        (t) =>
          t.category.toLowerCase().trim() ===
          selectedCategory.toLowerCase().trim()
      )
    }

    if (calibrationRequired && calibrationRequired !== "both") {
      newList = newList.filter((t) =>
        calibrationRequired === "true"
          ? t.calibrationRequired === true ||
            typeof t.calibrationRequired === "undefined" // this covers equipment added before implementation of calibration needed - remove the typeof check after re-structure of equipmentTypes where this will be added
          : t.calibrationRequired === false
      )
    }

    if (searchTerm) {
      newList = newList.filter(
        (t) =>
          t.name
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim()) ||
          t.prefix
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim())
      )
    }

    setFilteredData(newList)
  }, [
    searchTerm,
    calibrationRequired,
    selectedCategory,
    equipmentTypeListDisplay,
  ])

  return filteredData
}

export { useFilterEquipment, useFilterEquipmentTypes }
