import { useEffect } from "react"
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom"
import { useAuthContext } from "../../authentication"
import { useAuditorUserContext } from "../hooks/useAuditorUserContext"
import { useAgreeToTerms } from "../../../hooks/legal/useAgreeToTerms"
import TermsAndConditionsModal from "../../../components/legal/TermsAndConditionsModal"
import TermsAndConditionsForAuditorJSX from "../../../components/legal/TermsAndConditionsForAuditorJSX"
import {
  useAuditingCompanyContext,
  AuditingCompanyNavbar,
  UserDetails,
  AuditingCompanySettings,
  Staff,
  AuditRequest,
} from ".."
import AccessDenied from "../../../components/AccessDenied"
import ProtectedPage from "../../../pages/ProtectedPage"
import { Audits } from "../../audits"

export default function AuditingCompany({ provideAuditingCompanyId }) {
  const navigate = useNavigate()
  const { auditingCompanyId } = useParams()
  const { user } = useAuthContext()
  const { userData, userIsReady } = useAuditorUserContext()
  const {
    auditingCompanyIsReady,
    auditingCompanyData,
    pendingAudits,
    activeAudits,
    completeAudits,
    rejectedAudits,
  } = useAuditingCompanyContext()

  //get and reset the auditingCompanyId for the App
  provideAuditingCompanyId(auditingCompanyId)
  useEffect(() => {
    return () => provideAuditingCompanyId(null)
  }, [])

  //if the user has not agreed to the current terms they must before continuing, this function is executed when they do
  const { agreeToAuditorTerms, error: signTermsError } = useAgreeToTerms()
  const handleAgree = async ({ signedName }) => {
    await agreeToAuditorTerms({
      uid: user.uid,
      signedNameForAuditorAgreementofTermsAndConditions: signedName,
    })

    if (signTermsError)
      alert(
        "there was an error on our end, please contact elabtracker with this errorCode and include your accounts email address in the correspondence: SIGNTERMSERROR002"
      )
  }
  const handleCloseTermsModal = ({ closeSuccessful }) => {
    if (closeSuccessful) console.log("modal to close")
  }

  // no template if no company
  if (!auditingCompanyIsReady || !userIsReady) return <p>Loading...</p>

  return (
    <>
      <TermsAndConditionsModal
        show={!userData.agreedToAuditorTerms}
        TermsJSX={<TermsAndConditionsForAuditorJSX />}
        handleAgree={handleAgree}
        handleClose={handleCloseTermsModal}
        requireAgree={true}
      />
      <AuditingCompanyNavbar auditingCompanyId={auditingCompanyId} />
      <Routes>
        {/* Default copmponent/route */}
        <Route
          path="/"
          element={<Navigate to={`user-details/${user.uid}`} />}
        />
        <Route path="/user-details" element={<Navigate to={`${user.uid}`} />} />
        <Route path="access-denied" element={<AccessDenied />} />

        {/* Auditing company related nested routes */}
        <Route path="user-details/:userId" element={<UserDetails />} />

        <Route path="staff" element={<Staff />} />

        <Route
          path="audits"
          element={
            <Audits
              pendingAudits={pendingAudits}
              activeAudits={activeAudits}
              completeAudits={completeAudits}
              rejectedAudits={rejectedAudits}
            />
          }
        />
        <Route path="audit-request" element={<AuditRequest />} />

        <Route
          path="company-settings"
          element={
            <ProtectedPage
              allowUser={user.auditingAdmin}
              pathIfRejected={`/company/${user.auditingCompanyId}/access-denied`}
            >
              <AuditingCompanySettings
                auditingCompanyData={auditingCompanyData}
              />
            </ProtectedPage>
          }
        />
      </Routes>
    </>
  )
}
