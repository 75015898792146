import { formatUSD } from "../../util/formatCurrency"

import { Table } from "react-bootstrap"
import TableHeaderSortable from "../TableHeaderSortable"

export default function CalculatorTable({
  serviceData,
  paymentPlan,
  quantitiesUsed,
}) {
  const tableData = formatUsage(serviceData, paymentPlan, quantitiesUsed)
  const { total: serviceTotal, overageSubtotal } = getTotals({
    subtotals: tableData.map((data) => data.overageFee),
    serviceData,
    paymentPlan,
  })
  //console.log(quantitiesUsed)
  //   console.log(serviceData)

  const tableHeaderArray = [
    { sortable: false, title: "Usage Data", changeSortByTo: "" },
    { sortable: false, title: "included", changeSortByTo: "" },
    { sortable: false, title: "used", changeSortByTo: "" },
    { sortable: false, title: "remaining", changeSortByTo: "" },
    { sortable: false, title: "overage", changeSortByTo: "" },
    { sortable: false, title: "overage rate", changeSortByTo: "" },
    { sortable: false, title: "Subt.", changeSortByTo: "" },
  ]
  return (
    <div>
      <Table
        striped
        hover
        bordered
        className="data-list-table text-nowrap"
        size="md"
        responsive
      >
        <TableHeaderSortable
          thArray={tableHeaderArray}
          sortBy=""
          sortAsc=""
          changeSortBy=""
          classes="text-center text-wrap"
        />
        <tbody className="text-center">
          {tableData.map((u) => (
            <tr key={u.id}>
              <td className="fw-bold">{u.id}</td>
              <td>{u.maximum}</td>
              <td>{u.quantityUsed}</td>
              <td>{u.remaining}</td>
              <td>{u.overage}</td>
              <td>{u.overageRateText}</td>
              <td>{formatUSD(u.overageFee)}</td>
            </tr>
          ))}
          {/* Total rows */}
          <tr>
            <td colSpan={5} className="text-end">
              <strong>Sub Total**:</strong>
            </td>
            <td colSpan={2}>{formatUSD(overageSubtotal)}</td>
          </tr>
          <tr>
            <td colSpan={5} className="text-end">
              <strong>Total**:</strong>
            </td>
            <td colSpan={2}>
              {formatUSD(serviceTotal)} /{" "}
              {paymentPlan === "monthly" ? "month" : "year"}
            </td>
          </tr>
          {/*included Features Rows */}
          <tr>
            <td colSpan={2} className="text-end">
              Document Store:
            </td>
            <td>{serviceData.additionalDocs ? "included" : "not included"}</td>
            <td colSpan={2} className="text-end">
              Messaging:
            </td>
            <td>{serviceData.messaging ? "included" : "not included"}</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

//format the useage in a way that can be displayed correctly in a table
const formatUsage = (serviceData, paymentPlan, quantitiesUsed) => {
  //   console.log(serviceData)
  return quantitiesUsed.map((use) => {
    // console.log(use)
    const maximum = serviceData[use.propIdForIncluded]
    const remaining =
      maximum - use.quantityUsed > 0 ? maximum - use.quantityUsed : 0
    const overage = remaining <= 0 ? Math.abs(maximum - use.quantityUsed) : 0
    const overageRate = serviceData[use.propIdForRate][paymentPlan]
    const overageRateText = `${formatUSD(
      serviceData[use.propIdForRate][paymentPlan]
    )} / ${
      serviceData[use.propIdForRate]["lotSize"] === 1
        ? "ea"
        : serviceData[use.propIdForRate]["lotSize"]
    }`
    const overageFee =
      Math.ceil(overage / serviceData[use.propIdForRate].lotSize) * overageRate

    return {
      ...use,
      remaining,
      maximum,
      overage,
      overageRate,
      overageRateText,
      overageFee,
    }
  })
}

const getTotals = ({ subtotals, serviceData, paymentPlan }) => {
  let overageSubtotal = subtotals.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue
  }, 0)
  const total =
    paymentPlan === "monthly"
      ? overageSubtotal + serviceData.monthlyFee
      : overageSubtotal + serviceData.annualFee

  return { overageSubtotal, total }
}
