import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"


//options for Selection of tags
const tagOptions = formatSelectArray(['equipment', 'evaluation', 'none'])

export default function MessageForm({ tag, content, updateFields, children }) {
  return (
    <div>
        <span>tag: </span>
        <Select 
            onChange={(option) => updateFields({ tag: option })}
            options={tagOptions}
            value={ tag }
        />
        <div className={children ? 'd-md-flex text-wrap' : ''}>
            <label style={{ width:"100%" }} className="me-4">
                <span>Message*: </span>
                <textarea
                    style={{ height: '10rem', width:"100%" }}
                    onChange={(e) => updateFields({ content: e.target.value})}
                    value={content}
                    required
                    className="form-control"
                    max-length={500}
                    wrap='hard'
                />
            </label>
            {children}
        </div>
    </div>
  )
}
