import minorLogo from "../../../assets/logo/logo_square.png"

import { useAuthContext } from "../../authentication/index"
import { useAuditingCompanyContext } from "../index"
import { useInfoContext } from "../../../hooks/useInfoContext"

import { Link, NavLink } from "react-router-dom"
import { Navbar, Nav } from "react-bootstrap"
import { useLogout } from "../../authentication/index"

//styles
import "./AuditingCompanyNavbar.css"

export default function AuditingCompanyNavbar({ auditingCompanyId }) {
  const { user } = useAuthContext()
  const { auditingCompanyData } = useAuditingCompanyContext()
  const { dispatch } = useInfoContext()
  const { logout } = useLogout()

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="app-nav align-items-baseline flex-nowrap"
    >
      <Navbar.Brand>
        <div className="navbar-brand">
          <Link
            to={`/auditing-company/${auditingCompanyId}`}
            className="navbar-brand"
          >
            {auditingCompanyData.navLogoURL && (
              <img
                className="nav-logo-img"
                src={auditingCompanyData.navLogoURL}
                alt={auditingCompanyData.companyName}
              />
            )}
            {(!auditingCompanyData.navLogoURL ||
              typeof auditingCompanyData.navLogoURL === "undefined") && (
              <h1 className="nav-logo-img">
                {auditingCompanyData.companyName}
              </h1>
            )}
          </Link>
        </div>
      </Navbar.Brand>

      <div className="logout ms-auto">
        <span
          className="clickable color-white text-decoration-underline fs-6 me-3"
          onClick={() => dispatch({ type: "FLY_IN" })}
        >
          help
        </span>
        <button className="btn text-bg-primary align-self-top" onClick={logout}>
          Logout
        </button>
        <Link to="/" className="minor-brand me-3">
          <img src={minorLogo} alt="eLab Tracker" />
        </Link>
      </div>

      <div className="main-nav-link-container text-left">
        <Navbar.Toggle className="me-4" />
        <span
          className="d-md-none clickable color-white text-decoration-underline fs-6 me-3"
          onClick={() => dispatch({ type: "FLY_IN" })}
        >
          help
        </span>
        <Navbar.Collapse className="text-end me-4 rounded-3">
          <Nav className="ms-auto d-flex">
            <Nav.Item className="me-1 text-nowrap">
              <Nav.Link
                eventKey="0"
                as={NavLink}
                to={`user-details/${user.uid}`}
                style={{ color: "lightgrey" }}
              >
                My Page
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="me-1">
              <Nav.Link
                eventKey="1"
                as={NavLink}
                to={`audits`}
                style={{ color: "lightgrey" }}
              >
                Audits
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="me-1">
              <Nav.Link
                eventKey="2"
                as={NavLink}
                to={`staff`}
                style={{ color: "lightgrey" }}
              >
                Staff
              </Nav.Link>
            </Nav.Item>

            {/* This maybe should be a Dropdown with different types of docs */}
            {/* TODO: implement additional documents for auditing companies? */}
            {/* {auditingCompanyData.additionalDocuments && (
              <Nav.Item>
                <Nav.Link
                  eventKey="4"
                  as={NavLink}
                  to={`additional-docs`}
                  style={{ color: "lightgrey" }}
                >
                  Docs
                </Nav.Link>
              </Nav.Item>
            )} */}

            {user.auditingAdmin && (
              <Nav.Item className="me-1">
                <Nav.Link
                  eventKey="5"
                  as={NavLink}
                  to={`company-settings`}
                  style={{ color: "lightgrey" }}
                >
                  Settings
                </Nav.Link>
              </Nav.Item>
            )}

            {/* //TODO: need to figure out how this is going to be charged or if it is just free */}
            {/* {user && user.primary && (
              <Nav.Item className="me-1">
                <Nav.Link
                  eventKey="6"
                  as={NavLink}
                  to={`billing`}
                  style={{ color: "lightgrey" }}
                >
                  Payments
                </Nav.Link>
              </Nav.Item>
            )} */}

            <button className="d-md-none btn mb-0 ms-auto" onClick={logout}>
              Logout
            </button>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
