import React from "react"
import { useCompanyContext } from "../../../company"
import AuditView from "./AuditView"
import LabView from "./LabView"

export default function EquipmentList({
  selectedType = "",
  selectedCategory = "all",
  selectedCustody,
}) {
  const { isAuditView, auditId } = useCompanyContext()

  if (isAuditView)
    return (
      <AuditView
        selectedCustody={selectedCustody}
        selectedCategory={selectedCategory}
        selectedType={selectedType}
      />
    )

  return (
    <LabView
      selectedCustody={selectedCustody}
      selectedCategory={selectedCategory}
      selectedType={selectedType}
    />
  )
}
