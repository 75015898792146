import { Button } from "react-bootstrap"
import { useNavigate } from "react-router"
import { useAuditStatus } from "../../hooks/useAuditStatus"
import { AuditStatusLabControls } from "../../components/AuditStatusControls"

export default function LabRepView({ auditData }) {
  const navigate = useNavigate()

  const handleNavigateToAudit = () =>
    navigate(`/lab-audit/${auditData.id}/lab-company/${auditData.labCompanyId}`)

  const handleNavigateToControls = (e) => {
    if (e.button === 2) return
    if (e.button === 0)
      navigate(
        `/lab-audit/${auditData.id}/lab-company/${auditData.labCompanyId}/lab-controls`
      )
    if (e.button === 1)
      window.open(
        `/lab-audit/${auditData.id}/lab-company/${auditData.labCompanyId}/lab-controls`,
        "_blank"
      )
  }

  return (
    <div>
      <div className="row">
        <div className="col"></div>
        <div className="col">
          <Button
            className="btn-sm mt-3"
            onMouseDown={(e) => handleNavigateToControls(e)}
            disabled={
              auditData.status === "complete" || auditData.status === "rejected"
            }
          >
            Go To Controls
          </Button>
        </div>
      </div>

      <hr />

      {/* Basic Controls for Audit based on status */}
      <AuditStatusLabControls
        auditStatus={auditData.status}
        auditId={auditData.id}
        handleNavigateToAudit={handleNavigateToAudit}
      />
    </div>
  )
}
