import { Text, StyleSheet, View } from "@react-pdf/renderer"
import { format } from "date-fns"

// Create styles
const styles = StyleSheet.create({
  reportTitle: {
    fontSize: "20pt",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  boxedSection: {
    display: "block",
    marginTop: "15px",
    marginBottom: "15px",
    padding: "5px",
    border: "2px grooved darkgray",
  },
  sectionTitle: {
    fontSize: "18pt",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  infoBlock: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "space-between",
    // border: "1px solid orange",
  },
  labelValuePair: {
    minWidth: "100ch",
    flexGrow: "1",
    marginBottom: "10px",
    // border: "1px solid red",
  },
})

export default function EquipmentPieceReportBody({ companyData, displayData }) {
  return (
    <div style={{ fontSize: "12pt" }}>
      <Text style={styles.reportTitle}>
        {displayData.type.name}: {displayData.id}
      </Text>
      <Text>Report run on: {format(new Date(), "yyyy/MM/dd")}</Text>
      <Text>
        Report run for: {format(displayData.dateOfReport, "yyyy/MM/dd")}
      </Text>

      <div style={styles.boxedSection}>
        <Text style={styles.sectionTitle}>Equipment Info</Text>

        <View style={styles.infoBlock}>
          <Text style={styles.labelValuePair}>Make: {displayData.make} </Text>
          <Text style={styles.labelValuePair}>Model: {displayData.model}</Text>
          <Text style={styles.labelValuePair}>
            Serial Number: {displayData.serialNumber}
          </Text>
          <Text style={styles.labelValuePair}>
            Aquired on:{" "}
            {typeof displayData.acquisitionDate === "string"
              ? ""
              : format(displayData.acquisitionDate.toDate(), "yyyy/MM/dd")}
          </Text>
        </View>
        <View style={styles.infoBlock}></View>
      </div>

      <div style={styles.boxedSection}>
        <Text style={styles.sectionTitle}>
          Current Summary ({format(new Date(), "yyyy/MM/dd")})
        </Text>

        <View style={styles.infoBlock}>
          <Text style={styles.labelValuePair}>
            Status: {displayData.status}{" "}
          </Text>
          <Text style={styles.labelValuePair}>
            Under Control of: {displayData.currentCustody.displayName}
          </Text>
          {(typeof displayData.type.calibrationRequired === "undefined" ||
            displayData.type.calibrationRequired) && (
            <Text style={styles.labelValuePair}>
              Last Calibration:{" "}
              {typeof displayData.lastCalibration === "undefined" ||
              !displayData.lastCalibration
                ? "no data"
                : format(displayData.lastCalibration.toDate(), "yyyy/MM/dd")}
            </Text>
          )}
        </View>
        {typeof displayData.type.calibrationRequired === "undefined" ||
        displayData.type.calibrationRequired ? (
          <View style={styles.infoBlock}>
            <Text style={styles.labelValuePair}>
              Next Calibration Due:{" "}
              {typeof displayData.nextCalibration === "undefined" ||
              !displayData.nextCalibration
                ? "no data"
                : format(
                    displayData.nextCalibration.toDate(),
                    "yyyy/MM/dd"
                  )}{" "}
            </Text>
          </View>
        ) : (
          <View style={styles.infoBlock}>
            <Text>Calibrations not required for this equipment.</Text>
          </View>
        )}
      </div>

      {/* This would change to use the info from the selected date */}
      {format(displayData.dateOfReport, "yyyy/MM/dd") !==
        format(new Date(), "yyyy/MM/dd") && (
        <div style={styles.boxedSection}>
          <Text style={styles.sectionTitle}>
            Summary For Entered Date (
            {format(displayData.dateOfReport, "yyyy/MM/dd")})
          </Text>

          <View style={styles.infoBlock}>
            <Text style={styles.labelValuePair}>
              Status: {displayData.historicalStatus || "no data"}{" "}
            </Text>
            <Text style={styles.labelValuePair}>
              Location/Custody:{" "}
              {displayData.historicalCustody?.displayName || "no data"}
            </Text>
            {(typeof displayData.type.calibrationRequired === "undefined" ||
              displayData.type.calibrationRequired) && (
              <Text style={styles.labelValuePair}>
                Previous Calibration:{" "}
                {typeof displayData.historicalCalibration === "undefined" ||
                !displayData.historicalCalibration
                  ? "no data"
                  : format(displayData.historicalCalibration, "yyyy/MM/dd")}
              </Text>
            )}
          </View>
        </div>
      )}
    </div>
  )
}
