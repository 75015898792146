export default function ContactPage() {
  return (
    <div className="page-container container m-auto ps-4 pe-4">
      <h1 className="section-title fs-2 fw-bold">Contact eLab Tracker</h1>
      <hr />
      <section className="border-top ms-3">
        <ul style={{ listStyleType: "square" }}>
          <li>
            <strong>help@eLabTracker.com</strong>
            <ul className="mt-2 ms-5" style={{ listStyleType: "disc" }}>
              <li>
                Catch all contact - email if you are confused about which email
                to contact.
              </li>
              <li>Get support from developers or sales.</li>
              <li>
                To report errors or expected behavior with the web app or site.
              </li>
            </ul>
          </li>
          <li>
            <strong>sales@eLabTracker.com</strong>
            <ul className="mt-2 ms-5" style={{ listStyleType: "disc" }}>
              <li>
                If you have questions about our packages or how to sign up.
              </li>
              <li>Request a Demonstration.</li>
              <li>
                Ask about other subscription options or custom subscription
                packages.
              </li>
            </ul>
          </li>
          <li>
            <strong>payments@eLabTracker.com</strong>
            <ul className="mt-2 ms-5" style={{ listStyleType: "disc" }}>
              <li>Contact with questions about invoices.</li>
              <li>Report issues with payments.</li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  )
}
