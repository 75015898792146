import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
import { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import ListFilter from "../../../components/basicComponents/ListFilter"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import TableHeaderSortable from "../../../components/TableHeaderSortable"
import sortObjects from "../../../util/sortObjects"
import { useAuthContext } from "../../authentication"
import { format } from "date-fns"
import { useAuditStatus } from "../index"

import TrashCanIcon from "../../../assets/trashcan-icon.svg"

export default function Audits({
  pendingAudits,
  activeAudits,
  completeAudits,
  rejectedAudits,
}) {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { companyId, auditingCompanyId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { error, isPending, deletePendingAudit } = useAuditStatus()

  const [selectedAudit, setSelectedAudit] = useState(null)
  const { filterBy, sortBy, order } = {
    filterBy: searchParams.get("filterBy") || "active",
    sortBy: searchParams.get("sortBy") || "requestedAt",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ filterBy, sortBy, order, ...newParams }, { replace })
  }
  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (sortBy === newSortBy && order === "asc") newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  const displayAudits = useGetDisplayAudits({
    pendingAudits,
    activeAudits,
    completeAudits,
    rejectedAudits,
    activeFilter: filterBy,
    sortBy,
    order,
  })

  const tableHeaderArray = getTableHeaders(
    filterBy,
    user.isAuditor || user.isAuditing
  )

  const handleDelete = async (auditId) => {
    const confirmMessage =
      "THIS CANNOT BE UNDONE! Are you sure you want to delete this audit? "

    if (!window.confirm(confirmMessage)) return alert("deletion aborted")

    //handle the actual delete
    const { success, errorMessage } = await deletePendingAudit({ auditId })
    if (!success)
      return alert(`Deletion not successful. server says: ${errorMessage}`)

    return alert(
      "Successfully deelted the pending audit. If you still see it in the list, you may have to refresh the page."
    )
  }

  return (
    <ContentOptionalRight
      content={
        <>
          <h3 className="mb-3">Audits</h3>
          <ListFilter
            filterOptions={["active", "pending", "complete", "rejected"]}
            currentFilter={filterBy}
            changeFilter={(newFilter) =>
              updateSearchParams({ newParams: { filterBy: newFilter } })
            }
          />
          {displayAudits.length !== 0 && (
            <Table striped hover bordered className="data-list-table break-60">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                changeSortBy={setSortBy}
                sortBy={sortBy}
                sortAsc={order === "asc" ? true : false}
              />
              <tbody className="text-center">
                {displayAudits.map((audit) => (
                  <tr
                    key={audit.id}
                    onClick={() => setSelectedAudit(audit.id)}
                    onDoubleClick={() => navigate(`/lab-audit/${audit.id}`)}
                    className={
                      selectedAudit === audit.id
                        ? "active clickable"
                        : "clickable"
                    }
                  >
                    <td>{audit.labCompany.name}</td>
                    <td>{audit.labCompany.primaryAccount.email}</td>
                    <td>{audit.requestedBy.email}</td>
                    <td>{format(audit.requestedAt.toDate(), "MM/dd/yyyy")}</td>
                    {(filterBy === "active" || filterBy === "complete") &&
                      typeof audit.acceptedAt !== "undefined" && (
                        <td>
                          {format(audit.acceptedAt.toDate(), "MM/dd/yyyy")}
                        </td>
                      )}
                    {audit.status === "pending" && (
                      <td>
                        {(user.auditingAdmin ||
                          (user.isAuditor &&
                            user.uid === audit.requestedBy.uid)) && (
                          <img
                            src={TrashCanIcon}
                            onClick={() => handleDelete(audit.id)}
                            className="icon"
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {displayAudits.length === 0 && (
            <p className="error">...cannot find audits meeting these filters</p>
          )}

          <div className="buttons d-flex">
            {user.isAuditor && typeof auditingCompanyId !== "undefined" && (
              <Link className="btn text-bg-primary" to="../audit-request">
                Create an Audit - TEST ME
              </Link>
            )}
            {selectedAudit && (
              <>
                <Link
                  to={`/lab-audit/${selectedAudit}`}
                  className={
                    typeof auditingCompanyId !== "undefined"
                      ? "ms-3 btn text-bg-secondary"
                      : "btn text-bg-secondary"
                  }
                >
                  Go To Audit
                </Link>
                <Button
                  variant="secondary"
                  onClick={() => setSelectedAudit(null)}
                  className="ms-auto"
                >
                  Unselect
                </Button>
              </>
            )}
          </div>
        </>
      }
    />
  )
}

const getTableHeaders = (auditStatus, canDelete) => {
  const tableHeaderArray = [
    { sortable: true, title: "Auditee", changeSortByTo: "labCompanyId" },
    { sortable: false, title: "Contact", changeSortByTo: "" },
    {
      sortable: true,
      title: "RequestedBy",
      changeSortByTo: "requestedBy",
    },
    {
      sortable: true,
      title: "Date Requested",
      changeSortByTo: "requestedAt",
    },
  ]

  if (auditStatus === "active" || auditStatus === "complete")
    tableHeaderArray.push({
      sortable: false,
      title: "Date Accepted",
      changeSortByTo: "",
    })

  if (canDelete && auditStatus === "pending")
    tableHeaderArray.push({
      sortable: false,
      title: "Delete",
      changeSortByTo: "",
    })

  return tableHeaderArray
}

const useGetDisplayAudits = ({
  pendingAudits,
  activeAudits,
  completeAudits,
  rejectedAudits,
  activeFilter,
  sortBy,
  order,
}) => {
  const [displayList, setDisplayList] = useState([])
  useEffect(() => {
    let list = []

    switch (activeFilter) {
      case "pending":
        list = pendingAudits
        break
      case "active":
        list = activeAudits
        break
      case "complete":
        list = completeAudits
        break
      case "rejected":
        list = rejectedAudits
        break
      default:
        list = activeAudits
    }

    list = sortObjects(list, sortBy, order)

    setDisplayList(list)
  }, [activeFilter, sortBy, order])

  return displayList
}
