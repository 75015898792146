import logo from "../assets/logo/logo_long.png"
import { Link, NavLink } from "react-router-dom"
import { Navbar, Nav } from "react-bootstrap"
import { useAuthContext } from "../features/authentication"
import { useLogout } from "../features/authentication"

//styles
import "./MainNavbar.css"

export default function MainNavbar() {
  const { user } = useAuthContext()
  const { logout } = useLogout()

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="app-nav align-items-baseline flex-nowrap"
    >
      <Navbar.Brand>
        <Link to="/" className="me-5">
          <img src={logo} alt="service logo" />
        </Link>
      </Navbar.Brand>
      <div className="main-nav-link-container text-center">
        <Navbar.Toggle className="me-4" />
        <Navbar.Collapse className="text-end me-4 rounded-3">
          <Nav className="ms-auto d-flex">
            <Nav.Item className="me-1">
              <Nav.Link
                eventKey="1"
                as={NavLink}
                to="/"
                style={{ color: "lightgrey" }}
              >
                Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="me-1">
              <Nav.Link
                eventKey="2"
                as={NavLink}
                to="/FAQ"
                style={{ color: "lightgrey" }}
              >
                FAQ
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="me-1">
              <Nav.Link
                eventKey="3"
                as={NavLink}
                to="/pricing"
                style={{ color: "lightgrey" }}
              >
                Pricing
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="me-1">
              <Nav.Link
                eventKey="4"
                as={NavLink}
                to="/contact"
                style={{ color: "lightgrey" }}
              >
                Contact
              </Nav.Link>
            </Nav.Item>

            {user && (
              <div className="nav-user-buttons d-flex ms-5">
                {user.company && (
                  <Nav.Link eventKey="6" as={NavLink} to="/company">
                    <button
                      className="btn text-bg-primary"
                      disabled={!user.company}
                    >
                      Lab Dashboard
                    </button>
                  </Nav.Link>
                )}

                {user.auditingCompanyId && (
                  <Nav.Link eventKey="6" as={NavLink} to="/auditing-company/">
                    <button
                      className="btn text-bg-primary"
                      disabled={!user.auditingCompanyId}
                    >
                      Audit Dashboard
                    </button>
                  </Nav.Link>
                )}

                <Nav.Link eventKey="7">
                  <button className="btn text-bg-primary" onClick={logout}>
                    Logout
                  </button>
                </Nav.Link>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}
