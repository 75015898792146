import { useFirestore } from "../../../hooks/useFirestore"
import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useVerify } from "../../authentication"

export function useServiceAgreement() {
  const { error, isPending, getDocument, updateDocument, increment } =
    useFirestore()
  const { verifyByEmail, error: verificationError } = useVerify()

  const getServiceAgreement = async ({ serviceLevelId }) => {
    const pricingDoc = await getDocument(`public/pricing`)
    let tierInfo = pricingDoc.tiers.filter(
      (tier) => `${tier.id}` === `${serviceLevelId}`
    )[0]
    // console.log(tierInfo)
    tierInfo = assignDefaults(tierInfo, pricingDoc)

    return tierInfo
  }

  //get the quantities used by the company account OR return quantities of 0 in the correct format for the calculator table
  const getQuantitiesUsed = ({ companyData, equipmentList = [] }) => {
    const getPersonnellUsed = () => {
      let admins = 0
      let moderators = 0
      let users = 0

      if (companyData && companyData.employees.length) {
        companyData.employees.forEach((employee) => {
          if (employee.rights === "user") users += 1
          else if (employee.rights === "moderator") moderators += 1
          else if (employee.rights === "admin") admins += 1
        })
      }

      return [
        {
          use: "users",
          id: "users",
          quantityUsed: users,
          propIdForIncluded: "usersIncluded",
          propIdForRate: "additionalUserFee",
        },
        {
          use: "moderators",
          id: "moderators",
          quantityUsed: moderators,
          propIdForIncluded: "moderatorsIncluded",
          propIdForRate: "additionalModeratorFee",
        },
        {
          use: "admins",
          id: "admins",
          quantityUsed: admins,
          propIdForIncluded: "adminsIncluded",
          propIdForRate: "additionalAdminFee",
        },
      ]
    }

    const getEquipmentUsed = () => {
      return {
        use: "equipment",
        id: "equipment",
        quantityUsed: equipmentList.length,
        propIdForIncluded: "equipmentIncluded",
        propIdForRate: "additionalEquipmentFee",
      }
    }

    const getDocumentsUsed = () => {
      return {
        use: "documents",
        id: "documents",
        quantityUsed: companyData ? companyData.documentsOwned : 0,
        propIdForIncluded: "documentsIncluded",
        propIdForRate: "additionalDocumentFee",
      }
    }

    return [...getPersonnellUsed(), getEquipmentUsed(), getDocumentsUsed()]
  }

  const changePaymentPlan = async ({ companyId, paymentPlan }) => {
    console.log("changing payment plan")
    //update the billingInfo doc to reflect the change.
    await updateDocument(`companies/${companyId}/billing/billingInfo`, {
      paymentPlan,
    })
  }

  const upgradeFromSandbox = async ({
    companyId,
    serviceLevelId,
    paymentPlan,
    primaryAccount,
  }) => {
    const generateInitialInvoiceAfterUpgradeFromSandbox = httpsCallable(
      functions,
      "initialInvoice-generateInitialInvoiceAfterUpgradeFromSandbox"
    )
    const getUserObject = httpsCallable(
      functions,
      "getUserObject-getUserObject"
    )
    let displayError = ""

    //check if the user's email is verified
    const firestoreUserData = await getDocument(`users/${primaryAccount.uid}`)
    console.log(firestoreUserData)
    if (
      typeof firestoreUserData.emailVerified === "undefined" ||
      !firestoreUserData.emailVerified
    ) {
      const userObject = await getUserObject({
        uid: primaryAccount.uid,
        companyId,
      })
      console.log(userObject)
      if (userObject.data.error) {
        displayError = `error when getting userObject when upgrading from Sandbox: ${userObject.data.error}`
        return { error: displayError }
      }
      if (!userObject.data.response.emailVerified) {
        displayError =
          "You're email is not verified: you cannot upgrade. A verification email will be sent to your email shortly; please verify your email and try again."
        window.alert(displayError)
        await verifyByEmail()
        return { error: displayError }
      }
      //if it is actually verified, update the firestore and move on
      await updateDocument(`users/${primaryAccount.uid}`, {
        emailVerified: true,
      })
    }

    const cont = window.confirm(
      "This action will generate an invoice to you including a setup fee and the first subscription period fee based on the selected subscription and payment plan. Payment will be due within 7 days. Do you wish to continue?"
    )
    if (!cont) return { error: "upgrade canceled" }

    console.log("changing service level")
    //generate the initial invoice
    const invoiceRes = await generateInitialInvoiceAfterUpgradeFromSandbox({
      companyId,
      paymentPlan,
      primaryAccount,
      serviceLevelId,
    })
    console.log(invoiceRes)
    if (invoiceRes.error) {
      displayError = `Something went wrong, please contact elab tracker with the following message: ${invoiceRes.error} - error with generating the invoice for the service level change in useServiceAgreement when upgrading from sandbox`
      window.alert(displayError)
      return { error: displayError }
    }

    const pricingDoc = await getDocument("public/pricing")
    const serviceData = pricingDoc.tiers.filter(
      (tier) => `${tier.id}` === `${serviceLevelId}`
    )[0]

    //update company doc to update messaging and additionalDocuments and permitted overages to reflect the serviceLevel change.
    await updateDocument(`companies/${companyId}`, {
      messaging: serviceData.messaging,
      additionalDocuments: serviceData.additionalDocs,
      maxPermittedEquipment: serviceData.equipmentIncluded,
      maxPermittedDocuments: serviceData.documentsIncluded,
      maxPermittedAdmins: serviceData.adminsIncluded,
      maxPermittedModerators: serviceData.moderatorsIncluded,
      maxPermittedUsers: serviceData.usersIncluded,
    })
    if (error) {
      displayError = `Something went wrong, please contact eLab Tracker with the following message: ${error} - error with changing firestore data relating to the company's parent data/document in useServiceAgreement when upgrading from Sandbox`
      window.alert(displayError)
      return { error: displayError }
    }

    //update company's billingInfo documents, and reset overages
    await updateDocument(`companies/${companyId}/billing/billingInfo`, {
      serviceLevelId,
      selectedServiceLevelId: serviceLevelId,
      adminOverage: 0,
      moderatorOverage: 0,
      userOverage: 0,
      documentOverage: 0,
      equipmentOverage: 0,
    })
    if (error) {
      displayError = `Something went wrong, please contact eLab Tracker with the following message: ${error} - error with changing firestore data relating to the company's billing info in useServiceAgreement when upgrading from Sandbox`
      window.alert(displayError)
      return { error: displayError }
    }

    return { message: "success", error: null }
  }

  const changeServiceLevel = async ({
    companyId,
    serviceLevelId,
    paymentPlan,
  }) => {
    console.log("changing service level")
    const pricingDoc = await getDocument("public/pricing")
    const serviceData = pricingDoc.tiers.filter(
      (tier) => tier.id === serviceLevelId
    )[0]

    //update company doc to update messaging and additionalDocuments and user types to reflect the serviceLevel change.
    await updateDocument(`companies/${companyId}`, {
      messaging: serviceData.messaging,
      additionalDocuments: serviceData.additionalDocs,
      maxPermittedEquipment: serviceData.equipmentIncluded,
      maxPermittedDocuments: serviceData.documentsIncluded,
      maxPermittedAdmins: serviceData.adminsIncluded,
      maxPermittedModerators: serviceData.moderatorsIncluded,
      maxPermittedUsers: serviceData.usersIncluded,
    })

    //update the billingInfo doc as well just to reflect the serviceLevelId change.
    await updateDocument(`companies/${companyId}/billing/billingInfo`, {
      serviceLevelId,
      paymentPlan,
    })
  }

  //Overage Allowance Incrementors
  const incrementEquipmentAllowance = async ({ companyId, newValue }) => {
    await updateDocument(`companies/${companyId}`, {
      maxPermittedEquipment: newValue,
    })
  }
  const incrementDocumentAllowance = async ({ companyId, newValue }) => {
    await updateDocument(`companies/${companyId}`, {
      maxPermittedDocuments: newValue,
    })
  }
  const incrementUserAllowance = async ({ companyId, newValue }) => {
    await updateDocument(`companies/${companyId}`, {
      maxPermittedUsers: newValue,
    })
  }
  const incrementModeratorAllowance = async ({ companyId, newValue }) => {
    await updateDocument(`companies/${companyId}`, {
      maxPermittedModerators: newValue,
    })
  }
  const incrementAdminAllowance = async ({ companyId, newValue }) => {
    await updateDocument(`companies/${companyId}`, {
      maxPermittedAdmins: newValue,
    })
  }

  return {
    error,
    isPending,
    getServiceAgreement,
    getQuantitiesUsed,
    changePaymentPlan,
    changeServiceLevel,
    upgradeFromSandbox,
    incrementEquipmentAllowance,
    incrementDocumentAllowance,
    incrementUserAllowance,
    incrementModeratorAllowance,
    incrementAdminAllowance,
  }
}

//assign default overage fees if there are none set on service aggreement
const assignDefaults = (tierInfo, pricingSheet) => {
  if (typeof tierInfo.additionalUserFee === "undefined")
    tierInfo.additionalUserFee = pricingSheet.additionalUserFee
  if (typeof tierInfo.additionalModeratorFee === "undefined")
    tierInfo.additionalModeratorFee = pricingSheet.additionalModeratorFee
  if (typeof tierInfo.additionalAdminFee === "undefined")
    tierInfo.additionalAdminFee = pricingSheet.additionalAdminFee
  if (typeof tierInfo.additionalEquipmentFee === "undefined")
    tierInfo.additionalEquipmentFee = pricingSheet.additionalEquipmentFee
  if (typeof tierInfo.additionalDocumentFee === "undefined")
    tierInfo.additionalDocumentFee = pricingSheet.additionalDocumentFee

  return tierInfo
}
