import { Link, useParams } from "react-router-dom"
import { UserEvaluationTable } from "../index"
import { useCompanyContext } from "../../company"

export default function UserEvaluations({ embedded }) {
  const { companyId, uniqueId } = useParams()
  let embeddedDisplay = embedded ? embedded : false

  const { companyData, isAuditView, auditId } = useCompanyContext()

  const thisUser = companyData.employees.find((u) => u.uid === uniqueId)

  return (
    <div className={embeddedDisplay ? "" : "page-container container"}>
      {!embeddedDisplay && (
        <h2 className="page-title">{thisUser.displayName}'s Evaluations</h2>
      )}
      <UserEvaluationTable userDisplayName={thisUser.displayName} />

      {!isAuditView && (
        <Link
          className="btn text-bg-primary"
          to={`/company/${companyId}/user/${uniqueId}`}
        >
          Back to user details
        </Link>
      )}
      {isAuditView && (
        <Link
          className="btn text-bg-primary"
          to={`/lab-audit/${auditId}/lab-company/${companyId}/company-evaluations`}
        >
          Back to all Evaluations
        </Link>
      )}
    </div>
  )
}
