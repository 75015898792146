import { useFirestore } from "../../../hooks/useFirestore"

const useGetAuditingCompany = () => {
  const { error, isPending, getDocument } = useFirestore()

  const getAuditingCompany = async ({ auditingCompanyId }) => {
    const auditingCompanyDocData = await getDocument(
      `auditingCompanies/${auditingCompanyId}`
    )

    return auditingCompanyDocData
  }

  return { getAuditingCompany, error, isPending }
}

export { useGetAuditingCompany }
