import { useState } from "react"
import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { useDocument } from "../../../hooks/useDocument"
import {
  useGetEquipmentPiece,
  EquipmentCalibrationForm,
  useChangeEquipment,
} from "../index"
import { CheckboxInput } from "../../../components/basicFormComponents/"

//components
import { Modal, Button } from "react-bootstrap"
import DownloadFileButton from "../../../components/basicComponents/DownloadFileButton"

const INITIAL_DATA = {
  calibrationDate: "",
  calibratedBy: null,
  comment: "",
  calibrationDocument: null,
}

export default function CalibrationModal({ show, handleClose, equipment }) {
  const { user } = useAuthContext()
  const { companyData, equipmentTypes, documentsOverMaxPermitted } =
    useCompanyContext()
  //   const { docData: equipment } = useDocument(
  //     `companies/${companyData.companyId}/equipment/${equipmentID}`
  //   )
  const {
    calibrateEquipment,
    uploadError,
    error,
    firestoreError,
    isPending,
    uploadPending,
  } = useChangeEquipment()

  //form entry state
  const [ignoreMinDate, setIgnoreMinDate] = useState(false)
  const [data, setData] = useState(INITIAL_DATA)
  const [formError, setFormError] = useState(null)

  const { type: equipmentType, hasDocument: hasBlankCalibDoc } =
    useGetEquipmentPiece(equipment, equipmentTypes)

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.moderator)
      return setFormError(
        "must have moderator rights to add a calibration to the database"
      )
    const newEquipment = await calibrateEquipment({
      companyId: companyData.companyId,
      user,
      equipment,
      type: equipmentType,
      calibrationDate: data.calibrationDate,
      calibrationDocument: data.calibrationDocument,
      documentsOverMaxPermitted,
      calibratedBy: data.calibratedBy,
      comment: data.comment,
    })

    if (typeof newEquipment.error !== "undefined") {
      setFormError(newEquipment.error)
    } else if (firestoreError) {
      setFormError(firestoreError)
    } else if (uploadError) {
      setFormError(uploadError)
    } else {
      handleModalClose()
    }
  }

  const handleModalClose = () => handleClose()

  if (!equipment || !equipmentType) {
    return <div className="pending">...Loading</div>
  }

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Calibrate {equipmentType.name} {equipment.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to add an equipment calibration
            </p>
          )}

          <form className="" id="calibration-form" onSubmit={handleSubmit}>
            <EquipmentCalibrationForm
              {...data}
              updateFields={updateFields}
              minDate={ignoreMinDate ? null : equipment.acquisitionDate}
              setFormError={setFormError}
              employeeList={companyData.employees}
            />

            <CheckboxInput
              label="log calibration before acquisition"
              onChange={() => setIgnoreMinDate((prev) => !prev)}
              value={ignoreMinDate}
              horizontal={true}
            />

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
            {uploadError && <div className="error">{uploadError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          {hasBlankCalibDoc && (
            <DownloadFileButton
              docURL={equipmentType.documentURL}
              fileName={`${equipment.type}_calibration-form_blank.pdf`}
              setDownloadError={setFormError}
              buttonText="Download blank form"
              disabled={isPending || uploadPending}
            />
          )}
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending || uploadPending}
          >
            Close
          </Button>
          <Button
            type="submit"
            form="calibration-form"
            variant="primary"
            onClick={handleSubmit}
            disabled={isPending || uploadPending}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
