import { useState } from "react"
import Select from "react-select"
import { format } from "date-fns"
import formatSelectArray from "../../../../util/formatSelectArray"
import DateInput from "../../../../components/basicFormComponents/DateInput"
import { isModerator } from "../../../../util/rightsCheckUtils"
import { Timestamp } from "firebase/firestore"

export default function EquipmentCustodyForm({
  newCustody,
  custodyChangeDate,
  custodyComment,
  minDate,
  defaultCustody,
  updateFields,
  employeeList,
}) {
  const [selectedCustody, setSelectedCustody] = useState(null)
  const updateCustodyChangeDate = (newDate) =>
    updateFields({ custodyChangeDate: newDate })

  //format emplopyees to be used by react-select
  const employees = formatSelectArray(
    [
      ...employeeList,
      {
        ...defaultCustody,
        displayName: `Default - ${defaultCustody.userDisplayName}`,
      },
    ],
    "displayName",
    { rights: "", displayName: "", uid: "" }
  )

  return (
    <div>
      <DateInput
        label="date transferred to new user*"
        required={true}
        value={custodyChangeDate}
        setValue={updateCustodyChangeDate}
        min={
          minDate instanceof Timestamp
            ? format(minDate.toDate(), "yyyy-MM-dd")
            : minDate
        }
        max={format(new Date(), "yyyy-MM-dd")}
      />

      <span>transferred to*: </span>
      <Select
        onChange={(option) => updateFields({ newCustody: option })}
        options={employees}
        value={newCustody}
      />

      <label>
        <span>comment: </span>
        <input
          type="text-area"
          onChange={(e) => updateFields({ custodyComment: e.target.value })}
          value={custodyComment}
          className="form-control"
        />
      </label>
    </div>
  )
}
