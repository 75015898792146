//this useSignup signs a new user up without signing them in by calling cloud function.
//This is used in the company's SignupUser Page component
import { useState } from "react"
import { useAuditingCompanyContext } from "../index"
import { useAuthContext } from "../../authentication/index"
import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"
import { useFirestore } from "../../../hooks/useFirestore"
import { Timestamp } from "firebase/firestore"

export function useSignupAuditor() {
  const { auditingCompanyData } = useAuditingCompanyContext()
  const { user } = useAuthContext()
  const [error, setError] = useState("")
  const [isPending, setIsPending] = useState(false)
  //cloud functions
  const signupNewUser = httpsCallable(functions, "signupUser-signupNewUser")
  const updateDisplayName = httpsCallable(functions, "changeDisplayName")
  const changeClaims = httpsCallable(functions, "changeUserData-changeClaims")
  //firestore
  const {
    error: firestoreError,
    isPending: firestorePending,
    updateDocument,
    setDocument,
  } = useFirestore()

  //function to add a new user to firebase and associate that user with the company of the user signing them up
  const addNewAuditor = async ({ newUser, password }) => {
    console.log("signupNewAuditor fired", newUser)

    if (!user.auditingAdmin)
      return {
        error: "You must have admin rights to add a user to the company roster",
        success: false,
        response: null,
      }

    //check if the email has the correct email for verification
    if (
      !newUser.email
        .toLowerCase()
        .includes(auditingCompanyData.emailVerificationText.toLowerCase())
    )
      return {
        error:
          "The email entered does not contain the required verification text for your company.",
        success: false,
        response: null,
      }
    setIsPending(true)
    setError("")
    const userObject = {
      ...newUser,
      auditingCompanyId: auditingCompanyData.companyId,
      auditingCompanyName: auditingCompanyData.companyName,
      createdAt: Timestamp.fromDate(new Date()),
      settings: {},
      doneTutorial: false,
    }

    //call cloud function for admin to sign up, get new user data as response
    const functionData = await signupNewUser({
      email: userObject.email,
      rights: userObject.rights,
      displayName: userObject.displayName,
      password,
    })
    if (functionData.data.error) {
      setIsPending(false)
      setError(functionData.data.error)
      console.log(functionData.data.fullError)
      return {
        success: false,
        error: `User could not be signed up, server says: ${functionData.data.error}`,
        response: null,
      }
    }

    //*** Firestore *** */
    //add new userDocument
    await setDocument(`users/${functionData.data.response.uid}`, {
      ...userObject,
      uid: functionData.data.response.uid,
    })

    await updateAuditingCompanyEmployeeList({
      displayName: userObject.displayName,
      uid: functionData.data.response.uid,
      rights: userObject.rights,
      email: userObject.email,
      isAuditor: userObject.isAuditor,
      auditingAdmin: userObject.auditingAdmin,
    })
    if (firestoreError) {
      setIsPending(false)
      setError(firestoreError)
      return {
        success: false,
        error: `firestore could not set or update user document or Auditing Company document in firestore. Firestore says: ${firestoreError}`,
        response: null,
      }
    }

    //*** claims *** */
    //change the claims of the new user
    const claimChangeResponse = await changeClaims({
      initial: true,
      rights: userObject.auditingCompanyRights,
      auditingCompanyId: auditingCompanyData.companyId,
      uid: functionData.data.response.uid,
      email: functionData.data.response.email,
      newClaimsObject: {
        isAuditor: userObject.isAuditor,
        auditingAdmin: userObject.auditingAdmin,
        isConfirmedAuditor: false,
        auditingCompanyId: auditingCompanyData.companyId,
      },
    })

    if (claimChangeResponse.data.error) {
      setError(claimChangeResponse.data.error)
      setIsPending(false)
      console.log(claimChangeResponse.data.fullError)
      return {
        success: false,
        error: `claims of the user could not be updated. Server says: ${claimChangeResponse.data.error}`,
        response: null,
      }
    }

    setIsPending(false)
    return {
      success: true,
      error: null,
      response: functionData.data.response.uid,
    }
  }

  //add the new User to the company employee list
  const updateAuditingCompanyEmployeeList = async (newUser) => {
    await updateDocument(`auditingCompanies/${auditingCompanyData.companyId}`, {
      employees: [...auditingCompanyData.employees, newUser],
    })
  }

  return { addNewAuditor, error, isPending }
}
