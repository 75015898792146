import { useEffect, useState } from "react"
import { useAuditorSetup } from "../../hooks/useAuditorSetup"
import Select from "react-select"
import formatSelectArray from "../../util/formatSelectArray"
import { Button } from "react-bootstrap"
import { useFirestore } from "../../hooks/useFirestore"

export default function AddAuditingToExistingAccountForm({ user }) {
  const [formError, setFormError] = useState("")
  const {
    error,
    isPending,
    getAuditingCompanies,
    addEmployeeToAuditingCompany,
    addAuditingToExistingLabUserWithMatchingAuditingCompany,
    createAuditingCompany,
  } = useAuditorSetup()
  const {
    getDocument,
    error: firestoreError,
    isPending: firestorePending,
  } = useFirestore()
  const [auditingCompanies, setAuditingCompanies] = useState([])
  const [thisUsersLabCompany, setThisUsersLabCompany] = useState(null)
  const [matchingCompany, setMatchingCompany] = useState({
    name: "not found",
    id: "not found",
    location: "not found",
    primaryAccountEmail: "not found",
    emailVerificationText: "not found",
    found: false,
    emailMatches: false,
    confirmed: true,
  })

  function updateMatchingCompanyState(fields) {
    setMatchingCompany((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    const confirmMessage =
      "Are you sure the information displayed is correct. much of this information cannot be cahanged once the setup process is complete."

    //two situations:
    //one is if the user's company information does already match an auditing company account
    //two, if they are adding auditing to their current lab company

    //way 1:
    if (matchingCompany.found && window.confirm(confirmMessage)) {
      //   console.log("adding user to matching company auditing company")
      const response =
        await addAuditingToExistingLabUserWithMatchingAuditingCompany({
          user,
          auditingCompanyData: matchingCompany,
          labCompanyData: thisUsersLabCompany,
        })

      if (response.error) return setFormError(response.error)
      return alert(response.message)
    }
    // way 2
    if (!matchingCompany.found && window.confirm(confirmMessage)) {
      if (!user.admin)
        return setFormError(
          "only an admin for your company's lab account can sign your company up for an auditing."
        )
      //createObject to represent:
      const newCompany = {
        name: thisUsersLabCompany.companyName,
        companyId: thisUsersLabCompany.companyId,
        address: thisUsersLabCompany.streetAddress,
        address2: thisUsersLabCompany.streetAddress2,
        city: thisUsersLabCompany.city,
        state: thisUsersLabCompany.state,
        zip: thisUsersLabCompany.zipCode,
        phone: thisUsersLabCompany.companyPhone,
        primaryAccount: {
          ...thisUsersLabCompany.primaryAccount,
          isAuditor: true,
          auditingAdmin: true,
        },
        emailVerificationText: thisUsersLabCompany.emailVerificationText,
      }
      //create the auditing account
      await createAuditingCompany(newCompany)

      await addAuditingToExistingLabUserWithMatchingAuditingCompany({
        user,
        auditingCompanyData: newCompany,
        labCompanyData: thisUsersLabCompany,
      })

      await addEmployeeToAuditingCompany({
        auditingCompanyId: thisUsersLabCompany.companyId,
        userObject: {
          email: user.email,
          displayName: user.displayName,
          uid: user.uid,
          rights: user.admin ? "admin" : "user",
          isAuditor: true,
          isAuditingAdmin: user.admin ? true : false,
        },
      })
    }
  }
  //   get auditing companies and the logged in user's lab company account information
  useEffect(() => {
    getAuditingCompanies().then((list) => {
      setAuditingCompanies(formatSelectArray(list, "name", { name: "name" }))

      //find the existing company if there is one
      const thisUsersCompany = list.find((c) => c.id === user.company)
      if (typeof thisUsersCompany === "undefined") return

      updateMatchingCompanyState({
        name: thisUsersCompany.name,
        id: thisUsersCompany.id,
        location: `${thisUsersCompany.city}, ${thisUsersCompany.state}`,
        primaryAccountEmail: thisUsersCompany.primaryAccount.email,
        emailVerificationText: thisUsersCompany.emailVerificationText,
        found: true,
        confirmed: true,
        emailMatches: user.email
          .toLowerCase()
          .trim()
          .includes(
            thisUsersCompany.emailVerificationText.toLowerCase().trim()
          ),
      })
    })
    getDocument(`companies/${user.company}`).then((data) => {
      const primaryAccount = data.employees.find((e) => e.rights === "primary")
      const emailVerificationText = primaryAccount.email.substring(
        primaryAccount.email.indexOf("@"),
        primaryAccount.email.length
      )
      setThisUsersLabCompany({ ...data, primaryAccount, emailVerificationText })
    })
  }, [])

  //getting list of auditing companies
  if (
    (isPending || firestorePending) &&
    (auditingCompanies.length === 0 || !thisUsersLabCompany)
  ) {
    return <p className="loading">...Loading</p>
  }

  //if cannot get auditing companies list.
  if (
    (!isPending && !firestorePending && auditingCompanies.length === 0) ||
    error ||
    firestoreError
  ) {
    return (
      <div className="error">
        <p>
          No auditing companies found. There may have been an error. Please
          cancel and try by signing up with a new auditing company.
        </p>
        {error && <p>{error}</p>}
      </div>
    )
  }

  //intended render
  return (
    <div>
      <h2 className="mt-4">Confirm the below information</h2>
      <form onSubmit={handleSubmit}>
        {/* show if there is an auditing company match to user's company */}
        {/* display user and company data to confirm if there is no match */}
        {/* submit the user to the existing auditingCompany or submit the user and the company to be registered */}

        <fieldset className="mt-4">
          <legend>Your Information</legend>
          <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
            Display Name:
            <input disabled type="text" value={user.displayName} />
          </label>

          <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
            Email:
            <input disabled type="text" value={user.email} />
          </label>
        </fieldset>

        {/* if the company is found, display the information for confirmation */}
        {matchingCompany.found && (
          <fieldset className="mt-3">
            <legend>Company Information</legend>
            <p>
              The Laboratory company associated with your account has already
              set up auditing. Join by confirming the information.
            </p>
            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Name:
              <input disabled type="text" value={matchingCompany.name} />
            </label>
            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Location:
              <input disabled type="text" value={matchingCompany.location} />
            </label>
            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Public email:
              <input
                disabled
                type="text"
                value={matchingCompany.primaryAccountEmail}
              />
            </label>

            {/* if the user won't be able to sign up because the email is wrong, let them know */}
            {!matchingCompany.emailMatches && (
              <p className="error">
                You're email does not match the required criteria the admin set
                when registering this auditing company account. If this is your
                company, please contact that admin (email above) to change the
                requirements. If this is not your company, please contact
                help@eLabTracker.com to resolve the issue. Please check the list
                below to identify if your company has an auditing company
                account.
              </p>
            )}

            <div className="d-flex gap-4">
              <Button
                variant="primary"
                type="submit"
                disabled={!matchingCompany.confirmed}
              >
                Confirm and Set Up
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={() => updateMatchingCompanyState({ confirmed: false })}
                disabled={!matchingCompany.confirmed}
              >
                This is not correct
              </Button>
            </div>
          </fieldset>
        )}

        {/* If there is not a matching auditing company found, render partially filled form for submission */}
        {!matchingCompany.found && (
          <fieldset className="mt-3">
            <legend>Company Information</legend>
            <p className="error">
              The laboratory company you are associated with does not have
              auditing set up. Auditing can be set up by confirming the
              information below. You must be an admin of your laboratory company
              to complete this action.
            </p>

            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Name:
              <input
                disabled
                type="text"
                value={thisUsersLabCompany.companyName}
              />
            </label>
            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Location:
              <input
                disabled
                type="text"
                value={`${thisUsersLabCompany.city}, ${thisUsersLabCompany.state}`}
              />
            </label>
            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Public/Primary email:
              <input
                disabled
                type="text"
                value={thisUsersLabCompany.primaryAccount.email}
              />
            </label>
            <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
              Emails must include this for verification:
              <input
                disabled
                type="text"
                value={thisUsersLabCompany.emailVerificationText}
              />
            </label>
            {/* if the user won't be able to sign up because the email is wrong, let them know */}
            {!user.email
              .toLowerCase()
              .trim()
              .includes(
                thisUsersLabCompany.emailVerificationText.toLowerCase().trim()
              ) && (
              <p className="error">
                You're email does not match the lab account's primary rights
                user. Check that all the information is correct and contact
                help@eLabTracker.com to resolve the issue.
              </p>
            )}
            {/* If the user is not an admin within their company, they can't do it, let them know. */}
            {!user.admin && (
              <p className="error">
                You are not an admin within your company's laboratory account.
                Only admins can add auditing to a Laboratory account.
              </p>
            )}

            <Button variant="primary" type="submit">
              {" "}
              Confirm and Set Up
            </Button>
          </fieldset>
        )}

        {/* if something is wrong or not found, show the registered auditing company list */}
        {(!matchingCompany.found ||
          !matchingCompany.emailMatches ||
          !matchingCompany.confirmed) && (
          <div>
            <p className="mt-5">Auditing companies registered:</p>
            <Select
              options={auditingCompanies}
              className="mb-4"
              value={null}
              onChange={(option) => {
                return
              }}
            />
          </div>
        )}

        {/* If the user actively says the information does not match, display help */}
        {!matchingCompany.confirmed && (
          <ul style={{ listStyleType: "disc" }} className="px-5">
            <li className="mt-2">
              If the above user information does not appear as expected, sign in
              using the correct account.
            </li>
            <li className="mt-2">
              If the company Information does not appear as expected, use the
              dropdown list above to see if your company is registered as an
              auditor. If it is present in the list but not appearing properly
              above, confirm that you are signed in appropriately and also
              confirm the name of your company within eLab Tracker's system with
              your account admin(s).{" "}
            </li>
            <li className="mt-2">
              If the above company information is not as expected and your
              company does not appear in the list above, you or your supervisor
              may need to set up an auditing company with us.
            </li>
            <li className="mt-2">
              Feel free to contact help@eLabTracker.com with any questions or
              requests.
            </li>
            Click{" "}
            <span
              className="clickable text-decoration-underline color-blue"
              onClick={() => updateMatchingCompanyState({ confirmed: true })}
            >
              here
            </span>{" "}
            if the information is correct upon closer review
          </ul>
        )}

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}
