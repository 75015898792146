import { useState } from "react"
export default function CheckboxInput({
  label,
  value,
  onChange,
  defaultChecked = false,
  required = false,
  horizontal = false,
  options = {},
}) {
  const [checked, setChecked] = useState(defaultChecked)
  return (
    <label
      style={
        horizontal
          ? {
              display: "flex",
              gap: "1rem .5rem",
              width: "90%",
            }
          : null
      }
    >
      <span style={horizontal ? { marginRight: "1rem" } : null}>
        {label}
        {required ? "*" : ""}:{" "}
      </span>
      <input
        {...options}
        type="checkbox"
        checked={checked}
        value={value}
        className="clickable"
        onChange={(e) => {
          setChecked((prevChecked) => !prevChecked)
          onChange(e)
        }}
        required={required || false}
        style={{ maxWidth: "25px", height: "25px" }}
      />
    </label>
  )
}
