import { useState } from "react"
import Select from "react-select"
import { useAuthContext } from "../../authentication"
import { useEvaluation } from "../index"

import { Modal, Button } from "react-bootstrap"
import { useCompanyContext } from "../../company"
import { useFirestore } from "../../../hooks/useFirestore"

export default function AddUserToEvaluationListModal({
  show,
  handleClose,
  evaluation,
  employeeOptions,
}) {
  const { user } = useAuthContext()
  const { companyData } = useCompanyContext()
  const {
    updateDocument,
    isPending: firestorePending,
    error: firestoreError,
  } = useFirestore()
  const { error, isPending, setUserFrequencyOfEvaluation } = useEvaluation()
  const [formError, setFormError] = useState("")

  //adding a new user to the list needing evaluated
  const [newActiveEmployee, setNewActiveEmployee] = useState(null)
  const [newActiveEmployeeFrequency, setNewActiveEmployeeFrequency] =
    useState(24)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.moderator)
      return setFormError(
        "you need to have moderator rights to require a user get evaluated"
      )
    if (!newActiveEmployee)
      return setFormError("you need to select an employee from the drop down")
    // check for duplicate
    if (evaluation[newActiveEmployee.uid]) {
      console.log(evaluation[newActiveEmployee.uid])
      return setFormError("This employee has already been added to the list")
    }
    const updateObject = {
      ...newActiveEmployee,
      required: true,
      frequencyOfEvaluation: Number(newActiveEmployeeFrequency),
      currentEvaluation: {},
      history: [],
    }

    //update Firestore
    await updateDocument(
      `companies/${companyData.companyId}/evaluations/${evaluation.name}`,
      {
        [newActiveEmployee.uid]: { ...updateObject },
      }
    )

    if (firestoreError) return setFormError(firestoreError)
    //reset state
    setNewActiveEmployee(null)
    setNewActiveEmployeeFrequency(24)
    handleClose()
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add a user to be evaluated for {evaluation.name} -{" "}
            {evaluation.standard}.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to add required evaluations for users.
            </p>
          )}

          <form onSubmit={(e) => handleSubmit(e)}>
            <label>
              User to add:
              <Select
                options={employeeOptions}
                onChange={(option) => {
                  setNewActiveEmployee(option.value)
                }}
                name="user-to-add"
              />
            </label>

            <label>
              Frequency (months):
              <input
                className="ms-3"
                type="number"
                value={newActiveEmployeeFrequency}
                required
                onChange={(e) => setNewActiveEmployeeFrequency(e.target.value)}
                min="1"
                step={1}
                style={{ height: "2em", width: "100px" }}
              />
            </label>
          </form>
          {error && <p className="error">{error}</p>}
          {formError && <p className="error">{formError}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isPending}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
