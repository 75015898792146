//styles
import { Link } from "react-router-dom"
import styles from "./ReportsPage.module.css"

export default function LabView() {
  return (
    <>
      <h1>Equipment Reports</h1>
      <div className={`${styles["column-container"]}`}>
        <div className={`${styles.column}`}>
          <h2 className={`${styles["column-title"]}`}>General</h2>
          <ul className={`${styles["report-list"]}`}>
            <li className={`${styles["report-list-item"]}`}>
              <Link to="equipment-list">Equipment List</Link>
            </li>
            <li className={`${styles["report-list-item"]}`}>
              <Link to="equipment-calibration-status">Calibration Status</Link>
            </li>
          </ul>
        </div>

        <div className={`${styles.column}`}>
          <h2 className={`${styles["column-title"]}`}>Types</h2>
          <ul className={`${styles["report-list"]}`}>
            <li className={`${styles["report-list-item"]}`}>
              <Link to="equipment-list-by-type">Equipment List by Type</Link>
            </li>
            <li className={`${styles["report-list-item"]}`}>
              <Link to="equipment-calibration-status-by-type">
                Calibration Status by Type
              </Link>
            </li>
          </ul>
        </div>

        <div className={`${styles.column}`}>
          <h2 className={`${styles["column-title"]}`}>Assets</h2>
          <ul className={`${styles["report-list"]}`}>
            <li className={`${styles["report-list-item"]}`}>
              <Link to="asset-status">Asset Status and Historical Status</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={`${styles.footnote}`}>
        <i>* - coming soon</i>
      </div>
    </>
  )
}
