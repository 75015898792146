//compatible with firebase 9 and react
import { useAuthContext } from '../index'

//firebase imports
import { auth } from '../../../firebase/config'
import { signOut } from 'firebase/auth'

import { useNavigate } from 'react-router-dom'

export const useLogout = () => {
    const navigate = useNavigate()
    const { dispatch } = useAuthContext()

    const logout = async() => {
        await signOut(auth)
        dispatch({ type: 'LOGOUT' })
        dispatch({ type: 'REFRESH' })
        navigate("/company")
    }

    return { logout }
}