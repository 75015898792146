import { useContext } from "react"
import { CompanyContext } from "../context/CompanyContext"

export const useCompanyContext = () => {
  const context = useContext(CompanyContext)

  if (context === undefined) {
    throw new Error("useCompanyContext() must be used inside a CompanyProvider")
  }

  return context
}