import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { format } from "date-fns"
import React from "react"

// Create styles
const styles = StyleSheet.create({
  footer: {
    position: "relative",
    flexDirection: "row",
    alignSelf: "baseline",
    maxWidth: "15vh",
    marginLeft: "10px",
    marginVertical: "10px",
  },
  footerInfo: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    flexDirection: "column",
    alignSelf: "flex-start",
    fontSize: "9pt",
  },
  pageNumber: { position: "absolute", bottom: "10px", right: "10px" },
})

export default function ReportFooter({ companyId }) {
  return (
    <View
      style={{ marginTop: "50px" }}
      fixed
      render={({ pageNumber, totalPages }) => (
        <View>
          <div style={styles.footerInfo}>
            <Text>Report produced by: eLab Tracker</Text>
            {companyId && <Text>Report for: {companyId}</Text>}
            <Text>Report Run on {format(new Date(), "MM-dd-yyyy")}</Text>
          </div>
          <div style={styles.pageNumber}>
            <Text>
              Page {pageNumber} / {totalPages}
            </Text>
          </div>
        </View>
      )}
    />
  )
}
