import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useCompanyContext } from "../../../company"
import sortObjects from "../../../../util/sortObjects"

export default function EquipmentTypeEquipmentListReportLoader({
  children,
  options,
}) {
  const { companyId } = useParams()
  const { companyData, equipmentListDisplay: equipmentList } =
    useCompanyContext()
  //   const { error, isPending, getAuditDocument } = useGetAudit()
  const error = null
  const isPending = false

  const [reportData, setReportData] = useState(null)

  useEffect(() => {
    //filter equipment list accordingly
    let reportedList = equipmentList.filter((eq) => {
      let keep = false
      //check if the equipment is of a type the user wants to include in the report
      if (
        options.includedTypes.find((t) => eq.type.prefix === t.value.prefix)
      ) {
        //filter by status as well
        if (options.includeInService && eq.status === "in service") keep = true
        else if (options.includeOutOfService && eq.status === "out of service")
          keep = true
        else if (options.includeDiscarded && eq.status === "discarded")
          keep = true
      }

      return keep
    })

    //get statuses included in report
    let statusesIncluded = []
    if (options.includeInService) statusesIncluded.push("in service")
    if (options.includeOutOfService) statusesIncluded.push("out of service")
    if (options.includeDiscarded) statusesIncluded.push("discarded")

    const equipmentTypesIncluded = options.includedTypes.map(
      (t) => t.value.name
    )

    //map to include only needed data
    reportedList = reportedList.map((eq) => {
      return {
        type: eq.type,
        id: eq.id,
        make: eq.make,
        model: eq.model,
        serialNumber: eq.serialNumber,
        acquisitionDate: eq.acquisitionDate,
        status: eq.status,
      }
    })

    //sort and accordingly
    reportedList = sortObjects(
      reportedList,
      options.sortBy.value,
      options.order === "asc"
    )

    //set the data
    return setReportData({
      displayData: reportedList,
      options: { ...options, statusesIncluded, equipmentTypesIncluded },
    })
  }, [options])

  //display error or loading
  if (error)
    return (
      <div className="error">
        <p>
          The database could not find the data for the equipment list fitting
          your report criteria. Or, if it did, you do not have permission to
          view the data:
        </p>
        <p>{error}</p>
      </div>
    )

  if (isPending || !reportData || typeof reportData === "undefined")
    return <p>...loading</p>

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return (
            reportData && React.cloneElement(child, { reportData, companyData })
          )
        }
        return child
      })}
    </>
  )
}
