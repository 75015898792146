import { Button } from "react-bootstrap"
import openInNewTabIcon from "../../assets/open-file-in-new-tab-icon.svg"

export default function ViewPDFButton({
  docURL,
  buttonText = "view",
  disabled,
  icon,
  className = "",
}) {
  if (icon) {
    return (
      <a href={docURL} target="_blank">
        <img
          src={openInNewTabIcon}
          className={`${className} icon`}
          alt="view file in new tab"
          title="view this file"
        />
      </a>
    )
  }

  return (
    <a href={docURL} target="_blank">
      <Button variant="primary" disabled={disabled} type="button">
        {buttonText}
      </Button>
    </a>
  )
}
