import { useRef, useState } from "react"
import { Modal, Button } from "react-bootstrap"

import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { useDeleteEquipment } from "../index"
import InfoIcon from "../../../components/basicComponents/InfoIcon"

export default function DeleteEquipmentModal({ show, handleClose, equipment }) {
  const { user } = useAuthContext()
  const { companyData } = useCompanyContext()
  const [formError, setFormError] = useState("")
  const { deleteEquipment, error, isPending } = useDeleteEquipment()
  //form entry state
  const inputId = useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")

    if (!user.admin)
      return setFormError("must have admin rights to delete equipment")

    //check the validity of the input
    if (inputId.current.value !== equipment.id) {
      return setFormError(
        "You must enter the equipment Id exactly as it appears in the header to validate the deletion."
      )
    }

    await deleteEquipment({
      companyId: companyData.companyId,
      equipment,
      userInfo: {
        uid: user.uid,
        displayname: user.displayName,
      },
    })

    if (error) return

    handleClose()
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete This Equipment Piece - {equipment.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.admin && (
            <p className="error">Must have admin rights to delete equipment</p>
          )}

          <form className="" onSubmit={handleSubmit}>
            <label>
              <div className="d-flex">
                <span className="me-2">
                  Validate deletion by typing "{equipment.id}"
                </span>
                <InfoIcon infoTitle="deletingequipment" />
              </div>
              <input
                required
                ref={inputId}
                type="text-area"
                className="form-control"
              />
            </label>

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isPending}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
