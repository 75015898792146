import { Timestamp } from "firebase/firestore"
import { useFirestore } from "../useFirestore"

const useAgreeToTerms = () => {
  const { error, isPending, updateDocument } = useFirestore()

  //update firestore user document for agreeing to user terms
  const agreeToUserTerms = async ({
    uid,
    signedNameForAgreementofTermsAndConditions,
  }) => {
    const updateObject = {
      agreedToUserTerms: true,
      signedNameForAgreementofTermsAndConditions,
      signedUserTermsAndConditionsAt: Timestamp.fromDate(new Date()),
    }
    await updateDocument(`users/${uid}`, updateObject)
  }

  const agreeToAuditorTerms = async ({
    uid,
    signedNameForAuditorAgreementofTermsAndConditions,
  }) => {
    const updateObject = {
      agreedToAuditorTerms: true,
      signedNameForAuditorAgreementofTermsAndConditions,
      signedAuditorTermsAndConditionsAt: Timestamp.fromDate(new Date()),
    }
    await updateDocument(`users/${uid}`, updateObject)
  }

  //add to local storage - this is for non users
  const agreeToSiteTerms = async () => {}

  return {
    error,
    isPending,
    agreeToUserTerms,
    agreeToAuditorTerms,
    agreeToSiteTerms,
  }
}

export { useAgreeToTerms }
