import { useFirestore } from "../../../hooks/useFirestore"
import { useStorage } from "../../../hooks/useStorage"
import sortObjects from "../../../util/sortObjects"

export function useEquipmentHistory() {
  const { updateDocument, isPending, error } = useFirestore()
  const {
    deleteFile,
    isPending: storagePending,
    error: storageError,
  } = useStorage()

  const sortHistory = (history, asc) => {
    const sortedHistory = sortObjects(
      history.map((hist) => {
        return { ...hist, dateOfAction: hist.dateOfAction.toDate() }
      }),
      "dateOfAction",
      asc
    )

    return sortedHistory
  }

  const getLastCustody = (history, date) => {
    //console.log("getting last custody", date)
    let lastCustody = null
    let isMostRecent = true
    const sortedHistory = sortHistory(
      history.filter((h) => h.category === "custody"),
      true
    )
    if (sortedHistory.length > 0) {
      sortedHistory.forEach((h) => {
        if (!lastCustody) lastCustody = h
        if (
          h.dateOfAction > lastCustody.dateOfAction &&
          h.dateOfAction < date
        ) {
          lastCustody = h
        }
        if (h.dateOfAction > date) isMostRecent = false
      })
    }

    return { lastCustody, isMostRecent }
  }

  const getLastStatus = (history, date) => {
    // console.log("getting last status", date)
    let lastStatus = null
    let isMostRecent = true
    const sortedHistory = sortHistory(
      history.filter((h) => h.category === "status"),
      true
    )
    if (sortedHistory.length > 0) {
      sortedHistory.forEach((h) => {
        if (!lastStatus) lastStatus = h
        if (
          h.dateOfAction > lastStatus.dateOfAction &&
          h.dateOfAction <= date
        ) {
          lastStatus = h
        }
        if (h.dateOfAction > date) isMostRecent = false
      })
    }

    return { lastStatus, isMostRecent }
  }

  const getLastCalibration = (history, date) => {
    //console.log("getting last calibration", date)
    let lastCalibration = null
    let isMostRecent = true
    const sortedHistory = sortHistory(
      history.filter((h) => h.category === "calibration"),
      true
    )
    if (sortedHistory.length > 0) {
      sortedHistory.forEach((h) => {
        if (!lastCalibration) lastCalibration = h
        if (
          h.dateOfAction > lastCalibration.dateOfAction &&
          h.dateOfAction < date
        ) {
          lastCalibration = h
        }
        if (h.dateOfAction > date) isMostRecent = false
      })
    }

    return { lastCalibration, isMostRecent }
  }

  const needsCalibrated = (history, date, frequency) => {
    const { lastCalibration, isLatest } = getLastCalibration(history, date)
    //check if the date is within the range of the last calibration
  }

  const isDiscarded = (history) => {
    history.forEach((h) => {
      if (h.category === "status - discarded") return true
    })
    return false
  }

  //pass whether the user is an admin, equipment (needs full documentfrom firestore), and the ID of the history Item to delete.
  //NOTE OF INTENTION: There is not revise function for history. intend just delete and re-enter if revision is needed.
  const deleteHistoryItem = async ({
    isAdmin,
    equipment,
    historyIdToDelete,
  }) => {
    //only admins
    if (!isAdmin) return alert("Only admins can delete history")
    //confirm deletion
    let confirmMessage =
      "Are you sure you want to delete this historical data? This deletion may cause historical data to appear non-compliant."

    //other checks
    const historyItem = equipment.history.find(
      (h) => h.id === historyIdToDelete
    )
    const category = historyItem.category
    let updateObject = {}
    //check if this is the most recent status change and warn user of consequences if so
    if (category === "status") {
      const { isMostRecent: isMostRecentStatus } = getLastStatus(
        equipment.history,
        historyItem.dateOfAction.toDate()
      )

      if (isMostRecentStatus) {
        confirmMessage =
          "The historical log you are deleting is the most recent change of status. Deleting will result in a status of 'unset'. Do you still want to delete this?"
        updateObject.status = "unset"
      }
    }

    //check if it is the most recent custody change and warn user of consequences if so
    else if (category === "custody") {
      const { isMostRecent: isMostRecentCustody } = getLastCustody(
        equipment.history,
        historyItem.dateOfAction.toDate()
      )

      if (isMostRecentCustody) {
        confirmMessage =
          "The historical log you are deleting is the most recent change of custody. Deleting will result in reseting the custody to default. Do you still want to delete this?"
        updateObject.currentCustody = equipment.defaultCustody
      }
    }

    //check if it is a calibration and warn user of consequences if so
    //and check if it is the most recent calibration and warn user of consequences if so
    //also, needs to delete the associated document from storage
    else if (category === "calibration") {
      const { isMostRecent: isMostRecentCalibration } = getLastCalibration(
        equipment.history,
        historyItem.dateOfAction.toDate()
      )

      if (isMostRecentCalibration) {
        //TODO: I feel like I can do better here than just set to null if there is something.
        confirmMessage =
          "The historical log you are deleting is the most recent calibration. Deleting will result in a lack of last calibration and next calibration schedule. Do you still want to delete this?"
        updateObject.nextCalibration = null
        updateObject.lastCalibration = null
      } else {
        confirmMessage =
          "The historical log you are deleting is a calibration. Deleting will result in potential appearance of non-compliance with regard to calibration frequency, status, and equipment use while out of calibration. Do you still want to delete this?"
      }
    }
    const confirmDelete = window.confirm(confirmMessage)
    if (!confirmDelete) return alert("Delete action successfully canceled")

    //filter history
    const updatedHistoryArray = equipment.history.filter(
      (h) => h.id !== historyIdToDelete
    )
    updateObject.history = updatedHistoryArray
    //update equipmentDoc in firestore
    await updateDocument(
      `companies/${equipment.companyId}/equipment/${equipment.id}`,
      updateObject
    )

    //delete the associated calibration document in storage
    if (typeof historyItem.calibrationURL !== "undefined")
      await deleteFile(historyItem.calibrationURL)
  }

  return {
    sortHistory,
    getLastCustody,
    getLastStatus,
    getLastCalibration,
    isDiscarded,
    deleteHistoryItem,
  }
}
