import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { format } from "date-fns"
import trashCan from "../../../assets/trashcan-icon.svg"
//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../components/TableHeaderSortable"
import MultiFilter from "../../../components/basicComponents/MultiFilter"
import DownloadFileButton from "../../../components/basicComponents/DownloadFileButton"
import CollapseIcon from "../../../components/basicComponents/CollapseIcon"
//util
import sortObjects from "../../../util/sortObjects"
import ViewPDFButton from "../../../components/basicComponents/ViewPDFButton"

export default function EquipmentHistoryLog({
  canDelete,
  history,
  equipmentId,
  equipmentType,
  calibrationRequired = true,
  deleteHistory,
}) {
  const [selectedRow, setSelectedRow] = useState(null)

  //options for filters
  const historyFilterOptions = calibrationRequired
    ? ["all", "calibration", "status", "maintenance", "custody"]
    : ["all", "status", "maintenance", "custody"]

  //filter history using search params and router
  const [searchParams, setSearchParams] = useSearchParams()
  const activeFilters = useMemo(
    () =>
      searchParams.getAll("filterHistoryBy").length !== 0
        ? searchParams.getAll("filterHistoryBy")
        : ["all"],
    [searchParams]
  )

  //filter functions and listener
  const updateFilters = (clickedFilter) => {
    if (clickedFilter === "all")
      return setSearchParams({ filterHistoryBy: ["all"] }, { replace: true })

    if (activeFilters.indexOf("all") > -1)
      return setSearchParams(
        { filterHistoryBy: [clickedFilter] },
        { replace: true }
      )

    if (activeFilters.includes(clickedFilter)) {
      if (activeFilters.length === 1)
        return setSearchParams({ filterHistoryBy: ["all"] }, { replace: true })
      return setSearchParams(
        { filterHistoryBy: activeFilters.filter((f) => f !== clickedFilter) },
        { replace: true }
      )
    }
    setSearchParams(
      { filterHistoryBy: [...activeFilters, clickedFilter] },
      { replace: true }
    )
  }
  const filteredHistory = useFilterHistory(history, activeFilters)

  //set up table headers
  const tableHeaderArray = [
    { sortable: false, title: "Type" },
    { sortable: false, title: "Date" },
    { sortable: false, title: "Status" },
    { sortable: false, title: "Location/ Custody" },
    { sortable: false, title: "Doucment" },
  ]

  if (canDelete) tableHeaderArray.push({ sortable: false, title: "" })

  return (
    <div className="">
      <h2 className="section-title mt-4 mb-3">Equipment History</h2>
      <div className="data-list">
        <MultiFilter
          filterOptions={historyFilterOptions}
          currentFilters={activeFilters}
          changeFilters={updateFilters}
        />
        {/* Equipment history table */}
        {filteredHistory.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table" size="md">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy="dateOfAction"
                sortAsc={true}
              />
              <tbody className="text-center">
                {filteredHistory.map((h) => (
                  <React.Fragment key={h.id}>
                    <tr className={selectedRow === h.id ? "active" : ""}>
                      <td className="d-flex align-items-center">
                        <span>{h.category}</span>
                        {h.comment && selectedRow !== h.id && (
                          <h4
                            className="ms-auto"
                            onClick={() => setSelectedRow(h.id)}
                          >
                            <CollapseIcon collapsed={true} />
                          </h4>
                        )}
                        {h.comment && selectedRow === h.id && (
                          <h4
                            className="ms-auto"
                            onClick={() => setSelectedRow(null)}
                          >
                            <CollapseIcon collapsed={false} />
                          </h4>
                        )}
                      </td>
                      <td>{format(h.dateOfAction.toDate(), "MM-dd-yyyy")}</td>
                      <td>
                        {typeof h.status === "undefined" || h.status === null
                          ? "no data"
                          : h.status}
                      </td>
                      <td>
                        {typeof h.custody === "undefined" || h.custody === null
                          ? "no data"
                          : h.custody.displayName}
                      </td>
                      {h.category !== "calibration" && <td>N/A</td>}
                      {h.category === "calibration" && (
                        <td>
                          <DownloadFileButton
                            docURL={h.calibrationURL}
                            fileName={`${equipmentType}_${equipmentId}_${format(
                              h.dateOfAction.toDate(),
                              "yyyy-MM-dd"
                            )}_calibration.pdf`}
                            disabled={false}
                            buttonText="Download"
                            icon={true}
                          />
                          <ViewPDFButton
                            docURL={h.calibrationURL}
                            icon={true}
                            className="ms-3"
                          />
                        </td>
                      )}
                      {canDelete && (
                        <td>
                          {h.category !== "acquisition" &&
                            h.category !== "equipment created" && (
                              <img
                                src={trashCan}
                                alt="delete"
                                className="icon ms-2 me-2"
                                onClick={() => deleteHistory(h.id)}
                                title="delete"
                              />
                            )}
                        </td>
                      )}
                    </tr>
                    {/* Expandable data */}
                    {selectedRow === h.id && (
                      <tr className="fs-6 text-muted expanded-row">
                        <td colSpan={canDelete ? 6 : 5}>{h.comment}</td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  )
}

const useFilterHistory = (history, activeFilters) => {
  const [historyItems, setHistoryItems] = useState([])

  useEffect(() => {
    if (!activeFilters) return
    let currentList = []
    if (history) {
      currentList = [...history]
      if (activeFilters.length !== 0 && !activeFilters.includes("all")) {
        currentList = currentList.filter((h) =>
          activeFilters.includes(h.category)
        )
      }
    }
    currentList = sortObjects(currentList, "dateOfAction.seconds", false)

    setHistoryItems(currentList)
  }, [history, activeFilters])

  return historyItems
}
