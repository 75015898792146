import { useFirestore } from "../../../hooks/useFirestore"
import { useCompanyContext } from "../../company"
import checkDuplicateInArray from "../../../util/checkDuplicateInArray"
import { Timestamp } from "firebase/firestore"
import { useStorage } from "../../../hooks/useStorage"

export function useEvaluationType() {
  const { evaluationList, companyData } = useCompanyContext()
  const {
    setDocument,
    updateDocument,
    isPending,
    error: firestoreError,
  } = useFirestore()

  const {
    uploadFile,
    isPending: storagePending,
    error: storageError,
  } = useStorage()

  const addEvaluationType = async ({ category, name, standard, user }) => {
    //check if prefix or equipment name exists in the companies equipment types yet
    if (evaluationList) {
      const { exists: standardExists, duplicateData: duplicateStandard } =
        checkDuplicateInArray(evaluationList, standard, "standard")
      const { exists: nameExists, duplicateData: duplicateName } =
        checkDuplicateInArray(evaluationList, name, "name")

      if (standardExists)
        return {
          success: false,
          error: `this standard is already being used by Evaluation Type: ${duplicateStandard.name}.`,
          message: null,
        }
      if (nameExists)
        return {
          success: false,
          error: `this Evaluation Type Name is already being used.`,
          message: null,
        }
    }

    //update firestore evaluation document
    //create document for the evaluation:
    const documentObject = {
      name,
      category,
      standard,
      createdAt: Timestamp.fromDate(new Date()),
      createdBy: { displayName: user.displayName, uid: user.uid },
      docs: [],
    }
    await setDocument(
      `companies/${companyData.companyId}/evaluations/${name}`,
      documentObject
    )

    return {
      success: true,
      message: "successfully added evaluation type",
      error: null,
    }
  }

  const updateEvaluationType = async ({ evaluationId, updateObject }) => {
    await updateDocument(
      `companies/${companyData.companyId}/evaluations/${evaluationId}`,
      updateObject
    )
  }

  const deleteEvaluationType = async ({ evaluationId, user }) => {
    //add deleted as a flag to the evaluatiuon document as well as who deleted
    let updateObject = {
      deleted: true,
      deletedBy: { displayName: user.displayName, uid: user.uid },
      deletedAt: new Date(),
    }

    await updateDocument(
      `companies/${companyData.companyId}/evaluations/${evaluationId}`,
      {
        ...updateObject,
      }
    )
  }

  const addDocumentToEvaluationType = async ({
    user,
    docName,
    evaluation,
    replacingExistingDoc,
    file,
  }) => {
    //upload to storage
    const fileName = `${evaluation.name}_Evaluation_${docName}`
    const formPath = `companies/${companyData.companyId}/evaluations/${evaluation.name}/${fileName}`
    const metadata = {
      contentType: "application/pdf",
    }
    const documentURL = await uploadFile(formPath, file, metadata)

    //update firestore
    if (storageError) return
    const documentObject = {
      documentURL,
      createdBy: { displayName: user.displayName, uid: user.uid },
      name: docName.trim(),
      createdAt: Timestamp.fromDate(new Date()),
    }
    let newList = []
    if (replacingExistingDoc) {
      newList = evaluation.docs.filter(
        (d) => d.name.toLowerCase() !== docName.toLowerCase().trim()
      )
    } else {
      newList = evaluation.docs
    }
    newList = [...newList, documentObject]
    //sort list
    newList = newList.sort((a, b) => {
      const textA = a.name.toUpperCase()
      const textB = b.name.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })

    await updateDocument(
      `companies/${companyData.companyId}/evaluations/${evaluation.name}`,
      {
        docs: newList,
      }
    )
  }

  const deleteDocumentFromEvaluationType = async ({
    evaluation,
    documentToDelete,
  }) => {
    //NOTE: the delete of the document from storage is handled in the DocumentList component or elsewhere
    //update firestore object
    let newList = evaluation.docs.filter(
      (d) => documentToDelete.name !== d.name
    )
    await updateDocument(
      `companies/${companyData.companyId}/evaluations/${evaluation.name}`,
      {
        docs: newList,
      }
    )
  }

  return {
    isPending,
    firestoreError,
    storagePending,
    storageError,
    addEvaluationType,
    updateEvaluationType,
    deleteEvaluationType,
    addDocumentToEvaluationType,
    deleteDocumentFromEvaluationType,
  }
}
