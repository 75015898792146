import { useEffect, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAuthContext } from "../../../authentication/index"
import { useCompanyContext } from "../../../company/index"
import { format } from "date-fns"
//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
import { useSortEquipmentList, useFilterEquipment } from "../../index"
import ListFilter from "../../../../components/basicComponents/ListFilter"
import { Timestamp } from "firebase/firestore"

//styles
import "../../../../DataList.css"

const euipmentFilterList = [
  "all",
  "in service",
  "out of service",
  "needs calibrated",
  "overdue",
]

export default function AuditView({
  selectedType = "",
  selectedCategory = "all",
  selectedCustody,
}) {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const { companyId, auditId, typePrefix } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    calibrationStatus: searchParams.get("calibrationStatus") || "in service",
    type: searchParams.get("type") || selectedType,
    category: searchParams.get("category") || "all",
    sortBy: searchParams.get("sortBy") || "nextCalibration",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  useEffect(() => {
    updateSearchParams({ newParams: { category: selectedCategory } })
  }, [selectedCategory])
  useEffect(() => {
    updateSearchParams({ newParams: { type: selectedType || "" } })
  }, [selectedType])

  const [selectedEquipment, setSelectedEquipment] = useState(null)

  const { equipmentTypes, equipmentListDisplay } = useCompanyContext()

  const filteredEquipment = useFilterEquipment(
    equipmentListDisplay,
    allSearchParams.calibrationStatus,
    allSearchParams.type,
    selectedCustody,
    selectedCategory,
    equipmentTypes
  )

  const displayedEquipment = useSortEquipmentList(
    filteredEquipment,
    allSearchParams.sortBy,
    allSearchParams.order === "asc" ? true : false
  )

  const tableHeaderArray = [
    { sortable: false, title: "Type" },
    { sortable: true, title: "ID", changeSortByTo: "id" },
    { sortable: true, title: "Status", changeSortByTo: "status" },
    {
      sortable: true,
      title: "Next Calibration",
      changeSortByTo: "nextCalibration",
    },
    {
      sortable: true,
      title: "Location/ Custody",
      changeSortByTo: "currentCustody.displayName",
    },
  ]

  return (
    <div>
      <div className="">
        <ListFilter
          filterOptions={euipmentFilterList}
          currentFilter={allSearchParams.calibrationStatus}
          changeFilter={(newFilter) =>
            updateSearchParams({ newParams: { calibrationStatus: newFilter } })
          }
        />

        {/* Equipment data table */}
        {displayedEquipment.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table break-60">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
              />
              <tbody className="text-center ">
                {displayedEquipment.map((e) => (
                  <tr
                    key={e.id}
                    onClick={() => setSelectedEquipment(e)}
                    onDoubleClick={() => {
                      navigate(
                        `/lab-audit/${auditId}/lab-company/${companyId}/company-equipment/${e.id}`
                      )
                    }}
                    className={selectedEquipment?.id === e.id ? "active" : ""}
                  >
                    <td>{e.type.name}</td>
                    <td>{e.id}</td>
                    <td>{e.status}</td>

                    {typeof e.type.calibrationRequired === "undefined" ||
                    e.type.calibrationRequired ? (
                      <>
                        {e.nextCalibration instanceof Timestamp && (
                          <td>
                            {format(e.nextCalibration.toDate(), "MMM, yyyy")}
                          </td>
                        )}
                        {!(e.nextCalibration instanceof Timestamp) && (
                          <td>no data</td>
                        )}
                      </>
                    ) : (
                      <td>N/A</td>
                    )}

                    <td>{e.currentCustody.displayName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedEquipment.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        {selectedEquipment && (
          <div className="d-flex mt-3">
            <Link
              className="btn text-bg-secondary ms-2"
              to={`/lab-audit/${auditId}/lab-company/${companyId}/company-equipment/${selectedEquipment.id}`}
            >
              Go to {selectedEquipment.id}
            </Link>

            {/* navigate to the equipment type if not already on equipment type route */}
            {selectedEquipment.type.prefix !== typePrefix && (
              <Link
                className="btn text-bg-secondary ms-2"
                to={`/company/${companyId}/company-equipment/types/${selectedEquipment.type.prefix}`}
              >
                Go to {selectedEquipment.type.name}
              </Link>
            )}

            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedEquipment(null)}
            >
              Unselect
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
