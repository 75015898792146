import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useAuthContext } from "./features/authentication/index"
import { CompanyContextProvider } from "./features/company/index"
import { AuditorUserContextProvider } from "./features/auditingCompany/context/AuditorUserContext"
import { UserContextProvider } from "./features/users"
//page components
import Home from "./pages/Home"
import ClientSetup from "./pages/ClientSetup/ClientSetup"

//other components
import { Login, ResetPassword, Signup } from "./features/authentication/index"
import MainNavbar from "./components/MainNavbar"
import { Company } from "./features/company/index"
import { useState } from "react"
import InfoFlyin from "./components/basicComponents/InfoFlyin"
import PricingPage from "./pages/PricingPage"
import FAQPage from "./pages/FAQPage"
import ContactPage from "./pages/ContactPage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage"
import AuditorSetup from "./pages/AuditorSetup/AuditorSetup"
import {
  AuditingCompany,
  AuditingCompanyContextProvider,
} from "./features/auditingCompany"
import {
  AuditLandingPage,
  AuditorControls,
  LabControls,
} from "./features/audits"

function App() {
  const { user, authIsReady } = useAuthContext()
  const [companyId, setCompanyId] = useState(null)
  const [auditingCompanyId, setAuditingCompanyId] = useState(null)

  //control getting the ids of the users auditing company or lab company, done like this because this is outside of those contexts.
  const getCompanyId = (id) => {
    if (id) {
      return setCompanyId(id)
    }
    return setCompanyId(null)
  }
  const getAuditingCompanyId = (id) => {
    if (id) return setAuditingCompanyId(id)
    return setAuditingCompanyId(null)
  }

  if (!authIsReady) {
    return <p>loading...</p>
  }

  return (
    <div className="App">
      <InfoFlyin />
      <BrowserRouter>
        {!companyId && !auditingCompanyId && <MainNavbar />}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/FAQ" element={<FAQPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/legal/privacy" element={<PrivacyPolicyPage />} />

          {/* setup account pages */}
          <Route path="/account-setup" element={<ClientSetup />} />
          <Route path="auditor-account-setup" element={<AuditorSetup />} />

          {/* Auth Pages */}
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Login />}
          />
          <Route path="/forgot-password" element={<ResetPassword />} />

          <Route
            path="/company"
            element={
              user && user.company ? (
                <Navigate to={`/company/${user.company}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* company route - contains nested routes that require CompanyContext */}
          <Route
            path="/company/:companyId/*"
            element={
              !user || !user.company ? (
                <Navigate to="/" />
              ) : (
                <CompanyContextProvider>
                  <UserContextProvider>
                    <Company provideCompanyId={getCompanyId} />
                  </UserContextProvider>
                </CompanyContextProvider>
              )
            }
          />

          <Route
            path="/auditing-company"
            element={
              user && user.auditingCompanyId ? (
                <Navigate to={`/auditing-company/${user.auditingCompanyId}`} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          {/* auditing company route - contains nested routes that require CompanyContext */}
          <Route
            path="/auditing-company/:auditingCompanyId/*"
            element={
              !user || !user.auditingCompanyId ? (
                <Navigate to="/" />
              ) : (
                <AuditingCompanyContextProvider>
                  <AuditorUserContextProvider>
                    <AuditingCompany
                      provideAuditingCompanyId={getAuditingCompanyId}
                    />
                  </AuditorUserContextProvider>
                </AuditingCompanyContextProvider>
              )
            }
          />

          {/* Audit Routes */}
          <Route path="/lab-audit" element={<Navigate to="/" />} />

          <Route
            path="/lab-audit/:auditId/*"
            element={
              !user ? (
                <Navigate to="/" replace={true} />
              ) : (
                <UserContextProvider>
                  <AuditLandingPage />
                  <Routes>
                    <Route path="lab-company" element={<Navigate to="../" />} />
                  </Routes>
                </UserContextProvider>
              )
            }
          />

          <Route
            path="/lab-audit/:auditId/lab-company/:companyId/*"
            element={
              !user ? (
                <Navigate to="/" />
              ) : (
                //Audit uses the (Lab) CompanyContext to get all the data. company components have audit and lab views where necessary which are rendered based on the route params.
                <CompanyContextProvider>
                  <UserContextProvider>
                    <Company provideCompanyId={getCompanyId} />
                    {/* These are the audit subroutes that need CompanyContext */}
                    <Routes>
                      {/* This needs company Context to select the equipment and evaluations part of the audit. */}
                      <Route path="lab-controls" element={<LabControls />} />
                    </Routes>
                  </UserContextProvider>
                </CompanyContextProvider>
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
