import { useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { useAuthContext } from "../../authentication"
//layout
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
//components
import { EmployeeList, useCompanyContext } from "../index"

export default function Staff() {
  const { companyId } = useParams()
  const { isAuditView } = useCompanyContext()
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const [searchTerm, setSearchTerm] = useState("")

  return (
    <ContentOptionalRight
      contentTitle="Company Staff"
      content={
        <>
          {user.admin && !isAuditView && (
            <Link
              className="btn btn-sm ms-4 text-bg-primary"
              to={`/company/${companyId}/signup`}
              style={{ height: "2.5em" }}
            >
              Add User
            </Link>
          )}
          <input
            className="ms-5 mb-2"
            placeholder="Search by name or email"
            style={{ height: "2.5em", width: "45%" }}
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <EmployeeList searchBy={searchTerm} />
        </>
      }
    />
  )
}
