import { useEffect, useState } from "react"
import { useAuthContext } from "../../../authentication"
import { useDocument } from "../../../../hooks/useDocument"
import { useServiceAgreement } from "../../index"
//components
import TabbedContent from "../../../../components/layouts/TabbedContent"
import ServiceAgreement from "./ServiceAgreement"
import PaymentHistory from "./PaymentHistory"
import CurrentStatus from "./CurrentStatus"

export default function Billing({ companyData }) {
  const { user } = useAuthContext()
  const [serviceData, setServiceData] = useState(null)
  const {
    getServiceAgreement,
    error: serviceAgreementError,
    isPending: serviceAgreementPending,
  } = useServiceAgreement()

  const { docData: billingData } = useDocument(
    `companies/${companyData.companyId}/billing/billingInfo`
  )

  useEffect(() => {
    if (billingData) {
      getServiceAgreement({ serviceLevelId: billingData.serviceLevelId }).then(
        (res) => {
          setServiceData(res)
        }
      )
    }
  }, [billingData?.serviceLevelId])

  //console.log(billingData)
  const tabs = [
    {
      title: "Current Status",
      content: (
        <CurrentStatus billingData={billingData} serviceData={serviceData} />
      ),
    },
    {
      title: "Service Agreement",
      content: (
        <ServiceAgreement billingData={billingData} serviceData={serviceData} />
      ),
    },
    { title: "Payment History", content: <PaymentHistory /> },
  ]
  if (!billingData || !serviceData) return <p className="loading">...loading</p>
  return (
    <div className="ps-5">
      <TabbedContent pageTitle="Billing Information" tabs={tabs} />
    </div>
  )
}
