import { useParams, useNavigate, Link } from "react-router-dom"
import { useCompanyContext } from "../../company"
import { EquipmentList } from "../../equipment"

export default function UserEquipment({ embedded = false }) {
  const navigate = useNavigate()
  const { uniqueId, companyId, auditId } = useParams()
  const { companyData, isAuditView } = useCompanyContext()

  const thisUser = companyData.employees.find((u) => u.uid === uniqueId)

  return (
    <div className={embedded ? "" : "page-container container"}>
      {!embedded && (
        <h2 className="page-title">{thisUser.displayName}'s Equipment</h2>
      )}
      {/* equipment list component */}
      <EquipmentList companyId={companyId} selectedCustody={uniqueId} />

      {!isAuditView && (
        <Link
          className="btn text-bg-primary"
          to={`/company/${companyId}/user/${uniqueId}`}
        >
          Back to user details
        </Link>
      )}
      {isAuditView && (
        <Link
          to={`/lab-audit/${auditId}/lab-company/${companyId}/company-equipment`}
          className="btn text-bg-primary"
        >
          Go to All Equipment
        </Link>
      )}
    </div>
  )
}
