import React, { useState } from "react"
import { useMultistepForm } from "../../hooks/basicHooks/useMultistepForm"
import CompanyInformationForm from "../../components/CompanyInformationForm"
import PersonalAccountFormForAuditor from "./PersonalAccountFormForAuditor"
import { Button } from "react-bootstrap"
import { useAuditorSetup } from "../../hooks/useAuditorSetup"
import { TextInput } from "../../components/basicFormComponents"

const INITIAL_DATA = {
  companyName: "",
  companyAddress: "",
  companyAddress2: "",
  companyCity: "",
  companyState: "",
  companyZip: "",
  companyPhone: "",
  emailVerificationText: "",
  accountName: "",
  displayName: "",
  accountEmail: "",
  accountPassword: "",
  accountConfirmPassword: "",
  agreedToAuditorTerms: false,
  signedNameForAgreement: "",
}

export default function AuditorCompanySetupForm({ dispatch }) {
  const [formError, setFormError] = useState("")
  const {
    error,
    isPending,
    resetError,
    auditingCompanyNameAvailable,
    createAuditor,
    createAuditingCompany,
  } = useAuditorSetup()

  //multistep form functionality
  const [data, setData] = useState(INITIAL_DATA)
  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }
  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <AuditorCompanyForm updateFields={updateFields} {...data} />,
      <PersonalAccountFormForAuditor updateFields={updateFields} {...data} />,
    ])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    // resetError()
    //on first step, check that the name of the company is available.
    if (currentStepIndex === 0) {
      const nameIsAvailable = await auditingCompanyNameAvailable(
        data.companyName
      )
      if (!nameIsAvailable)
        return setFormError(
          "This Company Name is already in use. use an available Company Name"
        )
    }
    // on step 2 (index 1), setup the client's account and the company under the sandbox tier automatically.
    if (currentStepIndex === 1) {
      if (!data.agreedToAuditorTerms)
        return setFormError("you must agree to the terms to continue.")
      //defined below
      await handlePersonalAccountFormSubmit({
        ...data,
        setFormError,
        createAuditingCompany,
        createAuditor,
      })
    }
    // console.log(data)
    if (!isLastStep) return next()

    dispatch({ type: "REFRESH" })
  }

  return (
    <div>
      <form
        className="multi-step-form in-modal"
        onSubmit={(e) => handleSubmit(e)}
        id="account-setup-form"
        name="account-setup-form"
      >
        <div className="multi-step-tracker">
          {currentStepIndex + 1} / {steps.length}
        </div>
        {/* form content from steps array */}
        {step}

        {formError && <div className="error">{formError}</div>}
      </form>
      {/* control */}
      {!isFirstStep && (
        <Button variant="primary" type="button" onClick={back}>
          Back
        </Button>
      )}
      <Button
        form="account-setup-form"
        type="submit"
        variant="primary"
        disabled={isPending}
      >
        {isLastStep ? "Finish" : "Next"}
      </Button>
    </div>
  )
}

const AuditorCompanyForm = ({
  companyName,
  companyAddress,
  companyAddress2,
  companyCity,
  companyState,
  companyZip,
  companyPhone,
  emailVerificationText,
  updateFields,
}) => {
  return (
    <CompanyInformationForm
      companyName={companyName}
      companyAddress={companyAddress}
      companyAddress2={companyAddress2}
      companyCity={companyCity}
      companyState={companyState}
      companyZip={companyZip}
      companyPhone={companyPhone}
      updateFields={updateFields}
    >
      <div className="d-flex">
        <TextInput
          label="website required to be in email to verify auditor signup"
          horizontal={true}
          required={true}
          value={emailVerificationText}
          onChange={(e) =>
            updateFields({ emailVerificationText: e.target.value })
          }
        />
      </div>
      <p className="text-center text-muted">ex: "@example.com"</p>
    </CompanyInformationForm>
  )
}

const handlePersonalAccountFormSubmit = async ({
  setFormError,
  createAuditor,
  createAuditingCompany,
  accountConfirmPassword,
  accountPassword,
  displayName,
  accountEmail,
  companyName,
  companyAddress,
  companyAddress2,
  companyCity,
  companyState,
  companyZip,
  companyPhone,
  emailVerificationText,
  agreedToAuditorTerms,
  signedNameForAgreement,
}) => {
  //check for errors
  if (!agreedToAuditorTerms) return setFormError("You must agree to the terms")
  if (accountConfirmPassword !== accountPassword)
    return setFormError("Passwords do not match")
  //does email contain emailVerificationText
  if (
    !accountEmail
      .toLowerCase()
      .trim()
      .includes(emailVerificationText.toLowerCase().trim())
  )
    return setFormError(
      "The email address entered does not contain the web address required of accounts for the company."
    )

  // create the client user account
  const { user } = await createAuditor({
    displayName,
    email: accountEmail,
    password: accountPassword,
    auditingAdmin: true,
    auditingCompanyName: companyName,
    auditingCompanyId: companyName.toLowerCase().trim().replace(" ", "-"),
    agreedToAuditorTerms,
    signedNameForAuditorAgreementofTermsAndConditions: signedNameForAgreement,
    isAuditor: true,
  })
  //create the company in sandbox tier
  await createAuditingCompany({
    name: companyName,
    address: companyAddress,
    address2: companyAddress2,
    city: companyCity,
    state: companyState,
    zip: companyZip,
    phone: companyPhone,
    primaryAccount: {
      displayName,
      email: accountEmail,
      rights: "admin",
      uid: user.uid,
      auditingAdmin: true,
      isAuditor: true,
    },
    companyId: companyName.toLowerCase().trim().replace(" ", "-"),
    emailVerificationText,
  })

  return user
}
