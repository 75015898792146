import './ListFilter.css'

export default function MultiFilter({ filterOptions, currentFilters, changeFilters }) {
    const handleClick = (newFilter) => changeFilters(newFilter)

    return (
        <div className="list-filter">
            <nav>
                <p>Filter by</p>
                {filterOptions.map((f, i) => (
                        <button 
                            onClick={() => handleClick(f)}
                            className={currentFilters.includes(f) ? 'active' : ''}
                            key={f}
                        >
                            {f}
                        </button>
                ))}
            </nav>
        </div>
    )
}