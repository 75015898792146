import "./ListFilter.css"

export default function ListFilter({
  filterTitle = "Filter by",
  filterOptions,
  currentFilter,
  changeFilter,
  transparent = false,
}) {
  const handleClick = (newFilter) => changeFilter(newFilter)

  return (
    <div className={transparent ? "list-filter transparent" : "list-filter"}>
      <nav>
        <p>{filterTitle}</p>
        {filterOptions.map((f, i) => (
          <button
            onClick={() => handleClick(f)}
            className={currentFilter === f ? "active" : ""}
            key={f}
          >
            {f}
          </button>
        ))}
      </nav>
    </div>
  )
}
