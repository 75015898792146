import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useMultistepForm } from "../../../hooks/basicHooks/useMultistepForm"
import formatSelectArray from "../../../util/formatSelectArray"
import checkDuplicateInArray from "../../../util/checkDuplicateInArray"
import { useAuthContext } from "../../authentication/index"
import { useCompanyContext } from "../../company/index"
//components
import {
  EquipmentPieceForm,
  EquipmentStatusForm,
  useChangeEquipment,
} from "../index"
import { Modal, Button } from "react-bootstrap"

const INITIAL_DATA = {
  selectedType: null,
  equipmentCategory: "",
  pieceNumber: "",
  pieceId: "",
  serialNumber: "",
  make: "",
  model: "",
  acquisitionDate: "",
  newStatus: "",
  statusChangeDate: "",
  statusComment: "",
}

export default function AddEquipmentPieceModal({
  show,
  handleClose,
  companyId,
}) {
  const navigate = useNavigate()
  const { equipmentTypes, equipmentList, equipmentOverMaxPermitted } =
    useCompanyContext()
  const { user } = useAuthContext()
  const { addEquipmentPiece, changeStatus, error, firestoreError, isPending } =
    useChangeEquipment()

  //format categories to be used by react-select
  const types = formatSelectArray(equipmentTypes, "name", {
    ...equipmentTypes[0],
    defaultCustody: null,
  })

  const [formError, setFormError] = useState("")

  //multistep form functionality
  const [data, setData] = useState(INITIAL_DATA)
  //use component specific custom hooks which are defined below component.
  useGetNextNumber(data.selectedType, equipmentList, updateFields)
  useGetEquipmentId(data.selectedType, data.pieceNumber, updateFields)

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <EquipmentPieceForm
        {...data}
        types={types}
        updateFields={updateFields}
      />,
      <EquipmentStatusForm
        {...data}
        minDate={data.acquisitionDate}
        updateFields={updateFields}
      />,
    ])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    //no continue if no manager rights
    if (!user.moderator)
      return setFormError(
        "must have moderator rights to add equipment to the database"
      )
    if (!data.selectedType)
      return setFormError(
        "you must select an equipment type from the dropdown."
      )

    const { exists: pieceIdExists, duplicateData: duplicatePiece } =
      checkDuplicateInArray(equipmentList, data.pieceID, "id")

    if (pieceIdExists) {
      console.log("duplicate")
      return setFormError(
        `Use a different equipment Number; this equipment ID is already in use by another piece: ${duplicatePiece.type} - ${duplicatePiece.id}, equipmnent piece status = ${duplicatePiece.status}`
      )
    }

    //check if adding this equipment is allowed based on the service agreement.
    if (equipmentOverMaxPermitted) {
      return setFormError(
        "You're company's account is at the max permitted equipment pieces per their service agreement, contact your primary account holder to increase the overage allowances in the billing portal."
      )
    }

    //if it is not the last form in the step, go to next step
    if (!isLastStep) return next()

    if (!data.newStatus)
      return setFormError("Need to select a status and status data")

    //add data to firestore
    let equipment = await addEquipmentPiece({
      ...data,
      companyId,
      user,
    })

    if (formError || error || firestoreError) return

    //set the status
    equipment = await changeStatus({
      ...data,
      companyId,
      user,
      equipment,
    })

    if (!formError && !error && !firestoreError) {
      handleModalClose()
      navigate(`/company/${companyId}/company-equipment/${data.pieceID}`)
    }
    if (
      !data.acquisitionDate ||
      !data.make ||
      !data.model ||
      !data.serialNumber
    )
      alert(
        "some data was not entered, this data can be entered by the admin or manager from the equipment page"
      )
  }

  const handleModalClose = () => {
    setData(INITIAL_DATA)
    setFormError("")

    handleClose()
  }

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Equipment Piece</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to add an equipment piece
            </p>
          )}
          <form
            className="multi-step-form in-modal"
            onSubmit={handleSubmit}
            id="equipment-form"
          >
            <div className="multi-step-tracker">
              {currentStepIndex + 1} / {steps.length}
            </div>
            {/* form content from steps array */}
            {step}

            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending}
          >
            Close
          </Button>

          {!isFirstStep && (
            <Button variant="primary" type="button" onClick={back}>
              Back
            </Button>
          )}

          <Button form="equipment-form" type="submit" variant="primary">
            {isLastStep ? "Finish" : "Next"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const useGetNextNumber = (type, equipmentList, updateFields) => {
  //auto-generate number
  useEffect(() => {
    if (type) {
      let number = 1
      equipmentList.forEach((e) => {
        if (e.typePrefix === type.value.prefix) {
          let idComponents = e.id.split("-")
          let pieceId = Number(idComponents[idComponents.length - 1])
          if (pieceId + 1 > number) number = pieceId + 1
        }
      })
      updateFields({ pieceNumber: number })
    }
  }, [type])
}

const useGetEquipmentId = (type, pieceNumber, updateFields) => {
  useEffect(() => {
    if (type) {
      updateFields({ pieceID: `${type.value.prefix}-${pieceNumber}` })
    }
  }, [type, pieceNumber])
}

/* ***Equipment Object in Firestore:***
companies collection -> company doc -> equipment collection -> equipmentData doc -> 
EquipmentList: [
    {
        type: '',
        id: '',
        status: '',
        currentCustody: '',
        lastCalibration: Timestamp,
        nextCalibration: Timestamp
    }, ...

companies collection -> company doc -> equipment collection -> {equipemntID} doc ->
    {
        type: {
            everything in equipmentTypes object from EquipmentClassification Doc
        },
        id: '',
        status: '',
        make: '',
        model: '',
        serialNumber: '',
        currentCustody: {
            uid: '',
            name: '',
            location: ''
        },
        createdAt: Timestamp,
        history: [
            {
                historyEntryID: NUMBER,
                entryCategory: '', //[equipment created, maintenance, custody change, calibration, status change]
                enteredAt: Timestamp,
                dateOfAction: Timestamp,
                loggedBy: {
                    uid: '',
                    name: ''
                }, // user's uid
                comment: '',
                //other fields pending on entryCategory 
                custodyFrom: {
                    uid: '',
                    name: ''
                },
                custodyTo: {
                    uid: '',
                    name: ''
                },
                calibratedBy: {
                    uid: '',
                    name: ''
                },
                calibrationURL: '',
                maintenancePerformedBy: {
                    uid: '',
                    name: ''
                }
            }
        ]
    }
]
*/
