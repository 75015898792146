import { Button } from "react-bootstrap"
import AuditorView from "./AuditorView"
import LabRepView from "./LabRepView"
import { useNavigate } from "react-router-dom"

export default function AuditLandingPageInfo({ viewInfo, auditData }) {
  const navigate = useNavigate()

  const navigateToAuditView = (e) => {
    if (e.button === 2) return
    if (e.button === 0)
      navigate(
        `/lab-audit/${auditData.id}/lab-company/${auditData.labCompanyId}`
      )
    if (e.button === 1)
      window.open(
        `/lab-audit/${auditData.id}/lab-company/${auditData.labCompanyId}`,
        "_blank"
      )
  }

  //if the view is not permitted
  if (!viewInfo.isPermittedView)
    return (
      <p className="error">
        You are not permitted to view this audit. If you are an auditor or lab
        company representative that should have access, please reach out to one
        of your admins to get viewing permissions.
      </p>
    )

  return (
    <>
      {/* general audit data display */}
      <h2>Audit by {auditData.auditingCompany.name}</h2>
      <div className="row">
        <div className="col">
          <p>
            <span className="fw-bold">Performed on:</span>{" "}
            {auditData.labCompany.name}
          </p>
          <p>
            <span className="fw-bold">Referenced Standard(s):</span>{" "}
            {auditData.auditStandard}
          </p>
        </div>
        <div className="col">
          <p>
            <span className="fw-bold">Status:</span> {auditData.status}
          </p>
          {!viewInfo.isCompanyView && (
            <Button
              className="btn-sm"
              onMouseDown={(e) => navigateToAuditView(e)}
              disabled={
                (auditData.status !== "active" &&
                  viewInfo.isAuditorView &&
                  !viewInfo.isInternalAudit) ||
                auditData.status === "complete"
              }
            >
              Go to Lab Data
            </Button>
          )}

          {viewInfo.isCompanyView && (
            <Button
              className="btn-sm"
              onClick={() => navigate(`/lab-audit/${auditData.id}`)}
              disabled={
                auditData.status !== "active" &&
                viewInfo.isAuditorView &&
                !viewInfo.isInternalAudit
              }
            >
              Exit Audit View
            </Button>
          )}
        </div>
      </div>

      <hr />

      {/* who will have access to the audit */}
      <div className="row mt-4">
        <div className="col">
          <h3>Auditors</h3>
          <ul>
            {auditData.auditorsPermitted.map((auditor) => (
              <li key={auditor.uid}>{auditor.displayName}</li>
            ))}
          </ul>
        </div>
        <div className="col">
          <h3>Lab Reps</h3>
          <ul>
            {auditData.labStaffPermitted.map((labRep) => (
              <li key={labRep.uid}>{labRep.displayName}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Specific view display */}
      {viewInfo.isAuditorView && <AuditorView auditData={auditData} />}

      {!viewInfo.isAuditorView && <LabRepView auditData={auditData} />}
    </>
  )
}
