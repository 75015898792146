import { useFirestore } from "../../../hooks/useFirestore"

const useChangeEquipmentType = () => {
  const { updateDocument, error, isPending } = useFirestore()

  //changes equipmentType data for a matching prefix
  const changeEquipmentType = async ({
    companyId,
    equipmentTypes,
    typePrefix,
    typeChangeObject,
  }) => {
    //get new array with the desired change
    const newTypes = equipmentTypes
      .map((type) => {
        if (typePrefix !== type.prefix) return type
        return { ...type, ...typeChangeObject }
      })
      .sort((a, b) => {
        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    //update the equipmentClassification document for the company
    await updateDocument(
      `companies/${companyId}/equipment/equipmentClassification`,
      { equipmentTypes: newTypes }
    )
  }

  const changeEquipmentTypeDefaultCustody = async ({
    companyId,
    equipmentTypes,
    type,
    newDefaultCustody,
    changeAllEquipmentDefaults = false,
    changeAllCurrentCustodiesofOutOfService = false,
    equipmentPieces = [],
  }) => {
    const newDefaultCustodyObject = {
      displayName: `Default - (${newDefaultCustody.displayName})`,
      uid: newDefaultCustody.uid,
      rights: "default",
      userDisplayName: newDefaultCustody.displayName,
    }

    //get new array with the desired change
    const newTypes = equipmentTypes
      .map((t) => {
        if (type.prefix !== t.prefix) return t
        return {
          ...t,
          defaultCustody: newDefaultCustodyObject,
        }
      })
      .sort((a, b) => {
        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })

    //update the equipmentClassification document for the company
    await updateDocument(
      `companies/${companyId}/equipment/equipmentClassification`,
      { equipmentTypes: newTypes }
    )

    //if updating all equipment of that type add the newDefault custody to all equipment with that type.
    if (!changeAllEquipmentDefaults && !changeAllCurrentCustodiesofOutOfService)
      return

    equipmentPieces.forEach(async (eq) => {
      let updateObject = {}
      if (changeAllEquipmentDefaults)
        updateObject.defaultCustody = newDefaultCustodyObject
      if (
        changeAllCurrentCustodiesofOutOfService &&
        (eq.status === "out of service" ||
          eq.currentCustody.rights === "lab" ||
          eq.currentCustody.rights === "default")
      )
        updateObject.currentCustody = newDefaultCustodyObject
      await updateDocument(
        `companies/${companyId}/equipment/${eq.id}`,
        updateObject
      )
    })
  }

  return {
    isPending,
    error,
    changeEquipmentType,
    changeEquipmentTypeDefaultCustody,
  }
}

export { useChangeEquipmentType }
