import { useEffect, useState } from "react"

import { Modal, Button } from "react-bootstrap"
import { useAuthContext } from "../../authentication"
import { useCompanyContext } from "../../company"
import { EvaluateUserForm, useEvaluation, useEvaluate } from "../index"

const INITIAL_DATA = {
  evaluationDate: "",
  nextEvaluationDate: "",
  evaluatedBy: null,
  comment: "",
  evaluationDocument: null,
}

export default function EvaluateUserModal({
  show,
  handleClose,
  companyId,
  evaluationId,
  selectedEvaluated,
}) {
  const { user } = useAuthContext()
  const { documentsOverMaxPermitted, companyData } = useCompanyContext()
  const {
    error,
    isPending,
    getEvaluatingManagers,
    getUserFrequencyOfEvaluation,
  } = useEvaluation()
  const {
    error: evaluateError,
    isPending: evaluateIsPending,
    evaluateUser,
  } = useEvaluate()

  //form entry state
  const [data, setData] = useState(INITIAL_DATA)
  const [formError, setFormError] = useState("")

  const [managerOptions, setManagerOptions] = useState([])
  const [frequencyOfEvaluation, setFrequencyOfEvaluation] = useState(null)

  //call the get managers function passing the evaluation.
  useEffect(() => {
    getEvaluatingManagers({
      positions: companyData.availablePositions,
      employees: companyData.employees,
      evaluationId,
      userId: selectedEvaluated.uid,
    }).then((res) => {
      setManagerOptions(res)
    })
  }, [])

  //call the getEvaluationFrequency function passing the evaluation.
  useEffect(() => {
    getUserFrequencyOfEvaluation({
      companyId,
      evaluationId,
      userId: selectedEvaluated.uid,
    }).then((res) => {
      setFrequencyOfEvaluation(res)
    })
  }, [])

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!user.moderator)
      return setFormError(
        "you much have moderator rights to enter an employee evaluation"
      )
    setFormError("")

    await evaluateUser({
      evaluatedUser: selectedEvaluated,
      companyId,
      evaluationId,
      evaluationDate: data.evaluationDate,
      evaluatedBy: data.evaluatedBy,
      evaluationDocument: data.evaluationDocument,
      comment: data.comment,
      nextEvaluationDate: data.nextEvaluationDate,
      user,
      documentsOverMaxPermitted,
    })

    handleModalClose()
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            Evaluation of {selectedEvaluated.displayName} for {evaluationId}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to evaluate an employee
            </p>
          )}
          <form className="" id="evaluate-user-form" onSubmit={handleSubmit}>
            <EvaluateUserForm
              {...data}
              setFormError={setFormError}
              updateFields={updateFields}
              managerOptions={managerOptions}
            />

            {error && <div className="error">{error}</div>}
            {evaluateError && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
          <i className="text-muted fs-6">
            {selectedEvaluated.displayName}'s frequency of evaluation is
            recorded as {frequencyOfEvaluation} months
          </i>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending || evaluateIsPending}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="evaluate-user-form"
            onClick={handleSubmit}
            disabled={isPending || evaluateIsPending}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
