import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useFirestore } from "../../../hooks/useFirestore"
import { useUserContext } from "../hooks/useUserContext"

//components
import ListFilter from "../../../components/basicComponents/ListFilter"
import { NotificationCard } from "../index"

const notificationFilterList = [
  "all",
  "equipment",
  "evaluation",
  "past due",
  "upcoming",
]

// May only need to pass in userData.notifications from parent (Company.js)
export default function Notifications({ user }) {
  const { notifications: userNotifications } = useUserContext()
  const navigate = useNavigate()
  const { companyId } = useParams()

  const { updateDocument, error, isPending } = useFirestore()
  const [currentFilter, setCurrentFilter] = useState("all")

  const changeFilter = (newFilter) => setCurrentFilter(newFilter)

  const toggleSeen = async (notification, seen) => {
    if (notification.category === "equipment") {
      const updateObject = {}
      updateObject[notification.equipmentId] = notification
      updateObject[notification.equipmentId].seen = seen
      updateObject[notification.equipmentId].markedForDeletion = seen

      await updateDocument(
        `companies/${companyId}/equipment/equipmentNotifications`,
        updateObject,
        { merge: true }
      )
      return
    }

    if (notification.category === "evaluation") {
      const updateObject = {}
      updateObject[notification.evaluationId] = {}
      updateObject[notification.evaluationId][notification.userToNotify] =
        notification
      updateObject[notification.evaluationId][notification.userToNotify].seen =
        seen
      updateObject[notification.evaluationId][
        notification.userToNotify
      ].markedForDeletion = seen

      await updateDocument(
        `companies/${companyId}/evaluations/evaluationNotifications`,
        updateObject,
        { merge: true }
      )
      return
    }
  }

  //go to the message
  const openMessage = (uid) => {
    navigate(`/company/${companyId}/messages/${uid}`)
  }

  const visibleNotifications = userNotifications
    ? userNotifications.filter((notification) => {
        switch (currentFilter) {
          case "all":
            return true
          case "past due":
            return notification.urgent
          case "upcoming":
            return !notification.urgent
          case "equipment":
          case "evaluation":
            return notification.category === currentFilter
          default:
            return true
        }
      })
    : null

  return (
    <div className="page-container container">
      <h2 className="page-title">Notifications</h2>
      <div className="data-list">
        <ListFilter
          filterOptions={notificationFilterList}
          currentFilter={currentFilter}
          changeFilter={changeFilter}
        />

        {visibleNotifications.length > 0 &&
          visibleNotifications.map((n) => (
            <div key={n.notificationId}>
              <NotificationCard
                data={n}
                toggleSeen={toggleSeen}
                openMessage={openMessage}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

/*
    notification document model:
    [{
        category: equipment || evaluation || request,
        createdAt: Timestamp,
        from: {uid, displayname},
        urgent: bool,
        seen: bool,
        content: 'text',
    }]
*/
