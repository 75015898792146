import { useFirestore } from "../../../hooks/useFirestore"

export const useDeleteEquipment = () => {
  const { error, isPending, updateDocument } = useFirestore()

  const deleteEquipment = async ({ companyId, equipment, userInfo }) => {
    //mark the custody and default custody of the equipment to discarded.
    //add deleted as a flag to the equipment docuiment
    let updateObject = {
      ...equipment,
      currentCustody: {
        uid: "deleted",
        displayName: "deleted",
        rights: "null",
      },
      defaultCustody: {
        uid: "deleted",
        displayName: "deleted",
        rights: "null",
      },
      deleted: true,
      status: "discarded",
      deletedBy: userInfo,
      deletedAt: new Date(),
      history: [
        {
          category: "equipment deleted",
          comment: `Deleted by ${userInfo.displayName}`,
          dateOfAction: new Date(),
          enteredAt: new Date(),
          id: Math.floor(Math.random() * 100000),
          loggedBy: userInfo,
          performedBy: userInfo,
          status: "discarded",
          custody: {
            uid: "deleted",
            displayName: "deleted",
            rights: "null",
          },
        },
        ...equipment.history,
      ],
      nextCalibration: null,
    }

    await updateDocument(`companies/${companyId}/equipment/${equipment.id}`, {
      ...updateObject,
    })
  }

  return { error, isPending, deleteEquipment }
}
