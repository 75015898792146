import { createContext, useReducer, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useCollection } from "../../../hooks/useCollection"
import { useDocument } from "../../../hooks/useDocument"
import { determineOverdueStatus } from "../../../util/dateUtils"

export const CompanyContext = createContext()

//may need cases for equipment subcollection or other subcollections that are needed later
export const companyReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_COMPANY":
      return {
        ...state,
        companyIsReady: true,
        companyData: action.payload,
        equipmentQuery: [],
        documentsOverMaxPermitted:
          action.payload.documentsOwned >= action.payload.maxPermittedDocuments,
        usersOverMaxPermitted: checkEmployeeOver(
          "user",
          action.payload.employees,
          action.payload.maxPermittedUsers
        ),
        moderatorsOverMaxPermitted: checkEmployeeOver(
          "moderator",
          action.payload.employees,
          action.payload.maxPermittedModerators
        ),
        adminsOverMaxPermitted: checkEmployeeOver(
          "admin",
          action.payload.employees,
          action.payload.maxPermittedAdmins
        ),
      }
    case "UPDATE_EQUIPMENT_LIST":
      const equipmentToDisplay = action.payload.filter(
        (equipment) => !equipment.deleted
      )
      const isEquipmentNotification = equipmentToDisplay.some(
        (e) => e.notification
      )
      return {
        ...state,
        equipmentList: action.payload,
        equipmentListDisplay: equipmentToDisplay,
        equipmentOverMaxPermitted:
          equipmentToDisplay.length >= state.companyData.maxPermittedEquipment,
        isEquipmentNotification,
      }
    case "UPDATE_EQUIPMENT_CATEGORIES":
      return { ...state, equipmentCategories: action.payload }
    case "UPDATE_EQUIPMENT_TYPES":
      return { ...state, equipmentTypes: action.payload }
    case "UPDATE_EVALUATION_LIST":
      const evaluationListToDisplay = action.payload.filter((e) => !e.deleted)
      const isEvaluationNotification = evaluationListToDisplay.some(
        (e) => e.numberNotifications
      )
      return {
        ...state,
        isEvaluationNotification,
        evaluationList: action.payload,
        evaluationListDisplay: evaluationListToDisplay,
      }
    case "UPDATE_EVALUATION_CATEGORIES":
      return { ...state, evaluationCategories: action.payload }
    case "UPDATE_DOCUMENT_FOLDERS":
      return { ...state, documentFolders: action.payload }
    case "UPDATE_ADDITIONAL_DOCUMENT_LIST":
      return { ...state, additionalDocuments: action.payload }
    case "UPDATE_AUDITS":
      return {
        ...state,
        pendingAudits: action.payload.filter(
          (audit) => audit.status === "pending"
        ),
        activeAudits: action.payload.filter(
          (audit) => audit.status === "active" || audit.status === "on hold"
        ),
        completeAudits: action.payload.filter(
          (audit) => audit.status === "complete"
        ),
        rejectedAudits: action.payload.filter(
          (audit) => audit.status === "rejected"
        ),
      }
    case "UPDATE_VIEW":
      return {
        ...state,
        isAuditView: typeof action.payload !== "undefined",
        auditId: action.payload,
      }
    default:
      return state
  }
}

export const CompanyContextProvider = ({ children }) => {
  const { companyId, auditId } = useParams()
  const [state, dispatch] = useReducer(companyReducer, {
    companyData: null,
    companyIsReady: false,
    equipmentListDisplay: [],
    equipmentList: [],
    euipmentCategories: [],
    equipmentTypes: [],
    isEquipmentNotification: false,
    evaluationCategories: [],
    evaluationListDisplay: [],
    isEvaluationNotification: false,
    evaluationNotifications: [],
    evaluationList: [],
    documentFolders: [],
    additionalDocuments: [],
    equipmentOverMaxPermitted: false,
    documentsOverMaxPermitted: false,
    usersOverMaxPermitted: false,
    moderatorsOverMaxPermitted: false,
    adminsOverMaxPermitted: false,
    pendingAudits: [],
    activeAudits: [],
    completeAudits: [],
    rejectedAudits: [],
    isAuditView: false,
    auditId,
  })

  const { docData: companyData } = useDocument(`companies/${companyId}`)
  const { documents: equipmentDocs } = useCollection(
    `companies/${companyId}/equipment`
  )
  const { docData: equipmentCategories } = useDocument(
    `companies/${companyId}/equipment/equipmentClassification`
  )
  const { documents: evaluationDocs } = useCollection(
    `companies/${companyId}/evaluations`
  )
  const { docData: evaluationCategories } = useDocument(
    `companies/${companyId}/evaluations/evaluationClassification`
  )
  const { docData: documentStoreDocuments } = useDocument(
    `companies/${companyId}/additionalDocuments/documentClassification`
  )
  const { documents: additionalDocuments } = useCollection(
    `companies/${companyId}/additionalDocuments`
  )

  const { documents: auditDocuments } = useCollection("audits", [
    "labCompanyId",
    "==",
    companyId,
  ])

  useEffect(() => {
    if (companyData) {
      dispatch({ type: "CHANGE_COMPANY", payload: { ...companyData } })
    }
  }, [companyId, companyData])

  useEffect(() => {
    if (equipmentCategories) {
      dispatch({
        type: "UPDATE_EQUIPMENT_CATEGORIES",
        payload: equipmentCategories.equipmentCategories,
      })
      let equipmentTypeWithNotifications = equipmentCategories.equipmentTypes

      if (equipmentDocs.length) {
        const actualEquipment = equipmentDocs
          .filter((equipment) => {
            if (typeof equipment.id === "undefined") return false
            return true
          })
          .map((equipment) => {
            // console.log(equipment)
            const equipmentType = equipmentTypeWithNotifications.find(
              (t) => t.prefix === equipment.typePrefix
            )
            //check if notification is  not applicable and return
            if (
              equipment.status === "discarded" ||
              equipment.status === "out of service" ||
              typeof equipmentType.calibrationFrequency === "undefined" ||
              !equipmentType.calibrationFrequency
            )
              return {
                ...equipment,
                type: equipmentType,
                notification: false,
                calibrationStatus: "not upcoming",
              }

            //if it does need calibrated check if increments notification
            //increment notifications if nextCalibration doesn't exist or is null,
            if (
              typeof equipment.nextCalibration === "undefined" ||
              !equipment.nextCalibration
            ) {
              return {
                ...equipment,
                notification: true,
                type: equipmentType,
                calibrationStatus: "overdue",
              }
            }
            //increment if nextCalibration is upcoming or overdue
            const status = determineOverdueStatus(
              equipment.nextCalibration.toDate()
            )
            // console.log(status)
            if (!status)
              return {
                ...equipment,
                notification: false,
                type: equipmentType,
                calibrationStatus: "not upcoming",
              }

            return {
              ...equipment,
              notification: true,
              type: equipmentType,
              calibrationStatus: status,
            }
          })

        //getting notification numbers for each equipment type
        equipmentTypeWithNotifications = equipmentTypeWithNotifications.map(
          (t) => {
            let notificationCount = 0
            //return if doesn't need calibrated
            if (
              !t.calibrationFrequency ||
              typeof t.calibrationFrequency === "undefined"
            )
              return { ...t, notificationCount }

            //check equipment if it does need calibrated
            actualEquipment.forEach((e) => {
              //check if notification is  not applicable and return
              if (e.typePrefix !== t.prefix) return

              if (e.notification) notificationCount++
            })
            return { ...t, notificationCount }
          }
        )

        dispatch({
          type: "UPDATE_EQUIPMENT_LIST",
          payload: actualEquipment,
        })
      }

      dispatch({
        type: "UPDATE_EQUIPMENT_TYPES",
        payload: equipmentTypeWithNotifications,
      })
    }
  }, [equipmentCategories, equipmentDocs])

  useEffect(() => {
    if (evaluationDocs.length) {
      const actualEvaluations = evaluationDocs.filter((evaluation) => {
        if (typeof evaluation.standard === "undefined") return false
        return true
      })

      const notificationsWithOverdue = actualEvaluations.map((evaluation) => {
        let numberNotifications = 0
        let userEvalObject = {}
        const keysValues = Object.keys(evaluation).forEach((key) => {
          if (
            typeof evaluation[key].required === "undefined" ||
            !evaluation[key].required
          )
            return (userEvalObject[key] = evaluation[key])
          //   console.log("checking current evaulation")
          if (
            typeof evaluation[key].currentEvaluation === "undefined" ||
            typeof evaluation[key].currentEvaluation.nextEvaluation ===
              "undefined"
          ) {
            numberNotifications++
            return (userEvalObject[key] = {
              ...evaluation[key],
              notification: true,
              evaluationStatus: "overdue",
            })
          }
          //   console.log("checking status of current evaluation")
          //   console.log(evaluation[key])
          const status = determineOverdueStatus(
            evaluation[key].currentEvaluation.nextEvaluation.toDate()
          )
          if (!status)
            return (userEvalObject[key] = {
              ...evaluation[key],
              notification: false,
              evaluationStatus: "not upcoming",
            })
          numberNotifications++
          return (userEvalObject[key] = {
            ...evaluation[key],
            notification: true,
            evaluationStatus: status,
          })
        })
        return { ...userEvalObject, numberNotifications }
      })

      dispatch({
        type: "UPDATE_EVALUATION_LIST",
        payload: notificationsWithOverdue,
      })
    }
  }, [evaluationDocs])

  useEffect(() => {
    if (evaluationCategories) {
      dispatch({
        type: "UPDATE_EVALUATION_CATEGORIES",
        payload: evaluationCategories.evaluationCategories,
      })
    }
  }, [evaluationCategories])

  useEffect(() => {
    if (documentStoreDocuments) {
      dispatch({
        type: "UPDATE_DOCUMENT_FOLDERS",
        payload: documentStoreDocuments.folders,
      })
    }
  }, [documentStoreDocuments])

  useEffect(() => {
    if (additionalDocuments.length) {
      const actualDocs = additionalDocuments.filter(
        (document) => typeof document.name !== "undefined"
      )
      dispatch({
        type: "UPDATE_ADDITIONAL_DOCUMENT_LIST",
        payload: actualDocs,
      })
    }
  }, [additionalDocuments])

  useEffect(() => {
    if (auditDocuments) {
      dispatch({ type: "UPDATE_AUDITS", payload: auditDocuments })
    }
  }, [auditDocuments])

  useEffect(() => {
    dispatch({ type: "UPDATE_VIEW", payload: auditId })
  }, [auditId])

  return (
    <CompanyContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CompanyContext.Provider>
  )
}

function checkEmployeeOver(employeeType, employees, maxPermitted) {
  return (
    employees.filter((employee) => employee.rights === employeeType).length >=
    maxPermitted
  )
}
