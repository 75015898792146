import "./legal.css"

export default function PrivacyPolicyJSX() {
  return (
    <div className="terms-and-conditions">
      <h2>Privacy Policy</h2>
      <p className="updated-statement">
        <strong>Last updated:</strong> 08/14/2023
      </p>
      <p className="introduction">
        eLab Tracker ("we", "us", or "our") is committed to protecting the
        privacy and security of your personal information. This Privacy Policy
        outlines how we collect, use, and disclose our client’s information
        gathered through our platform. By accessing or using our services, you
        agree to the terms outlined in this Privacy Policy. If you do not agree
        with our practices, please contact us with your exceptions or refrain
        from using our services .
      </p>
      <section>
        <h3>Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ol>
          <li>
            <strong>Personal Information:</strong> This includes information
            that can be used to identify you or your employees, such as a user’s
            name, email address, contact information, and billing details.
          </li>
          <li>
            <strong>Business Information:</strong> We may collect information
            about your business, including its name, location, industry, and
            other relevant details.
          </li>
          <li>
            <strong>Files and Equipment Data:</strong> Our platform allows
            clients to store files provided by your business aa well as
            equipment-related data and employee-related data. This may include
            equipment specifications, maintenance schedules, usage logs,
            training schedules, and other relevant information.
          </li>
          <li>
            <strong>Usage and Analytics Data:</strong> We collect information
            about how you interact with our platform, such as log files, IP
            addresses, browser type, pages visited, and the duration of your
            visits. This data will help us improve our services and provide a
            better user experience.
          </li>
        </ol>
      </section>

      <section>
        <h3>How We Use Your Information</h3>
        <p>We use the collected information for the following purposes:</p>
        <ol>
          <li>
            <strong>Providing and Improving Services:</strong> We use your
            information to deliver the services you've requested, personalize
            your experience, and continually enhance our platform's
            functionality and features.
          </li>
          <li>
            <strong>Communication:</strong> We may use your contact information
            to send you important updates, notifications, and promotional
            materials related to our services.
          </li>
          <li>
            <strong>Customer Support:</strong> Your information helps us respond
            to your inquiries, address technical issues, and provide efficient
            customer support.
          </li>
          <li>
            <strong>Analytics:</strong> We analyze usage patterns and behavior
            to gain insights into how our platform is used, allowing us to
            optimize and improve its performance.
          </li>
          <li>
            <strong>Legal and Security:</strong> We may use your information to
            comply with legal obligations, protect our rights and the rights of
            other users, and prevent fraudulent or unauthorized activities.
          </li>
        </ol>
      </section>

      <section>
        <h3>Data Sharing and Disclosure</h3>
        <p>We may share your information in the following situations:</p>
        <ol>
          <li>
            <strong>Third-Party Service Providers:</strong> We may engage
            trusted third-party service providers to assist us in delivering our
            services. These providers have limited access to your information
            and are obligated to protect its confidentiality.
          </li>
          <li>
            <strong>Business Transfers:</strong> If we undergo a merger,
            acquisition, or sale of all or part of our assets, your information
            may be transferred as part of the transaction. We will notify you
            and provide choices in such events.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required to do so by law or in response to a valid
            request from a governmental authority.
          </li>
          <li>
            <strong>Authorized Sharing:</strong> The aim of eLab Tracker is to
            ultimately help our client’s and improve industry standards
            regarding traceability and transparency; with your explicit
            permission, eLab Tracker may share information such as templates,
            equipment schedules, procedures, usage data, or more with other
            clients to improve upon their experience and compliance. Upon asking
            your permission you will be informed of who will receive the
            information as well as what information will be shared. Your company
            may remain anonymous in this process.
          </li>
        </ol>
      </section>

      <section>
        <h3>Data Security</h3>
        <p>
          We take security seriously and employ measures to protect your
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no method of transmission over the internet or
          electronic storage is entirely secure, so we cannot guarantee absolute
          security. Our employed security measures are in place to protect your
          data from being accessed by anyone who has not been explicitly granted
          access by you or an authorized representative of your organization
          through eLab Tracker.
        </p>
      </section>

      <section>
        <h3>Your Choices</h3>
        <p>You have the right to:</p>
        <ol>
          <li>Access, correct, or update any information stored with us.</li>
          <li>
            Close your account; however, please note that some information may
            be retained for legal or legitimate business purposes.
          </li>
        </ol>
      </section>

      <section>
        <h3>Children's Privacy</h3>
        <p>
          Our services are not directed at individuals under the age of 18 other
          than information which is deemed business necessary by the child’s
          employer. We do not knowingly collect personal information from
          children. If you believe we have collected information from a child,
          please contact us to have it removed.
        </p>
      </section>

      <section>
        <h3>Changes to Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any significant changes and provide an
          updated version of the policy on our website.
        </p>
      </section>

      <section>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about our Privacy Policy, please
          contact us at sales@eLabTracker.com.
        </p>
      </section>
    </div>
  )
}
