import { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useSortData } from "../../../../hooks/basicHooks/useSortList"
//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
import { useCompanyContext } from "../../hooks/useCompanyContext"
import { useGetFilteredEmployeeListData } from "../../index"
//styles
import "../../../../DataList.css"

export default function EmployeeList({ searchBy }) {
  const navigate = useNavigate()
  const { companyData, auditId } = useCompanyContext()
  const [selectedEmployee, setSelectedEmployee] = useState("")

  //filtering and sorting state using search params
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    sortBy: searchParams.get("sortBy") || "displayName",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  const filteredEmployees = useGetFilteredEmployeeListData(
    companyData.employees,
    allSearchParams.role,
    searchBy,
    setSelectedEmployee
  )

  const displayedEmployees = useSortData(
    filteredEmployees,
    allSearchParams.sortBy,
    allSearchParams.order === "asc" ? true : false
  )

  const tableHeaderArray = [
    { sortable: true, title: "Name", changeSortByTo: "displayName" },
    { sortable: true, title: "Email", changeSortByTo: "email" },
    { sortable: true, title: "Position", changeSortByTo: "position" },
  ]

  return (
    <div>
      <div className="data-list">
        {/* Employee data table */}
        {displayedEmployees.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table" size="md">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
                classes="text-center text-wrap"
              />
              <tbody className="text-center">
                {displayedEmployees.map((e) => (
                  <tr
                    key={e.uid}
                    onClick={() => setSelectedEmployee(e.uid)}
                    className={selectedEmployee === e.uid ? "active" : ""}
                  >
                    <td>{e.displayName}</td>
                    <td>{e.email}</td>
                    <td>{e.position}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedEmployees.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        <div className="d-flex mt-3">
          {selectedEmployee && (
            <Link
              className="btn text-bg-primary ms-2"
              to={`/lab-audit/${auditId}/lab-company/${companyData.companyId}/user/${selectedEmployee}/equipment`}
            >
              Go to user's equipment
            </Link>
          )}

          {selectedEmployee && (
            <Link
              className="btn text-bg-primary ms-2"
              to={`/lab-audit/${auditId}/lab-company/${companyData.companyId}/user/${selectedEmployee}/evaluations`}
            >
              Go to user's evaluations
            </Link>
          )}

          {selectedEmployee && (
            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedEmployee("")}
            >
              Unselect
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
