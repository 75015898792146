import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useInvoicing } from "../../index"

import { addDays, format } from "date-fns"
import { formatUSD } from "../../../../util/formatCurrency"

import { Table } from "react-bootstrap"

export default function PaymentHistory() {
  const { companyId } = useParams()
  const [invoices, setInvoices] = useState([])
  const { error, isPending, getInvoices } = useInvoicing()
  useEffect(() => {
    getInvoices({ companyId }).then((res) => {
      formatInvoicesForTable({ invoices: res, setInvoices })
    })
  }, [])

  if (invoices.length === 0 && isPending)
    return <p className="loading">loading...</p>
  if (error) return <p className="error">Something went wrong: {error}</p>

  return (
    <div>
      <Table
        striped
        hover
        bordered
        className="data-list-table"
        size="md"
        responsive
      >
        <thead className="text-center">
          <tr>
            <th>Date</th>
            <th>Due</th>
            <th>Sent To</th>
            <th>Amount</th>
            <th>Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td>{format(invoice.date, "MM-dd-yyyy")}</td>
              <td>{format(invoice.dateDue, "MM-dd-yyyy")}</td>
              <td>{invoice.sentTo}</td>
              <td>{formatUSD(invoice.amount)}</td>
              <td>{invoice.status}</td>
              <td>
                <a href={invoice.url} target="_blank">
                  link
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const formatInvoicesForTable = ({ invoices, setInvoices }) => {
  if (invoices.length === 0) return

  let newInvoices = invoices.map((invoice) => {
    let amount = 0
    invoice.items.forEach((item) => {
      let quantity = typeof item.quantity === "undefined" ? 1 : item.quantity
      amount += (item.amount * quantity) / 100
    })
    return {
      amount,
      date: invoice.createdAt.toDate(),
      dateDue:
        typeof invoice.daysUntilDue == "undefined"
          ? addDays(invoice.createdAt.toDate(), 7)
          : addDays(invoice.createdAt.toDate(), invoice.daysUntilDue),
      sentTo: invoice.email,
      status: invoice.stripeInvoiceStatus,
      url: invoice.stripeInvoiceUrl,
      id: invoice.stripeInvoiceId,
    }
  })

  return setInvoices(newInvoices)
}
