import { useState } from "react"
import InfoIcon from "../../../components/basicComponents/InfoIcon"
import CollapseIcon from "../../../components/basicComponents/CollapseIcon"
import formatDistanceToNow from "date-fns/formatDistanceToNow"

//styles
import "../../../components/DataCard.css"

export default function NotificationCard({ data, toggleSeen, openMessage }) {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div className={collapsed ? "data-card" : "data-card expanded"}>
      <div className="header align-items-baseline pt-1">
        <div className="me-3">
          <InfoIcon infoTitle={`${data.category} notification`} />
        </div>
        <div className="d-sm-flex flex-shrink-1">
          <h3 className="fs-3">{data.category}</h3>
          <p className="additional-info me-1 ms-3">
            {formatDistanceToNow(data.createdAt.toDate(), { addSuffix: true })}
          </p>
          <p className="additional-info">
            from <span className="from">{data.from.displayName}</span>
          </p>
          {data.seen && (
            <p
              className=" ms-4 fs-6 clickable text-decoration-underline text-muted"
              onClick={() => toggleSeen(data, !data.seen)}
            >
              mark unread
            </p>
          )}
        </div>

        <div className="ms-auto d-flex flex-nowrap">
          <div className="bool-ction">
            {data.seen && <p className="fs-6 pt-1 fw-bold me-2">Read</p>}

            {!data.seen && <p className="fs-6 pt-1 fw-bold me-2">Unread</p>}
          </div>

          {/* Mark the notification as seen if uncollapsed */}
          <div
            className="collapse-icon"
            onClick={() =>
              setCollapsed((prevCollapsed) => {
                if (!data.seen && prevCollapsed) toggleSeen(data, !data.seen)
                return !prevCollapsed
              })
            }
          >
            <CollapseIcon collapsed={collapsed} />
          </div>
        </div>
      </div>

      <div className="notification-subject">
        {data.category === "equipment" && data.equipmentId}
        {data.category === "evaluation" && data.evaluationId}
        <div className="urgency">
          <span className="me-2">Urgency:</span>
          {data.category === "request"
            ? data.urgent
              ? "ASAP"
              : "at your pace"
            : data.urgent
            ? " past due"
            : "  due now"}
        </div>
      </div>

      {!collapsed && (
        <div>
          <div className="content pe-4 ps-4">
            <p className="fs-6">{data.content}</p>

            {data.from.uid !== "system" && (
              <div className="button-action">
                <button
                  className="btn text-bg-primary"
                  onClick={() => openMessage(data.from.uid)}
                >
                  Message
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
