import { useState } from "react"
import Select from "react-select"
import { format } from "date-fns"
import formatSelectArray from "../../../../util/formatSelectArray"
import DateInput from "../../../../components/basicFormComponents/DateInput"
import { verifyPDF } from "../../../../util/verifyFileInput"
import { Timestamp } from "firebase/firestore"

export default function EquipmentCalibrationForm({
  calibratedBy,
  calibrationDate,
  comment,
  minDate,
  updateFields,
  setFormError,
  employeeList,
}) {
  const [tempUser, setTempUser] = useState(null)
  const updateCalibrationDate = (newDate) =>
    updateFields({ calibrationDate: newDate })

  //format emplopyees to be used by react-select
  const employees = formatSelectArray(
    [
      ...employeeList,
      { displayName: `by others`, uid: "none", role: "third party" },
    ],
    "displayName",
    { ...employeeList[0] }
  )

  const handleCalibratedBy = (option) => {
    setTempUser(null)
    if (option.value.displayName !== "by others")
      return updateFields({ calibratedBy: option })
    const tempUser = {
      value: { displayName: option.label, uid: "none", role: "third party" },
      label: "by others",
    }

    return setTempUser(tempUser)
  }

  //check validity of selected file in form
  const handleFileChange = (e) => {
    updateFields({ calibrationDocument: null })
    setFormError("")
    const { pdf: selected, error: fileError } = verifyPDF(e.target.files[0])

    if (fileError) return setFormError(fileError)
    updateFields({ calibrationDocument: selected })
  }

  return (
    <div>
      <DateInput
        label="calibration date*"
        required
        value={calibrationDate}
        setValue={updateCalibrationDate}
        min={
          minDate instanceof Timestamp
            ? format(minDate.toDate(), "yyyy-MM-dd")
            : null
        }
        max={format(new Date(), "yyyy-MM-dd")}
      />

      <span>calibrated by*: </span>
      <Select
        onChange={(option) => handleCalibratedBy(option)}
        options={employees}
        value={tempUser ? tempUser : calibratedBy}
      />

      {tempUser && (
        <label>
          <span>calibrating company: </span>
          <input
            type="text"
            onChange={(e) =>
              updateFields({
                calibratedBy: {
                  value: { ...tempUser.value, displayName: e.target.value },
                  label: e.target.value,
                },
              })
            }
            className="form-control"
          />
        </label>
      )}

      <label>
        <span>calibration form (doc-type .pdf)*: </span>
        <input
          type="file"
          onChange={(e) => handleFileChange(e)}
          className="form-control"
        />
      </label>

      <label>
        <span>comment: </span>
        <input
          required
          type="text-area"
          onChange={(e) => updateFields({ comment: e.target.value })}
          value={comment}
          className="form-control"
        />
      </label>
    </div>
  )
}
