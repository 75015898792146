import { useFirestore } from "../../../hooks/useFirestore"

export function useUpdateUser() {
  const { error, isPending, updateDocument } = useFirestore()

  const updateCompanyEmployee = async ({
    companyId,
    uid,
    companyEmployees,
    changedProps,
  }) => {
    let changedEmployee = companyEmployees.find(
      (employee) => employee.uid === uid
    )
    const newEmployees = companyEmployees.filter(
      (employee) => employee.uid !== uid
    )

    changedEmployee = { ...changedEmployee, ...changedProps }
    newEmployees.push(changedEmployee)

    await updateDocument(`companies/${companyId}`, {
      employees: newEmployees,
    })
    await updateUserDoc({ uid, changedProps })
  }

  const updateUserDoc = async ({ uid, changedProps }) => {
    await updateDocument(`users/${uid}`, changedProps)
  }

  return { error, isPending, updateCompanyEmployee, updateUserDoc }
}
