import { useEffect, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAuthContext } from "../../../authentication/index"
import { useCompanyContext } from "../../../company/index"
//components
import { Table } from "react-bootstrap"
import TableHeaderSortable from "../../../../components/TableHeaderSortable"
import ListFilter from "../../../../components/basicComponents/ListFilter"

//styles
import "../../../../DataList.css"
import DownloadFileButton from "../../../../components/basicComponents/DownloadFileButton"
import ViewPDFButton from "../../../../components/basicComponents/ViewPDFButton"
import { useFilterEquipmentTypes } from "../../hooks/useFilterEquipment"
import { useSortEquipmentTypeList } from "../../hooks/useSortEquipmentList"

export default function LabView({ searchTerm }) {
  const navigate = useNavigate()
  const { companyId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const allSearchParams = {
    category: searchParams.get("category") || "all",
    calibrationRequired: searchParams.get("calibrationRequired") || "both",
    sortBy: searchParams.get("sortBy") || "nextCalibration",
    order: searchParams.get("order") || "asc",
  }

  const updateSearchParams = ({ newParams, replace = true }) => {
    setSearchParams({ ...allSearchParams, ...newParams }, { replace })
  }

  const setSortBy = (newSortBy) => {
    let newOrder = "asc"
    if (allSearchParams.sortBy === newSortBy && allSearchParams.order === "asc")
      newOrder = "desc"

    updateSearchParams({ newParams: { sortBy: newSortBy, order: newOrder } })
  }

  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null)

  const { equipmentTypes, equipmentCategories } = useCompanyContext()

  //sorting and filtering
  const filteredEquipmentTypes = useFilterEquipmentTypes({
    equipmentTypeListDisplay: equipmentTypes,
    calibrationRequired: allSearchParams.calibrationRequired || "both",
    selectedCategory: allSearchParams.category || "all",
    searchTerm,
  })

  const displayedEquipmentTypes = useSortEquipmentTypeList({
    equipmentTypes: filteredEquipmentTypes,
    sortBy: allSearchParams.sortBy,
    sortAsc: allSearchParams.order === "asc" ? true : false,
  })

  //table headers to display - reacts to the filter for calibration required
  const tableHeaderArray =
    allSearchParams.calibrationRequired !== "false"
      ? [
          { sortable: true, title: "name", changeSortByTo: "name" },
          { sortable: true, title: "ID", changeSortByTo: "id" },
          { sortable: true, title: "Category", changeSortByTo: "category" },
          {
            sortable: false,
            title: "Default Custody",
          },
          {
            sortable: false,
            title: "Calibration Frequency",
          },
          {
            sortable: false,
            title: "Calibration Form",
          },
        ]
      : [
          { sortable: true, title: "name", changeSortByTo: "name" },
          { sortable: true, title: "ID", changeSortByTo: "id" },
          { sortable: true, title: "Category", changeSortByTo: "category" },
          {
            sortable: false,
            title: "Default Custody",
          },
        ]

  return (
    <div>
      <div className="">
        <ListFilter
          filterTitle="Filter by calibration required:"
          filterOptions={["both", "true", "false"]}
          currentFilter={allSearchParams.calibrationRequired}
          changeFilter={(newFilter) =>
            updateSearchParams({
              newParams: { calibrationRequired: newFilter },
            })
          }
          transparent={true}
        />
        <ListFilter
          filterTitle="Filter by Category:"
          filterOptions={["all", ...equipmentCategories]}
          currentFilter={allSearchParams.category}
          changeFilter={(newFilter) =>
            updateSearchParams({ newParams: { category: newFilter } })
          }
        />

        {/* Equipment data table */}
        {displayedEquipmentTypes.length > 0 && (
          <div className="data-list-container">
            <Table striped hover bordered className="data-list-table break-60">
              <TableHeaderSortable
                thArray={tableHeaderArray}
                sortBy={allSearchParams.sortBy}
                sortAsc={allSearchParams.order === "asc" ? true : false}
                changeSortBy={setSortBy}
              />
              <tbody className="text-center ">
                {displayedEquipmentTypes.map((t) => (
                  <tr
                    key={t.prefix}
                    onClick={() => setSelectedEquipmentType(t)}
                    data-notification={t.notificationCount ? true : false}
                    onDoubleClick={() => {
                      navigate(
                        `/company/${companyId}/company-equipment/types/${t.prefix}`
                      )
                    }}
                    className={
                      selectedEquipmentType?.prefix === t.prefix ? "active" : ""
                    }
                  >
                    <td
                      data-notification={t.notificationCount ? true : false}
                      className="pe-5"
                    >
                      {t.name}{" "}
                      {t.notificationCount !== 0 && (
                        <span className="notification-marker">
                          {t.notificationCount ? t.notificationCount : ""}
                        </span>
                      )}
                    </td>
                    <td>{t.prefix}</td>
                    <td>{t.category}</td>
                    <td>
                      {t.defaultCustody
                        ? t.defaultCustody.displayName
                        : "unassigned"}
                    </td>
                    {allSearchParams.calibrationRequired !== "false" && (
                      <td>
                        {t.calibrationFrequency
                          ? `${t.calibrationFrequency} months`
                          : "N/A"}
                      </td>
                    )}
                    {allSearchParams.calibrationRequired !== "false" ? (
                      t.documentURL ? (
                        <td className="align-left">
                          <DownloadFileButton
                            className="ms-2 me-2"
                            docURL={t.documentURL}
                            fileName={`${t.name}_BlankCalibrationForm`}
                            buttonText=""
                            icon={true}
                          />
                          <ViewPDFButton docURL={t.documentURL} icon={true} />
                        </td>
                      ) : (
                        <td>
                          {allSearchParams.calibrationRequired !== "false"
                            ? "N/A"
                            : "No Form"}
                        </td>
                      )
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {displayedEquipmentTypes.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}

        {selectedEquipmentType && (
          <div className="d-flex mt-3 mb-3">
            <Link
              className="btn text-bg-secondary ms-2"
              to={`/company/${companyId}/company-equipment/types/${selectedEquipmentType.prefix}`}
            >
              Go to {selectedEquipmentType.name}
            </Link>

            <button
              className="btn text-bg-secondary ms-auto"
              onClick={() => setSelectedEquipmentType(null)}
            >
              Unselect
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
