import { useParams } from "react-router-dom"
import UserLoader from "./UserLoader"
import UserInfo from "./UserInfo"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"

export default function UserDetails() {
  const { userId } = useParams()

  return (
    <ContentOptionalRight
      content={
        <UserLoader userId={userId}>
          <UserInfo />
        </UserLoader>
      }
    />
  )
}
