import { useState } from "react"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { Modal, Button } from "react-bootstrap"
import { useChangeEquipmentType } from "../hooks/useChangeEquipmentType"
import { CheckboxInput } from "../../../components/basicFormComponents"

export default function EquipmentTypeDefaultCustodyModal({
  show,
  handleClose,
  type,
}) {
  const { user } = useAuthContext()
  const { companyData, equipmentTypes, equipmentListDisplay } =
    useCompanyContext()
  //form entry state
  const [formError, setFormError] = useState("")
  const { isPending, error, changeEquipmentTypeDefaultCustody } =
    useChangeEquipmentType()

  //only allow moderators, admins and primary, not users to have default custody
  const employeeList = formatSelectArray(
    companyData.employees.filter(
      (e) =>
        e.rights === "primary" ||
        e.rights === "admin" ||
        e.rights === "moderator"
    ),
    "displayName",
    companyData.employees[0]
  )
  const [newDefaultCustody, setNewDefaultCustody] = useState(null)
  const [changeAllEquipmentDefaults, setChangeAllEquipmentDefaults] =
    useState(false)
  const [
    changeAllCurrentCustodiesofOutOfService,
    setChangeAllCurrentCustodiesofOutOfService,
  ] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.admin)
      return setFormError("Must have admin rights to set default custodies")

    await changeEquipmentTypeDefaultCustody({
      companyId: companyData.companyId,
      equipmentTypes,
      type,
      newDefaultCustody: newDefaultCustody.value,
      changeAllEquipmentDefaults,
      changeAllCurrentCustodiesofOutOfService,
      equipmentPieces: equipmentListDisplay.filter(
        (eq) => eq.typePrefix === type.prefix
      ),
    })

    handleModalClose()
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Update Default Custody for {type.name} equipment type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.admin && (
            <p className="error">
              Must have admin rights to change the default custody of equipment.
            </p>
          )}

          <form className="" id="default-custody-form" onSubmit={handleSubmit}>
            <Select
              options={employeeList}
              value={
                newDefaultCustody ? newDefaultCustody : type.currentCustody
              }
              onChange={(option) => setNewDefaultCustody(option)}
            />

            <CheckboxInput
              label="Change Default custodies of all equipments of this type?"
              value={changeAllEquipmentDefaults}
              onChange={(e) => setChangeAllEquipmentDefaults((prev) => !prev)}
              horizontal={true}
            />

            <CheckboxInput
              label="Change actual custodies of all out of service equipment and unassigned equipment of this type?"
              value={changeAllCurrentCustodiesofOutOfService}
              onChange={(e) =>
                setChangeAllCurrentCustodiesofOutOfService((prev) => !prev)
              }
              horizontal={true}
            />
            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="default-custody-form"
            disabled={isPending}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
