import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuditingCompanyContext } from "../../hooks/useAuditingCompanyContext"
import { useAuditStatus } from "../../../audits/index"
import formatSelectArray from "../../../../util/formatSelectArray"
import Select from "react-select"
import { Button } from "react-bootstrap"

export default function AuditForm({ labCompanies }) {
  const navigate = useNavigate()
  const { auditingCompanyData } = useAuditingCompanyContext()
  const { createAuditRequest, error, isPending } = useAuditStatus()

  const [formError, setFormError] = useState(null)

  const companyOptions = formatSelectArray(
    labCompanies,
    "name",
    labCompanies[0]
  )
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [auditingStandard, setAuditingStandard] = useState("")

  const handleConfirm = async (e) => {
    e.preventDefault()
    if (!selectedCompany) return setFormError("no information")

    console.log(selectedCompany.value)
    console.log(auditingCompanyData)

    const {
      docId: auditId,
      error: createAuditError,
      success,
    } = await createAuditRequest({
      auditingCompany: {
        id: auditingCompanyData.companyId,
        name: auditingCompanyData.companyName,
        city: auditingCompanyData.city,
        state: auditingCompanyData.state,
        streetAddress: auditingCompanyData.streetAddress,
        streetAddress2: auditingCompanyData.streetAddress2,
        zipCode: auditingCompanyData.zipCode,
        timezone: auditingCompanyData.timezone,
        companyPhone: auditingCompanyData.companyPhone,
      },
      labCompany: selectedCompany.value,
      standard: auditingStandard,
    })

    if (error || createAuditError || !success)
      return setFormError(error || createAuditError || "not successful")

    //navigate to the audit page.
    navigate(`/lab-audit/${auditId}`)
  }

  return (
    <div>
      <h2>Select the eLab Tracker Lab Client to Audit</h2>
      <form onSubmit={handleConfirm}>
        <fieldset className="mt-4">
          <legend>Lab Company</legend>
          <Select
            options={companyOptions}
            value={selectedCompany}
            onChange={(option) => setSelectedCompany(option)}
          />
          <label>
            Auditing Standard:
            <input
              type="text"
              value={auditingStandard}
              onChange={(e) => setAuditingStandard(e.target.value)}
            />
          </label>
        </fieldset>

        <fieldset className="mt-4">
          <legend>Confirm Information</legend>
          <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
            Name:
            <input
              disabled
              type="text"
              value={selectedCompany ? selectedCompany.value.name : ""}
            />
          </label>
          <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
            Location:
            <input
              disabled
              type="text"
              value={
                selectedCompany
                  ? `${selectedCompany.value.city || ""}, ${
                      selectedCompany.value.state
                    }`
                  : ""
              }
            />
          </label>
          <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
            Contact name:
            <input
              disabled
              type="text"
              value={
                selectedCompany
                  ? selectedCompany.value.primaryAccount.displayName
                  : ""
              }
            />
          </label>
          <label className="d-flex gap-3 ms-5 text-nowrap align-items-baseline">
            Contact email:
            <input
              disabled
              type="text"
              value={
                selectedCompany
                  ? selectedCompany.value.primaryAccount.email
                  : ""
              }
            />
          </label>
        </fieldset>
        {formError && <p className="error">{formError}</p>}
        <Button variant="primary" type="submit">
          Information Confirmed
        </Button>
      </form>
    </div>
  )
}
