import { useEffect, useState } from "react"
import { useAuditorSetup } from "../../hooks/useAuditorSetup"
import PersonalAccountFormForAuditor from "./PersonalAccountFormForAuditor"
import Select from "react-select"
import formatSelectArray from "../../util/formatSelectArray"
import { Button } from "react-bootstrap"

const INITIAL_DATA = {
  displayName: "",
  accountEmail: "",
  accountPassword: "",
  accountConfirmPassword: "",
  agreedToAuditorTerms: false,
  signedNameForAgreement: "",
}

export default function AddAuditingAccountToExistingAuditingCompanyForm() {
  const [formError, setFormError] = useState("")
  const [data, setData] = useState(INITIAL_DATA)
  const {
    error,
    isPending,
    getAuditingCompanies,
    createAuditor,
    addEmployeeToAuditingCompany,
  } = useAuditorSetup()
  const [auditingCompanies, setAuditingCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)

  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")

    await handleSubmitThisForm({
      setFormError,
      ...data,
      selectedCompany: selectedCompany ? selectedCompany.value : null,
      createAuditor,
      addEmployeeToAuditingCompany,
    })
  }
  //   get auditing companies
  useEffect(() => {
    getAuditingCompanies().then((list) => {
      setAuditingCompanies(formatSelectArray(list, "name", { ...list[0] }))
    })
  }, [])

  //getting list of auditing companies
  if (isPending && auditingCompanies.length === 0) {
    return <p className="loading">...Loading</p>
  }

  //if cannot get auditing companies list.
  if ((!isPending && auditingCompanies.length === 0) || error) {
    return (
      <div className="error">
        <p>
          No auditing companies found. There may have been an error. Please
          cancel and try by signing up with a new auditing company.
        </p>
        {error && <p>{error}</p>}
      </div>
    )
  }

  //intended render
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PersonalAccountFormForAuditor {...data} updateFields={updateFields}>
          {" "}
          <p>Select your company:</p>
          <Select
            options={auditingCompanies}
            className="mb-4"
            value={selectedCompany}
            onChange={(option) => setSelectedCompany(option)}
          />
        </PersonalAccountFormForAuditor>

        <Button variant="primary" type="submit">
          Submit
        </Button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}

//handle form submit
const handleSubmitThisForm = async ({
  setFormError,
  displayName,
  accountEmail,
  accountPassword,
  accountConfirmPassword,
  agreeToAuditorTerms,
  signedNameForAgreement,
  selectedCompany,
  createAuditor,
  addEmployeeToAuditingCompany,
}) => {
  if (!selectedCompany)
    return setFormError(
      "you did not select an existing auditing company. Please select one from the dropdown or cancel and sign up with a new auditing company account."
    )
  if (!agreeToAuditorTerms) return setFormError("You must agree to the terms")
  if (accountConfirmPassword !== accountPassword)
    return setFormError("Passwords do not match")

  //does the email match the companies required email criteria?
  if (
    !accountEmail
      .trim()
      .toLowerCase()
      .includes(selectedCompany.emailVerificationText.trim().toLowerCase())
  )
    return setFormError(
      `Your email does not match the expected email for this company. Please either set up your account with a new company or contact your companies admin, ${selectedCompany.primaryAccount.displayName}, to help resolve the issue.`
    )
  //   console.log("no errors")
  // create the client user account
  const { user } = await createAuditor({
    displayName,
    email: accountEmail,
    password: accountPassword,
    auditingAdmin: false,
    auditingCompanyName: selectedCompany.name,
    auditingCompanyId: selectedCompany.id,
    agreeToAuditorTerms,
    signedNameForAuditorAgreementofTermsAndConditions: signedNameForAgreement,
  })

  await addEmployeeToAuditingCompany({
    auditingCompanyId: selectedCompany.id,
    userObject: {
      displayName,
      email: accountEmail,
      rights: "admin",
      uid: user.uid,
      auditingAdmin: false,
      isAuditor: true,
    },
  })
}
