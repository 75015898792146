import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import {
  useCompanyContext,
  useInvoicing,
  useServiceAgreement,
} from "../../index"

import { addDays, format } from "date-fns"
import { formatUSD } from "../../../../util/formatCurrency"

import { Table } from "react-bootstrap"
import PriceCalculator from "../../../../components/PriceCalculator/PriceCalculator"

export default function CurrentStatus({ billingData, serviceData }) {
  const { companyId } = useParams()
  const { companyData, equipmentList } = useCompanyContext()
  const { getQuantitiesUsed } = useServiceAgreement()
  const quantitiesUsed = getQuantitiesUsed({ companyData, equipmentList })

  //getting the overage fees
  const tableData = formatUsage(
    serviceData,
    billingData.paymentPlan,
    quantitiesUsed
  )
  const { overageSubtotal } = getTotals({
    subtotals: tableData.map((data) => data.overageFee),
    serviceData,
    paymentPlan: billingData.paymentPlan,
  })

  return (
    <div>
      {/* UnpaidInvoiceInfo defined below */}
      <UnpaidInvoiceInfo />

      <hr className="mt-4" />
      <div>
        <h3 className="mb-2">Next Subscription Invoice:</h3>
        <div className="d-flex justify-content-around">
          <p>
            <span className="fw-bold me-2">Amount Due: </span>
            {formatUSD(
              billingData.paymentPlan === "monthly"
                ? serviceData.monthlyFee
                : serviceData.annualFee
            )}
          </p>
          <p>
            <span className="fw-bold me-2">Issue Date:</span>
            {format(
              billingData.nextSubscriptionInvoiceDate.toDate(),
              "MM-dd-yyyy"
            )}
          </p>
        </div>
      </div>
      <hr className="mt-4" />
      <div className="mt-4 mb-4">
        <h3>Cost of Current Usage</h3>
        <PriceCalculator
          showForm={false}
          showSetup={false}
          companyData={companyData}
          equipmentList={equipmentList}
          paymentPlan={billingData.paymentPlan}
          serviceId={serviceData.id}
        />
        <div className="mt-3">
          <p>
            Overages are charged on the first of every month, based on your
            current overages,{" "}
            {overageSubtotal !== 0 && (
              <span className="fw-bold">
                an invoice for {formatUSD(overageSubtotal)} will be issued on{" "}
                {format(
                  billingData.nextOverageInvoiceDate.toDate(),
                  "MM/dd/yyyy"
                )}
                .
              </span>
            )}
            {overageSubtotal === 0 && (
              <span className="fw-bold">you will not recieve an invoice.</span>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

//format the useage in a way that can be displayed correctly in a table
const formatUsage = (serviceData, paymentPlan, quantitiesUsed) => {
  //   console.log(serviceData)
  return quantitiesUsed.map((use) => {
    // console.log(use)
    const maximum = serviceData[use.propIdForIncluded]
    const remaining =
      maximum - use.quantityUsed > 0 ? maximum - use.quantityUsed : 0
    const overage = remaining <= 0 ? Math.abs(maximum - use.quantityUsed) : 0
    const overageRate =
      paymentPlan === "monthly"
        ? serviceData[use.propIdForRate][paymentPlan]
        : serviceData[use.propIdForRate][paymentPlan] / 12
    const overageRateText = `${formatUSD(overageRate)} / ${
      serviceData[use.propIdForRate]["lotSize"] === 1
        ? "ea"
        : serviceData[use.propIdForRate]["lotSize"]
    }`
    const overageFee =
      Math.ceil(overage / serviceData[use.propIdForRate].lotSize) * overageRate

    return {
      ...use,
      remaining,
      maximum,
      overage,
      overageRate,
      overageRateText,
      overageFee,
    }
  })
}

const getTotals = ({ subtotals, serviceData, paymentPlan }) => {
  let overageSubtotal = subtotals.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue
  }, 0)
  const total =
    paymentPlan === "monthly"
      ? overageSubtotal + serviceData.monthlyFee
      : overageSubtotal + serviceData.annualFee

  return { overageSubtotal, total }
}

const UnpaidInvoiceInfo = () => {
  const { companyId } = useParams()
  const [invoices, setInvoices] = useState([])
  const [totalDue, setTotalDue] = useState(0)
  const [dueDate, setDueDate] = useState("N/A")
  const { error, isPending, getInvoices } = useInvoicing()
  useEffect(() => {
    getInvoices({ companyId }).then((res) => {
      const openInvoices = res.filter(
        (invoice) => invoice.stripeInvoiceStatus === "open"
      )
      formatInvoicesForTable({
        invoices: openInvoices,
        setInvoices,
        setTotal: setTotalDue,
        setDueDate,
      })
    })
  }, [])

  if (invoices.length === 0 && isPending)
    return <p className="loading">loading...</p>
  if (error) return <p className="error">Something went wrong: {error}</p>

  return (
    <div>
      {/* Outstanding Invoices Tablel */}
      {invoices.length === 0 && (
        <h3 className="mt-3">No outstanding invoices. Thank you!</h3>
      )}
      {invoices.length > 0 && (
        <div className="mt-3">
          <h3 className="mb-3">Unpaid Invoices:</h3>
          <div className="d-flex justify-content-around">
            <p>
              <span className="fw-bold me-2">Amount Due: </span>
              {formatUSD(totalDue)}
            </p>
            <p>
              <span className="fw-bold me-2">Due Date:</span>
              {dueDate === "N/A" ? dueDate : format(dueDate, "MM-dd-yyyy")}
            </p>
          </div>
          <Table
            striped
            hover
            bordered
            className="data-list-table"
            size="md"
            responsive
          >
            <thead className="text-center">
              <tr>
                <th>Pay</th>
                <th>Issue Date</th>
                <th>Due Date</th>
                <th>Sent To</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="text-center text-nowrap">
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td>
                    <a href={invoice.url} target="_blank">
                      Go To Bill
                    </a>
                  </td>
                  <td>{format(invoice.date, "MM-dd-yyyy")}</td>
                  <td>{format(invoice.dateDue, "MM-dd-yyyy")}</td>
                  <td>{invoice.sentTo}</td>
                  <td>{formatUSD(invoice.amount)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  )
}

const formatInvoicesForTable = ({
  invoices,
  setInvoices,
  setTotal,
  setDueDate,
}) => {
  if (invoices.length === 0) return

  let total = 0
  let dates = []
  let newInvoices = invoices.map((invoice) => {
    let amount = 0
    invoice.items.forEach((item) => {
      let quantity = typeof item.quantity === "undefined" ? 1 : item.quantity
      amount += (item.amount * quantity) / 100
    })
    total += amount
    let color = null
    let dateDue =
      typeof invoice.daysUntilDue == "undefined"
        ? addDays(invoice.createdAt.toDate(), 7)
        : addDays(invoice.createdAt.toDate(), invoice.daysUntilDue)
    dates.push(dateDue)
    return {
      amount,
      date: invoice.createdAt.toDate(),
      dateDue,
      sentTo: invoice.email,
      status: invoice.stripeInvoiceStatus,
      url: invoice.stripeInvoiceUrl,
      id: invoice.stripeInvoiceId,
    }
  })
  setDueDate(dates.sort()[0])
  setInvoices(newInvoices)
  setTotal(total)
  return
}
