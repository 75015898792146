import React from "react"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
import AuditFormLoader from "./AuditFormLoader"
import AuditForm from "./AuditForm"

export default function AuditRequest() {
  return (
    <ContentOptionalRight
      content={
        <AuditFormLoader>
          <AuditForm />
        </AuditFormLoader>
      }
    />
  )
}
