import { useEffect, useRef, useState } from "react"

//components
import { Button } from "react-bootstrap"
import { BasicFormBody } from "../../../components/basicFormComponents"
import makeAnimated from "react-select/animated"
import { Link } from "react-router-dom"
import { useCompanyContext } from "../../company"
import EquipmentTypeCalibrationStatusReportLoader from "../reports/EquipmentTypeCalibrationStatusReport/EquipmentTypeCalibrationStatusReportLoader"
import EquipmentTypeCalibrationStatusReportRender from "../reports/EquipmentTypeCalibrationStatusReport/EquipmentTypeCalibrationStatusReportRender"
import formatSelectArray from "../../../util/formatSelectArray"
import removeDuplicatesInArray from "../../../util/removeDuplicatesInArray"

const FORMDATA = {
  sortBy: { label: "Type Name", value: "type.name" },
  order: "asc",
  includeOverdue: true,
  includeUpcoming: false,
  includeUpToDate: false,
  includedTypes: [],
}

export default function EquipmentTypeCalibrationStatusFormPage() {
  const { isAuditView, equipmentTypes } = useCompanyContext()

  const typeOptions = formatSelectArray(
    equipmentTypes.filter(
      (t) => t.calibrationRequired || t.calibrationFrequency
    ),
    "name",
    {
      name: "",
      prefix: "",
    }
  )
  //this is used for the react-select report for equipment types - makes it to where the selkected options disappear from the list and get re-added if they are removed from the selection.
  const animatedComponents = makeAnimated()

  const [formData, setFormData] = useState(FORMDATA)
  const [formError, setFormError] = useState(null)
  const [displayReport, setDisplayReport] = useState(false)
  const reportRef = useRef(null)

  const updateFormData = (updateObject) => {
    setFormData((prev) => {
      return { ...prev, ...updateObject }
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(null)
    // console.log("submitted")
    if (
      !formData.includeOverdue &&
      !formData.includeUpcoming &&
      !formData.includeUpToDate
    )
      return setFormError(
        "Must include at least one cvalibration status in your report."
      )
    if (formData.includedTypes.length === 0)
      return setFormError(
        "You must select at least one EquipmentType to include."
      )
    // console.log(formData)

    setDisplayReport(true)

    //handle scroll
    setTimeout(() => {
      reportRef.current.scrollIntoView({ behavior: "smooth" })
    }, 200)
  }

  useEffect(() => {
    setDisplayReport(false)
  }, [formData])

  if (isAuditView)
    return <p className="error">This report is not yet available in audits</p>

  return (
    <>
      <h1>Equipment Needing Calibrated Report</h1>
      <p>
        <Link to="./../">...back to reports list</Link>
      </p>
      <form onSubmit={handleSubmit}>
        Equipment to Include:
        <BasicFormBody
          formData={[
            {
              type: "select",
              isMulti: true,
              components: animatedComponents,
              onChange: (newValue, actionMeta) => {
                const uniqueTypesTypesToInclude = removeDuplicatesInArray(
                  newValue,
                  "label"
                )
                updateFormData({ includedTypes: uniqueTypesTypesToInclude })
              },
              label: "Equipment Types",
              options: typeOptions,
              name: "equipmentTypes",
              closeMenuOnSelect: false,
            },
            {
              type: "checkbox",
              label: "Overdue",
              horizontal: true,
              value: formData.includeOverdue,
              defaultChecked: formData.includeOverdue,
              onChange: (e) =>
                updateFormData({ includeOverdue: e.target.checked }),
            },
            {
              type: "checkbox",
              label: "Upcoming (this calendar month)",
              horizontal: true,
              value: formData.includeUpcoming,
              defaultChecked: formData.includeUpcoming,
              onChange: (e) =>
                updateFormData({ includeUpcoming: e.target.checked }),
            },
            {
              type: "checkbox",
              label: "Up to date",
              horizontal: true,
              value: formData.includeUpToDate,
              defaultChecked: formData.includeUpToDate,
              onChange: (e) =>
                updateFormData({ includeUpToDate: e.target.checked }),
            },
          ]}
          columns={2}
        >
          {formError && <p className="error">{formError}</p>}
        </BasicFormBody>
        <Button type="submit" variant="primary">
          Run Report
        </Button>
      </form>

      {/* Report */}
      <hr />
      {displayReport && (
        <div ref={reportRef} className="mb-4">
          <EquipmentTypeCalibrationStatusReportLoader options={formData}>
            <EquipmentTypeCalibrationStatusReportRender />
          </EquipmentTypeCalibrationStatusReportLoader>
        </div>
      )}
    </>
  )
}
