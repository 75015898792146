import React from "react"
import { useCompanyContext } from "../../../company"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function EvaluationUserList({ evaluationName, evalData }) {
  const { isAuditView, documentsOverMaxPermitted } = useCompanyContext()

  if (isAuditView)
    return <AuditView evaluationName={evaluationName} evalData={evalData} />

  return (
    <LabView
      evaluationName={evaluationName}
      evalData={evalData}
      documentsOverMaxPermitted={documentsOverMaxPermitted}
    />
  )
}
