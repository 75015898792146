import React from "react"
import PersonalAccountForm from "../../components/PersonalAccountForm"
import TermaAndConditionsForAuditorJSX from "../../components/legal/TermsAndConditionsForAuditorJSX"
import { CheckboxInput, TextInput } from "../../components/basicFormComponents"

export default function PersonalAccountFormForAuditor({
  displayName,
  accountEmail,
  accountPassword,
  accountConfirmPassword,
  updateFields,
  signedNameForAgreement,
  children,
}) {
  return (
    <PersonalAccountForm
      displayName={displayName}
      accountEmail={accountEmail}
      accountPassword={accountPassword}
      accountConfirmPassword={accountConfirmPassword}
      updateFields={updateFields}
    >
      {children}
      <div>
        <p>
          Please review our terms and conditions below and accept them before
          continuing with the account setup.
        </p>
        {accountPassword !== "" &&
          accountConfirmPassword !== "" &&
          accountPassword === accountConfirmPassword && (
            <p className="text-muted text-center">Passwords Match</p>
          )}
        {accountPassword !== "" &&
          accountConfirmPassword !== "" &&
          accountPassword !== accountConfirmPassword && (
            <p className="error text-center">Passwords Do Not Match</p>
          )}

        <article className="legal-terms">
          <TermaAndConditionsForAuditorJSX />
          <p className="mt-4">
            Signing below also indicates you have read and agreed to eLab
            Tracker's{" "}
            <span
              className="clickable text-muted text-decoration-underline"
              onClick={() => window.open("/legal/privacy")}
            >
              Privacy Policy
            </span>
          </p>
          <CheckboxInput
            label="The undersigned agrees to the terms and conditions contained herein"
            horizontal={true}
            onChange={(e) => {
              updateFields({ agreedToAuditorTerms: e.target.checked })
            }}
          />

          <TextInput
            label="Name"
            horizontal={true}
            required={true}
            value={signedNameForAgreement}
            onChange={(e) =>
              updateFields({ signedNameForAgreement: e.target.value })
            }
          />
        </article>
      </div>
    </PersonalAccountForm>
  )
}
