import { useState, useRef, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useFirestore } from "../../../hooks/useFirestore"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { statesAbbr } from "../../../lib/states"
import { functions } from "../../../firebase/config"
import { httpsCallable } from "firebase/functions"
//components
import { Button, Table } from "react-bootstrap"
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
import InfoIcon from "../../../components/basicComponents/InfoIcon"
import LogoCropperModal from "../../../components/LogoCropperModal"
import {
  CheckboxInput,
  TextInput,
} from "../../../components/basicFormComponents/index"

export default function CompanySettings({ companyData }) {
  const { companyId } = useParams()
  const {
    updateDocument,
    error: fireStoreError,
    isPending: fireStorePending,
  } = useFirestore()
  const stateOptions = formatSelectArray(statesAbbr)

  //logo modal control
  const [showLogoCropperModal, setShowLogoCropperModal] = useState(false)
  const logoCropperModalShow = () => setShowLogoCropperModal(true)
  const logoCropperModalHide = () => setShowLogoCropperModal(false)

  //address data control
  const streetAddressRef = useRef()
  const cityRef = useRef()
  const [state, setState] = useState(null)
  const zipRef = useRef()
  const [timezone, setTimezone] = useState({ label: "EST", value: "EST" }) //TODO: hook up to a Select component

  useSetupInitialValues({
    streetAddressRef,
    cityRef,
    setState,
    zipRef,
    setTimezone,
    companyData,
  })

  //notification settings control
  const [equipmentNotificationFrequency, setEquipmentNotificationFrequency] =
    useState(companyData.equipmentNotificationFrequency)
  const [evaluationNotificationFrequency, setEvaluationNotificationFrequency] =
    useState(companyData.evaluationNotificationFrequency)

  //update anything that changed on the page other than the company logo
  const handleUpdateSettings = async () => {
    console.log("updating settings")

    const updateObject = {
      streetAddress: streetAddressRef.current.value,
      city: cityRef.current.value,
      zipCode: zipRef.current.value,
      state: state.value,
      timezone: timezone.value,
      equipmentNotificationFrequency,
      evaluationNotificationFrequency,
    }

    await updateDocument(`companies/${companyId}`, updateObject)
    if (!fireStoreError) alert("update successful")
  }

  //   *****TEMPORARTY, for testing Notifications****//
  //   const checkCompany = httpsCallable(functions, "startCompanyCheck")
  //   const checkCompanyEquipment = httpsCallable(
  //     functions,
  //     "startCompanyEquipmentCheck"
  //   )
  //   const checkCompanyEvals = httpsCallable(
  //     functions,
  //     "startCompanyEvaluationCheck"
  //   )

  //   const handleClick = async () => {
  //     console.log("checking company equipment")
  //     await checkCompanyEvals({ companyId })
  //     console.log("company equipment Checked")
  //   }
  // ***** Temporary for testing updating Storage file Metadata
  //   const updateMetadata = httpsCallable(
  //     functions,
  //     "changeAllPDFMetaData-changeAllPDFMetaData"
  //   )

  //   const handleClick = async () => {
  //     console.log("updating meta data")
  //     await updateMetadata()
  //     console.log("metadata updated")
  //   }
  //**** End Testing */

  return (
    <>
      <ContentOptionalRight
        contentTitle="Company Settings"
        content={
          <>
            <div className="display-info container">
              <div className="row">
                <div className="company-name col-12 col-md-6">
                  <p>
                    <span className="fw-bold me-3">Company Name: </span>
                    {companyData.companyName}
                  </p>
                </div>

                {/* company logo block */}
                <div className="company-logo col-12 col-md-6 mt-4 mt-md-0 align-flex-end">
                  <div className="d-flex">
                    <p className="me-1">Company Logo</p>
                    <InfoIcon infoTitle="Change Navbar Logo" />
                  </div>
                  <div
                    className="company-logo-container mb-4"
                    style={{
                      position: "relative",
                      zIndex: "1",
                    }}
                  >
                    {companyData.navLogoURL && (
                      <img
                        className="nav-logo-img"
                        src={companyData.navLogoURL}
                        alt={companyData.companyName}
                      />
                    )}
                    {(!companyData.navLogoURL ||
                      typeof companyData.navLogoURL === "undefined") && (
                      <h1 className="nav-logo-img">
                        {companyData.companyName}
                      </h1>
                    )}

                    {/* edit logo button */}
                    <Button
                      onClick={() => setShowLogoCropperModal(true)}
                      variant="primary"
                    >
                      Edit
                    </Button>
                  </div>
                </div>
                {/* end logo section */}
              </div>
            </div>

            {/* address info section */}
            <div className="address-info container">
              <div className="row">
                <label className="address-label col-12 col-md-6 pe-4">
                  Street Address
                  <input className="ps-1" type="text" ref={streetAddressRef} />
                </label>
                <label className="address-label col-12 col-md-6 pe-4">
                  City <input className="ps-1" type="text" ref={cityRef} />
                </label>
              </div>
              <div className="row">
                <label className="address-label col-12 col-md-6 pe-4">
                  State{" "}
                  <Select
                    options={stateOptions}
                    className="ps-1"
                    onChange={(option) => setState(option)}
                    value={state}
                  />
                </label>
                <label className="address-label col-12 col-md-6 pe-4">
                  Zip Code{" "}
                  <input
                    className="ps-1"
                    type="text"
                    ref={zipRef}
                    pattern="[0-9]{5}"
                    title="Must be 5 digits"
                    minLength={5}
                    maxLength={5}
                  />
                </label>
              </div>
            </div>

            <hr />

            {/* Position section */}
            <div className="customizable-section">
              <div className="d-flex">
                <h2 className="me-2">Available Positions</h2>
                <InfoIcon infoTitle="Positions that can Evaluate" />
              </div>
              <div className="position-section">
                <PositionSection
                  companyId={companyId}
                  availablePositions={companyData.availablePositions}
                />
              </div>
            </div>

            <hr />

            <div className="notification-section mt-3">
              <div className="d-flex">
                <h2 className="me-2">User Notifications</h2>
                <InfoIcon infoTitle="Notification Frequency" />
              </div>

              <div className="notification-options">
                <h5>Equipment Notification Frequency</h5>
                <div className="d-flex justify-content-around">
                  <div className="d-flex">
                    <input
                      type="radio"
                      value="daily"
                      checked={equipmentNotificationFrequency === "daily"}
                      name="equipmentNotificationFrequency"
                      onChange={(e) =>
                        setEquipmentNotificationFrequency(e.target.value)
                      }
                    />
                    <span className="ms-3">Daily</span>
                  </div>
                  <div className="d-flex">
                    <input
                      type="radio"
                      value="weekly"
                      checked={equipmentNotificationFrequency === "weekly"}
                      onChange={(e) =>
                        setEquipmentNotificationFrequency(e.target.value)
                      }
                      name="equipmentNotificationFrequency"
                    />
                    <span className="ms-3">Weekly</span>
                  </div>
                </div>
              </div>

              <div className="notification-options mt-4">
                <h5>Evaluation Notification Frequency</h5>
                <div className="d-flex justify-content-around">
                  <div className="d-flex">
                    <input
                      type="radio"
                      value="daily"
                      checked={evaluationNotificationFrequency === "daily"}
                      name="evaluationNotificationFrequency"
                      onChange={(e) =>
                        setEvaluationNotificationFrequency(e.target.value)
                      }
                    />
                    <span className="ms-3">Daily</span>
                  </div>
                  <div className="d-flex">
                    <input
                      type="radio"
                      value="weekly"
                      checked={evaluationNotificationFrequency === "weekly"}
                      onChange={(e) =>
                        setEvaluationNotificationFrequency(e.target.value)
                      }
                      name="evaluationNotificationFrequency"
                    />
                    <span className="ms-3">Weekly</span>
                  </div>
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              className="mt-3 me-4"
              onClick={handleUpdateSettings}
              disabled={fireStorePending}
            >
              {" "}
              Update Settings
            </Button>
          </>
        }
      />

      {/* Modals */}
      {showLogoCropperModal && (
        <LogoCropperModal
          show={logoCropperModalShow}
          handleClose={logoCropperModalHide}
          navLogoURL={companyData.navLogoURL}
          storageUploadPath={`companies/${companyData.companyId}/companyNavLogo`}
          firestoreDocumentPath={`companies/${companyData.companyId}`}
        />
      )}

      {/* FOR TESTING */}
      {/* NOTIFICATIONS */}
      {/* {companyId === "davlab" && (
        <div>
          This button notifies users of overdue things, this is be done daily
          or weekly automatically. This button is for testing notifications
          <Button variant="primary" onClick={handleClick}>
            Check CompanyCheck cloud function
          </Button>
        </div>
      )} */}
      {/* END NOTIFICAITONS */}
      {/* METADATA */}
      {/* {companyId === "dav-lab" && (
        <div>
          This button changes the metadata of all pdfs in storage for ALL
          company clients to contentType: application/pdf
          <Button variant="primary" onClick={handleClick}>
            Update Metadata
          </Button>
        </div>
      )} */}
      {/* END OF METADATA */}
      {/* END FOR TESTING */}
    </>
  )
}

const useSetupInitialValues = ({
  streetAddressRef,
  cityRef,
  setState,
  zipRef,
  setTimezone,
  companyData,
}) => {
  useEffect(() => {
    streetAddressRef.current.value =
      typeof companyData.streetAddress !== "undefined"
        ? companyData.streetAddress
        : ""
    cityRef.current.value =
      typeof companyData.city !== "undefined" ? companyData.city : ""
    setState(() => {
      return typeof companyData.state !== "undefined"
        ? { label: companyData.state, value: companyData.state }
        : ""
    })
    zipRef.current.value =
      typeof companyData.zipCode !== "undefined" ? companyData.zipCode : ""

    //timezone may be different since it is a selection type
  }, [])
}

const PositionSection = ({ companyId, availablePositions }) => {
  const [formError, setFormError] = useState("")
  const [addingPosition, setAddingPosition] = useState(false)
  const INITIAL_DATA = { title: "", description: "", canEvaluate: false }
  const [position, setPosition] = useState(INITIAL_DATA)
  const { isPending, error, updateDocument } = useFirestore()

  const updateFields = (fields) => {
    setPosition((prev) => {
      return { ...prev, ...fields }
    })
  }

  const handleAddPosition = async () => {
    setFormError("")
    if (
      availablePositions.find(
        (p) =>
          p.title.trim().toLowerCase() === position.title.trim().toLowerCase()
      )
    ) {
      return setFormError("This position is already in use.")
    }
    await updateDocument(`companies/${companyId}`, {
      availablePositions: [...availablePositions, { ...position }],
    })
    handleCancel()
  }

  const handleDeletePosition = async (title) => {
    setFormError("")
    console.log(title)
    const newPositionList = availablePositions.filter(
      (pos) => pos.title !== title
    )
    await updateDocument(`companies/${companyId}`, {
      availablePositions: newPositionList,
    })
  }

  const handleCancel = () => {
    setAddingPosition(false)
    setPosition(INITIAL_DATA)
  }

  return (
    <>
      {availablePositions.length === 0 && <p>No Positions Created</p>}
      {availablePositions.length !== 0 && (
        <Table striped hover bordered size="md">
          <thead className="fw-bold text-center">
            <td colSpan={1}>Title</td>
            <td colSpan={5}>Description</td>
            <td colSpan={1}>Can Evaluate</td>
            <td colSpan={1}>Delete</td>
          </thead>
          <tbody>
            {availablePositions.map((pos) => (
              <tr className="text-center" key={pos.title}>
                <td colSpan={1}>{pos.title}</td>
                <td colSpan={5}>{pos.description}</td>
                <td colSpan={1}>{pos.canEvaluate ? "yes" : "no"}</td>
                <td
                  colSpan={1}
                  className="clickable text-muted text-decoration-underline"
                  onClick={() => handleDeletePosition(pos.title)}
                >
                  Delete
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {!addingPosition && (
        <Button onClick={() => setAddingPosition(true)}>Add Position</Button>
      )}
      <AddPositionForm
        show={addingPosition}
        isPending={isPending}
        handleCancel={handleCancel}
        addPosition={handleAddPosition}
        updateFields={updateFields}
        data={position}
      />
      {formError && <p className="error">{formError}</p>}
    </>
  )
}

const AddPositionForm = ({
  show,
  handleCancel,
  data,
  isPending,
  addPosition,
  updateFields,
}) => {
  if (!show) return <p></p>
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          addPosition()
        }}
        id="add-position-form"
      >
        <div className="d-flex flex-wrap">
          <TextInput
            label="title"
            value={data.title}
            onChange={(e) => updateFields({ title: e.target.value })}
          />
          <TextInput
            label="description"
            value={data.description}
            onChange={(e) => updateFields({ description: e.target.value })}
          />
          <CheckboxInput
            label="can this position evaluate employees?"
            value={data.canEvaluate}
            onChange={(e) => updateFields({ canEvaluate: !data.canEvaluate })}
            horizontal
          />
        </div>
      </form>
      <div className="d-flex">
        <Button
          variant="primary"
          className="mt-2 me-5"
          type="submit"
          form="add-position-form"
        >
          Submit
        </Button>
        <Button
          disabled={isPending}
          onClick={handleCancel}
          variant="secondary"
          className="mt-2"
        >
          Cancel
        </Button>
      </div>
    </>
  )
}
