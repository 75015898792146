import { useParams } from "react-router-dom"
import { useCompanyContext } from "../../../company/index"
import { useAuthContext } from "../../../authentication"
import { useStorage } from "../../../../hooks/useStorage"

import { EvaluationUserList, useGetEmployeeEvaluationData } from "../../index"
import DocumentList from "../../../../components/DocumentList"
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"

export default function EvaluationType() {
  const { evaluationId } = useParams()
  const { user } = useAuthContext()
  const { companyData, documentsOverMaxPermitted, evaluationList } =
    useCompanyContext()

  const evaluation = evaluationList.find((e) => e.name === evaluationId)

  const {
    downloadPDF,
    isPending: storagePending,
    error: storageError,
  } = useStorage()

  //getting the data from companyEmployees and those who have been evaluated
  const { usersEvaluationData: usersEvalData } = useGetEmployeeEvaluationData(
    companyData,
    evaluation
  )

  //adding Documents funtionality
  const handleDownload = async (doc) => {
    const fileName = `${evaluation.name}_Evaluation_${doc.name}`
    await downloadPDF(doc.documentURL, `${fileName}.pdf`)
  }

  const handleUpload = () => {
    return
  }

  const handleDelete = () => {
    return
  }

  //render loading while fetching evaluation docData from firestore
  if (!evaluation) return <p className="pending">Loading...</p>

  return (
    <ContentOptionalRight
      contentTitle={
        <div className="d-flex">
          <div className="me-5">
            Evaluation - <span className="fw-bold">{evaluation.name}</span>{" "}
            <span className="text-muted">({evaluation.category})</span>
          </div>
        </div>
      }
      content={
        <>
          {/* Changable data -can probably be changed to refs instead of state */}
          <div className="mb-5">
            <p className="mb-3 d-flex">
              <span>Performed as per: {evaluation.standard}</span>
            </p>

            <hr />

            <DocumentList
              listTitle="Related Documents"
              buttonText="Add a document"
              documents={evaluation.docs}
              rightsToAdd={false}
              rightsToDelete={false}
              handleUpload={handleUpload}
              handleDownload={handleDownload}
              handleDelete={handleDelete}
              documentsOverMaxPermitted={documentsOverMaxPermitted}
            />
          </div>
          <h2 className="section-header">
            Users required to be evaluated on {evaluation.name}
          </h2>
          <EvaluationUserList
            evaluationName={evaluation.name}
            evalData={usersEvalData}
            documentsOverMaxPermitted={documentsOverMaxPermitted}
          />
        </>
      }
    />
  )
}
