import { PDFViewer, Document, StyleSheet } from "@react-pdf/renderer"
import React from "react"
import ReportHeader from "./ReportHeader"
import ReportFooter from "./ReportFooter"

// Create styles
const styles = StyleSheet.create({
  documentContainer: {
    position: "relative",
    margin: "auto",
    top: "0",
    left: "5%",
    height: "75dvh",
    width: "90%",
    borderRadius: "10px",
  },
})

export default function ReportTemplate({
  children,
  title = undefined,
  pageMode = "fullScreen",
  pageLayout = "singlePage",
  creator = "eLab Tracker",
}) {
  return (
    <PDFViewer style={styles.documentContainer}>
      <Document
        title={title}
        pageMode={pageMode}
        pageLayout={pageLayout}
        creator={creator}
        producer="eLab Tracker"
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, null)
          }
          return child
        })}
      </Document>
    </PDFViewer>
  )
}
