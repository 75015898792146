import { useRef } from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../authentication"
import { useEvaluation } from "../index"

import { Modal, Button } from "react-bootstrap"

export default function ChangeFrequencyOfEvaluationModal({
  show,
  handleClose,
  companyId,
  evaluation,
  userToChange,
}) {
  const { user } = useAuthContext()
  const { error, isPending, setUserFrequencyOfEvaluation } = useEvaluation()

  const newFrequency = useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!user.moderator) {
      handleClose()
      alert("You do not have moderator rights.")
      return
    }

    await setUserFrequencyOfEvaluation({
      companyId,
      evaluationId: evaluation.id,
      userId: userToChange.uid,
      newFrequencyOfEvaluation: newFrequency.current.value,
    })

    handleClose()
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Change the Default Frequency that {userToChange.displayName} is
            evaluated for {evaluation.name}.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.moderator && (
            <p className="error">
              Must have moderator rights to change this data
            </p>
          )}

          <form className="" onSubmit={handleSubmit}>
            <label>
              <div className="d-flex">
                <span className="me-2">New Frequency (months):</span>
              </div>
              <input
                required
                ref={newFrequency}
                type="number"
                step={1}
                min="1"
                className="form-control"
              />
            </label>
          </form>
          {error && <p className="error">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isPending}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
