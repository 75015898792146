import { Text, StyleSheet, View } from "@react-pdf/renderer"
import { format, formatDistanceToNow } from "date-fns"
import { Table } from "react-bootstrap"

// Create styles
const styles = StyleSheet.create({
  reportTitle: {
    fontSize: "20pt",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  boxedSection: {
    display: "block",
    marginTop: "15px",
    marginBottom: "15px",
    padding: "5px",
    border: "2px grooved darkgray",
  },
  sectionTitle: {
    fontSize: "18pt",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  infoBlock: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "space-between",
    // border: "1px solid orange",
  },
  labelValuePair: {
    minWidth: "100ch",
    flexGrow: "1",
    marginBottom: "10px",
    // border: "1px solid red",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #ccc",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
  },
  rowLight: {},
  rowDark: { backgroundColor: "#eee" },
  header: {
    borderTop: "none",
    backgroundColor: "#ccc",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  col1: {
    width: "22%",
  },
  col2: {
    width: "10%",
  },
  col3: {
    width: "17%",
  },
  col4: {
    width: "15%",
  },
  col5: {
    width: "12%",
  },
  col6: {
    width: "9%",
  },
  col7: {
    width: "15%",
  },
})

export default function EquipmentListReportBody({ companyData, reportData }) {
  return (
    <div style={{ fontSize: "12pt", paddingBottom: "50px" }}>
      <Text style={styles.reportTitle}>Equipment List Report</Text>
      <Text>Report run on: {format(new Date(), "yyyy/MM/dd")}</Text>
      <Text>Report run for: {companyData.companyName}</Text>

      <div style={styles.boxedSection}>
        <Text style={styles.sectionTitle}>Report Options</Text>

        <View style={styles.infoBlock}>
          <Text style={styles.labelValuePair}>
            Sorted by: {reportData.options.sortBy.label}{" "}
          </Text>
          <Text style={styles.labelValuePair}>
            Order: {reportData.options.order}
          </Text>
          <Text style={styles.labelValuePair}>
            Statuses included: {reportData.options.statusesIncluded.join(", ")}
          </Text>
        </View>
      </div>

      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header]} fixed={true}>
          <Text style={styles.col1}>Apparatus</Text>
          <Text style={styles.col2}>ID</Text>
          <Text style={styles.col3}>Make</Text>
          <Text style={styles.col4}>Model</Text>
          <Text style={styles.col5}>SN</Text>
          <Text style={styles.col6}>Acquired</Text>
          <Text style={styles.col7}>Status</Text>
        </View>

        {reportData.displayData.map((row, i) => (
          <View
            key={i}
            style={
              i % 2 === 0
                ? [styles.row, styles.rowLight]
                : [styles.row, styles.rowDark]
            }
            wrap={false}
          >
            <Text style={styles.col1}>{row.type.name}</Text>
            <Text style={styles.col2}>{row.id}</Text>
            <Text style={styles.col3}>{row.make}</Text>
            <Text style={styles.col4}>{row.model}</Text>
            <Text style={styles.col5}>{row.serialNumber}</Text>
            <Text style={styles.col6}>
              {typeof row.acquisitionDate === "undefined" ||
              row.acquisitionDate === "none"
                ? ""
                : format(row.acquisitionDate.toDate(), "MM/dd/yy")}
            </Text>
            <Text style={styles.col7}>{row.status}</Text>
          </View>
        ))}
      </View>
    </div>
  )
}
