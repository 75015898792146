import React, { useMemo } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCollection } from "../../../hooks/useCollection"

import { MessageCard, useMessaging } from "../../messaging"
//COMPONENTS
import ListFilter from "../../../components/basicComponents/ListFilter"
import { useAuthContext } from "../../authentication"

const messageFilterList = [
  "all",
  "read",
  "unread",
  "equipment tag",
  "evaluation tag",
]

export default function Messages() {
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const [currentFilter, setCurrentFilter] = useState("all")
  const { documents: messageDocs } = useCollection(`users/${user.uid}/messages`)

  const { markRead, markUnread, error, isPending } = useMessaging()

  const handleGoToMessage = async (e, message) => {
    if (e.button === 2) return
    if (message.unread) await markRead({ uid: user.uid, message })
    if (e.button === 0) navigate(message.from.uid)
    if (e.button === 1) window.open(`messages/${message.from.uid}`, "_blank")
  }

  const handleMarkUnread = async (messageId, messageUnread) => {
    if (!messageUnread) await markUnread({ uid: user.uid, messageId })
  }

  const changeFilter = (newFilter) => setCurrentFilter(newFilter)

  const filteredMessages = useFilterMessages(messageDocs, currentFilter)

  return (
    <div className="page-container container">
      <h2 className="page-title">Messages</h2>
      <div className="data-list">
        <ListFilter
          filterOptions={messageFilterList}
          currentFilter={currentFilter}
          changeFilter={changeFilter}
        />

        {filteredMessages.length === 0 && (
          <p className="error">No data fitting current filters...</p>
        )}
        {/* Message data cards */}
        {filteredMessages.length > 0 &&
          filteredMessages.map((message) => (
            <React.Fragment key={message.from.uid}>
              <MessageCard
                message={message}
                handleGoToMessage={handleGoToMessage}
                handleMarkUnread={handleMarkUnread}
              />
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

const useFilterMessages = (messages, currentFilter) => {
  const [filteredData, setFilteredData] = useState([])
  //switch statement for filter
  useMemo(() => {
    let newList = []

    switch (currentFilter) {
      case "read":
        newList = messages.filter((message) => !message.unread)
        break
      case "unread":
        newList = messages.filter((message) => message.unread)
        break
      case "equipment tag":
        newList = messages.filter(
          (message) => message.messages[0].tag === "equipment"
        )
        break
      case "evaluation tag":
        newList = messages.filter(
          (message) => message.messages[0].tag === "evaluation"
        )
        break
      case "all":
      default:
        newList = messages
    }

    setFilteredData(newList)
  }, [currentFilter, messages])

  return filteredData
}
