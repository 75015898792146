import { useState } from "react"
import Select from "react-select"
import formatSelectArray from "../../../util/formatSelectArray"
import { useCompanyContext } from "../../company/hooks/useCompanyContext"
import { useAuthContext } from "../../authentication"
import { Modal, Button } from "react-bootstrap"
import { useChangeEquipment } from "../hooks/useChangeEquipment"

export default function ChangeDefaultCustodyModal({
  show,
  handleClose,
  equipment,
}) {
  const { user } = useAuthContext()
  const { companyData } = useCompanyContext()
  const { changeDefaultCustody, error, firestoreError, isPending } =
    useChangeEquipment()
  //form entry state
  const [formError, setFormError] = useState("")

  //only allow moderators, admins and primary, not users to have default custody
  const employeeList = formatSelectArray(
    companyData.employees.filter(
      (e) =>
        e.rights === "primary" ||
        e.rights === "admin" ||
        e.rights === "moderator"
    ),
    "displayName",
    companyData.employees[0]
  )
  const [newDefaultCustody, setNewDefaultCustody] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError("")
    if (!user.admin)
      return setFormError(
        "Must have admin rights to transfer equipment between users"
      )

    await changeDefaultCustody({
      companyId: companyData.companyId,
      user,
      equipment,
      uidToAssign: newDefaultCustody.value.uid,
      displayNameToAssign: newDefaultCustody.value.displayName,
    })

    handleModalClose()
  }

  const handleModalClose = () => handleClose()

  return (
    <div>
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Update Default Custody of {equipment.type.name} {equipment.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!user.admin && (
            <p className="error">
              Must have admin rights to change the default custody of equipment.
            </p>
          )}

          <form className="" id="default-custody-form" onSubmit={handleSubmit}>
            <Select
              options={employeeList}
              value={
                newDefaultCustody ? newDefaultCustody : equipment.currentCustody
              }
              onChange={(option) => setNewDefaultCustody(option)}
            />
            {error && <div className="error">{error}</div>}
            {formError && <div className="error">{formError}</div>}
            {firestoreError && <div className="error">{firestoreError}</div>}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModalClose}
            disabled={isPending}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            form="default-custody-form"
            disabled={isPending}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
