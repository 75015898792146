import { useInfoContext } from "../../hooks/useInfoContext"
import { useState, useRef } from "react"
import infoIcon from "./assets/info-icon.svg"
//styles
import "./InfoIcon.css"

export default function InfoIcon({ infoTitle }) {
  const { infoLibrary, dispatch } = useInfoContext()
  let hovering = false
  const [displayTip, setDisplayTip] = useState(false)
  const [displayToast, setDisplayToast] = useState(false)
  const toastBlock = useRef(null)
  const toastClose = useRef(null)

  const key = infoTitle.replaceAll(" ", "").toLowerCase()
  const text = infoLibrary[key]
    ? infoLibrary[key].text
    : `Info Error: Text not found for ${key}`
  const title = infoLibrary[key] ? infoLibrary[key].title : infoTitle
  const tags = infoLibrary[key] ? infoLibrary[key].tags : []

  const handleClick = (e) => {
    if (e) e.preventDefault()
    hovering = false
    setDisplayTip(false)
    setDisplayToast(true)
  }
  const handleHover = () => {
    // hover text
    setTimeout(() => {
      if (hovering) setDisplayTip(true)
      else setDisplayTip(false)
    }, 500)
  }

  const handleLeave = () => {
    hovering = false
    setDisplayTip(false)
  }

  const handleClose = (e) => {
    if (
      !toastBlock.current.contains(e.target) ||
      toastClose.current.contains(e.target)
    )
      setDisplayToast(false)
  }

  const handleHelp = () => {
    dispatch({ type: "FILTER_BY_TAG", payload: tags })
    dispatch({ type: "FLY_IN" })
  }

  return (
    <>
      <div className="info-icon">
        <img
          src={infoIcon}
          alt={`info icon for ${title}`}
          onPointerEnter={() => {
            hovering = true
            handleHover()
          }}
          onPointerLeave={() => handleLeave()}
          onTouchEnd={(e) => {
            handleClick(e)
          }}
          onClick={() => handleClick()}
        />
        {displayTip && (
          <div className="tool-tip">
            <p className="triangle"></p>
            <p>
              <span className="info-title">{title}: </span>
              {text}
            </p>
          </div>
        )}
      </div>

      {/* Toast for click/touch */}
      {displayToast && (
        <div
          className="toast-blanket"
          onClick={(e) => handleClose(e)}
          onTouchEnd={(e) => handleClose(e)}
        >
          <div className="toast-block" ref={toastBlock}>
            <div className="toast-header">
              <h3 className="info-title">{title}</h3>
              <span className="help" onClick={handleHelp}>
                help
              </span>
            </div>
            <p className="info-text">{text}</p>

            <p
              className="toast-close"
              ref={toastClose}
              onClick={(e) => handleClose(e)}
              onTouchEnd={(e) => handleClose(e)}
            >
              X Close
            </p>
          </div>
        </div>
      )}
    </>
  )
}
