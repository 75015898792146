import { AuditLandingPage } from "../../audits"
import { useCompanyContext } from "../hooks/useCompanyContext"

export default function CompanyDashboard() {
  const { isAuditView, auditId, companyData, equipment, evaluations } =
    useCompanyContext()

  if (isAuditView) {
    return <AuditLandingPage />
  }

  return (
    <div className="error">
      <p>Coming soon...</p>
      <p>
        You have found an incomplete page, this page will be coming soon to
        replace notifications.
      </p>
      <p>However, this page is available within an audit view.</p>
    </div>
  )
}
