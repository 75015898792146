import { createContext, useReducer, useEffect, useMemo } from "react"
import { useAuthContext } from "../../authentication/index"
import { useDocument } from "../../../hooks/useDocument"
import { useCollection } from "../../../hooks/useCollection"
import sortObjects from "../../../util/sortObjects"

export const UserContext = createContext()

//may need cases for messages subcollection or other subcollections that are needed later
export const userReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_USER":
      return { ...state, userIsReady: true, userData: action.payload }
    case "UPDATE_MESSAGES":
      return {
        ...state,
        messages: action.payload.messages,
        unreadMessages: action.payload.unreadMessages,
      }
    case "UPDATE_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload.notifications,
      }
    default:
      return state
  }
}

export const UserContextProvider = ({ children }) => {
  const { user } = useAuthContext()
  const [state, dispatch] = useReducer(userReducer, {
    userData: null,
    userIsReady: false,
    messages: [],
    unreadMessages: [],
    notifications: [],
  })

  const { docData: userData } = useDocument(`users/${user.uid}`)
  //also need messages docs from subcollection.
  const { documents: messages } = useCollection(`users/${user.uid}/messages`)
  const { docData: equipmentNotifications } = useDocument(
    `companies/${user.company}/equipment/equipmentNotifications`
  )
  const { docData: evaluationNotifications } = useDocument(
    `companies/${user.company}/evaluations/evaluationNotifications`
  )

  useEffect(() => {
    if (!userData) return

    dispatch({ type: "CHANGE_USER", payload: { ...userData } })
  }, [userData])

  useEffect(() => {
    if (!equipmentNotifications || !evaluationNotifications) return
    const notifications = getNotifications(
      equipmentNotifications,
      evaluationNotifications,
      user.uid
    )
    dispatch({ type: "UPDATE_NOTIFICATIONS", payload: { notifications } })
  }, [equipmentNotifications, evaluationNotifications])

  useMemo(() => {
    if (!userData) return

    const unreadMessages = messages.filter((message) => message.unread)

    dispatch({ type: "UPDATE_MESSAGES", payload: { messages, unreadMessages } })
  }, [messages])

  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      {children}
    </UserContext.Provider>
  )
}

function getNotifications(
  equipmentNotifications,
  evaluationNotifications,
  uid
) {
  const notifications = []
  Object.keys(equipmentNotifications).forEach((key) => {
    if (equipmentNotifications[key].userToNotify === uid)
      notifications.push(equipmentNotifications[key])
  })

  Object.keys(evaluationNotifications).forEach((evaluation) => {
    Object.keys(evaluationNotifications[evaluation]).forEach((key) => {
      if (key === uid && evaluationNotifications[evaluation][key])
        notifications.push(evaluationNotifications[evaluation][key])
    })
  })

  sortObjects(notifications, "createdAt", true)

  return notifications
}
