import minorLogo from "../../../assets/logo/logo_square.png"

import { useUserContext } from "../../users"
import { useAuthContext } from "../../authentication/index"
import { useCompanyContext } from "../index"
import { useInfoContext } from "../../../hooks/useInfoContext"

import { Link } from "react-router-dom"
import {
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap"
import { useLogout } from "../../authentication/index"

//styles
import "./CompanyNavbar.css"
// import { useState } from "react"
// import { determineOverdueStatus } from "../../../util/dateUtils"

export default function CompanyNavbar({ companyId }) {
  const { user } = useAuthContext()
  const { unreadMessages, notifications } = useUserContext()
  const {
    companyData,
    isEvaluationNotification,
    isEquipmentNotification,
    evaluationListDisplay,
    equipmentListDisplay,
    pendingAudits,
    activeAudits,
    completeAudits,
    rejectedAudits,
    isAuditView,
    auditId,
  } = useCompanyContext()
  const { dispatch } = useInfoContext()
  const { logout } = useLogout()

  const userHasOverdueEvaluations = checkUserHasOverdueEvaluations({
    evaluations: evaluationListDisplay,
    uid: user.uid,
  })

  const userHasOverdueEquipment = checkUserHasOverdueEquipment({
    equipment: equipmentListDisplay,
    uid: user.uid,
  })

  //   console.log(isEvaluationNotification)
  //   console.log(userHasOverdueEvalustions)

  const hasAudits =
    pendingAudits.length !== 0 ||
    activeAudits.length !== 0 ||
    completeAudits.length !== 0 ||
    rejectedAudits.length !== 0

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="app-nav align-items-baseline flex-nowrap"
    >
      <Navbar.Brand>
        <div className="navbar-brand">
          <Link
            to={
              isAuditView
                ? `/lab-audit/${auditId}/lab-company/dashboard`
                : `/company/${companyId}`
            }
            className="navbar-brand"
          >
            {companyData.navLogoURL && (
              <img
                className="nav-logo-img"
                src={companyData.navLogoURL}
                alt={companyData.companyName}
              />
            )}
            {(!companyData.navLogoURL ||
              typeof companyData.navLogoURL === "undefined") && (
              <h1 className="nav-logo-img">{companyData.companyName}</h1>
            )}
          </Link>
        </div>
      </Navbar.Brand>

      <div className="logout ms-auto">
        <span
          className="clickable color-white text-decoration-underline fs-6 me-3"
          onClick={() => dispatch({ type: "FLY_IN" })}
        >
          help
        </span>
        <button className="btn text-bg-primary align-self-top" onClick={logout}>
          Logout
        </button>
        <Link to="/" className="minor-brand me-3">
          <img src={minorLogo} alt="eLab Tracker" />
        </Link>
      </div>

      <div className="main-nav-link-container text-left">
        <Navbar.Toggle className="me-4" />
        <span
          className="d-md-none clickable color-white text-decoration-underline fs-6 me-3"
          onClick={() => dispatch({ type: "FLY_IN" })}
        >
          help
        </span>
        <Navbar.Collapse className="text-end me-4 rounded-3">
          <Nav className="ms-auto d-flex align-items-baseline">
            {/* if this is an audit view, add audit options */}
            {isAuditView && (
              <div
                className="nav-link d-flex align-items-baseline"
                data-notification={false}
              >
                <NavDropdown title="Audit" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to={`dashboard`}>
                    Audit Dashboard
                  </NavDropdown.Item>
                  {user.company === companyId && (
                    <NavDropdown.Item as={Link} to={`lab-controls`}>
                      Lab Audit Controls
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </div>
            )}

            {/* **** User Account Nav **** */}
            {!isAuditView && (
              <div
                className="nav-link d-flex align-items-baseline"
                data-notification={
                  unreadMessages.length > 0 ||
                  notifications.some((n) => !n.seen) ||
                  userHasOverdueEvaluations ||
                  userHasOverdueEquipment
                }
              >
                <NavDropdown title="My Account" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to={`user/${user.uid}`}>
                    My Details
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={`user/${user.uid}/equipment`}
                    data-notification={userHasOverdueEquipment}
                  >
                    My Equipment
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={`user/${user.uid}/evaluations`}
                    data-notification={userHasOverdueEvaluations}
                  >
                    My Evaluations
                  </NavDropdown.Item>
                  {companyData.messaging && (
                    <NavDropdown.Item
                      as={Link}
                      to={`messages`}
                      data-notification={unreadMessages.length > 0}
                    >
                      Messaging
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item
                    as={Link}
                    to={`notifications`}
                    data-notification={notifications.some((n) => !n.seen)}
                  >
                    Notifications
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            )}

            {/* Equipment/Asset Nav */}
            <div
              className="nav-link d-flex align-items-baseline"
              data-notification={isEquipmentNotification}
            >
              <NavDropdown title="Equipment" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to={`company-equipment/types`}
                  data-notification={isEquipmentNotification}
                >
                  Types of Equipment/Asset
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={`company-equipment`}>
                  Equipment/Asset List
                </NavDropdown.Item>

                <NavDropdown.Divider />

                {/* may not keep this page in the navbar if there aren't too many equipment reports, but it should still exist in the routing */}
                <NavDropdown.Item as={Link} to={`company-equipment/reports`}>
                  Asset Reports
                </NavDropdown.Item>

                {/* Will likely move to this submenu or just make these links in the main dropdown depending how many reports there are. */}
                {/* <DropdownButton
                  as={ButtonGroup}
                  drop={"end"}
                  title="Select Report"
                >
                  <NavDropdown.Item as={Link} to={`company-equipment/reports`}>
                    Reports list
                  </NavDropdown.Item>
                </DropdownButton> */}
              </NavDropdown>
            </div>

            {/* Staff Nav */}
            <div
              className="nav-link d-flex align-items-baseline"
              data-notification={isEvaluationNotification}
            >
              <NavDropdown title="Staff" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to={`staff`}>
                  Staff List
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={`company-evaluations`}
                  data-notification={isEvaluationNotification}
                >
                  Evaluations
                </NavDropdown.Item>
              </NavDropdown>
            </div>

            {/* This maybe should be a Dropdown with different types of docs */}
            {/* Company Nav no need if there aren't audits or the company has no additional docs and the user is not an admin */}
            {(companyData.additionalDocuments ||
              hasAudits ||
              (user && user.admin)) && (
              <div
                className="nav-link d-flex align-items-baseline"
                data-notification={pendingAudits.length !== 0}
              >
                <NavDropdown title="Company" id="basic-nav-dropdown">
                  {companyData.additionalDocuments && (
                    <NavDropdown.Item as={Link} to={`additional-docs`}>
                      Doc Store
                    </NavDropdown.Item>
                  )}
                  {hasAudits && !isAuditView && (
                    <NavDropdown.Item
                      as={Link}
                      to={`audits`}
                      data-notification={
                        pendingAudits.length !== 0 && user.admin && !isAuditView
                      }
                    >
                      Audits
                    </NavDropdown.Item>
                  )}
                  {user && user.admin && !isAuditView && (
                    <NavDropdown.Item as={Link} to={`company-settings`}>
                      Settings
                    </NavDropdown.Item>
                  )}
                  {user && user.primary && !isAuditView && (
                    <NavDropdown.Item as={Link} to={`billing`}>
                      Billing
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              </div>
            )}

            <button
              className="d-md-none btn mb-0 ms-auto text-white"
              onClick={logout}
            >
              Logout
            </button>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

const checkUserHasOverdueEvaluations = ({ evaluations, uid }) => {
  return evaluations.some((e) => {
    let trigger = false
    Object.keys(e).forEach((key) => {
      if (key !== uid) return
      trigger = e[key].notification
    })
    return trigger
  })
}

const checkUserHasOverdueEquipment = ({ equipment, uid }) => {
  return equipment.some((e) => e.currentCustody.uid === uid && e.notification)
}
