import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../../authentication/index"

//layout
import ContentOptionalRight from "../../../../components/layouts/ContentOptionalRight"
//components
import { AddEquipmentTypeModal } from "../../../equipment/index"
import sortObjects from "../../../../util/sortObjects"
import { Button } from "react-bootstrap"
import EquipmentTypeList from "../../../equipment/components/EquipmentTypeList"

export default function LabView() {
  const { companyId } = useParams()
  const { user } = useAuthContext()

  //search the equipment
  const [searchTerm, setSearchTerm] = useState("")

  //modal control
  const [showAddTypeModal, setShowAddTypeModal] = useState(false)

  const addTypeModalShow = () => setShowAddTypeModal(true)
  const addTypeModalHide = () => setShowAddTypeModal(false)

  return (
    <>
      <ContentOptionalRight
        contentTitle="Equipment/Asset Types"
        content={
          <>
            {user.moderator && (
              <Button
                className="btn btn-sm ms-4 text-bg-primary"
                onClick={addTypeModalShow}
                style={{ height: "2.5em" }}
              >
                Add Asset Type
              </Button>
            )}
            <input
              className="ms-5 mb-2"
              placeholder="Search by name, id prefix, or category..."
              style={{ height: "2.5em", width: "40%" }}
              type="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <EquipmentTypeList searchTerm={searchTerm} />
          </>
        }
      />

      {/* Modals */}
      <AddEquipmentTypeModal
        show={showAddTypeModal}
        handleClose={addTypeModalHide}
        companyId={companyId}
      />
    </>
  )
}
