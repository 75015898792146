import React from "react"
import ReactDOM from "react-dom/client"

import App from "./App"

import { AuthContextProvider } from "./features/authentication/index"
import { InfoContextProvider } from "./context/InfoContext"

import "./index.css"

const rootElement = document.getElementById("root")
const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <InfoContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </InfoContextProvider>
  </React.StrictMode>
)
