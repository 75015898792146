import { createContext, useReducer, useEffect, useMemo } from "react"
import { useAuthContext } from "../../authentication/index"
import { useDocument } from "../../../hooks/useDocument"
import { useCollection } from "../../../hooks/useCollection"

export const AuditorUserContext = createContext()

//may need cases for messages subcollection or other subcollections that are needed later
export const auditorUserReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_USER":
      return { ...state, userIsReady: true, userData: action.payload }
    // case "UPDATE_MESSAGES":
    //   return {
    //     ...state,
    //     messages: action.payload.messages,
    //     unreadMessages: action.payload.unreadMessages,
    //   }
    default:
      return state
  }
}

export const AuditorUserContextProvider = ({ children }) => {
  const { user } = useAuthContext()
  const [state, dispatch] = useReducer(auditorUserReducer, {
    userData: null,
    userIsReady: false,
    // messages: [],
    // unreadMessages: [],
  })

  const { docData: userData } = useDocument(`users/${user.uid}`)
  //also need messages docs from subcollection.
  //   const { documents: messages } = useCollection(`users/${user.uid}/messages`)

  useEffect(() => {
    if (!userData) return

    dispatch({ type: "CHANGE_USER", payload: { ...userData } })
  }, [userData])

  //   useMemo(() => {
  //     if (!userData) return

  //     const unreadMessages = messages.filter((message) => message.unread)

  //     dispatch({ type: "UPDATE_MESSAGES", payload: { messages, unreadMessages } })
  //   }, [messages])

  return (
    <AuditorUserContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuditorUserContext.Provider>
  )
}
