import React from "react"
import { useCompanyContext } from "../../hooks/useCompanyContext"
import LabView from "./LabView"
import AuditView from "./AuditView"

export default function EmployeeList({ searchBy }) {
  const { isAuditView } = useCompanyContext()

  if (isAuditView) return <AuditView searchBy={searchBy} />

  return <LabView searchBy={searchBy} />
}
