import { useState, useEffect } from "react"
import { useFirestore } from "../../../hooks/useFirestore"

export function useInvoicing() {
  const [error, setError] = useState("")
  const [isPending, setIsPending] = useState(false)

  const {
    error: firestoreError,
    isPdending: firestorePending,
    getDocuments,
  } = useFirestore()
  //const addInvoice = httpsCallable(functions, "addInvoice")

  useError({ setError, firestoreError })
  usePending({ setIsPending, firestorePending })

  const getInvoices = async ({ companyId }) => {
    setError("")
    setIsPending(true)

    const invoices = await getDocuments("invoices", [
      "companyId",
      "==",
      companyId,
    ])

    setIsPending(false)
    // console.log(invoices)
    return error ? [] : invoices
  }

  return { error, isPending, getInvoices }
}

const useError = ({ setError, firestoreError }) => {
  useEffect(() => {
    setError(firestoreError)
  }, [firestoreError])
}

const usePending = ({ setIsPending, firestorePending }) => {
  useEffect(() => {
    setIsPending(firestorePending)
  }, [firestorePending])
}
