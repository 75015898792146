import { serverTimestamp } from "firebase/firestore"
import { useFirestore } from "../../../hooks/useFirestore"
import { useAuthContext } from "../../authentication"
import { v4 as uuidv4 } from "uuid"

export const useAuditStatus = () => {
  const {
    setDocument,
    getDocument,
    deleteDocument,
    updateDocument,
    error,
    isPending,
  } = useFirestore()
  const { user } = useAuthContext()

  //   console.log(uuidv4())

  const createAuditRequest = async ({
    auditingCompany,
    labCompany,
    standard,
  }) => {
    const docId = uuidv4()

    const firestoreObject = {
      auditingCompanyId: auditingCompany.id,
      labCompanyId: labCompany.id,
      auditingCompany,
      labCompany,
      auditStandard: standard,
      requestedBy: {
        email: user.email,
        uid: user.uid,
        displayName: user.displayName,
      },
      auditorsPermitted: [
        { email: user.email, uid: user.uid, displayName: user.displayName },
      ],
      uidsPermitted: [user.uid, labCompany.primaryAccount.uid],
      labStaffPermitted: [labCompany.primaryAccount],
      requestedAt: serverTimestamp(),
      status: "pending",
      id: docId,
      updatedLast: serverTimestamp(),
    }

    //add audit document to firestore
    await setDocument(`audits/${docId}`, firestoreObject)
    //needs to send an email - should be a cloud function

    if (error) return { error, docId: null, success: false }

    return { success: true, docId, error: null }
  }

  const acceptPendingAudit = async ({ auditId }) => {
    //get the audit data
    const auditData = await getDocument(`audits/${auditId}`)
    if (error)
      return {
        success: false,
        errorMessage: `could not retrieve audit data. Database says: ${error}`,
        message: "error fetching audit document from firestore.",
      }

    //check permissions - can only accept if the user's uid matches a labStaffPermitted uid from the audit data
    const userInLabStaff = auditData.labStaffPermitted.find(
      (staff) => staff.uid === user.uid && user.admin
    )
    if (typeof userInLabStaff === "undefined")
      return {
        success: false,
        errorMessage:
          "You are not permitted to accept this audit on the lab's behalf. Check with your lab's primary account or another permitted admin to accept the request",
        message:
          "The lab has not given this user permission to accept the request.",
      }

    //change status to active and add acceptedBy/At data
    const auditUpdateObject = {
      acceptedBy: {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      },
      acceptedAt: serverTimestamp(),
      status: "active",
      updatedLast: serverTimestamp(),
    }

    await updateDocument(`audits/${auditId}`, auditUpdateObject)
    if (error)
      return {
        success: false,
        errorMessage: `Could not mark the audit as accepted. Server says: ${error}`,
        message: "Could not update the audit document in firestore.",
      }

    return {
      success: true,
      errorMessage: null,
      message: "successfully accepted the audit request.",
    }
  }

  const changeAuditStatusToOnHold = async ({ auditId }) => {
    //get the audit data
    const auditData = await getDocument(`audits/${auditId}`)
    if (error)
      return {
        success: false,
        errorMessage: `could not retrieve audit data. Database says: ${error}`,
        message: "error fetching audit document from firestore.",
      }

    //check permissions - can only accept if the user's uid matches a labStaffPermitted uid from the audit data
    const userInLabStaff = auditData.labStaffPermitted.find(
      (staff) => staff.uid === user.uid && user.admin
    )
    if (typeof userInLabStaff === "undefined")
      return {
        success: false,
        errorMessage:
          "You are not permitted to put the audit on hold on the lab's behalf. Check with your lab's primary account or another permitted admin to accept the request",
        message:
          "The lab has not given this user permission to accept the request.",
      }

    //change status to active and add acceptedBy/At data
    const auditUpdateObject = {
      status: "on hold",
      updatedLast: serverTimestamp(),
    }

    await updateDocument(`audits/${auditId}`, auditUpdateObject)
    if (error)
      return {
        success: false,
        errorMessage: `Could not mark the audit as on hold. Server says: ${error}`,
        message: "Could not update the audit document in firestore.",
      }

    return {
      success: true,
      errorMessage: null,
      message: "successfully placed the audit on hold.",
    }
  }

  const changeAuditStatusToComplete = async ({ auditId }) => {
    //get the audit data
    const auditData = await getDocument(`audits/${auditId}`)
    if (error)
      return {
        success: false,
        errorMessage: `could not retrieve audit data. Database says: ${error}`,
        message: "error fetching audit document from firestore.",
      }

    //check permissions - can only change if the user is the one that requested the audit or isAuditAdmin
    if (user.uid !== auditData.requestedBy.uid && !user.isAuditingAdmin) {
      return {
        success: false,
        errorMessage:
          "You are not permitted to mark the audit as complete. Only an auditing admin or the audotir that requested the audit can mark audits as complete",
        message:
          "The auditing company has not given this user permission to mark the audit as completed.",
      }
    }

    //change status to active and add acceptedBy/At data
    const auditUpdateObject = {
      status: "complete",
      markedCompletedBy: { displayName: user.displayName, uid: user.uid },
      markedCompletedAt: serverTimestamp(),
      updatedLast: serverTimestamp(),
    }

    await updateDocument(`audits/${auditId}`, auditUpdateObject)
    if (error)
      return {
        success: false,
        errorMessage: `Could not mark the audit as complete. Server says: ${error}`,
        message: "Could not update the audit document in firestore.",
      }

    return {
      success: true,
      errorMessage: null,
      message: "successfully marked the audit as completed.",
    }
  }

  const rejectPendingAudit = async ({ auditId }) => {
    //get the audit data
    const auditData = await getDocument(`audits/${auditId}`)
    if (error)
      return {
        success: false,
        errorMessage: `could not retrieve audit data. Database says: ${error}`,
        message: "error fetching audit document from firestore.",
      }

    //check permissions - can only accept if the user's uid matches a labStaffPermitted uid from the audit data
    const userInLabStaff = auditData.labStaffPermitted.find(
      (staff) => staff.uid === user.uid && user.admin
    )
    if (typeof userInLabStaff === "undefined")
      return {
        success: false,
        errorMessage:
          "You are not permitted to reject this audit on the lab's behalf. Check with your lab's primary account or another permitted admin to reject the request",
        message:
          "The lab has not given this user permission to accept the request.",
      }

    //change status to active and add acceptedBy/At data
    const auditUpdateObject = {
      rejectedBy: {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      },
      rejectedAt: serverTimestamp(),
      status: "rejected",
      updatedLast: serverTimestamp(),
    }

    await updateDocument(`audits/${auditId}`, auditUpdateObject)
    if (error)
      return {
        success: false,
        errorMessage: `Could not mark the audit as rejected. Server says: ${error}`,
        message: "Could not update the audit document in firestore.",
      }

    return {
      success: true,
      errorMessage: null,
      message: "successfully accepted the audit request.",
    }
  }

  const deletePendingAudit = async ({ auditId }) => {
    const auditToDelete = await getDocument(`audits/${auditId}`)

    if (auditToDelete.status !== "pending")
      return {
        success: false,
        errorMessage:
          "The audit you attempted to delete does not have a pending status. You cannot delete an sudit that has been accepted, rejected, or completed. Please contact eLab Tracker if this deletion is needed. The lab company must also confirm the mistake.",
      }

    await deleteDocument(`audits/${auditId}`)

    if (error) return { success: false, errorMessage: error }
    return {
      success: true,
      message: "Successfully deleted the penging audit",
      errorMessage: null,
    }
  }

  return {
    error,
    isPending,
    createAuditRequest,
    acceptPendingAudit,
    changeAuditStatusToOnHold,
    changeAuditStatusToComplete,
    rejectPendingAudit,
    deletePendingAudit,
  }
}
