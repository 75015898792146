//uses bootstrap classes
//content for most basic pages within the company route (see company/index.js)
//right cotent is optional

//styles
import "./layout.css"

export default function ContentOptionalRight({
  content,
  contentTitle,
  right,
  rightTitle,
}) {
  let mainClassNames = "flex-grow-1 main"
  if (right) mainClassNames += " has-side-content"
  return (
    <div className="page-container container d-flex flex-wrap align-items-start justify-content-around gap-2">
      <div className={mainClassNames}>
        {contentTitle && <h2 className="page-title mb-3">{contentTitle}</h2>}
        {content}
      </div>

      {right && (
        <div className="side-data flex-md-grow-1 text-center">
          {rightTitle && <h3 className="section-title">{rightTitle}</h3>}
          {right}
        </div>
      )}
    </div>
  )
}
