import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

export default function AccessDenied() {
  const { companyId, auditId } = useParams()
  const isAuditView = typeof auditId !== "undefined"
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      //  will actually need this to go to my.elabtracker.com
      if (!isAuditView) navigate(`/company/${companyId}`)
      else navigate(`/lab-audit/${auditId}`)
    }, 3000)
  }, [])

  return (
    <div>
      <h1>Access Denied....</h1>
      <p className="error">
        You do not have access to this page, please contact your Adminitstrator
        or Primary Account Holder for access
      </p>
      <p>You will be redirected in 3 seconds...</p>
    </div>
  )
}
