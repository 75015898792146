import { useEffect, useState } from "react"
import sortObjects from "../../../util/sortObjects"

const useSortEquipmentList = (data, sortBy, sortAsc) => {
  const [sortedData, setSortedData] = useState([])
  useEffect(() => {
    let newList = []
    if (data) {
      const dataToSort = data.map((item) => {
        if (typeof item.id === "undefined") return item

        let idComponents = item.id.split("-")
        let pieceId = Number(idComponents[idComponents.length - 1])

        return { ...item, pieceId }
      })

      //if sorting id, need to sort by prefix (type), then pieceId
      if (sortBy === "id") {
        const segregatedIntoTypes = {}

        dataToSort.forEach((item) => {
          if (typeof segregatedIntoTypes[item.typePrefix] === "undefined")
            segregatedIntoTypes[item.typePrefix] = []

          segregatedIntoTypes[item.typePrefix].push(item)
        })

        //getting order arrays should be in
        const typeOrder = Object.keys(segregatedIntoTypes).sort((x, y) => {
          if (sortAsc) {
            if (x.toLowerCase() < y.toLowerCase()) {
              return -1
            }
            if (x.toLowerCase() > y.toLowerCase()) {
              return 1
            }
          } else {
            if (x.toLowerCase() > y.toLowerCase()) {
              return -1
            }
            if (x.toLowerCase() < y.toLowerCase()) {
              return 1
            }
          }
          return 0
        })

        //sorted order
        typeOrder.forEach((type) => {
          newList.push(
            ...sortObjects(segregatedIntoTypes[type], "pieceId", sortAsc)
          )
        })
      } else if (sortBy === "nextCalibration") {
        let listThatRequiresCalibration = sortObjects(
          dataToSort.filter(
            (eq) =>
              typeof eq.type.calibrationRequired === "undefined" ||
              eq.type.calibrationRequired
          ),
          sortBy,
          sortAsc
        )
        let listNotRequiresCalibration = sortObjects(
          dataToSort.filter(
            (eq) =>
              typeof eq.type.calibrationRequired !== "undefined" &&
              !eq.type.calibrationRequired
          ),
          "typePrefix",
          sortAsc
        )

        newList = [
          ...listThatRequiresCalibration,
          ...listNotRequiresCalibration,
        ]
      } else newList = sortObjects(dataToSort, sortBy, sortAsc)

      setSortedData(newList)
    }
  }, [data, sortBy, sortAsc])

  return sortedData
}

const useSortEquipmentTypeList = ({ equipmentTypes, sortBy, sortAsc }) => {
  const [sortedData, setSortedData] = useState([])
  useEffect(() => {
    let newList = equipmentTypes
    if (newList) {
      newList = sortObjects(
        newList,
        sortBy.toLowerCase() === "id" ? "prefix" : sortBy,
        sortAsc
      )

      setSortedData(newList)
    }
  }, [equipmentTypes, sortBy, sortAsc])

  return sortedData
}

export { useSortEquipmentList, useSortEquipmentTypeList }
