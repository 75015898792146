import { useNavigate } from "react-router-dom"

//components
import { Login } from "../features/authentication/index"

//styles
import "./Home.css"
import FlipCard from "../components/FlipCard"

export default function Home() {
  const navigate = useNavigate()

  const flipCards = getProblemsAndSolutionCards()

  return (
    <div className="page-container container m-auto d-flex flex-wrap justify-content-around flex-row-reverse gap-1 text-center pl-2 pr-2 pt-3 pb-3">
      <div className="home-right-section">
        <div className="login">
          <Login placeholder={true} />
        </div>
        <div className="calls-to-action">
          <section className="call-to-action">
            <p>To get started or explore a sandbox:</p>
            <button
              className="btn text-bg-primary fs-5"
              onClick={() => navigate("/account-setup")}
            >
              Setup a Lab Account
            </button>
          </section>

          <section className="call-to-action">
            <p>Need to audit someone who uses eLab Tracker?</p>
            <button
              className="btn text-bg-primary fs-5"
              onClick={() => navigate("/auditor-account-setup")}
            >
              Register as an auditor
            </button>
          </section>
        </div>
      </div>

      <div className="home-left-section">
        <div className="mission">
          <h2 className="section-title fw-bold">Mission</h2>
          <p>
            {" "}
            To make your lab’s industry compliance easy and to provide a
            comprehensive solution for your lab to succeed during audits and
            acquire or maintain your accreditations and certifications.
          </p>
        </div>

        <div className="features">
          <h1 className="section-title fw-bold fs-2">Features</h1>
          <ul className="feature-list">
            <li className="feature-list-item">
              <h3 className="feature-title">Equipment Inventory List</h3>
              <p className="feature-text">
                All of your equipment and equipment information including a log
                of all status changes, calibrations, maintenance, and
                change-of-hand events.{" "}
              </p>
            </li>

            <li className="feature-list-item">
              <h3 className="feature-title">Calibration Schedule</h3>
              <p className="feature-text">
                Available to all users. Get notifications and see which
                equipment has upcoming or overdue calibrations.
              </p>
            </li>

            <li className="feature-list-item">
              <h3 className="feature-title">Employee Evaluation Schedule</h3>
              <p className="feature-text">
                Keep a record and schedule of your employee evaluations on test
                procedures and standards and get notified when one is upcoming
                or due.
              </p>
            </li>

            <li className="feature-list-item">
              <h3 className="feature-title">Still Innovating</h3>
              <p className="feature-text">
                More features are being added to eLab Tracker as we operate. You
                can see the current plans and proposed plans for new feature
                roll-outs in the About section of the website.
              </p>
            </li>

            <li className="feature-list-item">
              <h3 className="feature-title">Document Store</h3>
              <i>(*Advanced tier)</i>
              <p className="feature-text">
                Store all of your QMS manual documents. This document store also
                has version control which keeps track of all document revisions.
              </p>
            </li>

            <li className="feature-list-item">
              <h3 className="feature-title">Employee Evaluation Schedule</h3>
              <i>(*Advanced tier)</i>
              <p className="feature-text">
                Employees within your company can message each other regarding
                equipment, evaluations, or other matters important to your lab’s
                success.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="solutions">
        {flipCards.map((flipCard) => (
          <FlipCard
            key={flipCard.id}
            front={flipCard.front}
            back={flipCard.back}
          />
        ))}
      </div>
    </div>
  )
}

const getProblemsAndSolutionCards = () => {
  return [
    {
      front: (
        <>
          <h2>Problem</h2>
          <p>
            Access to your lab manual is limited to a physical copy or a cloud
            location with other internally sensitive data.
          </p>
        </>
      ),
      back: (
        <>
          <h2>Solution</h2>
          <p>Provide access to everyone who needs it anywhere they need it.</p>
        </>
      ),
      id: 1,
    },
    {
      front: (
        <>
          <h2>Problem</h2>
          <p>
            Missing equipment calibrations that are required to take place at a
            specific frequency to maintain your accreditations.
          </p>
        </>
      ),
      back: (
        <>
          <h2>Solution</h2>
          <p>
            Notifications for upcoming and overdue calibrations to those that
            hold that particular piece of equipment as well as to the manager
            with ultimate responsibility.
          </p>
        </>
      ),
      id: 2,
    },
    {
      front: (
        <>
          <h2>Problem</h2>
          <p>
            Inconsistant access to and misinformation spread of your labs
            procedures leading to technician and managers inconsistent solutions
            to problems and inconsistant test practices.
          </p>
        </>
      ),
      back: (
        <>
          <h2>Solution</h2>
          <p>
            Provides access to the latest version of your published procedures
            to all employees ensuring a decrease in misinformation and an
            increase in your lab's standardization.
          </p>
        </>
      ),
      id: 2,
    },
  ]
}
