//hooks
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useCompanyContext } from "../../company"
import { useAuthContext } from "../../authentication"
import { useAddUsersToAudit } from "../hooks/useAddUsersToAudit"
import { useDocument } from "../../../hooks/useDocument"
//util
import formatSelectArray from "../../../util/formatSelectArray"
//components
import ContentOptionalRight from "../../../components/layouts/ContentOptionalRight"
import { Button } from "react-bootstrap"
import Select from "react-select"
import { AuditStatusLabControls } from "../components/AuditStatusControls"

// Eventually the goal is for this to be the page where the lab admin controls what types of equipment and evaluations are shown and all of that.
// FOr now just a place to add a user to access the audit.
export default function LabControls({}) {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const {
    isAuditView,
    auditId,
    companyData,
    companyIsReady,
    equipmentTypes,
    evaluationListDisplay,
  } = useCompanyContext()
  console.log(companyData)

  const { docData: auditData } = useDocument(`audits/${auditId}`)

  const handleNavigateToAudit = () =>
    navigate(`/lab-audit/${auditData.id}/lab-company/${auditData.labCompanyId}`)

  //add employee control
  const {
    error: addAuditorError,
    isPending: addAuditorPending,
    addLabRep,
  } = useAddUsersToAudit()
  const [showLabStaffOptions, setShowLabStaffOptions] = useState(false)
  const [labStaffOptions, setLabStaffOptions] = useState([])
  const [selectedLabStaffOption, setSelectedLabStaffOption] = useState(null)

  const handleAddLabStaff = async () => {
    if (!selectedLabStaffOption) alert("no account selected")

    const { success, error, response } = await addLabRep({
      auditId: auditId,
      userToAdd: selectedLabStaffOption,
    })
    if (!success) return alert(error)

    // reset
    setSelectedLabStaffOption(null)
    setShowLabStaffOptions(false)
  }

  useEffect(() => {
    if (auditData && companyIsReady) {
      const employees = companyData.employees.filter((e) => {
        return (
          e.uid !== user.uid &&
          !auditData.labStaffPermitted.some((l) => l.uid === e.uid)
        )
      })

      const formattedEmployees = formatSelectArray(
        employees,
        "displayName",
        employees[0]
      )
      setLabStaffOptions(formattedEmployees)
    }
  }, [auditData, companyIsReady])

  if (!companyIsReady || !auditData)
    return <p className="loading">...loading</p>

  return (
    <ContentOptionalRight
      contentTitle="Lab Controls"
      content={
        <div>
          <p>
            Coming soon - Control for what equipment types and evaluations are
            shown in the audit views.
          </p>
          <div className="d-flex gap-3 mt-3">
            <Link className="btn btn-sm text-bg-primary" to="../">
              Go to Audit Dashboard
            </Link>
          </div>
          <hr />
          {/* access controls */}
          <div className="col">
            <h3>Lab Reps with access</h3>
            <ul>
              {auditData.labStaffPermitted.map((labRep) => (
                <li key={labRep.uid}>{labRep.displayName}</li>
              ))}
            </ul>
            {!showLabStaffOptions && (
              <Button
                className="btn-sm mt-3"
                onClick={() => setShowLabStaffOptions(true)}
              >
                Add Lab Rep
              </Button>
            )}

            {showLabStaffOptions && (
              <div>
                {labStaffOptions.length > 0 && (
                  <>
                    <Select
                      options={labStaffOptions}
                      onChange={(option) =>
                        setSelectedLabStaffOption(option.value)
                      }
                    />
                    <div className="d-flex align-items-baseline">
                      <Button
                        className="btn-sm mt-3"
                        onClick={handleAddLabStaff}
                        disabled={!selectedLabStaffOption}
                      >
                        Add Lab Rep
                      </Button>
                    </div>
                  </>
                )}{" "}
                <p
                  className="clickable text-decoration-underline text-muted ms-4"
                  onClick={() => setShowLabStaffOptions(false)}
                >
                  cancel
                </p>
                {labStaffOptions.length === 0 && (
                  <p className="error">no options available</p>
                )}
              </div>
            )}
          </div>

          {/* AuditStatus Controls */}
          <AuditStatusLabControls
            auditStatus={auditData.status}
            auditId={auditData.id}
            handleNavigateToAudit={handleNavigateToAudit}
          />
        </div>
      }
    />
  )
}
